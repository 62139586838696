import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./style.scss";
import DaysSince from "../DaysSince";
import LocaleAwareText from "../LocaleAwareText";
import AsyncImg from "../AsyncImg";
import { withRouter } from "react-router";

const LogoText = props => {
  const {
    theme,
    name,
    date,
    locale,
    text,
    subText,
    black,
    blackTitle,
    img,
    withTime,
    fallbackImage,
    isNameClickable,
    history
  } = props;

  return (
    <div
      className={
        isNameClickable ? "photo-text-container cursor" : "photo-text-container"
      }
      onClick={() =>
        isNameClickable && history.push(`/users/${isNameClickable}`)
      }
    >
      <AsyncImg
        alt="SourceImg"
        img={img}
        style={{
          borderColor: theme.accentColor
        }}
        onError={e => (fallbackImage ? (e.target.src = fallbackImage) : null)}
      />

      <div
        className={locale.ltr ? "text-Container ltr" : "text-Container"}
        style={{ borderColor: theme.accentColor }}
      >
        <div className="title-text-container">
          <p style={{ color: blackTitle ? null : theme.primary }}>{name}</p>
        </div>
        {date && (
          <div className="second-text">
            <DaysSince
              style={{ color: theme.textGray }}
              startDate={date}
              withTime={withTime}
            />
          </div>
        )}
        {text && (
          <LocaleAwareText>
            <p
              className="second-text"
              style={{ color: black ? null : theme.textGray }}
            >
              {text}
            </p>
          </LocaleAwareText>
        )}
        {subText && (
          <p
            className="second-text"
            style={{ color: black ? null : theme.textGray }}
          >
            {subText}
          </p>
        )}
      </div>
    </div>
  );
};

LogoText.propTypes = {
  theme: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    locale: state.locale
  };
};

export default connect(mapStateToProps, null)(withRouter(LogoText));
