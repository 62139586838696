import React, { Component } from "react";
import PropTypes from "prop-types";
import MainDatePicker, { registerLocale } from "react-datepicker";
import ar from "date-fns/locale/ar-SA";
import { DatePickerStyle, DatePickerContainerStyle } from "./DatePickerStyle";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "formik";
import moment from "moment";
import "./style.scss";

registerLocale("ar", ar);

class DatePicker extends Component {
  handleChange = value => {
    this.props.formik.setFieldValue(this.props.name, value);

    this.props.formik.setFieldTouched(this.props.name, true);
  };

  componentDidMount() {
    this.props.formik.registerField(this.props.name, this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.name !== prevProps.name) {
      this.props.formik.unregisterField(prevProps.name);
      this.props.formik.registerField(this.props.name, this);
    }

    if (this.props.validate !== prevProps.validate) {
      this.props.formik.registerField(this.props.name, this);
    }
  }

  componentWillUnmount() {
    this.props.formik.unregisterField(this.props.name);
  }

  render() {
    const {
      name,
      formik,
      placeholder,
      time,
      timeOnly,
      minDate,
      maxDate,
      excludeDates,
      errors,
      isClearableDate
    } = this.props;

    const dateNow = new Date().setHours(0, 0, 0, 0);
    let value = new Date().setHours(0, 0, 0, 0);
    if (this.props.formik.values[name]) {
      value = new Date(this.props.formik.values[name]).setHours(0, 0, 0, 0);
    } else if (this.props.formik.touched[name]) {
      value = "";
    }

    return (
      <div
        style={{
          borderColor: errors
            ? this.props.theme.errorColor
            : this.props.theme.accentColor
        }}
        className={DatePickerContainerStyle(moment.locale())}
      >
        <MainDatePicker
          timeIntervals={15}
          name={name}
          locale={moment.locale()}
          className="datePicker"
          calendarClassName={DatePickerStyle(this.props.theme, moment.locale())}
          selected={formik.values[name]}
          showTimeSelect={time}
          showTimeSelectOnly={timeOnly}
          placeholderText={placeholder}
          startDate={new Date()}
          onChange={this.handleChange}
          onBlur={formik.onBlur}
          minDate={minDate ? minDate : null}
          maxDate={maxDate ? maxDate : null}
          minTime={
            new Date(dateNow).getTime() === new Date(value).getTime()
              ? Date.now()
              : null
          }
          maxTime={
            new Date(dateNow).getTime() === new Date(value).getTime()
              ? moment()
                  .endOf("day")
                  .toDate()
              : null
          }
          excludeDates={excludeDates ? [excludeDates] : null}
          dateFormat={time ? "dd -MMMM- yyyy  hh:mm a" : "dd - MMMM - yyyy"}
          isClearable={formik.values[name] && isClearableDate}
        />
      </div>
    );
  }
}

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired
};

export default connect(DatePicker);
