import React, * as react from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SideNavHeader from "../SideNavHeader";
import SmallCalenderContainer from "../SmallCalenderContainer";
import UpcomingMeeting from "../UpcomingMeeting";
import SideNavBarChartContainer from "../SideNavBarChartContainer";
import {
  calenderData,
  fetchUpcomingMeetings,
  fetchTaskChart
} from "../../actions";

import "./style.scss";

class EventsSideNav extends react.Component {
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, false);
    this.props.calenderData();
    this.props.fetchUpcomingMeetings();
    this.props.fetchTaskChart();
  }
  render() {
    const { locale } = this.props;
    return (
      <nav className="left-side-nav-container">
        <SideNavHeader loacal={locale} />
        <div className="card hidden">
          <SmallCalenderContainer loacal={locale} />
        </div>
        <UpcomingMeeting loacal={locale} />

        <SideNavBarChartContainer />
      </nav>
    );
  }
}

EventsSideNav.propTypes = {
  intl: PropTypes.object,
  theme: PropTypes.object,
  locale: PropTypes.object,
  history: PropTypes.object,
  current: PropTypes.string
};

const mapStateToProps = state => {
  return {
    loacal: state.locale
  };
};

const mapDispatchToProps = {
  calenderData,
  fetchUpcomingMeetings,
  fetchTaskChart
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(EventsSideNav)));
