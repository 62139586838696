import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import AdhocInnerCardTitleWithDescription from "../AdhocInnerCardTitleWithDescription";
import LogoText from "../LogoText";
import { getDisplayName } from "../../utils";
import ActionBar from "../ActionBar";
import "./style.scss";

class AdhocListCard extends Component {
  render() {
    const { theme, data, key, intl, suffix } = this.props;

    return (
      <div
        className="adhoc-card card"
        key={key}
        onClick={() =>
          this.props.history.push({
            pathname: `/adhoc/${data.ID}`
          })
        }
      >
        <div className="main-container">
          <AdhocInnerCardTitleWithDescription data={data} />
          {theme.isWorkflowMember ? (
            <LogoText
              name={intl.formatMessage({
                id: "adhoc.RequestBy",
                defaultMessage: "Request By"
              })}
              text={getDisplayName(data.Creator)}
              img={data.Creator.UserProfilePicture}
            />
          ) : null}
        </div>
        <ActionBar
          theme={theme}
          data={data}
          color={theme.Adhoc_Status[data.Status].Color}
          text={theme.Workflow_Stage[data.Stage][`Title${suffix}`]}
          hideStatus={theme.isWorkflowMember}
        />
      </div>
    );
  }
}

AdhocListCard.propTypes = {
  theme: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    suffix: state.locale.suffix
  };
};

export default connect(
  mapStateToProps,
  null
)(injectIntl(withRouter(AdhocListCard)));
