import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import EmptyComponent from "../../components/EmptyComponents/EmptyComponent";
import AdhocListLoadingCard from "../../components/LoadingCards/AdhocListLoadingCard";
import AdhocListCard from "../../components/AdhocListCard";
import Tabs from "../../components/Tabs";
import { fetchAdhoc, fetchAdhocHistory, fetchMemberAdhoc } from "../../actions";
import { getSections } from "../../utils";
import "./style.scss";

const tabs = [{ name: "did_not_take_action" }, { name: "take_action" }];
class AdhocPage extends Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, false);
    const { match, isWorkflowMember, history } = this.props;

    if (match.path === "/adhoc/history" && !isWorkflowMember) {
      history.replace("/adhoc");
    }
    this.fetchData("");
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.path !== prevProps.match.path) {
      this.fetchData("");
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll, false);
  }

  fetchData(PagingInfo) {
    const {
      match,
      isWorkflowMember,
      fetchAdhoc,
      fetchAdhocHistory,
      fetchMemberAdhoc
    } = this.props;

    if (match.path === "/adhoc/history") {
      fetchAdhocHistory(PagingInfo);
    } else if (isWorkflowMember) {
      fetchMemberAdhoc(PagingInfo);
    } else {
      fetchAdhoc(PagingInfo);
    }
  }

  handleScroll() {
    const offset = 600;
    const { adhoc } = this.props;
    if (
      !adhoc.Loading &&
      adhoc.PagingInfo !== "noMore" &&
      window.innerHeight + window.scrollY >= document.body.offsetHeight - offset
    ) {
      this.fetchData(adhoc.PagingInfo);
    }
  }

  selectTab = tab => {
    const { history, match } = this.props;
    const next = tab === tabs[0].name ? "/adhoc" : "/adhoc/history";
    if (match.path !== next) {
      history.replace(next);
    }
  };

  getAdhocList = () => {
    const { adhoc, intl } = this.props;
    const sections = getSections(adhoc.AdhocList, "CreatedDate");
    const loading = adhoc.Loading
      ? [...Array(4)].map((item, index) => (
          <AdhocListLoadingCard key={`loading-${index}`} />
        ))
      : [];
    let list = Object.keys(sections).map((sectionKey, sectionIndex) => {
      if (sections[sectionKey].length < 1) return null;
      return (
        <div className="task-section" key={sectionKey}>
          <h2 className="section-header">
            {intl.formatMessage({
              id: `section.${sectionKey}`,
              defaultMessage: sectionKey
            })}
          </h2>
          <div className="adhoc-list">
            {[
              ...sections[sectionKey].map((adhoc, index) => (
                <AdhocListCard data={adhoc} key={index} />
              )),
              ...loading
            ]}
          </div>
        </div>
      );
    });
    if (adhoc.Loading && adhoc.AdhocList.length < 1)
      list = <div className="adhoc-list">{[...loading]}</div>;
    return list;
  };

  render() {
    const { adhoc, intl, isWorkflowMember, match } = this.props;

    const currentTab =
      match.path === "/adhoc/history" ? tabs[1].name : tabs[0].name;

    return (
      <div className="adhoc-page">
        {isWorkflowMember && (
          <div className="card">
            <Tabs
              tabs={tabs}
              selected={currentTab}
              selectTab={this.selectTab}
            />
          </div>
        )}

        {adhoc.AdhocList.length < 1 && !adhoc.Loading ? (
          <EmptyComponent
            title={intl.formatMessage({
              id: isWorkflowMember
                ? `emptyComponent.title.${currentTab}`
                : "emptyComponent.title.adhoc",
              defaultMessage: "adhoc"
            })}
          />
        ) : null}

        {this.getAdhocList()}
      </div>
    );
  }
}

AdhocPage.propTypes = {};
const mapDispatchToProps = { fetchAdhoc, fetchAdhocHistory, fetchMemberAdhoc };

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    adhoc: state.adhoc,
    isWorkflowMember: state.theme.isWorkflowMember
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AdhocPage));
