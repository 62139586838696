export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";

export function showToast(ID, pageType, isUpdate, isCommittee, isMeetingID) {
  return { type: SHOW_TOAST, ID, pageType, isUpdate, isCommittee, isMeetingID };
}

export function hideToast() {
  return { type: HIDE_TOAST };
}
