import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { object, string } from "yup";
import { injectIntl } from "react-intl";
import Modal from "../Modal";
import { hideModal, addCommitteeFolder } from "../../actions";
import FormInput from "../FormInput";
import FormButton from "../FormButton";
import { cleanApiDataForm } from "../../utils";
import { limits } from "../../configs";

class AddFolderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        NameAr: "",
        Attachments: []
      }
    };
  }

  validationSchema = object().shape({
    NameAr: string()
      .required(
        this.props.intl.formatMessage({
          id: "validation.nameRequired",
          defaultMessage: "Name is required"
        })
      )
      .max(
        limits.title,
        this.props.intl.formatMessage(
          {
            id: "validation.charLimit",
            defaultMessage: "should not exceed {limit} characters"
          },
          { limit: limits.title }
        )
      )
  });

  render() {
    const { hideModal, id, loading, progress } = this.props;

    return (
      <Modal isOpen title="addFolder" hideModal={hideModal}>
        <Formik
          validationSchema={this.validationSchema}
          initialValues={this.state.initialValues}
          onSubmit={(values, { setSubmitting }) => {
            const apiData = {
              NameAr: values.NameAr,
              CommitteeID: id,
              Attachments: values.Attachments
            };
            this.props.addCommitteeFolder(cleanApiDataForm(apiData));
          }}
        >
          {({ isSubmitting, touched, errors }) => (
            <Form autoComplete="off">
              <input
                autoComplete="false"
                name="hidden"
                type="text"
                style={{ display: "none" }}
              />
              <FormInput
                name="NameAr"
                type="text"
                required
                errors={touched["NameAr"] && errors["NameAr"]}
              />
              <FormInput name="Attachments" type="upload" multi />
              <FormButton loading={loading} progress={progress} />
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

AddFolderModal.propTypes = {
  loading: PropTypes.bool
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loading: state.committee.CreateLoading,
    progress: state.committee.Progress
  };
};

const mapDispatchToProps = { hideModal, addCommitteeFolder };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AddFolderModal));
