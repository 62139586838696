import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { string, object, date, array } from "yup";
import Modal from "../Modal";
import { hideModal, addCommittee } from "../../actions";
import FormInput from "../FormInput";
import FormButton from "../FormButton";
import CreateNewCommitteeMemberModal from "../CreateNewCommitteeMemberModal";
import { limits } from "../../configs";
import axios from "axios";
import {
  cleanApiDataForm,
  getUsername,
  setSelectSingleOptions,
  setFilteredMembersOption,
  sortUsers,
  cleanUsersForSelect,
  getUnixDate,
  cleanObjectOptionsForSelect
} from "../../utils";

class AddCommittesModal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    const committeeTypeItem =
      props.data && props.CommitteeType[props.data.CommitteeType];
    this.state = {
      users: [],
      allMembers: [],
      committeeUsers: [],
      addMember: false,
      initialValues: {
        NameAr: props.data ? props.data.NameAr : "",
        DescriptionAr: props.data ? props.data.DescriptionAr : "",
        Manager:
          props.data && props.data.Manager
            ? setSelectSingleOptions("Manager", props.data)
            : [],
        Attachment: [],
        Members: [],
        ProfilePic: [],

        Secretary:
          props.data && props.data.Secretary
            ? setSelectSingleOptions("Secretary", props.data)
            : [],
        CommitteeCreatedDate: props.data
          ? new Date(props.data.CommitteeCreatedDate * 1000)
          : null,
        CommitteeType: props.data
          ? [
              {
                value: committeeTypeItem.Value,
                label: committeeTypeItem[`Title${props.suffix}`],
                ...committeeTypeItem
              }
            ]
          : ""
      }
    };
    this.ref = React.createRef();
  }

  addCommitteeSchema = object().shape({
    NameAr: string()
      .required(
        this.props.intl.formatMessage({
          id: "validation.nameRequired",
          defaultMessage: "Address is required"
        })
      )
      .max(
        limits.title,
        this.props.intl.formatMessage(
          {
            id: "validation.charLimit",
            defaultMessage: "should not exceed {limit} characters"
          },
          { limit: limits.title }
        )
      ),

    CommitteeType: array()
      .min(1)
      .required(),

    CommitteeCreatedDate: date()
      .nullable()

      .required(
        this.props.intl.formatMessage({
          id: "validation.dateRequired",
          defaultMessage: "date is required"
        })
      ),
    DescriptionAr: string().max(
      limits.description,
      this.props.intl.formatMessage(
        {
          id: "validation.charLimit",
          defaultMessage: "should not exceed {limit} characters"
        },
        { limit: limits.description }
      )
    )
  });

  componentDidMount() {
    this._isMounted = true;

    axios.get("/ActiveDirectory/CommitteeUsers").then(response => {
      const committeeUsers = cleanUsersForSelect(
        response,
        false,
        this.props.ltr
      );
      if (this._isMounted) {
        this.setState({ committeeUsers: sortUsers(committeeUsers) });
      }
    });
  }

  handleCreateMultiSelect = (value, handleChange) => {
    this.setState({
      value,
      addMember: true,
      changeHandler: handleChange,
      allMembers: this.ref.current.state.values.Members
    });
  };

  onCreate = member => {
    this.setState({
      committeeUsers: sortUsers([
        member[member.length - 1],
        ...this.state.committeeUsers
      ]),
      addMember: false
    });

    this.state.changeHandler(member);
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      hideModal,
      loading,
      data,
      username,
      progress,
      CommitteeType,
      suffix
    } = this.props;
    const { committeeUsers } = this.state;

    return (
      <Modal isOpen title="addCommittees" hideModal={hideModal}>
        <Formik
          ref={this.ref}
          initialValues={this.state.initialValues}
          validationSchema={this.addCommitteeSchema}
          onSubmit={(values, { setSubmitting }) => {
            const ID = data ? data.ID : null;
            const apiData = {
              ...values,
              NameAr: values.NameAr,
              NameEn: values.NameAr,
              DescriptionAr: values.DescriptionAr,
              DescriptionEn: values.DescriptionAr,
              Attachment: null,
              Attachments: values.Attachment,
              Manager:
                values.Manager.length > 0 && getUsername(values.Manager)[0],
              Members: getUsername(values.Members),
              ID,
              Secretary:
                values.Secretary.length > 0 && getUsername(values.Secretary)[0],
              CommitteeCreatedDate: getUnixDate(values.CommitteeCreatedDate),
              CommitteeType: values.CommitteeType[0].value
            };

            this.props.addCommittee(cleanApiDataForm(apiData), ID, username);
          }}
        >
          {({ isSubmitting, values, touched, errors }) => (
            <Form autoComplete="off">
              <input
                autoComplete="false"
                name="hidden"
                type="text"
                style={{ display: "none" }}
              />

              <FormInput
                type="text"
                name="NameAr"
                required
                errors={touched["NameAr"] && errors["NameAr"]}
              />
              <FormInput type="textarea" name="DescriptionAr" />
              <FormInput type="upload" name="ProfilePic" single imageOnly />
              <FormInput
                type="select"
                name="Manager"
                options={committeeUsers}
                limit={1}
                creatableMultiSelect
                handleCreateMultiSelect={this.handleCreateMultiSelect}
              />

              <FormInput
                type="select"
                name="Secretary"
                options={committeeUsers}
                limit={1}
                creatableMultiSelect
                handleCreateMultiSelect={this.handleCreateMultiSelect}
                top
              />

              <FormInput
                type="select"
                name="CommitteeType"
                options={cleanObjectOptionsForSelect(CommitteeType, suffix)}
                limit={1}
                top
                required
                errors={touched["CommitteeType"] && errors["CommitteeType"]}
              />

              <FormInput
                name="CommitteeCreatedDate"
                type="dateTime"
                required
                minDate={new Date()}
                errors={
                  touched["CommitteeCreatedDate"] &&
                  errors["CommitteeCreatedDate"]
                }
              />

              {!data ? (
                <>
                  <FormInput
                    type="select"
                    name="Members"
                    options={setFilteredMembersOption(
                      values,
                      this.state.committeeUsers
                    )}
                    creatableMultiSelect
                    handleCreateMultiSelect={this.handleCreateMultiSelect}
                    top
                  />
                  <FormInput type="upload" name="Attachment" multi />
                </>
              ) : null}
              <FormButton loading={loading} edit={!!data} progress={progress} />
            </Form>
          )}
        </Formik>

        {this.state.addMember ? (
          <CreateNewCommitteeMemberModal
            hideModal={() => this.setState({ addMember: false })}
            onCreate={this.onCreate}
            value={this.state.value}
            allMembers={this.state.allMembers}
          />
        ) : null}
      </Modal>
    );
  }
}

AddCommittesModal.propTypes = {
  intl: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loading: state.committee.CreateLoading,
    progress: state.committee.Progress,
    username: state.user.UserData.Username,
    ltr: state.locale.ltr,
    CommitteeType: state.theme.Committee_Type,
    suffix: state.locale.suffix
  };
};

const mapDispatchToProps = { hideModal, addCommittee };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AddCommittesModal));
