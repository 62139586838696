import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import axios from "axios";
import { connect } from "react-redux";
import * as yup from "yup";
import { injectIntl } from "react-intl";
import Modal from "../Modal";
import { withRouter } from "react-router-dom";
import { hideModal, addMeeting, addCommitteeMeeting } from "../../actions";
import FormInput from "../FormInput";
import FormButton from "../FormButton";
import "./style.scss";
import {
  getUsername,
  getUnixDate,
  cleanApiDataForm,
  getSelectUsers,
  setSelectMultiOptions,
  setSelectSingleOptions,
  cleanHandshakeForSelect,
  cleanUsersForSelect,
  sortUsers,
  cleanObjectOptionsForSelect
} from "../../utils";
import { limits } from "../../configs";

class AddMeetingModal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    const MeetingTypeItem =
      props.data &&
      props.data.MeetingType &&
      props.MeetingType[props.data.MeetingType];
    this.state = {
      startDate:
        props.data && props.data.StartDate
          ? new Date(props.data.StartDate * 1000)
          : null,
      users: [],
      usersWithAllData: [],
      MembersFilter: [],
      usersWithoutCurrentUser: [],
      initialValues: {
        TitleAr: props.data && props.data.TitleAr ? props.data.TitleAr : "",
        StartDate:
          props.data && props.data.StartDate
            ? new Date(props.data.StartDate * 1000)
            : null,
        EndDate:
          props.data && props.data.EndDate
            ? new Date(props.data.EndDate * 1000)
            : null,
        AgendaItems:
          props.data && props.data.AgendaItems ? props.data.AgendaItems : "",
        Venue: props.data && props.data.Venue ? props.data.Venue : "",
        Attachment: [],
        Organizer:
          props.data && props.data.Organizer
            ? setSelectSingleOptions("Organizer", props.data)
            : [],
        Members:
          props.data && props.data.Members
            ? setSelectMultiOptions(this.setMemberSelect(props.data.Members))
            : [],
        CommitteeID:
          props.data && props.data.CommitteeID ? props.data.CommitteeID : null,
        ID: props.data ? props.data.ID : null,

        Priority: props.data && props.data.Priority ? props.data.Priority : "1",

        Notes: props.data ? props.data.Notes : "",
        MeetingType:
          props.data && props.data.MeetingType
            ? [
                {
                  value: MeetingTypeItem.Value,
                  label: MeetingTypeItem[`Title${props.suffix}`],
                  ...MeetingTypeItem
                }
              ]
            : "",
        RepeatMeeting: props.data ? props.data.RepeatMeeting : false
      },
      Priorities: [],
      RepeatMeeting: [
        { value: false, label: props.locale.ltr ? "no" : "لا" },
        { value: true, label: props.locale.ltr ? "yes" : "نعم" }
      ]
    };
  }

  setMemberSelect(data) {
    return data.map(data => {
      return data.UserInfo;
    });
  }

  getCommitteeMembers = async id => {
    return await axios.get(`/Committee/Members/${id}`).then(response => {
      this.setUsersWithAllData(response);
      return cleanUsersForSelect(response, true, this.props.locale.ltr);
    });
  };

  setUsersWithAllData = response => {
    this.setState({
      usersWithAllData: response.data.Data.map(data => {
        return {
          Username: data.MemberInfo.Username,
          isActiveDirectoryUser: data.MemberInfo.isActiveDirectoryUser
        };
      })
    });
  };

  componentDidMount() {
    this._isMounted = true;
    const { id, data, currentUser } = this.props;
    if (id || (data && data.CommitteeID)) {
      this.getCommitteeMembers(
        id ? id : data.CommitteeID,
        this.props.locale.ltr
      ).then(users => {
        if (this._isMounted) {
          const sortusersData = sortUsers(users);

          const usersWithoutCurrentUser = sortusersData.filter(user => {
            return user.value !== currentUser;
          });
          this.setState({
            users: sortusersData,
            usersWithoutCurrentUser,
            MembersFilter: usersWithoutCurrentUser
          });
        }
      });
    } else {
      getSelectUsers(this.props.locale.ltr).then(users => {
        if (this._isMounted) {
          const sortusersData = sortUsers(users);

          const usersWithoutCurrentUser = sortusersData.filter(user => {
            return user.value !== currentUser;
          });
          this.setState({
            users: sortusersData,
            usersWithoutCurrentUser,
            MembersFilter: usersWithoutCurrentUser
          });
        }
      });
    }
    this.setState({
      Priorities: cleanHandshakeForSelect(
        this.props.theme.Meeting_Priority,
        null,
        this.props.locale.suffix
      )
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  addMeetingSchema = () => {
    const { startDate } = this.state;
    const date = new Date(startDate);
    return yup.object().shape({
      TitleAr: yup
        .string()
        .required(
          this.props.intl.formatMessage({
            id: "validation.nameRequired",
            defaultMessage: "Title is required"
          })
        )
        .max(
          limits.title,
          this.props.intl.formatMessage(
            {
              id: "validation.charLimit",
              defaultMessage: "should not exceed {limit} characters"
            },
            { limit: limits.title }
          )
        ),
      AgendaItems: yup.string().max(
        limits.description,
        this.props.intl.formatMessage(
          {
            id: "validation.charLimit",
            defaultMessage: "should not exceed {limit} characters"
          },
          { limit: limits.description }
        )
      ),
      Notes: yup.string().max(
        limits.description,
        this.props.intl.formatMessage(
          {
            id: "validation.charLimit",
            defaultMessage: "should not exceed {limit} characters"
          },
          { limit: limits.description }
        )
      ),
      StartDate: yup
        .date()
        .nullable()
        .min(
          new Date(),
          this.props.intl.formatMessage({
            id: "validation.startDateInFuture",
            defaultMessage: "start date can't be older than today"
          })
        )
        .required(
          this.props.intl.formatMessage({
            id: "validation.startDate",
            defaultMessage: "start date is required"
          })
        ),
      EndDate: yup
        .date()
        .nullable()
        .min(
          startDate ? new Date(startDate) : new Date(),
          this.props.intl.formatMessage({
            id: "validation.endDateInFutureWithTime",
            defaultMessage: "start date can't be older than today"
          })
        )
        .max(
          startDate
            ? new Date(
                new Date(date.setDate(date.getDate() + 1)).setHours(
                  0,
                  0,
                  0,
                  0
                ) - 1
              )
            : new Date(),
          this.props.intl.formatMessage({
            id: "validation.endDateInFuture",
            defaultMessage: "end date can't be older than start day"
          })
        )

        .required(
          this.props.intl.formatMessage({
            id: "validation.endDateRequired",
            defaultMessage: "end date is required"
          })
        ),
      Members: yup
        .array()
        .of(
          yup.object().shape({
            Username: yup.string()
          })
        )
        .required(
          this.props.intl.formatMessage({
            id: "validation.membersRequire",
            defaultMessage: "the meeting should have at least one member"
          })
        ),
      Organizer: yup
        .array()
        .of(
          yup.object().shape({
            Username: yup.string()
          })
        )
        .required(
          this.props.intl.formatMessage({
            id: "validation.orginizer",
            defaultMessage: "the meeting should have at least one member"
          })
        ),

      MeetingType: yup
        .array()
        .of(yup.object())
        .required(),
      Venue: yup
        .string()
        .required(
          this.props.intl.formatMessage({
            id: "validation.Venue",
            defaultMessage: "Title is required"
          })
        )
        .max(
          limits.title,
          this.props.intl.formatMessage(
            {
              id: "validation.charLimit",
              defaultMessage: "should not exceed {limit} characters"
            },
            { limit: limits.title }
          )
        ),
      Priority: yup.string().required(
        this.props.intl.formatMessage({
          id: "validation.priority",
          defaultMessage: "priority is required"
        })
      )
    });
  };

  getUsernameWithActiveDirectory = data => {
    const users = data.map(data => {
      return data.value;
    });
    return this.state.usersWithAllData.filter(user => {
      return users.includes(user.Username);
    });
  };

  render() {
    const {
      hideModal,
      data,
      meeting,
      committee,
      id,
      // currentUser,
      suffix,
      MeetingType
    } = this.props;
    const {
      users,
      // usersWithoutCurrentUser,
      MembersFilter
    } = this.state;

    return (
      <Modal
        isOpen
        title={data && data.ID ? "editMeeting" : "addMeeting"}
        hideModal={hideModal}
      >
        <Formik
          initialValues={this.state.initialValues}
          validate={values =>
            this.setState({
              startDate: values.StartDate
              // MembersFilter:
              //   values.Organizer.length > 0
              //     ? usersWithoutCurrentUser.filter(
              //         (user) => user.value !== values.Organizer[0].value
              //       )
              //     : usersWithoutCurrentUser,
            })
          }
          validationSchema={this.addMeetingSchema}
          onSubmit={(values, { setSubmitting }) => {
            const apiData = {
              ...values,
              Organizer: getUsername(values.Organizer, true),
              Members:
                id && id !== 0
                  ? this.getUsernameWithActiveDirectory(values.Members)
                  : getUsername(values.Members),
              Attachment: values.Attachment,
              StartDate: getUnixDate(values.StartDate),
              EndDate: getUnixDate(values.EndDate),
              AgendaItems: values.AgendaItems,
              Venue: values.Venue,
              CommitteeID: id ? id : values.CommitteeID,
              ID: values.ID,
              MeetingType: values.MeetingType[0].value
            };

            if (
              apiData.CommitteeID !== 0 &&
              this.props.location.pathname.includes("committee")
            ) {
              this.props.addCommitteeMeeting(
                cleanApiDataForm(apiData),
                apiData.ID
              );
            } else {
              this.props.addMeeting(
                cleanApiDataForm(apiData),
                apiData.ID,
                this.props.user.UserData.Username,
                this.props.location.state && this.props.location.state.location
                  ? this.props.location.state.location.includes("committee")
                  : false
              );
            }
          }}
        >
          {({ isSubmitting, values, errors, touched }) => (
            <Form autoComplete="off">
              <input
                autoComplete="false"
                name="hidden"
                type="text"
                style={{ display: "none" }}
              />
              <div>
                <FormInput
                  type="text"
                  name="TitleAr"
                  required
                  errors={touched["TitleAr"] && errors["TitleAr"]}
                />

                <div>
                  <FormInput type="richText" name="AgendaItems" />
                  <div className="meeting-date-modal">
                    <div className="from-date-container">
                      <FormInput
                        type="dateTime"
                        name="StartDate"
                        required
                        time
                        minDate={new Date()}
                        errors={touched["StartDate"] && errors["StartDate"]}
                      />
                    </div>
                    <div className="to-date-container">
                      <FormInput
                        type="dateTime"
                        name="EndDate"
                        time
                        required
                        minDate={new Date()}
                        errors={touched["EndDate"] && errors["EndDate"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="meeting-date-modal">
                  <div className="from-date-container">
                    <FormInput
                      type="select"
                      options={users}
                      name="Organizer"
                      limit={1}
                      required
                      errors={touched["Organizer"] && errors["Organizer"]}
                    />
                  </div>
                  <div className="to-date-container">
                    <FormInput
                      type="text"
                      name="Venue"
                      required
                      errors={touched["Venue"] && errors["Venue"]}
                    />
                  </div>
                </div>
                <FormInput type="textarea" name="Notes" />
                <FormInput type="upload" name="Attachment" multi />
                <FormInput
                  type="select"
                  options={MembersFilter}
                  name="Members"
                  module="meetings"
                  required
                  errors={touched["Members"] && errors["Members"]}
                  top
                />
                <FormInput
                  withIcon={true}
                  name="Priority"
                  type="radio"
                  options={this.state.Priorities}
                  required
                />

                <FormInput
                  withIcon={true}
                  name="RepeatMeeting"
                  type="radio"
                  options={this.state.RepeatMeeting}
                  required
                />

                <FormInput
                  type="select"
                  name="MeetingType"
                  options={cleanObjectOptionsForSelect(MeetingType, suffix)}
                  limit={1}
                  top
                  required
                  errors={touched["MeetingType"] && errors["MeetingType"]}
                />
              </div>

              <FormButton
                loading={
                  id !== 0 && this.props.location.pathname.includes("committee")
                    ? committee.CreateLoading
                    : meeting.CreateLoading
                }
                progress={
                  id !== 0 && this.props.location.pathname.includes("committee")
                    ? committee.Progress
                    : meeting.Progress
                }
                edit={data && data.ID}
              />
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

AddMeetingModal.propTypes = {
  intl: PropTypes.object,
  meeting: PropTypes.object,
  hideModal: PropTypes.func,
  addMeeting: PropTypes.func
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    meeting: state.meeting,
    committee: state.committee,
    theme: state.theme,
    user: state.user,
    locale: state.locale,
    currentUser: state.user.UserData.Username,
    MeetingType: state.theme.Meeting_Type,
    suffix: state.locale.suffix
  };
};

const mapDispatchToProps = {
  hideModal,
  addMeeting,
  addCommitteeMeeting
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(AddMeetingModal)));
