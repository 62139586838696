import React, { Component } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import Modal from "../Modal";
import { cancelMeeting } from "../../actions";
import FormInput from "../FormInput";
import { injectIntl } from "react-intl";
import axios from "axios";
import "./style.scss";
import { string, object } from "yup";
import Button from "../Button";
import { FormattedMessage } from "react-intl";

class CancelMeetingModal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        CancelationReason: "",
        ID: props.meeting.ID
      },

      loading: false
    };
  }

  cancelMeeting = values => {
    this._isMounted = true;

    if (values.CancelationReason !== "") {
      this.setState({ loading: true }, () => {
        axios.post("/Meetings/Cancel", values).then(res => {
          if (res.data.StatusCode === "Success") {
            this.props.cancelMeeting(
              this.props.meeting,
              values.CancelationReason
            );
            this.props.hideModal();
          }
          if (this._isMounted) {
            this.setState({ loading: false });
          }
        });
      });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  cancelMeetingSchema = object().shape({
    CancelationReason: string().required(
      this.props.intl.formatMessage({
        id: "validation.CancelationReason",
        defaultMessage: "Cancelation reason is required"
      })
    )
  });

  render() {
    const { hideModal } = this.props;

    return (
      <Modal isOpen title="CancelationReason" hideModal={hideModal}>
        <Formik
          validationSchema={this.cancelMeetingSchema}
          initialValues={this.state.initialValues}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, values }) => (
            <Form autoComplete="off">
              <input
                autoComplete="false"
                name="hidden"
                type="text"
                style={{ display: "none" }}
              />
              <FormInput type="text" name="CancelationReason" required />

              <div
                className={
                  this.props.locale.ltr
                    ? "accept-reject-buttons-modal ltr"
                    : "accept-reject-buttons-modal"
                }
              >
                <Button
                  loading={this.state.loading}
                  onClick={() => this.cancelMeeting(values)}
                >
                  <FormattedMessage
                    id="confirmation.confirm"
                    defaultMessage="Confirm"
                  />
                </Button>
                <Button onClick={() => this.props.hideModal()}>
                  <FormattedMessage
                    id="confirmation.cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    locale: state.locale
  };
};

const mapDispatchToProps = { cancelMeeting };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CancelMeetingModal));
