import React from "react";
import { FormattedMessage } from "react-intl";
import DropDown from "../DropDown";
import { ReactComponent as ArrowIcon } from "../../assets/images/arrow-down.svg";
import "./style.scss";

class CalViewSelect extends React.Component {
  _isMounted = false;
  constructor() {
    super();
    this.state = {
      open: false
    };
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu = event => {
    if (this._isMounted) {
      this.setState({ open: true }, () => {
        document.addEventListener("click", this.closeMenu);
      });
    }
    event.stopPropagation();
  };

  closeMenu = () => {
    if (this._isMounted) {
      this.setState({ open: false }, () => {
        document.removeEventListener("click", this.closeMenu);
      });
    }
  };

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { view, theme, options } = this.props;
    const { open } = this.state;
    return (
      <div className="cal-view-select">
        <button
          className="cal-view-button"
          style={{ color: theme.textGray }}
          onClick={this.showMenu}
        >
          <FormattedMessage id={`calSelect.${view}`} defaultMessage={view} />
          <ArrowIcon style={{ fill: theme.textGray }} />
        </button>
        {open ? <DropDown title="calViewOptions" options={options} /> : null}
      </div>
    );
  }
}

export default CalViewSelect;
