import React, * as react from "react";

import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import "./style.scss";

import StatusIndicator from "../StatusIndicator";

class StatusIndicatorFooter extends react.Component {
  render() {
    const { theme, data } = this.props;
    return (
      <div
        className="small-calender-footer-container"
        style={{
          backgroundColor: theme.primaryLight,
          borderColor: theme.accentColor
        }}
      >
        {data.map((data, index) => (
          <div className="section" key={index}>
            <StatusIndicator color={data.color} text={data.text} />
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme
  };
};

export default connect(
  mapStateToProps,
  null
)(injectIntl(StatusIndicatorFooter));
