import React from "react";
import "./style.scss";
import PropTypes from "prop-types";
import { getDisplayName } from "../../utils";

import { connect } from "react-redux";

import AsyncImg from "../AsyncImg";

const UserItem = props => {
  const { user, role, theme, locale, isNameClickable, history } = props;

  return (
    <div
      className={isNameClickable ? "userItem cursor" : "userItem"}
      onClick={() =>
        isNameClickable && history.push(`/users/${isNameClickable}`)
      }
    >
      <AsyncImg img={user.UserProfilePicture} alt={user.role} />

      <div className="userDetails" style={{ borderColor: theme.accentColor }}>
        <p className="userName">
          {user.DisplayName && getDisplayName(user, locale.ltr)}
        </p>
        <div className="row">
          {role !== "Creator" ? (
            <div
              className="roleCircle"
              style={{
                backgroundColor: theme.RACI[role] && theme.RACI[role].Color
              }}
            />
          ) : null}
          <p className="userRole" style={{ color: theme.textGray }}>
            {theme.RACI[role][`Title${locale.suffix}`]}
          </p>
        </div>
      </div>
    </div>
  );
};

UserItem.propTypes = {
  user: PropTypes.object,
  role: PropTypes.string,
  theme: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    themeColor: state.theme.primary
  };
};

export default connect(mapStateToProps, null)(UserItem);
