import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { withConfirmation } from "../../actions";

class DropDownItem extends React.Component {
  state = {
    isHovered: false
  };

  render() {
    const {
      item,
      errorColor,
      accentColor,
      id,
      status,
      noTranslate,
      withConfirmation
    } = this.props;
    const backgroundColor = this.state.isHovered ? accentColor : "white";
    return (
      <li>
        <button
          onMouseEnter={() => this.setState({ isHovered: true })}
          onMouseLeave={() => this.setState({ isHovered: false })}
          onClick={event => {
            item.warning
              ? withConfirmation(() => item.action(id, status))
              : item.action(id, status);
            event.stopPropagation();
          }}
          style={{ color: item.warning ? errorColor : null, backgroundColor }}
        >
          {noTranslate ? (
            <span>{item.name}</span>
          ) : (
            <FormattedMessage
              id={`dropdown.${item.name}`}
              defaultMessage={item.name}
            />
          )}
        </button>
        <div className="line" />
      </li>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    errorColor: state.theme.errorColor,
    accentColor: state.theme.accentColor
  };
};

const mapDispatchToProps = {
  withConfirmation
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropDownItem);
