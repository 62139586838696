import React from "react";
import { css } from "emotion";

const OptionComponent = props => {
  const {
    children,
    className,
    cx,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
    data,
    isRtl
  } = props;
  return (
    <div
      ref={innerRef}
      className={cx(
        css(getStyles("option", props)),
        {
          option: true,
          "option--is-disabled": isDisabled,
          "option--is-focused": isFocused,
          "option--is-selected": isSelected
        },
        className
      )}
      {...innerProps}
    >
      {data.img ? (
        <img
          src={data.img}
          alt="img"
          style={{
            height: 30,
            width: 30,
            borderRadius: "50%",
            marginLeft: isRtl ? 20 : 0,
            marginRight: isRtl ? 0 : 20
          }}
        />
      ) : null}
      {children}
    </div>
  );
};

export default OptionComponent;
