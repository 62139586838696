import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import AttachmentImage from "../AttachmentImage";
import Loading from "../Loading";
import "./style.scss";
import axios from "axios";

class AttachmentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      images: this.getImages(props.photos),
      isOpen: false,
      loading: true,
      photos: []
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.photos.length !== this.props.photos.length) {
      this.getAllImages();
      // this.setState({ images: this.getImages(this.props.photos) });
    }
  }

  componentDidMount() {
    this.getAllImages();
  }

  ImagesType = ["png", "jpg", "gif", "jpeg", "svg"];

  getImages = data => {
    return data.filter(photo => this.ImagesType.includes(photo.AttachmentType));
  };

  getAllImages = () => {
    const { photos } = this.props;
    const images = this.getImages(photos);
    const imagesUrl = images.map(item => {
      return axios.get(item.AttachmentURL, { responseType: "blob" });
    });

    Promise.all(imagesUrl).then(res => {
      const newList = images.map((el, index) => {
        const buffer = URL.createObjectURL(new Blob([res[index].data]));

        return {
          ...el,
          AttachmentURL: buffer
        };
      });

      const newPhotoList = photos.map(photoItem => {
        const findIfImage = newList.find(el => {
          return el.AttachmentID === photoItem.AttachmentID;
        });
        return {
          ...photoItem,
          AttachmentURL:
            findIfImage && findIfImage.AttachmentID
              ? findIfImage.AttachmentURL
              : photoItem.AttachmentURL
        };
      });

      this.setState({
        loading: false,
        images: this.getImages(newPhotoList),
        photos: newPhotoList
      });
    });
  };

  nextPrevConfigs = () => {
    const { images, photoIndex } = this.state;
    if (images.length < 2) {
      return null;
    } else {
      return {
        nextSrc: images[(photoIndex + 1) % images.length].AttachmentURL,
        prevSrc:
          images[(photoIndex + images.length - 1) % images.length]
            .AttachmentURL,
        onMovePrevRequest: () =>
          this.setState({
            photoIndex: (photoIndex + images.length - 1) % images.length
          }),
        onMoveNextRequest: () =>
          this.setState({
            photoIndex: (photoIndex + 1) % images.length
          })
      };
    }
  };

  lightboxConfigs = () => {
    const { images, photoIndex } = this.state;
    const { ltr } = this.props;
    return {
      mainSrc: images[photoIndex].AttachmentURL,
      onCloseRequest: () => this.setState({ isOpen: false }),
      isRtl: !ltr
    };
  };

  render() {
    const { ltr, theme, deletable, type, themeColor } = this.props;
    const { isOpen, loading, photos } = this.state;
    return (
      <div className="photos-light-box">
        <div className="photos-container">
          {loading ? (
            <div className="loading-attachment">
              <Loading color={themeColor} />
            </div>
          ) : (
            <>
              {photos &&
                photos.map((photo, index) => (
                  <AttachmentImage
                    key={index}
                    attachment={photo}
                    onClick={() =>
                      this.setState({
                        isOpen: true,
                        photoIndex: this.state.images.indexOf(photo)
                      })
                    }
                    theme={theme}
                    ltr={ltr}
                    deletable={deletable}
                    type={type}
                  />
                ))}
            </>
          )}
        </div>

        {isOpen && (
          <div className="kkkk">
            <Lightbox {...this.lightboxConfigs()} {...this.nextPrevConfigs()} />
          </div>
        )}
      </div>
    );
  }
}

AttachmentList.propTypes = {
  ltr: PropTypes.bool
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ltr: state.locale.ltr,
    theme: state.theme,
    themeColor: state.theme.primary
  };
};

export default connect(mapStateToProps, null)(AttachmentList);
