import React, { Component } from "react";
import { connect } from "react-redux";
import { toggleLocale } from "../../actions";
import OptionsMenu from "../OptionsMenuLang";
import "./style.scss";

class DropDownLang extends Component {
  onClickList() {}
  render() {
    const { list, theTitle, selected, locale, theme } = this.props;

    return (
      <OptionsMenu
        list={list}
        theme={theme}
        className="options-list-component-lang"
        locale={locale}
        clickable={true}
        theTitle={theTitle}
        selected={selected}
      />
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ...state
  };
};

const mapDispatchToProps = { toggleLocale };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropDownLang);
