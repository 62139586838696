import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { showModal, hideModal } from "../../actions";
import AddButton from "../AddButton";
import AddTaskModal from "../AddTaskModal";
import AddNewsModal from "../AddNewsModal";
import AddCommitteeModal from "../AddCommittesModal";
import AddMeetingModal from "../AddMeetingModal";
import AddAdhocModal from "../AddAdhocModal";
import { extractPathName } from "../../utils";

const AddButtonSwitch = props => {
  const { path, isWorkflowMember, showModal, hideModal, theme } = props;

  let components = {
    tasks: AddTaskModal,
    news: AddNewsModal,
    committees: AddCommitteeModal,
    meetings: AddMeetingModal
  };

  let permissions = {
    tasks: theme.allowCreateTask,
    news: theme.allowAddNews,
    committees: theme.isCommitteeCreatorMemebr,
    meetings: true
  };
  if (!isWorkflowMember) components.adhoc = AddAdhocModal;
  const pathName = extractPathName(path);
  const Component = components[pathName];

  if (Object.keys(components).includes(pathName) && permissions[pathName])
    return (
      <AddButton
        title={pathName}
        onClick={() => showModal(<Component hideModal={hideModal} />)}
      />
    );
  return null;
};

AddButtonSwitch.propTypes = {
  path: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    isWorkflowMember: state.theme.isWorkflowMember,
    theme: state.theme
  };
};

const mapDispatchToProps = { showModal, hideModal };

export default connect(mapStateToProps, mapDispatchToProps)(AddButtonSwitch);
