import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import "./style.scss";
import Calendar from "../../components/Calendar";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import { injectIntl } from "react-intl";
import AddMeetingModal from "../../components/AddMeetingModal";
import MeetingsLoadingCard from "../../components/LoadingCards/MeetingsLoadingCard";
import CalViewSelect from "../../components/CalViewSelect";
import ApiCalendar from "react-google-calendar-api";
import GoogleIcon from "../../assets/images/google-icon.png";

import { fetchMeeting, showModal, fetchGoogleMeeting } from "../../actions";

class MeetingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      view: "month",
      sign: false
    };
    this._handleSelect = this._handleSelect.bind(this);
    this._handleSelectEvent = this._handleSelectEvent.bind(this);
    this.selectView = this.selectView.bind(this);
  }

  loadGoogleData = () => {
    const { fetchGoogleMeeting } = this.props;
    return ApiCalendar.gapi.client.calendar.events
      .list({
        calendarId: "primary",

        showDeleted: false,
        singleEvents: true,

        orderBy: "startTime"
      })
      .then(res => {
        fetchGoogleMeeting(res.result.items);
      });
  };

  componentDidMount() {
    this.props.fetchMeeting();

    ApiCalendar.onLoad(() => {
      setTimeout(() => {
        if (ApiCalendar.sign) {
          this.setState({ sign: false });
          this.loadGoogleData();
        } else {
          this.setState({ sign: true });
        }
      }, 500);
    });
  }

  _handleSelect = info => {
    const { view } = this.state;
    const today = moment(new Date()).startOf("day");
    const selectedDate = moment(info.start);

    if (selectedDate >= today)
      this.props.showModal(
        <AddMeetingModal
          data={{
            StartDate: info.start.getTime() / 1000,
            EndDate: view === "month" ? null : info.end.getTime() / 1000
          }}
        />
      );
  };

  handleItemClick = () => {
    const { fetchGoogleMeeting } = this.props;

    return ApiCalendar.gapi.client
      .init({
        apiKey: "AIzaSyDaWOUv7dPDpcVethyUkSTXsMVb8eXAasg",
        clientId:
          "936217694315-0jup3iblhcvhvnf15bk4obhe4opiqi7s.apps.googleusercontent.com",
        discoveryDocs: [
          "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
        ],
        scope: "https://www.googleapis.com/auth/calendar"
      })

      .then(
        () => {
          window.gapi.auth2.getAuthInstance().signIn();

          ApiCalendar.gapi.auth2
            .getAuthInstance()
            .isSignedIn.listen(this.updateSigninStatus);

          // Handle the initial sign-in state.
          this.updateSigninStatus(
            ApiCalendar.gapi.auth2.getAuthInstance().isSignedIn.get()
          );
          window.gapi.auth2
            .getAuthInstance()
            .isSignedIn.listen(function(signedIn) {
              ApiCalendar.gapi.client.setToken(
                ApiCalendar.gapi.client.getToken()
              );
              return ApiCalendar.gapi.client.calendar.events
                .list({
                  calendarId: "primary",

                  showDeleted: false,
                  singleEvents: true,

                  orderBy: "startTime"
                })
                .then(res => {
                  fetchGoogleMeeting(res.result.items);
                });
            });
        },
        function(error) {
          // appendPre(JSON.stringify(error, null, 2));
        }
      );
  };

  updateSigninStatus = isSignedIn => {
    if (isSignedIn) {
      this.setState({ sign: false });
      this.loadGoogleData();
    } else {
      console.log("not sign");
    }
  };

  _handleSelectEvent = info => {
    // this.props.showModal(<MeetingDetailsModal meeting={info} />);

    this.props.history.push({
      pathname: `/meeting/${info.ID}`,
      state: { modal: true }
    });
  };

  selectView = view => {
    this.setState({ view });
  };

  options = [
    {
      id: 1,
      name: "month",
      action: () => this.selectView("month")
    },
    {
      id: 2,
      name: "week",
      action: () => this.selectView("week")
    },
    {
      id: 3,
      name: "day",
      action: () => this.selectView("day")
    }
  ];

  render() {
    const { locale, meeting, intl, theme } = this.props;
    const { view } = this.state;
    if (meeting.Loading) return <MeetingsLoadingCard />;

    return (
      <>
        {this.state.sign && false && (
          <button
            style={{ backgroundColor: theme.primaryLight }}
            onClick={e =>
              ApiCalendar.gapi.load("client:auth2", this.handleItemClick)
            }
            className="google-icon-container"
          >
            <div className="google-icon">
              sync with google
              <img src={GoogleIcon} alt={"img"} />
            </div>
          </button>
        )}

        <div
          className={
            view === "month" ? "meetingsPage card month" : "meetingsPage card"
          }
        >
          <div className="cal-controls">
            <div className="btn-meeting">
              <Button
                onClick={() => this.props.history.push("/meeting-requests")}
              >
                <div className="meeting-number">
                  {meeting.RequestsLoading ? (
                    <Loading color={theme.primary} small />
                  ) : (
                    <div style={{ color: theme.primary }}>
                      {meeting.RequestsCount}
                    </div>
                  )}
                </div>
                {intl.formatMessage({
                  id: "button.meeting-requests",
                  defaultMessage: "Meeting requests"
                })}
              </Button>
            </div>
            <CalViewSelect view={view} theme={theme} options={this.options} />
          </div>

          <Calendar
            events={[...meeting.Meetings, ...this.props.meeting.googleMeeting]}
            rtl={locale.ltr}
            handleSelect={this._handleSelect}
            handleSelectEvent={this._handleSelectEvent}
            view={view}
          />
        </div>
      </>
    );
  }
}
// {this.props.match.params.id ? (
//   <MeetingDetailsModal match={this.props.match} />
// ) : null}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ...state
  };
};

const mapDispatchToProps = { fetchMeeting, showModal, fetchGoogleMeeting };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(MeetingsPage));
