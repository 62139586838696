import {
  ADD_COMMITTEE_ASYNC,
  COMMITTEE_LOADING,
  ADD_COMMITTEE_LOADING,
  FETCH_COMMITTEE_ASYNC,
  COMMITTEE_FAILED,
  ADD_COMMITTEE_ITEMS,
  REMOVE_COMMITTEE_MEMBER,
  REMOVE_COMMITTEE_ATTACHMENT,
  REMOVE_COMMITTEE_MEETING,
  REMOVE_COMMITTEE_FOLDER,
  CANCEL_COMMITTEE_MEETING,
  RENAME_FOLDER,
  ADD_FILES_TO_FOLDER,
  REMOVE_FILE_FROM_FOLDER,
  EDIT_COMMITTEE_MEETING_DETAILS,
  UPDATE_COMMITTEE_UPLOAD_PROGRESS
} from "../actions";
import {
  getNewList,
  filterList,
  sortCommitteeMeetings,
  cleanCommitteeFiles,
  getCommitteesCounts
} from "../utils";

const initialState = {
  Loading: true,
  CreateLoading: false,
  Error,
  Committees: [],
  Committee: null,
  Progress: "",
  Counts: {
    committeehead: 0,
    committeemember: 0,
    boardhead: 0,
    boardmember: 0,
    all: 0
  }
};

const keys = {
  Members: "Members"
};

function committee(state = initialState, action) {
  switch (action.type) {
    case ADD_COMMITTEE_ASYNC:
      return {
        ...state,
        CreateLoading: false,
        Error: "",
        Progress: "",
        Counts:
          state.Committees.length === 0
            ? getCommitteesCounts([action.committee], action.username)
            : getCommitteesCounts(
                [...state.Committees, action.committee],
                action.username
              ),
        Committees: getNewList(state.Committees, action.committee),
        Committee:
          state.Committee && state.Committee.ID === action.committee.ID
            ? cleanCommitteeFiles(action.committee)
            : state.Committee
      };
    case FETCH_COMMITTEE_ASYNC:
      const data = Array.isArray(action.committee)
        ? {
            Counts: getCommitteesCounts(action.committee, action.username),
            Committees: action.committee
          }
        : { Committee: cleanCommitteeFiles(action.committee) };
      return {
        ...state,
        Loading: false,
        Error: "",
        ...data
      };
    case ADD_COMMITTEE_ITEMS:
      let newCommittee = state.Committee;
      if (action.key === "Members") {
        newCommittee[action.key] = [
          ...state.Committee[action.key],
          ...action.items[keys[action.key]]
        ];
      } else {
        if (action.items.StartDate) {
          newCommittee[action.key] = sortCommitteeMeetings([
            action.items,
            ...filterList(action.items.ID, newCommittee[action.key])
          ]);
        } else {
          newCommittee["Attachment"] = Array.isArray(action.items)
            ? [...action.items, ...newCommittee["Attachment"]]
            : [action.items, ...newCommittee["Attachment"]];
        }
      }
      return {
        ...state,
        CreateLoading: false,
        Error: "",
        Progress: "",
        Committee: newCommittee
      };
    case ADD_FILES_TO_FOLDER:
      const newAttachment = state.Committee.Attachment.map(folder => {
        if (folder.ID === action.items[0].FolderID) {
          folder.attachments = [...folder.attachments, ...action.items];
        }
        return folder;
      });
      return {
        ...state,
        CreateLoading: false,
        Error: "",
        Progress: "",
        Committee: {
          ...state.Committee,
          Attachment: newAttachment
        }
      };
    case REMOVE_FILE_FROM_FOLDER:
      const removedAttachment = state.Committee.Attachment.map(folder => {
        if (folder.ID === action.folderId) {
          folder.attachments = folder.attachments.filter(
            file => file.AttachmentID !== action.id
          );
        }
        return folder;
      });
      return {
        ...state,
        Committee: {
          ...state.Committee,
          Attachment: removedAttachment
        }
      };
    case REMOVE_COMMITTEE_MEMBER:
      const Members = state.Committee.Members.filter(
        member => member.MemberID !== action.id
      );
      return {
        ...state,
        Committee: {
          ...state.Committee,
          Members
        }
      };
    case REMOVE_COMMITTEE_MEETING:
      let Meetings = [];
      if (state.Committee) {
        Meetings = state.Committee.Meetings.filter(
          meeting => meeting.ID !== action.id
        );
      }

      return {
        ...state,
        Committee: state.Committee
          ? {
              ...state.Committee,
              Meetings
            }
          : {}
      };
    case CANCEL_COMMITTEE_MEETING:
      let newMeetings = [];
      if (state.Committee) {
        newMeetings = state.Committee.Meetings.map(meeting =>
          meeting.ID === action.id ? { ...meeting, Status: "3" } : meeting
        );
      }
      return {
        ...state,
        Committee: state.Committee
          ? {
              ...state.Committee,
              Meetings: newMeetings
            }
          : {}
      };
    case REMOVE_COMMITTEE_ATTACHMENT:
      const Attachment = state.Committee.Attachment.filter(
        attachment => attachment.AttachmentID !== action.id
      );
      return {
        ...state,
        Committee: {
          ...state.Committee,
          Attachment
        }
      };
    case REMOVE_COMMITTEE_FOLDER:
      const folderFiltered = state.Committee.Attachment.filter(
        attachment => attachment.ID !== action.id
      );
      return {
        ...state,
        Committee: {
          ...state.Committee,
          Attachment: folderFiltered
        }
      };
    case RENAME_FOLDER:
      const renamedFolders = state.Committee.Attachment.map(attachment => {
        if (attachment.ID === action.id) {
          attachment.NameAr = action.name;
        }
        return attachment;
      });
      return {
        ...state,
        Committee: {
          ...state.Committee,
          Attachment: renamedFolders
        }
      };
    case UPDATE_COMMITTEE_UPLOAD_PROGRESS:
      return {
        ...state,
        Progress: action.progress
      };
    case COMMITTEE_LOADING:
      return {
        ...state,
        Loading: true
      };
    case ADD_COMMITTEE_LOADING:
      return {
        ...state,
        CreateLoading: true
      };
    case COMMITTEE_FAILED:
      return {
        ...state,
        Loading: false,
        CreateLoading: false,
        Error: action.error,
        Progress: ""
      };

    case EDIT_COMMITTEE_MEETING_DETAILS:
      let newlist = "";
      if (state.Committee && state.Committee.Meetings.length > 0) {
        newlist = state.Committee.Meetings.map(committee => {
          if (committee.ID === action.data.ID) {
            return action.data;
          } else {
            return committee;
          }
        });
      }

      return {
        ...state,
        Committee: state.Committee
          ? {
              ...state.Committee,
              Meetings: newlist
            }
          : {}
      };

    default:
      return state;
  }
}

export default committee;
