import React, { Component } from "react";
import PropTypes from "prop-types";
import { fetchUsers } from "../../actions";

import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getDisplayName } from "../../utils";
import EmptyComponent from "../../components/EmptyComponents/EmptyComponent";
import Loading from "../../components/LoadingCards/UsersLoadingCard";
import AsyncImg from "../../components/AsyncImg";

import "./style.scss";

class UsersPage extends Component {
  state = {
    filter: ""
  };
  componentDidMount() {
    if (!this.props.match.params.userName) {
      this.props.fetchUsers(null);
    }
  }

  getEmptyMessage(text) {
    if (text && text !== "") {
      return text;
    }
    return <FormattedMessage id="usersPage.empty" defaultMessage="empty" />;
  }

  filterList() {
    return this.props.users.UsersList.filter(user => {
      return user.DisplayName.toLowerCase().includes(
        this.props.location.search.substr(1).toLowerCase()
      );
    });
  }

  render() {
    const { users, intl, theme, locale } = this.props;

    if (users.Loading) return <Loading />;
    if (this.filterList().length === 0)
      return (
        <EmptyComponent
          title={intl.formatMessage({
            id: "emptyComponent.users",
            defaultMessage: "users"
          })}
        />
      );

    return (
      <div className="users-page-container">
        <div className="users-page">
          {this.filterList().map((user, index) => (
            <div
              className="users card"
              key={index}
              style={{ color: theme.textGray }}
            >
              <div className="first-section">
                <div className="users-section ">
                  <div className="title bold" style={{ color: theme.primary }}>
                    <FormattedMessage
                      id="usersPage.name"
                      defaultMessage="name"
                    />
                    :
                  </div>
                  <div className="user-details">
                    {getDisplayName(user, locale.ltr)}
                  </div>
                </div>
                <div className="users-section ">
                  <div className="title bold" style={{ color: theme.primary }}>
                    <FormattedMessage
                      id="usersPage.phone"
                      defaultMessage="phone"
                    />
                    :
                  </div>
                  <div className="user-details">
                    {this.getEmptyMessage(user.PhoneNumber)}
                  </div>
                </div>

                <div className="users-section ">
                  <div className="title bold" style={{ color: theme.primary }}>
                    <FormattedMessage
                      id="usersPage.email"
                      defaultMessage="email"
                    />
                    :
                  </div>
                  <div className="user-details">
                    {user.Email !== "" ? (
                      <a href={`mailto:${user.Email}`}>{user.Email}</a>
                    ) : (
                      this.getEmptyMessage(user.Email)
                    )}
                  </div>
                </div>
                <div className="users-section ">
                  <div className="title bold" style={{ color: theme.primary }}>
                    <FormattedMessage
                      id="label.Department"
                      defaultMessage="Department"
                    />
                    :
                  </div>
                  <div className="user-details">
                    {this.getEmptyMessage(user.Department)}
                  </div>
                </div>

                <div className="users-section ">
                  <div className="title bold" style={{ color: theme.primary }}>
                    <FormattedMessage
                      id="label.Sector"
                      defaultMessage="Sector"
                    />
                    :
                  </div>
                  <div className="user-details">{user.Sector}</div>
                </div>

                <div className="users-section ">
                  <div className="title bold" style={{ color: theme.primary }}>
                    <FormattedMessage
                      id="label.Entity"
                      defaultMessage="Entity"
                    />
                    :
                  </div>
                  <div className="user-details">{user.Entity}</div>
                </div>

                <div className="users-section ">
                  <div className="title bold" style={{ color: theme.primary }}>
                    <FormattedMessage
                      id="label.taskType"
                      defaultMessage="taskType"
                    />
                    :
                  </div>
                  <div className="user-details">{user.Mission}</div>
                </div>
              </div>
              <div className="second-section">
                <div className="users-image">
                  <AsyncImg
                    alt="SourceImg"
                    img={user.UserProfilePicture}
                    style={{ borderColor: theme.accentColor }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

UsersPage.propTypes = {
  theme: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    users: state.users,
    theme: state.theme,
    locale: state.locale
  };
};

const mapDispatchToProps = {
  fetchUsers
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(UsersPage)));
