import React from "react";
import { connect } from "formik";
import { FormattedMessage } from "react-intl";

import DatePicker, { registerLocale } from "react-datepicker";
import ar from "date-fns/locale/ar-SA";
import moment from "moment";
import Select from "react-select/lib/Creatable";
import OptionComponent from "../OptionComponent";
import { DatePickerStyle } from "../DatePicker/DatePickerStyle";
import SingleSelectValue from "../SingleSelectValue";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as PlusIcon } from "../../assets/images/plus-icon.svg";
import { ReactComponent as MinusIcon } from "../../assets/images/minus-icon.svg";
import "./style.scss";
registerLocale("ar", ar);

class ActionItemsInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [
        {
          DescriptionAr: "",
          DueDate: null,
          Responsible: {}
        }
      ]
    };
    this.handleDescription = this.handleDescription.bind(this);
    this.handleDueDate = this.handleDueDate.bind(this);
    this.handleResponsible = this.handleResponsible.bind(this);
    this.addItem = this.addItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }

  emptyItem = {
    DescriptionAr: "",
    DueDate: null,
    Responsible: ""
  };

  customTheme = theme => ({
    ...theme,
    borderRadius: 5,
    colors: {
      ...theme.colors,
      primary: this.props.theme.primary,
      primary75: this.props.theme.primaryLight,
      primary50: this.props.theme.primaryLight,
      primary25: this.props.theme.primaryLight
    }
  });

  customStyles = {
    option: (provided, state) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      height: 40
    }),
    input: (provided, state) => ({
      height: 40,
      borderColor: this.props.theme.accentColor
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "none",
      borderRight: `1px solid ${this.props.theme.accentColor}`,
      borderRadius: 0,
      boxShadow: "none",
      "&:hover": { borderColor: this.props.theme.accentColor }
    }),
    singleValue: (provided, state) => ({
      ...provided,
      display: "flex",
      alignItems: "center"
    }),
    indicatorsContainer: (provided, state) => ({
      display: "none"
    }),
    indicatorSeparator: (provided, state) => ({
      display: "none"
    })
  };

  handleDescription = (event, index) => {
    const { formik, name } = this.props;
    const values = formik.values[name];
    values[index] = { ...values[index], DescriptionAr: event.target.value };
    this.props.formik.setFieldValue(this.props.name, values);
  };

  handleDueDate = (value, index) => {
    const { formik, name } = this.props;
    const values = formik.values[name];
    values[index] = { ...values[index], DueDate: value };
    this.props.formik.setFieldValue(this.props.name, values);
  };

  handleResponsible = (value, action, index) => {
    const { formik, name } = this.props;
    const values = formik.values[name];
    values[index] = {
      ...values[index],
      Responsible: value,
      Email: action.action === "create-option" ? value.value : null,
      IsExternal: action.action === "create-option"
    };
    this.props.formik.setFieldValue(this.props.name, values);
  };

  addItem = () => {
    const { formik, name } = this.props;
    const values = formik.values[name];
    values.push(this.emptyItem);
    this.props.formik.setFieldValue(this.props.name, values);
  };

  removeItem = index => {
    const { formik, name } = this.props;
    const values = formik.values[name];
    values.splice(index, 1);
    this.props.formik.setFieldValue(this.props.name, values);
  };

  render() {
    const { name, theme, options, intl, formik } = this.props;
    const values = formik.values[name];

    return (
      <div
        id={name}
        className="action-items-input"
        style={{ borderColor: theme.accentColor }}
      >
        <ul>
          {values.map((item, index) => (
            <li
              style={{
                borderColor: theme.accentColor
              }}
              key={index}
              className="action-items-box"
            >
              <button
                className="action-items-button"
                type="button"
                style={{ borderColor: theme.accentColor }}
                onClick={() => this.removeItem(index)}
              >
                <MinusIcon style={{ fill: theme.primary }} />
              </button>
              <div
                className="action-section"
                style={{ borderColor: theme.accentColor }}
              >
                <textarea
                  className="action-description"
                  style={{ borderColor: theme.accentColor }}
                  type="text"
                  value={item.DescriptionAr}
                  onChange={event => this.handleDescription(event, index)}
                  placeholder={intl.formatMessage({
                    id: "actionItemsInput.details",
                    defaultMessage: "Details"
                  })}
                />
              </div>
              <div
                className="mom-date-picker"
                style={{ borderColor: theme.accentColor }}
              >
                <DatePicker
                  style={{ borderColor: theme.accentColor }}
                  calendarClassName={DatePickerStyle(theme)}
                  onChange={value => this.handleDueDate(value, index)}
                  selected={item.DueDate}
                  dateFormat="dd-MM-yyyy"
                  placeholderText={intl.formatMessage({
                    id: "actionItemsInput.dueDate",
                    defaultMessage: "Due Date"
                  })}
                  minDate={new Date()}
                  locale={moment.locale()}
                />
              </div>
              <div
                className="action-section"
                style={{ borderColor: theme.accentColor }}
              >
                <Select
                  menuPlacement="auto"
                  maxMenuHeight={220}
                  options={options}
                  styles={this.customStyles}
                  theme={this.customTheme}
                  value={item.Responsible}
                  onChange={(value, action) =>
                    this.handleResponsible(value, action, index)
                  }
                  placeholder={intl.formatMessage({
                    id: "actionItemsInput.responsible",
                    defaultMessage: "Assign to"
                  })}
                  components={{
                    Option: OptionComponent,
                    SingleValue: SingleSelectValue
                  }}
                />
              </div>
            </li>
          ))}
        </ul>
        <button
          type="button"
          className="add-action-item"
          style={{ color: theme.primary, borderColor: theme.accentColor }}
          onClick={this.addItem}
        >
          <PlusIcon style={{ fill: theme.primary }} />
          <FormattedMessage
            id="actionItemsInput.add"
            defaultMessage="Add new action"
          />
        </button>
      </div>
    );
  }
}

export default connect(ActionItemsInput);
