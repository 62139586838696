import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import "./style.scss";
import moment from "moment";
import userImage from "../../assets/images/profile-image.jpg";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router";
import { ReactComponent as AcceptMemberIcon } from "../../assets/images/accept-member-icon.svg";
import { ReactComponent as RejectMemberIcon } from "../../assets/images/reject-member-icon.svg";
import { ReactComponent as YellowClockIcon } from "../../assets/images/yellow-clock-icon.svg";
import AsyncImg from "../AsyncImg";

class MeetingDetailsMembersStatusLogoText extends Component {
  setMemberIconResponse = response => {
    switch (response) {
      case "1":
        return <AcceptMemberIcon />;

      case "0":
        return <RejectMemberIcon />;

      case "3":
        return <YellowClockIcon />;

      default:
        return null;
    }
  };

  setAttendeesStatus = (attendeesStatus, response) => {
    const { theme, locale, SuggestedDate } = this.props;

    switch (response) {
      case "1":
        return theme.Meeting_Attendees_Status[attendeesStatus][
          `Title${locale.suffix}`
        ];

      case "0":
        return (
          <FormattedMessage
            id="meeting-details-members-reject"
            defaultMessage="Reject attend meeting"
          />
        );

      case "3":
        return (
          <div>
            {SuggestedDate > 0 ? (
              moment(SuggestedDate).format("Do  MMM  YYYY ,h:mm a")
            ) : (
              <FormattedMessage
                id="meeting-details-members-postpone"
                defaultMessage="suggest another date"
              />
            )}
          </div>
        );

      default:
        return <div />;
    }
  };
  render() {
    const {
      theme,
      name,
      locale,
      attendeesStatus,
      img,
      response,
      isNameClickable
    } = this.props;

    return (
      <div
        className={
          isNameClickable
            ? "meeting-details-members-status cursor"
            : "meeting-details-members-status"
        }
        style={{ opacity: response === "2" ? 0.5 : null }}
        onClick={() =>
          isNameClickable &&
          this.props.history.push(`/users/${isNameClickable}`)
        }
      >
        <AsyncImg
          alt="SourceImg"
          img={img ? img : userImage}
          style={{
            borderColor: theme.accentColor
          }}
        />
        <div
          className={this.props.locale.ltr ? "status-dot ltr" : "status-dot"}
        >
          {this.setMemberIconResponse(response)}
        </div>
        <div
          className={locale.ltr ? "text-Container ltr" : "text-Container"}
          style={{ borderColor: theme.accentColor }}
        >
          <div style={{ color: response === "2" ? theme.textGray : null }}>
            {name}
          </div>
          {response !== "2" && (
            <div className="second-text" style={{ color: theme.textGray }}>
              {this.setAttendeesStatus(attendeesStatus, response)}
            </div>
          )}
        </div>
      </div>
    );
  }
}

MeetingDetailsMembersStatusLogoText.propTypes = {
  theme: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    locale: state.locale
  };
};

export default connect(
  mapStateToProps,
  null
)(injectIntl(withRouter(MeetingDetailsMembersStatusLogoText)));
