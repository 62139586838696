// import logo from "./assets/images/logo.svg";
// import logoTransparent from "./assets/images/logo-transparent.png";
import Logo from "./assets/images/diwan-logo.png";
import LogoTransparent from "./assets/images/transparent-logo.png";

export const themeData = {
  logo: Logo,
  logoTransparent: LogoTransparent,
  appTitleAr: "البوابة التنفيذية",
  appTitleEn: "Executive Portal",
  primary: "#1F4C39",
  primaryDark: "#00a551",
  primaryLight: "#f9fafa",
  textColor: "#152836",
  textGray: "#8e8e8e",
  // textGray: "#727272",
  lightGray: "#FCFCFC",
  secondaryColor: "",
  accentColor: "#E5E5E5",
  accentColorActive: "#e6ecf0",
  backgroundColor: "#F4F5F4",
  errorColor: "#c62828",
  inputBackground: "#f5f8fa",
  raciColors: {
    responsible: "#9F2666",
    accountable: "#98B7B3",
    informed: "#D96347",
    consulted: "#F0BE95"
  },
  statusColors: {
    active: "#009966",
    done: "red"
  },
  statusMeetingColors: {
    active: "#009966",
    canceled: "#D2D2DB",
    finished: "red"
  }
};

export const api = {
  basic: "Basic RVBNV2ViVXNlcjpzZXJ2aWNlQDEyMw==",
  baseUrl: window.env.SERVICE_URL,
  ppBasic: "Basic c2VydmljZXVzZXI6UEBzc3cwcmQ=",
  ppBaseUrl: "http://95.177.217.191:90/mobile",
  ppHandshake: "/projectService.svc/handshake",
  ppLogin: "/ProjectService.svc/login",
  ppPortfolios: "/api.svc/portfolios",
  ppProject: "/api.svc/project/"
};

export const limits = {
  title: 255,
  description: 40000
};

export const attachmentsBlackList = [
  "exe",
  "pif",
  "application",
  "gadget",
  "msi",
  "msp",
  "com",
  "scr",
  "hta",
  "cpl",
  "msc",
  "jar",
  "bat",
  "cmd",
  "vb",
  "vbs",
  "vbe",
  "jse",
  "ws",
  "wsf",
  "reg",
  "inf",
  "lnk",
  "scf",
  "ps1",
  "ps1xml",
  "ps2",
  "ps2xml",
  "psc1",
  "psc2",
  "msh",
  "msh1",
  "msh2",
  "mshxml",
  "msh1xml",
  "msh2xml"
];

export const attachmentsWhitelist = [
  // images
  "image/*",
  "image/heic",
  // docs
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  "application/vnd.ms-word.document.macroEnabled.12",
  "application/vnd.ms-word.template.macroEnabled.12",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  "application/vnd.ms-excel.template.macroEnabled.12",
  "application/vnd.ms-excel.addin.macroEnabled.12",
  "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.presentationml.template",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  "application/vnd.ms-powerpoint.template.macroEnabled.12",
  "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  "application/x-iwork-keynote-sffkey",
  "application/x-iwork-pages-sffpages",
  "application/x-iwork-numbers-sffnumbers",
  "application/pdf",
  "application/illustrator",
  "application/photoshop",
  "text/plain",
  "text/rtf",
  "text/csv",
  // videos
  "video/*"
];
