import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Formik, Form } from "formik";
import { injectIntl } from "react-intl";
import { string, object, array } from "yup";
import Modal from "../Modal";
import FormButton from "../FormButton";
import { connect } from "react-redux";
import FormInput from "../FormInput";
import { cleanSourcesForSelect, cleanApiDataForm } from "../../utils";
import { onCreateNewsSources } from "../../actions";
import { limits } from "../../configs";

class AddNewsSourceModal extends Component {
  state = {
    progress: ""
  };
  initialValues = {
    TitleAr: this.props.value,
    MainUrl: "",
    Attachment: []
  };

  addNewsSourceSchema = object().shape({
    TitleAr: string()
      .required(
        this.props.intl.formatMessage({
          id: "validation.nameRequired",
          defaultMessage: "Source title is required"
        })
      )
      .max(
        limits.title,
        this.props.intl.formatMessage(
          {
            id: "validation.charLimit",
            defaultMessage: "should not exceed {limit} characters"
          },
          { limit: limits.title }
        )
      ),
    MainUrl: string()
      .url(
        this.props.intl.formatMessage({
          id: "validation.ShapeSourceURL",
          defaultMessage: "must be url"
        })
      )
      .required(
        this.props.intl.formatMessage({
          id: "validation.SourceURL",
          defaultMessage: "Source news is required"
        })
      )
      .max(
        limits.title,
        this.props.intl.formatMessage(
          {
            id: "validation.charLimit",
            defaultMessage: "should not exceed {limit} characters"
          },
          { limit: limits.title }
        )
      ),
    Attachment: array()
      .min(
        1,
        this.props.intl.formatMessage({
          id: "validation.attachments",
          defaultMessage: "attachments is required"
        })
      )
      .required(
        this.props.intl.formatMessage({
          id: "validation.attachments",
          defaultMessage: "attachments is required"
        })
      )
  });

  render() {
    const { hideModal, onCreate, suffix } = this.props;
    const { progress } = this.state;
    return (
      <Modal isOpen title="addNewsSource" hideModal={hideModal}>
        <Formik
          initialValues={this.initialValues}
          validationSchema={this.addNewsSourceSchema}
          onSubmit={(values, { setSubmitting }) => {
            const apiData = {
              ...values,
              TitleEn: values.TitleAr,
              TitleAr: values.TitleAr
            };
            axios
              .post("/News/Sources", cleanApiDataForm(apiData), {
                onUploadProgress: progressEvent => {
                  this.setState({
                    progress: Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    )
                  });
                }
              })
              .then(response => {
                if (response.data.StatusCode === "Success") {
                  this.props.onCreateNewsSources({
                    value: response.data.Data.ID,
                    label: response.data.Data[`Title${suffix}`],
                    img: response.data.Data.Attachment.AttachmentURL
                  });
                  onCreate(cleanSourcesForSelect(response));
                }

                setSubmitting(false);
              });
          }}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form autoComplete="off">
              <input
                autoComplete="false"
                name="hidden"
                type="text"
                style={{ display: "none" }}
              />
              <FormInput
                type="text"
                name="TitleAr"
                required
                errors={touched["TitleAr"] && errors["TitleAr"]}
              />
              <FormInput
                type="text"
                name="MainUrl"
                required
                errors={touched["MainUrl"] && errors["MainUrl"]}
              />
              <FormInput
                type="upload"
                module="newsSource"
                name="Attachment"
                imageOnly
                required
                errors={touched["Attachment"] && errors["Attachment"]}
              />
              <FormButton loading={isSubmitting} progress={progress} />
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

AddNewsSourceModal.propTypes = {
  intl: PropTypes.object,
  onCreate: PropTypes.func,
  hideModal: PropTypes.func
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    suffix: state.locale.suffix
  };
};

const mapDispatchToProps = { onCreateNewsSources };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AddNewsSourceModal));
