import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./style.scss";
import PropTypes from "prop-types";
import LogoText from "../LogoText";
import RingChart from "../RingChart";
import { ReactComponent as ArrowDown } from "../../assets/images/arrow-down.svg";

class TaskMemberItem extends Component {
  render() {
    const { theme, locale, key, data, intl, handleClick } = this.props;
    const subText = intl.formatMessage(
      {
        id: "taskMemberItem.completedNumber",
        defaultMessage:
          "{tasksCount} {tasksCount, plural, one {Task} other {Tasks}"
      },
      { tasksCount: data.completedCount }
    );

    return (
      <div
        key={key}
        className={
          locale.ltr ? "task-member-item ltr card" : "task-member-item card"
        }
        style={{
          borderColor: theme.accentColor
        }}
        onClick={e => handleClick(e, data.username)}
      >
        <LogoText name={data.displayName} img={data.img} />
        <div
          className="chart-container"
          style={{
            background: theme.lightGray,
            borderColor: theme.accentColor
          }}
        >
          <RingChart data={data.tasks} />
        </div>
        <button
          onClick={e => handleClick(e, data.username, true)}
          className="member-completed"
          style={{ color: theme.textGray }}
        >
          {subText}
          <ArrowDown
            className={locale.ltr ? "arrow-icon ltr" : "arrow-icon"}
            style={{ fill: theme.textGray }}
          />
        </button>
      </div>
    );
  }
}

TaskMemberItem.propTypes = {
  theme: PropTypes.object,
  locale: PropTypes.object,
  intl: PropTypes.object,
  committe: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ...state
  };
};

export default connect(
  mapStateToProps,
  null
)(injectIntl(withRouter(TaskMemberItem)));
