import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { object, array } from "yup";
import Modal from "../Modal";
import { hideModal, addCommitteeMembers } from "../../actions";
import axios from "axios";
import FormInput from "../FormInput";
import FormButton from "../FormButton";
import CreateNewCommitteeMemberModal from "../CreateNewCommitteeMemberModal";
import {
  cleanApiData,
  cleanUsersForSelect,
  getCommitteeMember,
  sortUsers
} from "../../utils";
import "./style.scss";

class AddMembersModal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      allMembers: [],
      addMember: false,
      initialValues: {
        Members: []
      }
    };
    this.myRef = React.createRef();
    this.ref = React.createRef();
  }

  componentDidMount() {
    const { Committee } = this.props;
    this._isMounted = true;
    axios.get("/ActiveDirectory/Users").then(response => {
      const users = cleanUsersForSelect(response, false, this.props.ltr);
      if (this._isMounted) {
        const filterFromcurrentMembers = sortUsers(users).filter(
          filteredUser => {
            return !this.props.currentMembers.includes(filteredUser.value);
          }
        );

        this.setState({
          users:
            !Committee.Manager && !Committee.Secretary
              ? filterFromcurrentMembers
              : filterFromcurrentMembers.filter(user => {
                  if (Committee.Manager && Committee.Secretary) {
                    return (
                      !Committee.Secretary.Username.includes(user.value) &&
                      !Committee.Manager.Username.includes(user.value)
                    );
                  } else if (Committee.Secretary) {
                    return !Committee.Secretary.Username.includes(user.value);
                  } else if (Committee.Manager) {
                    return !Committee.Manager.Username.includes(user.value);
                  }
                  return true;
                })
        });
      }
    });
  }

  validationSchema = () => {
    return object().shape({
      Members: array().required(
        this.props.intl.formatMessage({
          id: "validation.membersRequire",
          defaultMessage: "members is required"
        })
      )
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleCreateMultiSelect = (value, handleChange) => {
    this.setState({
      value,
      addMember: true,
      changeHandler: handleChange,
      allMembers: this.ref.current.state.values.Members
    });
  };

  onCreate = member => {
    this.setState({
      users: sortUsers([member[member.length - 1], ...this.state.users]),
      addMember: false
    });

    this.state.changeHandler(member);
  };

  render() {
    const { hideModal, data, id, loading } = this.props;
    const { users } = this.state;
    let buttonClassName = "";
    if (users.length > 3) {
      buttonClassName = "add-members-button-modal";
    }
    if (users.length > 0 && users.length < 4) {
      buttonClassName = "small-height";
    }

    return (
      <Modal isOpen title="members" hideModal={hideModal}>
        <Formik
          ref={this.ref}
          validationSchema={this.validationSchema}
          initialValues={data ? data : this.state.initialValues}
          onSubmit={(values, { setSubmitting }) => {
            const apiData = {
              Members: getCommitteeMember(values.Members),
              CommitteeID: id
            };
            this.props.addCommitteeMembers(cleanApiData(apiData));
          }}
        >
          {({ isSubmitting, status }) => (
            <Form>
              <FormInput
                name="Members"
                type="select"
                options={users}
                creatableMultiSelect
                handleCreateMultiSelect={this.handleCreateMultiSelect}
              />

              <div
                className={
                  status === "onMenuOpen" && users.length > 0
                    ? buttonClassName
                    : null
                }
                ref={this.ref}
              >
                <FormButton loading={loading} />
              </div>
            </Form>
          )}
        </Formik>
        {this.state.addMember ? (
          <CreateNewCommitteeMemberModal
            hideModal={() => this.setState({ addMember: false })}
            onCreate={this.onCreate}
            value={this.state.value}
            allMembers={this.state.allMembers}
          />
        ) : null}
      </Modal>
    );
  }
}

AddMembersModal.propTypes = {
  currentMembers: PropTypes.array
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loading: state.committee.CreateLoading,
    currentMembers: state.committee.Committee.Members.map(
      member => member.MemberInfo.Username
    ),
    Committee: state.committee.Committee,
    ltr: state.locale.ltr
  };
};

const mapDispatchToProps = { hideModal, addCommitteeMembers };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AddMembersModal));
