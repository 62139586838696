import React, * as react from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import CalenderIcon from "../CalenderIcon";
import moment from "moment";

import "./style.scss";

class MeetingsCell extends react.Component {
  cutString = string => {
    if (string.length > 25) {
      return `${string.substring(0, 28)}...`;
    } else {
      return string;
    }
  };
  render() {
    const { theme, data, ltr, upcomingMeetingsprop, withoutTime } = this.props;

    return (
      <div
        className={
          upcomingMeetingsprop ? "meetings-cell padding-cell" : "meetings-cell"
        }
      >
        <div className="calendar-icon-container">
          <CalenderIcon theme={theme} date={data.StartDate} ltr={ltr} />
        </div>
        <div className="text-containers bold">
          {upcomingMeetingsprop ? this.cutString(data.TitleAr) : data.TitleAr}
          <div
            className="venue small-font-size "
            style={{ color: theme.textGray }}
          >
            <div>
              {upcomingMeetingsprop ? this.cutString(data.Venue) : data.Venue}
            </div>
          </div>
          {!withoutTime && (
            <div
              className="date small-font-size"
              style={{ color: theme.textGray }}
            >
              <div>{`${moment(data.StartDate * 1000).format(
                "h:mm a"
              )} \xa0 - \xa0 ${moment(data.EndDate * 1000).format(
                "h:mm a"
              )}`}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    upcomingMeetings: state.meeting.upcomingMeetings,
    loading: state.meeting.upcomingMeetingsLoading,
    ltr: state.locale.ltr
  };
};

export default connect(mapStateToProps, null)(injectIntl(MeetingsCell));
