import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import Slider from "rc-slider";
import axios from "axios";
import {
  updateTaskProgress,
  timelineUpdateProgress,
  fetchTimeline,
  updateCalenderProgress,
  updateTaskStatus,
  updateTimelineTaskStatus
} from "../../actions";
import "./style.scss";

class ProgressSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderValue: 0
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      sliderValue: this.props.sliderValue
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.sliderValue !== prevProps.sliderValue) {
      this.setState({ sliderValue: this.props.sliderValue });
    }
  }

  updateProgress = event => {
    axios
      .post("/Tasks/TaskProgress/", {
        ID: this.props.id,
        Progress: event
      })
      .then(res => {
        this.props.updateCalenderProgress(this.props.id, event);
        this.props.updateTaskStatus(this.props.id, event);
        this.props.updateTimelineTaskStatus(this.props.id, event);

        if (!this.props.timeline) {
          this.props.updateTaskProgress(this.props.id, event);
        } else {
          this.props.timelineUpdateProgress(this.props.id, event);
        }
      });
  };

  render() {
    const {
      theme,
      disabled,

      canProgress,
      mini
    } = this.props;

    return (
      <div
        className="progress-slider-container"
        onClick={e => e.stopPropagation()}
      >
        {!mini && (
          <div
            className={
              canProgress ? "header-container withMargin" : "header-container"
            }
          >
            <div className="title">
              <FormattedMessage
                id="task.details.progress"
                defaultMessage="progress percentage"
              />

              <p
                className="progress-percentege"
                style={{ color: theme.textGray }}
              >
                ({this.state.sliderValue}%)
              </p>
            </div>
          </div>
        )}
        <div className={`slider-with-number ${mini ? "mini" : ""}`}>
          {mini || canProgress ? (
            <Slider
              ref={this.myRef}
              min={0}
              max={100}
              defaultValue={this.state.sliderValue}
              value={this.state.sliderValue}
              railStyle={{ backgroundColor: theme.accentColor }}
              trackStyle={{ backgroundColor: theme.primary }}
              onChange={event => {
                return this.setState({
                  sliderValue: event
                });
              }}
              handleStyle={[{ display: disabled ? "none" : "block" }]}
              onAfterChange={this.updateProgress}
              disabled={disabled}
            />
          ) : null}
          {mini && (
            <div className="slider-value">{this.state.sliderValue}%</div>
          )}
        </div>
      </div>
    );
  }
}

ProgressSlider.propTypes = {};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    taskStatus: state.theme.Tasks_Status,
    suffix: state.locale.suffix
  };
};

const mapDispatchToProps = {
  updateTaskProgress,
  timelineUpdateProgress,
  fetchTimeline,
  updateCalenderProgress,
  updateTaskStatus,
  updateTimelineTaskStatus
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgressSlider);
