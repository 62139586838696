import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import "./style.scss";

import Tabs from "../Tabs";
import AdhocInformation from "../AdhocInformation";

import WorkFlowSteps from "../WorkFlowSteps";
import PreviousProcedure from "../PreviousProcedure";
import AdhocActionModal from "../AdhocActionModal";
import AddAdhocModal from "../AddAdhocModal";
import { showModal, hideModal } from "../../actions";

import { ReactComponent as TakeActionIcon } from "../../assets/images/take-action-icon.svg";

const tabs = [{ name: "info" }, { name: "previous-procedure" }];

class AdhocDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "info",
      showModal: false
    };

    this.hideModal = this.hideModal.bind(this);
  }

  selectTab = tab => {
    this.setState({
      tab: tab
    });
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { theme, locale, data } = this.props;
    return (
      <div>
        <div className="adhoc-details card">
          <WorkFlowSteps steps={data.Steps} currentStep={data.Stage} />

          <div className="adhoc-tabs-container">
            <div className="tabs">
              <Tabs
                tabs={tabs}
                selectTab={this.selectTab}
                selected={this.state.tab}
              />
            </div>
            {theme.isWorkflowMember &&
            theme.WorkflowGroup === data.Stage &&
            data.NeedAction ? (
              <div className="take-action-button-container">
                <button
                  className={
                    locale.ltr ? "take-action-button ltr" : "take-action-button"
                  }
                  style={{ backgroundColor: theme.primary }}
                  onClick={this.showModal}
                >
                  <TakeActionIcon />
                  <FormattedMessage
                    id="adhoc.take-action-button"
                    defaultMessage="Take action"
                  />
                </button>
              </div>
            ) : null}
            {!theme.isWorkflowMember && data.Status === "SendBack" ? (
              <div className="take-action-button-container">
                <button
                  className={
                    locale.ltr ? "take-action-button ltr" : "take-action-button"
                  }
                  style={{ backgroundColor: theme.primary }}
                  onClick={() =>
                    this.props.showModal(
                      <AddAdhocModal
                        data={data}
                        hideModal={this.props.hideModal}
                      />
                    )
                  }
                >
                  <FormattedMessage
                    id="adhoc.editAdhoc"
                    defaultMessage="Edit Request"
                  />
                </button>
              </div>
            ) : null}
          </div>
        </div>
        {this.state.tab === "info" ? (
          <AdhocInformation data={data} />
        ) : (
          <PreviousProcedure data={data} />
        )}
        {this.state.showModal ? (
          <AdhocActionModal adhoc={data} hideModal={this.hideModal} />
        ) : null}
      </div>
    );
  }
}

AdhocDetails.propTypes = {
  theme: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    locale: state.locale,
    statuses: state.theme.Adhoc_Status
  };
};

const mapDispatchToProps = {
  showModal,
  hideModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdhocDetails);
