import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import AttachmentType from "../AttachmentType";
import MenuButton from "../MenuButton";
import CommitteeAttachmentItem from "../CommitteeAttachmentItem";
import AddAttachmentsModal from "../AddAttachmentsModal";
import { ReactComponent as ArrowIcon } from "../../assets/images/arrow-down.svg";
import { ReactComponent as UploadIcon } from "../../assets/images/upload-icon.svg";
import RoundedButton from "../RoundedButton";
import FolderEmptyComponent from "../EmptyComponents/FolderEmptyComponent";
import RenameFolderModal from "../RenameFolderModal";
import DaysSince from "../DaysSince";
import { showModal, hideModal, removeCommitteeFolder } from "../../actions";
import { getPermission } from "../../utils";
import "./style.scss";

class Folder extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      loading: false,
      renameFolder: false
    };

    this.toggleFolder = this.toggleFolder.bind(this);
    this.deleteFolder = this.deleteFolder.bind(this);
    this.renameFolder = this.renameFolder.bind(this);
  }

  toggleFolder() {
    this.setState((state, props) => {
      return { isOpen: !state.isOpen };
    });
  }

  renameFolder = () => {
    this.setState({
      renameFolder: true
    });
  };

  deleteFolder() {
    this._isMounted = true;
    const { folder, removeCommitteeFolder } = this.props;
    this.setState({ loading: true }, () => {
      axios
        .post("/CommitteeFolders/Delete", {
          ID: folder.ID,
          CommitteeID: folder.CommitteeID
        })
        .then(res => {
          if (res.data.StatusCode === "Success") {
            removeCommitteeFolder(folder.ID);
          }
          this.setState({ loading: false });
        });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      folder,
      colors,
      showModal,
      hideModal,
      ltr,
      currentUser,
      data,
      theme
    } = this.props;
    const { isOpen, loading } = this.state;

    const options = [];

    const permission = getPermission(currentUser, data, [
      "Manager",
      "Secretary"
    ]);

    if (theme.allowUpdateFile || permission) {
      options.push({
        id: 1,
        name: "editName",
        action: () => this.renameFolder()
      });
    }

    if (theme.allowDeleteFile) {
      options.push({
        id: 2,
        name: "delete",
        warning: true,
        action: () => this.deleteFolder()
      });
    }

    return (
      <div className="folder">
        <div
          className="folder-details committee-attachment-item"
          onClick={this.toggleFolder}
        >
          <div className="folder-arrow-container">
            <ArrowIcon
              className="folder-arrow"
              style={{
                fill: colors.primary,
                transform: isOpen
                  ? "rotate(0deg)"
                  : `rotate(${ltr ? "-" : ""}90deg)`
              }}
            />
          </div>
          <div
            className="photo-container"
            style={{ borderColor: colors.accentColor }}
          >
            <div className="files-img">
              <AttachmentType type="folder" accentColor={colors.accentColor} />
            </div>
          </div>
          <div
            className="folder-text-container"
            style={{ borderColor: colors.accentColor }}
          >
            <div
              className="text-folder"
              // style={{ borderColor: colors.accentColor }}
            >
              <p className="file-name">{folder.NameAr}</p>
              <div className="second-text">
                <DaysSince
                  style={{ color: colors.accentColor }}
                  startDate={folder.CreatedDate}
                />
              </div>
            </div>
            {permission || theme.allowAddFile ? (
              <div className="folder-actions">
                <RoundedButton
                  title="files"
                  Icon={UploadIcon}
                  color={colors.accentColor}
                  onClick={() =>
                    showModal(
                      <AddAttachmentsModal
                        hideModal={hideModal}
                        id={folder.CommitteeID}
                        folderId={folder.ID}
                      />
                    )
                  }
                />
                {options.length ? (
                  <div className="menu-container">
                    <MenuButton
                      loading={loading}
                      options={options}
                      title="folderOptions"
                      dots
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
        {isOpen ? (
          <div
            className="folder-files"
            style={{ backgroundColor: colors.primaryLight }}
          >
            {folder.attachments.length === 0 ? (
              <FolderEmptyComponent color={colors.textGray} />
            ) : (
              folder.attachments.map((file, index) => (
                <CommitteeAttachmentItem key={index} attachment={file} />
              ))
            )}
          </div>
        ) : null}
        {this.state.renameFolder ? (
          <RenameFolderModal
            hideModal={() => this.setState({ renameFolder: false })}
            folder={folder}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    colors: {
      accentColor: state.theme.accentColor,
      primaryLight: state.theme.primaryLight,
      primary: state.theme.primary,
      textGray: state.theme.textGray
    },
    ltr: state.locale.ltr,
    currentUser: state.user.UserData.Username,
    theme: state.theme
  };
};

const mapDispatchToProps = { showModal, hideModal, removeCommitteeFolder };

export default connect(mapStateToProps, mapDispatchToProps)(Folder);
