import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import uniqid from "uniqid";
import { Formik, Form } from "formik";
import { injectIntl } from "react-intl";
import { string, object } from "yup";
import Modal from "../Modal";
import FormButton from "../FormButton";
import FormInput from "../FormInput";
import { cleanApiDataForm } from "../../utils";
import { limits } from "../../configs";

class CreateNewCommitteeMemberModal extends Component {
  state = {
    progress: false
  };

  initialValues = {
    DisplayName: this.props.value,
    Email: "",
    Image: []
  };

  validateDisplayName = () => {
    return string()
      .required(
        this.props.intl.formatMessage({
          id: "validation.DisplayName",
          defaultMessage: "DisplayName is required"
        })
      )
      .max(
        limits.title,
        this.props.intl.formatMessage(
          {
            id: "validation.charLimit",
            defaultMessage: "should not exceed {limit} characters"
          },
          { limit: limits.title }
        )
      );
  };

  CreateNewCommitteeMemberSchema = () => {
    return object().shape({
      DisplayName: this.validateDisplayName(),
      Email: string()
        .required(
          this.props.intl.formatMessage({
            id: "validation.Email",
            defaultMessage: "Email is required"
          })
        )
        .email(
          this.props.intl.formatMessage({
            id: "validation.EmailError",
            defaultMessage: "Must be Email"
          })
        )
        .max(
          limits.title,
          this.props.intl.formatMessage(
            {
              id: "validation.charLimit",
              defaultMessage: "should not exceed {limit} characters"
            },
            { limit: limits.title }
          )
        )
    });
  };

  validate = values => {
    if (values.email !== "") {
      this.CreateNewCommitteeMemberSchema();
    } else {
      object().shape({
        DisplayName: this.validateDisplayName()
      });
    }
  };

  render() {
    const { hideModal, onCreate, allMembers } = this.props;
    return (
      <Modal isOpen title="CreateNewCommitteeMember" hideModal={hideModal}>
        <Formik
          validate={values => this.validate(values)}
          initialValues={this.initialValues}
          validationSchema={this.CreateNewCommitteeMemberSchema}
          onSubmit={(values, { setSubmitting }) => {
            const apiData = {
              DisplayName: values.DisplayName,
              Email: values.Email,
              UserName: uniqid(),
              ProfilePicture: values.Image
            };

            axios
              .post("/Committee/ExternalUser", cleanApiDataForm(apiData), {
                onUploadProgress: progressEvent => {
                  this.setState({
                    progress: Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    )
                  });
                }
              })
              .then(response => {
                onCreate([
                  ...allMembers,
                  {
                    img: response.data.Data.ProfilePicture.AttachmentURL,
                    label: response.data.Data.DisplayName,
                    value: response.data.Data.UserName
                  }
                ]);
                this.setState({ progress: "" });
                setSubmitting(false);
              });
          }}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form autoComplete="off">
              <input
                autoComplete="false"
                name="hidden"
                type="text"
                style={{ display: "none" }}
              />
              <FormInput
                type="text"
                name="DisplayName"
                required
                errors={touched["DisplayName"] && errors["DisplayName"]}
              />
              <FormInput
                type="text"
                name="Email"
                required
                errorMessage
                errors={touched["Email"] && errors["Email"]}
              />

              <FormInput type="upload" name="Image" imageOnly />
              <FormButton
                loading={isSubmitting}
                progress={this.state.progress}
              />
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

CreateNewCommitteeMemberModal.propTypes = {
  intl: PropTypes.object,
  onCreate: PropTypes.func
};

export default injectIntl(CreateNewCommitteeMemberModal);
