import React from "react";
import AttachmentType from "../AttachmentType";
import DeleteButton from "../DeleteButton";
import "./style.scss";

const ImagesType = ["png", "jpg", "gif", "jpeg", "svg"];

const CommentAttachmentImage = props => {
  const { attachment, removeAttachment, index, ltr, type } = props;

  if (ImagesType.includes(type))
    return (
      <div className={ltr ? "image-box rtl tooltip" : "image-box tooltip"}>
        <div className="delete-attach-button">
          <DeleteButton
            deleteAction={() => removeAttachment(index)}
            attachment
            noConfirmation
          />
        </div>
        <img src={attachment.preview} alt="SourceImg" />
      </div>
    );
  return (
    <div
      className={
        ltr
          ? "image-box rtl tooltip file-image"
          : "image-box tooltip file-image"
      }
    >
      <div className="delete-attach-button">
        <DeleteButton
          deleteAction={() => removeAttachment(index)}
          attachment
          noConfirmation
        />
      </div>
      <div className="default-images">
        <AttachmentType type={type} />
      </div>
    </div>
  );
};

export default CommentAttachmentImage;
