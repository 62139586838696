import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import "./style.scss";

class Table extends React.Component {
  render() {
    const { data, Component, canEdit } = this.props;
    return (
      <div className="table-container">
        {data.map((dataItem, i) => (
          <Component data={dataItem} key={i} canEdit={canEdit} />
        ))}
      </div>
    );
  }
}

Table.propTypes = {
  data: PropTypes.array
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ...state
  };
};

export default connect(
  mapStateToProps,
  null
)(injectIntl(withRouter(Table)));
