import React from "react";
import MediaItem from "./MediaItem";
import "./style.scss";

const MediaGrid = ({ media = [], ...otherProps }) => {
  const viewableItems = media.slice(0, 3);
  const remaining = media.length - viewableItems.length;
  return (
    <div
      // css={(theme: any) => styles.grid(theme, viewableItems.length)}
      className={`media-grid i${viewableItems.length}`}
      {...otherProps}
    >
      {viewableItems.map((item, index) =>
        item ? (
          <MediaItem
            key={index}
            item={item}
            remaining={index === viewableItems.length - 1 ? remaining : 0}
          />
        ) : null
      )}
    </div>
  );
};

export default MediaGrid;
