import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FormattedMessage, injectIntl } from "react-intl";
import DropDown from "../DropDown";
import { ReactComponent as TasksFilterIcon } from "../../assets/images/tasks-filter-icon.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/arrow-down.svg";

class ViewButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu = event => {
    this.setState({ isOpen: true }, () => {
      document.addEventListener("click", e => {
        const paths = e.composedPath().map(path => path.className);
        if (!paths.includes("view-button")) {
          this.closeMenu();
        }
      });
    });
  };

  closeMenu = () => {
    this.setState({ isOpen: false }, () => {
      document.removeEventListener("click", this.closeMenu);
    });
  };

  list = () => {
    return [
      {
        id: 1,
        name: this.props.intl.formatMessage({
          id: "filterButton.timeline",
          defaultMessage: "Timeline"
        }),
        action: () => {
          this.props.history.push({
            pathname: "/tasks",
            search: this.props.location.search
          });
          this.closeMenu();
        }
      },
      {
        id: 2,
        name: this.props.intl.formatMessage({
          id: "filterButton.members",
          defaultMessage: "Members"
        }),
        action: () => {
          this.props.history.push({
            pathname: "/tasks/members",
            search: this.props.location.search
          });
          this.closeMenu();
        }
      }
    ];
  };

  render() {
    const { textGray, intl } = this.props;

    return (
      <div className="view-button" style={{ "--text-gray": textGray }}>
        <div
          onClick={this.showMenu}
          className="filter-button"
          style={{ borderColor: textGray }}
        >
          <TasksFilterIcon
            className="filter-icon"
            style={{ stroke: textGray }}
          />
          <p className="filter-text" style={{ color: textGray }}>
            <FormattedMessage
              id={`filterButton.filterBytasks`}
              defaultMessage="Filter by"
            />
          </p>
          <ArrowDown className="filter-icon" style={{ fill: textGray }} />
        </div>
        {this.state.isOpen ? (
          <DropDown
            title={intl.formatMessage({
              id: `filterButton.filterBytasks`,
              defaultMessage: "Filter by"
            })}
            options={this.list()}
            noTranslate
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    textGray: state.theme.textGray
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(injectIntl(ViewButton)));
