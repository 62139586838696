export const CALENDER = "CALENDER";
export const CALENDER_ASYNC = "CALENDER_ASYNC";
export const CALENDER_ERROR = "CALENDER_ERROR";
export const UPDATE_CALENDER_PROGRESS = "UPDATE_CALENDER_PROGRESS";
export const UPDATE_CALENDER_TASKS = "UPDATE_CALENDER_TASKS";
export const UPDATE_CALENDER_MEETINGS = "UPDATE_CALENDER_MEETINGS";

export function calenderData(calender) {
  return { type: CALENDER, calender };
}

export function calenderAsync(calender) {
  return { type: CALENDER_ASYNC, calender };
}

export function calenderFailed(error) {
  return { type: CALENDER_ERROR, error };
}

export function updateCalenderProgress(id, progress) {
  return { type: UPDATE_CALENDER_PROGRESS, id, progress };
}

export function updateCalenderTasks(data) {
  return { type: UPDATE_CALENDER_TASKS, data };
}

export function updateCalenderMeetings(data) {
  return { type: UPDATE_CALENDER_MEETINGS, data };
}
