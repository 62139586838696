import React, { Component } from "react";
import PropTypes from "prop-types";
import MainSelect from "react-select";
import MainSelectCreatable from "react-select/lib/Creatable";
import { connect } from "formik";
import OptionComponent from "../OptionComponent";
import SingleSelectValue from "../SingleSelectValue";
import Loading from "../Loading";
import "./style.scss";

class SingleSelect extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.refSelect = React.createRef();
  }
  customTheme = theme => ({
    ...theme,
    borderRadius: 5,
    colors: {
      ...theme.colors,
      primary: this.props.theme.primary,
      primary75: this.props.theme.primaryLight,
      primary50: this.props.theme.primaryLight,
      primary25: this.props.theme.primaryLight
    }
  });

  customStyles = {
    option: (provided, state) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      minHeight: 40
    }),
    input: (provided, state) => ({
      height: 40,
      borderColor: this.props.theme.accentColor
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: this.props.theme.accentColor,
      boxShadow: "none",
      "&:hover": { borderColor: this.props.theme.accentColor }
    }),
    singleValue: (provided, state) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      lineHeight: "1.7"
    }),
    indicatorSeparator: (provided, state) => ({
      display: "none"
    })
  };

  handleChange = value => {
    const { limit } = this.props;
    if (limit) {
      this.props.formik.setFieldValue(this.props.name, value.slice(-limit));
    } else {
      this.props.formik.setFieldValue(this.props.name, value);
    }
  };

  componentDidMount() {
    this.props.formik.registerField(this.props.name, this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.name !== prevProps.name) {
      this.props.formik.unregisterField(prevProps.name);
      this.props.formik.registerField(this.props.name, this);
    }

    if (this.props.validate !== prevProps.validate) {
      this.props.formik.registerField(this.props.name, this);
    }
  }

  componentWillUnmount() {
    this.props.formik.unregisterField(this.props.name);
  }

  render() {
    const {
      name,
      formik,
      placeholder,
      options,
      locale,
      theme,
      handleCreate,
      noCreate,
      clearable,
      taskFilter,
      disabled,
      errors,
      stopSelectLoading
    } = this.props;
    const value = formik.values[name];

    return (
      <div className={errors ? "select-container error" : "select-container"}>
        {noCreate ? (
          <MainSelect
            className="select"
            id={name}
            isRtl={!locale.ltr}
            components={{
              Option: OptionComponent
              // SingleValue: SingleSelectValue
            }}
            styles={this.customStyles}
            theme={this.customTheme}
            options={options}
            value={value}
            placeholder={placeholder}
            onChange={this.handleChange}
            onBlur={formik.onBlur}
            Clearable={clearable}
            isClearable={taskFilter ? true : false}
            isDisabled={disabled}
          />
        ) : (
          <MainSelectCreatable
            className="select"
            id={name}
            isRtl={!locale.ltr}
            components={{
              Option: OptionComponent,
              SingleValue: SingleSelectValue
            }}
            styles={this.customStyles}
            theme={this.customTheme}
            options={options}
            value={value}
            placeholder={placeholder}
            onChange={this.handleChange}
            onCreateOption={value => {
              handleCreate(value, this.handleChange);
              this.refSelect.select.select.blur();
            }}
            onBlur={formik.onBlur}
            Clearable={clearable}
            ref={ref => {
              this.refSelect = ref;
            }}
          />
        )}
        {options.length <= 0 && !stopSelectLoading ? (
          <Loading
            className={`select-loading ${locale.ltr ? "ltr" : ""}`}
            color={theme.primary}
          />
        ) : null}
      </div>
    );
  }
}

SingleSelect.propTypes = {
  theme: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired
};

export default connect(SingleSelect);
