import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { css } from "emotion";
import DOMPurify from "dompurify";
// import LocaleAwareText from "../LocaleAwareText";
import "./style.scss";

const MeetingModalSection = props => {
  const { item, title, primaryColor, actions } = props;
  const style = css`
    margin: 1.5em 0;
    ${actions ? "margin-bottom: 0;" : ""}
    li {
      position: relative;
      padding: 5px 1em;

      &::before {
        content: "";
        display: inline-block;
        margin: 0 5px;
        width: 6px;
        height: 6px;
        border: 2px solid ${primaryColor};
        border-radius: 10px;
      }
    }
  `;
  return (
    <div className="meeting-modal-section">
      <div className="sub-title">
        <div className="circle" style={{ background: primaryColor }} />
        <h4>
          <FormattedMessage
            id={`meetingDetailsModal.${title}`}
            defaultMessage={title}
          />
          {" :"}
        </h4>
      </div>
      {actions ? (
        <div className={style}>
          <ul>
            {item.map((i, key) => (
              <li key={key}>
                {i.DescriptionAr}{" "}
                <Link
                  className="details-link"
                  style={{ color: primaryColor }}
                  to={`/tasks/${i.TaskID}`}
                >
                  -{" "}
                  <FormattedMessage
                    id="meetingDetailsModal.details"
                    defaultMessage="Details"
                  />
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div
          className={style}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(item)
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    primaryColor: state.theme.primary,
    ltr: state.locale.ltr
  };
};

export default connect(
  mapStateToProps,
  null
)(MeetingModalSection);
