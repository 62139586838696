import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AdhocDetailsLoadingCard from "../../components/LoadingCards/AdhocDetailsLoadingCard";
import AdhocDetails from "../../components/AdhocDetails";
import { fetchAdhocDetails } from "../../actions";

class AdhocDetailsPage extends Component {
  componentDidMount() {
    const { match } = this.props;
    this.props.fetchAdhocDetails(match.params.id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.props.fetchAdhocDetails(this.props.match.params.id);
    }
  }

  render() {
    const { adhoc } = this.props;
    if (!adhoc.Adhoc || adhoc.Loading) return <AdhocDetailsLoadingCard />;
    return (
      <div>
        <AdhocDetails data={adhoc.Adhoc} />
      </div>
    );
  }
}

AdhocDetailsPage.propTypes = {
  theme: PropTypes.object,
  locale: PropTypes.object
};

const mapDispatchToProps = { fetchAdhocDetails };

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    locale: state.locale,
    adhoc: state.adhoc
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdhocDetailsPage));
