import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import Modal from "../Modal";
import LoadingComponent from "../Loading";

import Badge from "../Badge";
import { injectIntl } from "react-intl";
import moment from "moment";
import { ReactComponent as Clock } from "../../assets/images/clock-icon.svg";

import { fetchMeeting } from "../../actions";
import "./style.scss";
import { arrangeGoogleData } from "../../utils";
import ApiCalendar from "react-google-calendar-api";

class GoogleMeetingDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addMOM: false,
      data: null
    };
  }

  componentDidMount() {
    ApiCalendar.onLoad(() => {
      setTimeout(() => {
        if (ApiCalendar.sign)
          return ApiCalendar.gapi.client.calendar.events
            .list({
              calendarId: "primary",

              showDeleted: false,
              singleEvents: true,

              orderBy: "startTime",
              iCalUID: this.props.match.params.id
            })
            .then(res => {
              this.setState({ data: arrangeGoogleData(res.result.items)[0] });
            });
      }, 1000);
    });
  }

  render() {
    const {
      theme,

      history,
      location,

      locale,
      intl
    } = this.props;

    if (!this.state.data) {
      return (
        <Modal
          hideModal={() =>
            location.state ? history.goBack() : history.push("/meetings")
          }
          isOpen
          title="noTitle"
          noTitle
        >
          <div className="modal-loading">
            <LoadingComponent color={theme.primary} />
          </div>
        </Modal>
      );
    }

    const { data } = this.state;

    return (
      <Modal
        hideModal={() =>
          location.state ? history.goBack() : history.push("/meetings")
        }
        isOpen
        title="noTitle"
        noTitle
      >
        <div className="google-meeting-details">
          <div className="header-container">
            <div className="header-first-section">
              <p
                style={{ color: theme.textColor, fontSize: 20 }}
                className="bold"
              >
                {data.TitleAr}
              </p>
              <Badge
                backgroundColor={theme.Meeting_Status[data.Status].Color}
                text={
                  theme.Meeting_Status[data.Status][`Title${locale.suffix}`]
                }
                color="white"
              />
            </div>
          </div>

          <div className="meeting-detail" style={{ color: theme.textGray }}>
            <p className={locale.ltr ? "text ltr" : "text"}>
              {intl.formatMessage({
                id: "role.Creator",
                defaultMessage: "Creator"
              })}{" "}
            </p>
            <p className={locale.ltr ? "text ltr" : "text"}>
              {data.Creator.DisplayName}
            </p>
          </div>
          <div className="badges-container">
            <Badge
              text={`${moment(data.StartDate * 1000).format(
                "hh:mm a"
              )} - ${moment(data.EndDate * 1000).format(
                "hh:mm a  \xa0\xa0\xa0 DD MMM YYYY"
              )}`}
              Icon={Clock}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    Loading: state.meeting.SingleLoading,
    Meeting: state.meeting.Meeting,
    ltr: state.locale.ltr,
    locale: state.locale
  };
};

const mapDispatchToProps = { fetchMeeting };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(GoogleMeetingDetailsModal)));
