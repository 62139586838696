import React, { Component } from "react";
import { Formik, Form } from "formik";
import { object, string } from "yup";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import Modal from "../Modal";
import { renameFolder } from "../../actions";
import FormInput from "../FormInput";
import axios from "axios";
import "./style.scss";
import FormButton from "../FormButton";

class RenameFolderModal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        NameAr: props.folder.NameAr,
        ID: props.folder.ID,
        CommitteeID: props.folder.CommitteeID
      },
      loading: false
    };
    this.renameFolder = this.renameFolder.bind(this);
  }

  validationSchema = object().shape({
    NameAr: string().required(
      this.props.intl.formatMessage({
        id: "validation.nameRequired",
        defaultMessage: "Name is required"
      })
    )
  });

  renameFolder = values => {
    this._isMounted = true;
    this.setState({ loading: true }, () => {
      axios.post("/CommitteeFolders/Rename", values).then(res => {
        if (res.data.StatusCode === "Success") {
          this.props.renameFolder(this.props.folder.ID, res.data.Data.NameAr);
          this.props.hideModal();
        }
        if (this._isMounted) {
          this.setState({ loading: false });
        }
      });
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { hideModal } = this.props;
    const { loading } = this.state;

    return (
      <Modal isOpen title="renameFolder" hideModal={hideModal}>
        <Formik
          validationSchema={this.validationSchema}
          initialValues={this.state.initialValues}
          onSubmit={this.renameFolder}
        >
          {({ isSubmitting, values }) => (
            <Form autoComplete="off">
              <input
                autoComplete="false"
                name="hidden"
                type="text"
                style={{ display: "none" }}
              />
              <FormInput type="text" name="NameAr" />
              <FormButton loading={loading} edit />
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    locale: state.locale
  };
};

const mapDispatchToProps = { renameFolder };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(RenameFolderModal));
