import { SHOW_TOAST, HIDE_TOAST } from "../actions";

const initialState = {
  toast: false
};

function toast(state = initialState, action) {
  switch (action.type) {
    case SHOW_TOAST:
      return {
        toast: true,
        pageType: action.pageType === "Meetings" ? "meeting" : action.pageType,
        ID: action.ID,
        isUpdate: action.isUpdate ? true : false,
        isCommittee: action.isCommittee ? true : false,
        isMeetingID: action.isMeetingID ? true : null
      };
    case HIDE_TOAST:
      return {
        toast: false
      };
    default:
      return state;
  }
}

export default toast;
