import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  withConfirmation,
  showModal,
  updateTimelineMeetings
} from "../../actions";
import Loading from "../Loading";
import AcceptMeetingRequestModal from "../AcceptMeetingRequestModal";
import PostponeMeetingRequestModal from "../PostponeMeetingRequestModal";

import "./style.scss";

class MeetingRequestAction extends Component {
  constructor() {
    super();
    this.state = {
      id: ""
    };
  }

  accept = () => {
    if (this.props.adhoc) {
      this.setState({ id: this.props.id ? this.props.id : null }, () => {
        this.props.withConfirmation(this.props.acceptAction);
      });
    } else {
      this.props.showModal(
        <AcceptMeetingRequestModal
          id={this.props.id}
          timeline={this.props.timeline}
        />
      );
    }
  };

  reject = () => {
    this.setState({ id: this.props.id ? this.props.id : null }, () => {
      this.props.withConfirmation(this.props.rejectAction);
    });
  };

  postpone = () => {
    this.props.showModal(
      <PostponeMeetingRequestModal
        id={this.props.id}
        meetingDate={this.props.meetingDate}
        timeline={this.props.timeline}
      />
    );
    // this.setState({ id: this.props.id ? this.props.id : null }, () => {
    //   this.props.withConfirmation(this.props.postponeAction);
    // });
  };

  render() {
    const { theme, ltr, disabled, id, ActionLoading, locale } = this.props;
    return (
      <div
        className={
          ltr ? "meeting-request-actions ltr" : "meeting-request-actions"
        }
      >
        {ActionLoading && this.state.id === id ? (
          <Loading color={theme.primary} />
        ) : (
          <>
            <button
              className="accept-reject-btn "
              style={{
                backgroundColor: theme.Meeting_Request_Status["1"].Color
              }}
              disabled={disabled}
              onClick={() => this.accept()}
            >
              <div className="button-container">
                <p className={ltr ? "btn-text ltr" : "btn-text"}>
                  {theme.Meeting_Request_Status["1"][`Title${locale.suffix}`]}
                </p>
              </div>
            </button>

            <button
              className="accept-reject-btn"
              style={{
                backgroundColor: theme.Meeting_Request_Status["0"].Color
              }}
              disabled={disabled}
              onClick={() => this.reject()}
            >
              <div className="button-container">
                <p className={ltr ? "btn-text ltr" : "btn-text"}>
                  {theme.Meeting_Request_Status["0"][`Title${locale.suffix}`]}
                </p>
              </div>
            </button>
            {!this.props.adhoc && (
              <button
                className="accept-reject-btn "
                style={{
                  backgroundColor: theme.Meeting_Request_Status["3"].Color
                }}
                disabled={disabled}
                onClick={() => this.postpone()}
              >
                <div className="button-container">
                  <p className={ltr ? "btn-text ltr" : "btn-text"}>
                    {theme.Meeting_Request_Status["3"][`Title${locale.suffix}`]}
                  </p>
                </div>
              </button>
            )}
          </>
        )}
      </div>
    );
  }
}

MeetingRequestAction.propTypes = {
  theme: PropTypes.object,
  locale: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    ltr: state.locale.ltr,
    locale: state.locale
  };
};

const mapDispatchToProps = {
  withConfirmation,
  showModal,
  updateTimelineMeetings
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MeetingRequestAction);
