import React, { useState } from "react";
import { connect } from "react-redux";
import { object, string } from "yup";
import { Formik, Form } from "formik";
import ReactModal from "react-modal";
import { FormattedMessage } from "react-intl";
import Button from "../../components/Button";
import { hideModal } from "../../actions";
import FormInput from "../../components/FormInput";
import "./style.scss";
import { limits } from "../../configs";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    background: "white",
    zIndex: 1500,
    borderRadius: 5,
    border: "none",
    boxShadow: "1px 1px 3px 0px rgba(0, 0, 0, 0.1)",
    padding: 0
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    paddingTop: "3em"
  }
};

ReactModal.setAppElement("#root");

const RejectModal = props => {
  const [loading, setLoading] = useState(false);

  const { gray, hideModal, onConfirm, customMessage } = props;

  const validationSchema = object().shape({
    rejectcomments: string()
      .max(limits.description)
      .required()
  });

  return (
    <ReactModal
      onRequestClose={hideModal}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      style={customStyles}
      isOpen
    >
      <div className="confirmation-tasks-container">
        <h3 className="confirmation-message">
          {customMessage ? (
            customMessage
          ) : (
            <FormattedMessage
              id="label.rejectcomments"
              defaultMessage="Are you sure?"
            />
          )}
        </h3>

        <Formik
          initialValues={{ rejectcomments: "" }}
          validationSchema={validationSchema}
          onSubmit={values => {
            setLoading(true);
            onConfirm(values.rejectcomments, setLoading);
          }}
        >
          {({ touched, errors }) => (
            <Form autoComplete="off">
              <FormInput
                type="textarea"
                name="rejectcomments"
                withoutLabel
                required
                errors={touched["rejectcomments"] && errors["rejectcomments"]}
              />

              <div className="confirmation-buttons">
                <Button
                  loading={loading}
                  type="submit"
                  style={{ backgroundColor: "red" }}
                >
                  <FormattedMessage
                    id="meeting-request-reject"
                    defaultMessage="reject"
                  />
                </Button>
                <Button onClick={hideModal} style={{ backgroundColor: gray }}>
                  <FormattedMessage
                    id="confirmation.cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </ReactModal>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    customMessage: state.confirmation.Message,
    gray: state.theme.textGray
  };
};

const mapDispatchToProps = { hideModal };

export default connect(mapStateToProps, mapDispatchToProps)(RejectModal);
