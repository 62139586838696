import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import "./style.scss";
import { daysSince } from "../../utils";
import moment from "moment";

const DaysSince = props => {
  const { style, startDate, withTime } = props;
  const days = daysSince(startDate);

  return (
    <span className="daysSince" style={style}>
      <FormattedMessage
        id={`taskItem.${days.type}Since`}
        defaultMessage="{daysSince, plural, =0 {Today} one {Yesterday} other {{daysSince} days ago}}"
        values={{ daysSince: days.amount }}
      />
      {withTime ? (
        <span>{moment(startDate * 1000).format(" - h:mm a")}</span>
      ) : null}
    </span>
  );
};

DaysSince.propTypes = {
  style: PropTypes.object,
  startDate: PropTypes.number
};

export default DaysSince;
