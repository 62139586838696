import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

import "./style.scss";

class CommitteeMeetingsFilterEmptyComponent extends Component {
  render() {
    const { title, theme } = this.props;
    return (
      <div
        className="committee-meetings-filter-empty-component"
        style={{ color: theme.textGray }}
      >
        <FormattedMessage
          id="committeeMeetingsFilterEmptyComponent.title"
          defaultMessage="you don't have any {title} meetings"
          values={{ title: title }}
          className="text"
        />
      </div>
    );
  }
}

export default CommitteeMeetingsFilterEmptyComponent;
