export const MEETING_REQUEST_LOADING = "MEETING_REQUEST_LOADING";
export const MEETING_REQUEST_FAILED = "MEETING_REQUEST_FAILED";
export const FETCH_MEETING_REQUEST = "FETCH_MEETING_REQUEST";
export const FETCH_MEETING_REQUEST_ASYNC = "FETCH_MEETING_REQUEST_ASYNC";
export const MEETING_REQUEST_ACTION_ASYNC = "MEETING_REQUEST_ACTION_ASYNC";
export const MEETING_REQUEST_ACTION_LOADING = "MEETING_REQUEST_ACTION_LOADING";
export const UPDATE_REQUEST_STATUS = "UPDATE_REQUEST_STATUS";

export function fetchMeetingRequestAsync(requests, PagingInfo) {
  return { type: FETCH_MEETING_REQUEST_ASYNC, requests, PagingInfo };
}

export function fetchMeetingRequest(PagingInfo) {
  return { type: FETCH_MEETING_REQUEST, PagingInfo };
}

export function meetingRequestActionAsync(requestId) {
  return { type: MEETING_REQUEST_ACTION_ASYNC, requestId };
}

export function rejectMeetingRequest(requestId) {
  return { type: UPDATE_REQUEST_STATUS, requestId, action: 0 };
}

export function acceptMeetingRequest(requestId, attendeesStatus) {
  return {
    type: UPDATE_REQUEST_STATUS,
    requestId,
    action: 1,
    attendeesStatus
  };
}

export function postponeMeetingRequest(requestId, SuggestedDate) {
  return { type: UPDATE_REQUEST_STATUS, requestId, action: 3, SuggestedDate };
}

export function meetingRequestLoading(clear) {
  return { type: MEETING_REQUEST_LOADING, clear };
}

export function meetingRequestFailed(error) {
  return { type: MEETING_REQUEST_FAILED, error };
}

export function meetingRequestActionLoading() {
  return { type: MEETING_REQUEST_ACTION_LOADING };
}
