import React from "react";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import "./style.scss";

class UserProfileLoadingCard extends React.Component {
  _renderItem = key => (
    <ContentLoader
      className="card loading-card"
      rtl={!this.ltr}
      key={key}
      width={400}
      height={160}
    >
      <circle cx="200" cy="40" r="20" />
      <rect x="150" y="75" rx="5" ry="5" width="100" height="10" />
      <rect x="125" y="95" rx="5" ry="5" width="150" height="10" />
      <rect x="155" y="115" rx="5" ry="5" width="90" height="10" />
      <rect x="125" y="135" rx="5" ry="5" width="150" height="10" />
    </ContentLoader>
  );

  render() {
    return <div>{this._renderItem()}</div>;
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ltr: state.locale.ltr
  };
};

export default connect(
  mapStateToProps,
  null
)(UserProfileLoadingCard);
