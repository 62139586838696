import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as CommentIcon } from "../../assets/images/comment-icon.svg";
import { ReactComponent as AttachmentIcon } from "../../assets/images/attachment-icon.svg";
import StatusIndicator from "../StatusIndicator";
import PriorityIndicator from "../PriorityIndicator";
import "./style.scss";

function ActionBar(props) {
  const { theme, data, color, text, done, hideStatus, secondStatus } = props;

  return (
    <div
      className="taskActionBar"
      style={{
        borderColor: theme.accentColor,
        backgroundColor: done ? theme.primaryLight : "white"
      }}
    >
      <div className="taskActions">
        <div className="action" style={{ color: theme.textGray }}>
          <CommentIcon
            className="actionIcon"
            style={{
              fill: "transparent",
              stroke: theme.textGray
            }}
          />
          <p>{data.CommentsCount}</p>
        </div>
        <div className="action" style={{ color: theme.textGray }}>
          <AttachmentIcon
            className="actionIcon"
            style={{ fill: theme.textGray }}
          />
          <p>{data.AttachmentCount}</p>
        </div>
      </div>
      <div className="taskStatusContainer">
        {secondStatus ? (
          <StatusIndicator
            color={secondStatus.color}
            text={secondStatus.text}
          />
        ) : null}
        {data.Priority && (
          <PriorityIndicator value={data.Priority} theme={theme} />
        )}
        {hideStatus ? null : <StatusIndicator color={color} text={text} />}
      </div>
    </div>
  );
}

ActionBar.propTypes = {
  theme: PropTypes.object,
  task: PropTypes.object
};

export default ActionBar;
