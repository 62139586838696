import { connect } from "react-redux";
import { IntlProvider, addLocaleData } from "react-intl";
import enData from "react-intl/locale-data/en";
import arData from "react-intl/locale-data/ar";
import en from "../../locales/en.json";
import ar from "../../locales/ar.json";

addLocaleData([...enData, ...arData]);

function mapStateToProps(state) {
  const { locale } = state;
  const messages = state.locale.lang === "en" ? en : ar;
  return { locale: locale.lang, messages };
}

export default connect(mapStateToProps)(IntlProvider);
