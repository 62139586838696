import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import "./style.scss";
import NewsDetailsLoadingCard from "../../components/LoadingCards/NewsDetailsLoadingCard";
import DaysSince from "../../components/DaysSince";
import AddNewsModal from "../../components/AddNewsModal";
import LocaleAwareText from "../../components/LocaleAwareText";
import MenuButton from "../../components/MenuButton";
import NewsBar from "../../components/NewsBar";
import AsyncImg from "../../components/AsyncImg";
import CommentsContainer from "../../components/CommentsContainer";
import {
  fetchNews,
  showModal,
  deleteNews,
  newsAddComment
} from "../../actions";
import { cleanUsersForMention } from "../../utils";
import { FormattedMessage } from "react-intl";

class NewsDetailsPage extends Component {
  state = {
    users: []
  };

  componentDidMount() {
    const { match, fetchNews } = this.props;
    fetchNews(match.params.id, { PagingInfo: null });
    axios.get("/ActiveDirectory/Users").then(response => {
      this.setState({
        users: cleanUsersForMention(response.data.Data)
      });
    });
  }

  componentDidUpdate(prevProps) {
    const { match, fetchNews } = this.props;

    if (match.params.id !== prevProps.match.params.id) {
      fetchNews(match.params.id, { PagingInfo: null });
    }
  }

  deleteNews() {
    this.props.deleteNews(this.props.match.params.id, this.props.history);
  }

  handleEdit() {
    this.props.showModal(<AddNewsModal data={this.props.news.News} />);
  }

  render() {
    const { theme, locale, news, newsAddComment, suffix } = this.props;
    const { News } = news;
    if (news.Loading || !News) return <NewsDetailsLoadingCard />;

    const list = [];

    if (theme.allowPublishNews && News.Status !== "1") {
      list.push({
        id: 1,
        name: "publishNews",
        action: () => this.handleEdit()
      });
    }

    if (theme.allowDeleteNews) {
      list.push({
        id: 2,
        name: "delete",
        warning: true,
        action: () => this.deleteNews()
      });
    }

    const Sector = theme.lookups.Sectors.find(
      item => item.ID === News.SectorId
    );

    const Entity = theme.lookups.Entities.find(
      item => item.ID === News.EntityId
    );

    const Department = theme.lookups.Departments.find(
      item => item.ID === News.DepartmentId
    );

    return (
      <div className="more-news-page card">
        <div
          className="news-container"
          style={{
            borderBottom: "solid 1px",
            borderColor: theme.accentColor
          }}
        >
          <div
            className="header-container"
            style={{ borderColor: theme.accentColor }}
          >
            <div className="logo-text-container">
              <div className="photo-container">
                <AsyncImg
                  alt="SourceImg"
                  img={
                    News.Source.Attachment &&
                    News.Source.Attachment.AttachmentURL
                  }
                  style={{
                    borderColor: theme.accentColor
                  }}
                />
              </div>
              <div className="header-title">
                <div className="first-sec">
                  <p>{News.Source[`Title${locale.suffix}`]}</p>
                  <div className="date">
                    <div style={{ color: theme.accentColor }}>
                      <DaysSince
                        style={{ color: theme.textGray }}
                        startDate={News.ArticleDate}
                      />
                    </div>
                  </div>
                </div>

                <div className="second-sec">
                  {list.length ? (
                    <MenuButton title="newsOptions" options={list} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <NewsBar data={News} details={true} />

          <div className="news-box flex-container ">
            {News.Attachment && (
              <div className="news-image-container">
                <AsyncImg img={News.Attachment.AttachmentURL} alt="NewsImg" />
              </div>
            )}
            <div
              className={
                News.Attachment
                  ? locale.lang === "ar"
                    ? "news-text-container right-attach"
                    : "news-text-container right-attach ltr"
                  : "news-text-container"
              }
            >
              <LocaleAwareText>
                <p className="bold" style={{ color: theme.primary }}>
                  {News.TitleAr}
                </p>
              </LocaleAwareText>
              <div className="news-text">
                <LocaleAwareText>
                  <p>{News.DescriptionAr}</p>
                </LocaleAwareText>
              </div>

              <div className="news-section" style={{ color: theme.textGray }}>
                <span>
                  <FormattedMessage id="label.Sector" defaultMessage="Sector" />{" "}
                  :<span> {Sector?.Title}</span>
                </span>
              </div>

              <div className="news-section" style={{ color: theme.textGray }}>
                <span>
                  <FormattedMessage id="label.Entity" defaultMessage="Entity" />{" "}
                  :<span> {Entity?.Title}</span>
                </span>
              </div>

              <div className="news-section" style={{ color: theme.textGray }}>
                <span>
                  <FormattedMessage
                    id="label.Department"
                    defaultMessage="Department"
                  />{" "}
                  :<span> {Department?.Title}</span>
                </span>
              </div>

              <div className="news-section" style={{ color: theme.textGray }}>
                <span>
                  <FormattedMessage
                    id="label.NewsType"
                    defaultMessage="NewsType"
                  />{" "}
                  :
                  <span>
                    {" "}
                    {news.News.NewsType &&
                      theme.News_Type[news.News.NewsType][`Title${suffix}`]}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <CommentsContainer
          addComment={newsAddComment}
          loading={news.CommentLoading}
          id={news.News.ID}
          comments={news.News.Comments}
          uploadIcon={true}
          progress={news.Progress}
          users={this.state.users}
          type="news"
        />
      </div>
    );
  }
}

NewsDetailsPage.propTypes = {
  theme: PropTypes.object,
  locale: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    locale: state.locale,
    news: state.news,
    suffix: state.locale.suffix
  };
};

const mapDispatchToProps = { fetchNews, showModal, deleteNews, newsAddComment };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewsDetailsPage));
