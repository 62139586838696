import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Loading from "../Loading";
import DropDown from "../DropDown";
import { ReactComponent as ArrowIcon } from "../../assets/images/arrow-down.svg";
import { ReactComponent as MenuDots } from "../../assets/images/menu-dots.svg";
import "./style.scss";

class MenuButton extends React.Component {
  _isMounted = false;
  constructor() {
    super();
    this.state = {
      open: false
    };
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu = event => {
    if (this._isMounted) {
      this.setState({ open: true }, () => {
        document.addEventListener("click", this.closeMenu);
      });
    }
    event.stopPropagation();
  };

  closeMenu = () => {
    if (this._isMounted) {
      this.setState({ open: false }, () => {
        document.removeEventListener("click", this.closeMenu);
      });
    }
  };

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      textGray,
      primaryColor,
      loading,
      title,
      options,
      id,
      status,
      dots
    } = this.props;
    const { open } = this.state;
    return (
      <div className="menu-container">
        <button
          className="menu-button"
          disabled={loading}
          onClick={this.showMenu}
        >
          {loading ? (
            <Loading color={primaryColor} />
          ) : dots ? (
            <MenuDots style={{ fill: textGray, height: "15px" }} />
          ) : (
            <ArrowIcon style={{ fill: textGray }} />
          )}
        </button>
        {open ? (
          <DropDown
            title={title}
            options={options}
            id={id}
            status={status}
            modal
          />
        ) : null}
      </div>
    );
  }
}

MenuButton.propTypes = {
  accentColor: PropTypes.string,
  primaryColor: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    textGray: state.theme.textGray,
    primaryColor: state.theme.primary
  };
};

export default connect(
  mapStateToProps,
  null
)(MenuButton);
