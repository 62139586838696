import React, * as react from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import "./style.scss";
import moment from "moment";
import "moment/locale/ar";
import MomentLocaleUtils from "react-day-picker/moment";
import "react-day-picker/lib/style.css";
import { css } from "emotion";
import SmallCalenderRenderDay from "../SmallCalenderRenderDay";
import MeetingsTasksModal from "../MeetingsTasksModal";
import SmallCalenderNavBar from "../SmallCalenderNavBar";
import { showModal } from "../../actions";

function Weekday({ weekday, className, localeUtils, locale }) {
  const weekdayName = localeUtils.formatWeekdayLong(weekday, "en");

  return (
    <div
      className={className}
      style={{
        backgroundColor: "#f9fafa",
        borderTop: "1px solid",
        borderBottom: "1px solid",
        borderColor: "#E5E5E5"
      }}
    >
      {weekdayName.slice(0, 3)}
    </div>
  );
}

class SmallCalender extends react.Component {
  state = {
    month: moment().get("month"),
    year: moment(new Date()).get("year")
  };

  handleDayClick = e => {
    let meetingData = [];
    let taskData = [];
    if (this.props.meetingData && this.props.meetingData.length > 0) {
      meetingData = this.props.meetingData.filter(meeting => {
        return (
          new Date(
            new Date(meeting.StartDate * 1000).setHours(0, 0, 0, 0)
          ).getTime() === new Date(e.setHours(0, 0, 0, 0)).getTime()
        );
      });
    }
    if (this.props.taskData && this.props.taskData.length > 0) {
      taskData = this.props.taskData.filter(task => {
        return (
          new Date(
            new Date(task.DueDate * 1000).setHours(0, 0, 0, 0)
          ).getTime() === new Date(e.setHours(0, 0, 0, 0)).getTime()
        );
      });
    }

    this.props.showModal(
      <MeetingsTasksModal
        date={e}
        meetingData={meetingData}
        taskData={taskData}
      />
    );
  };

  render() {
    const { month, year } = this.state;
    const { meetingData, taskData, theme, loading } = this.props;

    const className = css`
      .DayPicker-Caption {
        background-color: ${theme.primaryLight};
      }
      .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        .cell-small-calender {
          background-color: ${theme.primary};
        }
      }
      .DayPicker:not(.DayPicker--interactionDisabled)
        .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        background-color: ${theme.primaryLight};
      }
    `;
    return (
      <div className={className}>
        <div
          className={
            this.props.locale.ltr
              ? "small-calender-main-container ltr"
              : "small-calender-main-container"
          }
        >
          <DayPicker
            selectedDays={[new Date()]}
            locale={this.props.locale.lang}
            localeUtils={MomentLocaleUtils}
            className="Birthdays"
            renderDay={day => (
              <SmallCalenderRenderDay
                date={day}
                month={month}
                year={year}
                meetingData={meetingData}
                taskData={taskData}
              />
            )}
            onMonthChange={date =>
              this.setState({
                year: moment(date).get("year"),
                month: moment(date).get("month")
              })
            }
            weekdayElement={<Weekday />}
            navbarElement={<SmallCalenderNavBar {...this.props} />}
            onDayClick={e => !loading && this.handleDayClick(e)}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = { showModal };

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    locale: state.locale,
    loading: state.calender.Loading
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SmallCalender));
