import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import AdhocInnerCardTitleWithDescription from "../AdhocInnerCardTitleWithDescription";
import LogoText from "../LogoText";
import AttachmentList from "../AttachmentList";
import CommentsContainer from "../CommentsContainer";
import { adhocAddComment } from "../../actions";

import { getDisplayName } from "../../utils";

import "./style.scss";

class AdhocInformation extends Component {
  render() {
    const {
      theme,
      intl,
      adhocAddComment,
      loading,
      progress,
      data,
      ltr
    } = this.props;

    return (
      <div className="adhoc-info card">
        <div
          className="logo-with-attachment-container"
          style={{ borderColor: theme.accentColor }}
        >
          <AdhocInnerCardTitleWithDescription data={data} withDescription />
          {theme.isWorkflowMember ? (
            <LogoText
              name={intl.formatMessage({
                id: "adhoc.RequestBy",
                defaultMessage: "Request By"
              })}
              text={getDisplayName(data.Creator, ltr)}
              img={data.Creator.UserProfilePicture}
            />
          ) : null}
          {data.Attachments && data.Attachments.length && (
            <AttachmentList photos={data.Attachments} key={data.ID} />
          )}
        </div>
        <CommentsContainer
          id={data.ID}
          loading={loading}
          addComment={adhocAddComment}
          comments={data.Comments}
          list={this.dropDownList}
          uploadIcon={true}
          progress={progress}
        />
      </div>
    );
  }
}

AdhocInformation.propTypes = {
  theme: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    loading: state.adhoc.CommentLoading,
    progress: state.adhoc.Progress,
    ltr: state.locale.ltr
  };
};

const mapDispatchToProps = {
  adhocAddComment
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AdhocInformation));
