import React from "react";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";

class NewsLoadingCard extends React.Component {
  _renderItem = () => (
    <ContentLoader
      className="card loading-card"
      rtl={!this.props.ltr}
      width={400}
      height={170}
    >
      <rect x="10" y="40" rx="5" ry="5" width="100" height="80" />
      <rect x="120" y="40" rx="5" ry="5" width="250" height="10" />
      <rect x="120" y="60" rx="5" ry="5" width="250" height="10" />
      <rect x="120" y="80" rx="5" ry="5" width="250" height="10" />
      <rect x="120" y="100" rx="5" ry="5" width="150" height="10" />

      <circle cx="20" cy="20" r="10" />
      <rect x="40" y="12" rx="3" ry="3" width="70" height="6" />
      <rect x="40" y="22" rx="3" ry="3" width="50" height="6" />

      <rect x="0" y="140" width="400" height="1" />
      <rect x="340" y="150" rx="5" ry="5" width="50" height="10" />
    </ContentLoader>
  );

  render() {
    return this._renderItem();
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ltr: state.locale.ltr
  };
};

export default connect(
  mapStateToProps,
  null
)(NewsLoadingCard);
