import React, { Component } from "react";
import PropTypes from "prop-types";
import { push } from "connected-react-router";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import "./style.scss";
import NotificationsButton from "../NotificationsButton";
import LocaleToggle from "../../components/LocaleToggle";
import ProfileButton from "../ProfileButton";
import userImage from "../../assets/images/profile-image.jpg";

class Header extends Component {
  render() {
    const { theme, intl, locale, user, history } = this.props;
    return (
      <header className="header" style={{ borderColor: theme.primary }}>
        <div className="headerContent">
          <div
            onClick={() => this.props.push("/")}
            className="headerLogoContainer"
          >
            <img src={theme.logo} alt="logo" />
            <div className="headerNameContainer">
              <p>{theme.appTitleAr}</p>
              <p>{theme.appTitleEn}</p>
            </div>
          </div>
          <div className="userControl">
            <NotificationsButton intl={intl} theme={theme} locale={locale} />
            <LocaleToggle />
            <ProfileButton
              intl={intl}
              theme={theme}
              locale={locale}
              userImage={userImage}
              user={user}
              history={history}
            />
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  intl: PropTypes.object,
  theme: PropTypes.object,
  locale: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ...state
  };
};

const mapDispatchToProps = { push };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Header));
