import React from "react";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";

const WorkFlowLoadingCard = props => {
  const { ltr } = props;
  const _renderItem = () => (
    <ContentLoader
      className="card loading-card"
      rtl={!ltr}
      width={400}
      height={100}
    >
      <circle cx="40" cy="30" r="10" />
      <rect x="60" y="27" rx="3" ry="3" width="70" height="6" />
      <rect x="25" y="50" rx="3" ry="3" width="30" height="6" />
      <circle cx="150" cy="30" r="10" />
      <rect x="170" y="27" rx="3" ry="3" width="70" height="6" />
      <rect x="135" y="50" rx="3" ry="3" width="30" height="6" />
      <circle cx="260" cy="30" r="10" />
      <rect x="280" y="27" rx="3" ry="3" width="70" height="6" />
      <rect x="245" y="50" rx="3" ry="3" width="30" height="6" />
      <circle cx="370" cy="30" r="10" />
      <rect x="355" y="50" rx="3" ry="3" width="30" height="6" />

      <rect x="0" y="70" rx="3" ry="3" width="400" height="1" />

      <rect x="20" y="83" rx="3" ry="3" width="30" height="6" />
      <rect x="70" y="83" rx="3" ry="3" width="40" height="6" />
    </ContentLoader>
  );

  const _renderItem2 = () => (
    <ContentLoader
      className="card loading-card"
      rtl={!ltr}
      width={400}
      height={100}
    >
      <rect x="10" y="10" rx="3" ry="3" width="120" height="6" />
      <rect x="340" y="10" rx="5" ry="5" width="40" height="10" />

      <rect x="10" y="25" rx="3" ry="3" width="30" height="6" />
      <rect x="10" y="40" rx="3" ry="3" width="370" height="6" />

      <rect x="0" y="70" rx="3" ry="3" width="400" height="1" />
      <circle cx="15" cy="85" r="5" />
      <rect x="30" y="80" width="350" height="10" />
    </ContentLoader>
  );
  return (
    <>
      {_renderItem()}
      {_renderItem2()}
    </>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ltr: state.locale.ltr
  };
};

export default connect(
  mapStateToProps,
  null
)(WorkFlowLoadingCard);
