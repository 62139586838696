import {
  MEETING_REQUEST_LOADING,
  FETCH_MEETING_REQUEST_ASYNC,
  MEETING_REQUEST_FAILED,
  MEETING_REQUEST_ACTION_ASYNC,
  MEETING_REQUEST_ACTION_LOADING
} from "../actions";

const initialState = {
  Loading: true,
  ActionLoding: false,
  PagingInfo: "",
  Error,
  Requests: []
};

function meetingRequest(state = initialState, action) {
  switch (action.type) {
    case FETCH_MEETING_REQUEST_ASYNC:
      return {
        ...state,
        Loading: false,
        ActionLoding: false,
        Error: "",
        PagingInfo: action.PagingInfo ? action.PagingInfo : "",
        Requests:
          state.Requests.length > 0
            ? [...state.Requests, ...action.requests]
            : action.requests
      };
    case MEETING_REQUEST_LOADING:
      if (action.clear) return { ...initialState, Loading: true };
      return {
        ...state,
        Loading: true
      };
    case MEETING_REQUEST_ACTION_ASYNC:
      const newRequests = state.Requests.filter(
        request => request.ID !== action.requestId
      );
      return {
        ...state,
        Loading: false,
        ActionLoding: false,
        Error: "",
        Requests: newRequests
      };
    case MEETING_REQUEST_ACTION_LOADING:
      return {
        ...state,
        ActionLoding: true
      };
    case MEETING_REQUEST_FAILED:
      return {
        ...state,
        Error: action.error,
        Loading: false,
        ActionLoding: false
      };

    default:
      return state;
  }
}

export default meetingRequest;
