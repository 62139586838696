import React, { Component } from "react";
import { connect } from "react-redux";
import CommitteesList from "../../components/CommitteesList";
import EmptyComponent from "../../components/EmptyComponents/EmptyComponent";
import CommitteesLoadingCard from "../../components/LoadingCards/CommitteesLoadingCard";
import { injectIntl } from "react-intl";
import { fetchCommittee } from "../../actions";

class CommitteesPage extends Component {
  componentDidMount() {
    const { match, history, username } = this.props;

    if (
      !match.params.filter ||
      !["all", "committee-memeber", "committee-head"].includes(
        match.params.filter
      )
    ) {
      history.replace("/committees/all");
    }

    this.props.fetchCommittee(null, username);
  }

  render() {
    const {
      committeesList,
      loading,
      match,
      history,
      intl,
      counts
    } = this.props;

    if (committeesList.length < 1 && !loading) {
      return (
        <EmptyComponent
          title={intl.formatMessage({
            id: "emptyComponent.title.committees",
            defaultMessage: "committees"
          })}
        />
      );
    }

    if (loading) return <CommitteesLoadingCard />;
    return (
      <div>
        <CommitteesList
          data={committeesList}
          counts={counts}
          match={match}
          history={history}
        />
      </div>
    );
  }
}

CommitteesPage.propTypes = {};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loading: state.committee.Loading,
    committeesList: state.committee.Committees,
    counts: state.committee.Counts,
    username: state.user.UserData.Username
  };
};

const mapDispatchToProps = { fetchCommittee };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CommitteesPage));
