export const HANDSHAKE = "HANDSHAKE";
export const HANDSHAKE_ASYNC = "HANDSHAKE_ASYNC";
export const HANDSHAKE_ERROR = "HANDSHAKE_ERROR";

export function handshake(workflow, DeviceID) {
  return { type: HANDSHAKE, workflow, DeviceID };
}

export function handshakeAsync(handshake, workflow, lookups) {
  return { type: HANDSHAKE_ASYNC, handshake, workflow, lookups };
}

export function handshakeFailed(error) {
  return { type: HANDSHAKE_ERROR, error };
}
