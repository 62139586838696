import moment from "moment";
import axios from "axios";
import sortBy from "lodash/sortBy";

export const daysSince = startDate => {
  startDate = moment(startDate * 1000).startOf("day");
  const today = moment(Date.now()).startOf("day");
  const daysSince = today.diff(startDate, "days");

  if (daysSince >= 365) {
    return { amount: Math.round(daysSince / 365), type: "years" };
  }

  if (daysSince >= 30) {
    return { amount: Math.round(daysSince / 30), type: "months" };
  }

  if (daysSince >= 7) {
    return { amount: Math.round(daysSince / 7), type: "weeks" };
  }

  return { amount: daysSince, type: "days" };
};

export const daysLeft = endDate => {
  endDate = moment(endDate * 1000).startOf("day");
  const today = moment(Date.now()).startOf("day");
  let daysLeft = endDate.diff(today, "days", true);
  if (daysLeft < 0) daysLeft = 0;

  if (daysLeft >= 365) {
    return { amount: Math.round(daysLeft / 365), type: "years" };
  }

  if (daysLeft >= 30) {
    return { amount: Math.round(daysLeft / 30), type: "months" };
  }

  if (daysLeft >= 7) {
    return { amount: Math.round(daysLeft / 7), type: "weeks" };
  }

  return { amount: Math.ceil(daysLeft), type: "days" };
};

export const extractPathName = path => {
  let split = path.split("/");
  return split[1];
};

export const extractPathParams = path => {
  let split = path.split("/");
  return split[split.length - 1];
};

export const getDataFromRes = data => {
  return data.Data;
};

export const getUsername = (data, single) => {
  if (data.length === 0) return null;
  if (single) return { Username: data[0].value };
  return data.map(item => ({ Username: item.value }));
};

export const getCommitteeMember = (data, single) => {
  if (data.length === 0) return null;
  if (single) return { Username: data[0].value };
  return data.map(item => ({ MemberInfo: { Username: item.value } }));
};

export const getAttachmentData = (data, single) => {
  if (!data || data.length === 0) return null;
  if (single)
    return { AttachmentName: data.name, AttachmentContent: data.base64 };
  return data.map(item => ({
    AttachmentName: item.name,
    AttachmentContent: item.base64
  }));
};

export const getUnixDate = date => {
  if (!date) return null;
  return date.getTime() / 1000;
};

export const cleanApiData = data => {
  const cleanData = {};
  Object.keys(data).forEach(key => {
    if (
      !data[key] ||
      data[key] === "" ||
      (Array.isArray(data[key]) && data[key].length === 0)
    )
      return;
    cleanData[key] = data[key];
  });
  return cleanData;
};

export const cleanApiDataForm = data => {
  const cleanData = { Data: {} };
  const formData = new FormData();
  Object.keys(data).forEach(key => {
    if (
      !data[key] ||
      data[key] === "" ||
      (Array.isArray(data[key]) && data[key].length === 0)
    )
      return;
    if (
      data[key] instanceof File ||
      (Array.isArray(data[key]) && data[key][0] instanceof File)
    ) {
      cleanData[key] = data[key];
    } else {
      cleanData["Data"][key] = data[key];
    }
  });
  Object.keys(cleanData).forEach(key => {
    if (key === "Data") {
      const jsonData = JSON.stringify(cleanData[key]);
      formData.append(key, jsonData);
    } else if (
      Array.isArray(cleanData[key]) &&
      cleanData[key][0] instanceof File
    ) {
      cleanData[key].forEach(attachment => formData.append(key, attachment));
    } else {
      formData.append(key, cleanData[key]);
    }
  });
  return formData;
};

export const getRoles = (task, username) => {
  let roles = [];
  if (task.Responsible && task.Responsible.Username === username)
    roles.push("Responsible");
  if (task.Accountable && task.Accountable.Username === username)
    roles.push("Accountable");
  // if (task.Creator && task.Creator.Username === username) roles.push("Creator");
  if (
    task.Consulted &&
    !!task.Consulted.find(item => item.Username === username)
  )
    roles.push("Consulted");
  if (task.Informed && !!task.Informed.find(item => item.Username === username))
    roles.push("Informed");
  return roles;
};

export const cleanHandshakeData = data => {
  let cleanData = {};
  data.forEach((item, index) => {
    cleanData[item.Name] = {};
    if (item.Items) {
      item.Items.forEach((subItem, subIndex) => {
        cleanData[item.Name][subItem.Value] = subItem;
      });
    }
  });
  return cleanData;
};

export const getTaskStatus = task => {
  if (task.Progress === 100) return "NeedClose";
  const dueDate = moment(task.DueDate * 1000).startOf("day");
  const today = moment(Date.now()).startOf("day");
  if (today > dueDate) return "Delayed";
  return "Active";
};

export const cleanUsersForSelect = (response, committee, ltr) => {
  if (committee)
    response.data.Data = response.data.Data.map(item => item.MemberInfo);
  return response.data.Data.map(user => ({
    value: user.Username,
    label: getDisplayName(user, ltr),
    img: user.UserProfilePicture
  }));
};

export const cleanSourcesForSelect = (response, suffix) => {
  const data = response.data.Data;
  if (Array.isArray(data))
    return data.map(source => ({
      value: source.ID,
      label: source.TitleAr,
      img: source.Attachment ? source.Attachment.AttachmentURL : ""
    }));
  return {
    value: data.ID,
    label: data.TitleAr,
    img: data.Attachment ? data.Attachment.AttachmentURL : ""
  };
};

export const cleanObjectOptionsForSelect = (data, suffix) => {
  return Object.keys(data).map(code => ({
    value: data[code].Value,
    label: data[code][`Title${suffix}`],
    ...data[code]
  }));
};

export const cleanLookupsForSelect = data => {
  if (Array.isArray(data))
    return data.map(item => ({
      value: item.ID,
      label: item.Title,
      ...item
    }));
  return {
    value: data.ID,
    label: data.Title,
    ...data
  };
};

export const getSelectUsers = async ltr => {
  return await axios.get("/ActiveDirectory/Users").then(response => {
    return cleanUsersForSelect(response, false, ltr);
  });
};

export const getCommitteeMembers = async (id, ltr) => {
  return await axios.get(`/Committee/Members/${id}`).then(response => {
    return cleanUsersForSelect(response, true, ltr);
  });
};

export const getNewList = (list, item) => {
  const IDs = list.map(listItem => listItem.ID);
  if (IDs.includes(item.ID)) {
    return list.map(listItem => (listItem.ID === item.ID ? item : listItem));
  } else {
    return [item, ...list];
  }
};

export const setSelectMultiOptions = data => {
  const arr = [];
  data.forEach(data => {
    arr.push({
      img: data.UserProfilePicture,
      value: data.Username,
      label: data.DisplayName
    });
  });
  return arr;
};

export const setSelectSingleOptions = (type, data) => {
  const arr = [];
  arr.push({
    img: data[type].UserProfilePicture,
    value: data[type].Username,
    label: data[type].DisplayName
  });
  return arr;
};

export const filterList = (id, list) => {
  return list.filter(e => {
    return e.ID !== id;
  });
};

export const getPermission = (currentUser, item, roles) => {
  let allwed = false;
  roles.forEach(role => {
    if (item[role] && currentUser === item[role].Username) allwed = true;
  });
  return allwed;
};

export const getAttachmentType = name => {
  const arr = name.split(".");

  return arr[arr.length - 1];
};

export const checkSuccess = res => {
  return res.data.StatusCode === "Success";
};

export const sortCommitteeMeetings = meetings => {
  if (meetings.length === 0 || !meetings[0].StartDate) return meetings;
  return sortBy(meetings, ["StartDate"]);
};

export const cleanHandshakeForSelect = (entities, key, suffix) => {
  if (key) return [getSelectItemFromHandshake(key, entities, suffix)];

  return Object.keys(entities).map((entity, i) => {
    return getSelectItemFromHandshake(entity, entities, suffix);
  });
};

export const cleanHandshakeForSingleSelect = (entities, key, suffix) => {
  return getSelectItemFromHandshake(key, entities, suffix);
};

const getSelectItemFromHandshake = (key, item, suffix) => {
  return {
    value: item[key].Value,
    label: item[key][`Title${suffix}`]
  };
};

export const filterCommittees = (type, committees, username, head) => {
  return committees.filter(committee => {
    if (head) {
      return committee.Manager
        ? username === committee.Manager.Username
        : false;
    }

    if (type === "secretary") {
      return (
        committee.Secretary &&
        committee.Secretary.Username.toLowerCase() === username.toLowerCase()
      );
    }

    return committee.Manager ? username !== committee.Manager.Username : true;
  });
};

export const getCommitteesCounts = (committees, username) => {
  return {
    committeehead: filterCommittees("Committee", committees, username, true)
      .length,
    committeemember: filterCommittees("Committee", committees, username, false)
      .length,
    boardhead: filterCommittees("Board", committees, username, true).length,
    boardmember: filterCommittees("Board", committees, username, false).length,
    secretary: filterCommittees("secretary", committees, username, false)
      .length,
    all: committees.length
  };
};

export const cleanCommitteeFiles = committee => {
  const { folders } = committee;
  const generalFiles = folders.find(folder => folder.ID === 0).attachments;
  const Attachment = [
    ...folders.filter(folder => folder.ID !== 0),
    ...generalFiles
  ];
  return {
    ...committee,
    Attachment: sortBy(Attachment, ["CreatedDate"]).reverse()
  };
};

export const getRequestsNewlist = (state, action) => {
  const IDs = state.Requests.map(req => {
    if (req.MeetingID) {
      return req.MeetingID;
    } else {
      return req.ID;
    }
  });

  if (IDs.includes(action.data.ID)) {
    return state.Requests.map(request => {
      if (request.MeetingID === action.data.ID) {
        return { ...request, ...action.data };
      } else {
        return request;
      }
    });
  } else {
    return [...state.Requests, action.data];
  }
};

export const filterCommitteeMeetings = (meetings, upcoming) => {
  const filteredMeetings = meetings.filter(meeting => {
    const endDate = moment(meeting.EndDate * 1000);
    const now = moment(Date.now());
    return upcoming ? endDate > now : endDate < now;
  });
  return filteredMeetings;
};

// projects

export const getProjectData = (handshake, login, url) => {
  const data = cleanHandshakeData(handshake.data.Data);
  const token = login.data.Data.UserToken;
  const { Branding } = data;
  return { name: Branding.ClientName, image: Branding.ClientImage, url, token };
};

export const createProject = (details, data) => {
  const { name, image, url } = details;
  const projects = data.data.Data;
  const projectsNumber = projects.length;
  const issuesNumber = projects.reduce(
    (accumulator, project) => accumulator + project.Issues.length,
    0
  );
  const budget = projects.reduce(
    (accumulator, project) => accumulator + parseInt(project.Budget),
    0
  );
  const project = {
    name,
    image,
    url,
    projectsNumber,
    issuesNumber,
    budget
  };
  return project;
};

export const createProjects = (details, data) => {
  const projects = data.map((project, index) => {
    const temp = createProject({}, project);
    return { ...temp, ...details[index] };
  });
  return projects;
};

export const setFilteredMembersOption = (values, users) => {
  if (values.Manager.length > 0 && values.Secretary.length === 0) {
    return users.filter(user => user.value !== values.Manager[0].value);
  }
  if (values.Secretary.length > 0 && values.Manager.length === 0) {
    return users.filter(user => user.value !== values.Secretary[0].value);
  }
  if (values.Secretary.length > 0 && values.Manager.length > 0) {
    return users.filter(user => {
      return (
        user.value !== values.Secretary[0].value &&
        user.value !== values.Manager[0].value
      );
    });
  }
  return users;
};

export const cleanProjectsForChart = (projects, handshake, lang) => {
  let cleanData = [];
  Object.keys(handshake).forEach(key => {
    cleanData.push({
      name: handshake[key][lang],
      value: projects[key] ? projects[key].length : 0,
      color: handshake[key].Style
    });
  });
  return cleanData;
};

export const sortUsers = users => {
  return sortBy(users, ["label"]);
};

export const getSections = (list, key) => {
  let items = [...list];
  let sections = {
    today: [],
    yesterday: [],
    week: [],
    month: [],
    year: [],
    older: []
  };
  const today = moment(Date.now()).startOf("day");
  items.forEach(item => {
    const date = moment(item[key] * 1000).startOf("day");
    const difference = Math.ceil(today.diff(date, "days"));
    if (difference < 1) {
      sections["today"].push(item);
      return;
    }
    if (difference === 1) {
      sections["yesterday"].push(item);
      return;
    }
    if (difference < 7 - today.day()) {
      sections["week"].push(item);
      return;
    }
    if (moment(item[key] * 1000).isSame(Date.now(), "month")) {
      sections["month"].push(item);
      return;
    }
    if (moment(item[key] * 1000).isSame(Date.now(), "year")) {
      sections["year"].push(item);
      return;
    }
    sections["older"].push(item);
  });
  return sections;
};

export const getDisplayName = (userInfo, ltr, ignoreExternal) => {
  let name = "";
  if (ltr) {
    name = userInfo.DisplayName
      ? userInfo.DisplayName
      : userInfo.DisplayNameAR
      ? userInfo.DisplayNameAR
      : userInfo.UserName;
  } else {
    name = userInfo.DisplayNameAR
      ? userInfo.DisplayNameAR
      : userInfo.DisplayName
      ? userInfo.DisplayName
      : userInfo.UserName;
  }
  return ignoreExternal || userInfo.isActiveDirectoryUser ? name : name + " *";
};

export const arrangeGoogleData = data => {
  return data.map(data => {
    return {
      TitleAr: data.summary,
      TitleEn: data.summary,
      StartDate: new Date(data.start.dateTime) / 1000,
      EndDate: new Date(data.end.dateTime) / 1000,
      Creator: { DisplayName: data.creator.email, Email: data.creator.email },
      Status: new Date(data.start.dateTime) > Date.now() ? "1" : "2",
      type: "google",
      ID: data.iCalUID,
      CommitteeID: null
    };
  });
};

function removeDuplicatesBy(keyFn, array) {
  var mySet = new Set();
  return array.filter(function(x) {
    var key = keyFn(x),
      isNew = !mySet.has(key);
    if (isNew) mySet.add(key);
    return isNew;
  });
}

export const getTaskUsers = task => {
  let users = [];
  if (task.Accountable) users = [...users, task.Accountable];
  if (task.Responsible) users = [...users, task.Responsible];
  if (task.Informed) users = [...users, ...task.Informed];
  if (task.Consulted) users = [...users, ...task.Consulted];
  return removeDuplicatesBy(x => x.Username, users);
};

export const getMeetingUsers = meeting => {
  let users = [];
  if (meeting.Organizer) users = [...users, meeting.Organizer];
  if (meeting.Members)
    users = [...users, ...meeting.Members.map(member => member.UserInfo)];
  return removeDuplicatesBy(x => x.Username, users);
};

export const cleanUsersForMention = users => {
  return users.map(data => {
    return {
      id: data.Username,
      display: data.DisplayName,
      img: data.UserProfilePicture
    };
  });
};
