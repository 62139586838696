import sortBy from "lodash/sortBy";
import {
  ADD_TASK_ASYNC,
  TASK_LOADING,
  CREATE_TASK_LOADING,
  FETCH_TASK_ASYNC,
  FETCH_TASK_CHART_ASYNC,
  TASK_ADD_COMMENT_ASYNC,
  TASK_COMMENT_LOADING,
  DELETE_TASK_COMMENT,
  DELETE_TASK_ATTACHMENT,
  TASK_FAILED,
  UPDATE_TASK_PROGRESS,
  FETCH_COMPLETED_TASK_ASYNC,
  UPDATE_TASK_UPLOAD_PROGRESS,
  LOADING_TASK_CHART,
  UPDATE_TASK_STATUS
} from "../actions";
import { filterList, getTaskStatus } from "../utils";

const initialState = {
  Loading: true,
  CreateLoading: false,
  CommentLoading: false,
  Error,
  Tasks: [],
  Task: null,
  Completed: false,
  Progress: "",
  taskChart: [],
  taskChartLoading: false
};

function task(state = initialState, action) {
  switch (action.type) {
    case ADD_TASK_ASYNC:
      return {
        ...state,
        CreateLoading: false,
        Error: "",
        Progress: "",
        Tasks: state.Task
          ? [action.task, ...filterList(state.Task.ID, state.Tasks)]
          : [action.task, ...state.Tasks],
        Task:
          state.Task && state.Task.ID === action.task.ID
            ? action.task
            : state.Task
      };
    case FETCH_TASK_ASYNC:
      const data = Array.isArray(action.tasks)
        ? {
            Tasks: action.tasks
          }
        : { Task: action.tasks };
      return {
        ...state,
        Loading: false,
        Completed: Array.isArray(action.tasks) ? false : state.Completed,
        Error: "",
        ...data
      };

    case FETCH_COMPLETED_TASK_ASYNC:
      const NewList =
        action.completedTasks.length > 0
          ? [...action.allTasks, ...action.completedTasks]
          : [...action.allTasks];

      const newSortingListTasks = { Tasks: sortBy(NewList, ["DueDate"]) };

      return {
        ...state,
        Loading: false,
        Completed: true,
        ...newSortingListTasks
      };
    case TASK_ADD_COMMENT_ASYNC:
      return {
        ...state,
        CommentLoading: false,
        Error: "",
        Progress: "",
        Task: {
          ...state.Task,
          Comments: [action.comment, ...state.Task.Comments],
          CommentsCount: state.Task.CommentsCount + 1
        }
      };
    case DELETE_TASK_COMMENT:
      const newComments = state.Task.Comments.filter(
        comment => comment.ID !== action.commentId
      );
      return {
        ...state,
        Task: {
          ...state.Task,
          Comments: newComments,
          CommentsCount: state.Task.CommentsCount - 1
        }
      };
    case DELETE_TASK_ATTACHMENT:
      const newAttachments = state.Task.Attachments.filter(
        attachment => attachment.AttachmentID !== action.id
      );
      return {
        ...state,
        Task: {
          ...state.Task,
          Attachments: newAttachments,
          AttachmentCount: state.Task.AttachmentCount - 1
        }
      };
    case UPDATE_TASK_PROGRESS:
      let newTask = {
        ...state.Task,
        Progress: action.progress
      };
      newTask.Status = getTaskStatus(newTask);
      return {
        ...state,
        Task: state.Task.ID === action.id ? newTask : state.Task
      };
    case UPDATE_TASK_UPLOAD_PROGRESS:
      return {
        ...state,
        Progress: action.progress
      };
    case TASK_LOADING:
      return {
        ...state,
        Loading: true
      };
    case TASK_COMMENT_LOADING:
      return {
        ...state,
        CommentLoading: true
      };

    case LOADING_TASK_CHART:
      return {
        ...state,
        taskChartLoading: true
      };
    case CREATE_TASK_LOADING:
      return {
        ...state,
        CreateLoading: true
      };
    case TASK_FAILED:
      return {
        ...state,
        error: action.error,
        Progress: "",
        Loading: false,
        CreateLoading: false,
        CommentLoading: false
      };
    case FETCH_TASK_CHART_ASYNC:
      return {
        ...state,
        taskChart: action.taskChart,
        taskChartLoading: false
      };

    case UPDATE_TASK_STATUS:
      const newData =
        state.Tasks.length > 0 &&
        state.Tasks.map(data => {
          if (action.id === data.ID) {
            let newTask = {
              ...data,
              Progress: action.status
            };

            newTask.Status = getTaskStatus(newTask);
            return { ...newTask };
          } else {
            return data;
          }
        });
      return {
        ...state,
        Tasks: state.Tasks.length > 0 ? newData : state.Tasks
      };
    default:
      return state;
  }
}

export default task;
