import React, { Component } from "react";

import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import moment from "moment";
import "./style.scss";
import Modal from "../Modal";
import TaskItem from "../TaskItem";
import TimelineMeeting from "../TimelineMeeting";

import { hideModal } from "../../actions";

import EmptyComponent from "../../components/EmptyComponents/EmptyComponent";
import { ReactComponent as TasksIcon } from "../../assets/images/nav/tasks-icon.svg";
import { ReactComponent as MeetingsIcon } from "../../assets/images/nav/meetings-icon.svg";

class MeetingsTasksModal extends Component {
  render() {
    const {
      hideModal,
      intl,
      date,
      taskData,
      meetingData,
      theme,
      // locale,
      ltr
    } = this.props;

    return (
      <Modal
        isOpen
        hideModal={hideModal}
        customTitle={`${intl.formatMessage({
          id: "modal.agenda",
          defaultMessage: "agenda"
        })}${" "}${moment(date).format("L")}`}
        noTitle
      >
        <div className={`calender-pop-up-container ${ltr ? "ltr" : ""}`}>
          <div className="meetings-tasksModal">
            <div className="tasks">
              <TasksIcon style={{ fill: theme.primary }} />
              <div className="title">
                <FormattedMessage id="sidenav.tasks" defaultMessage="tasks" />
              </div>
            </div>
            <div
              className="meetings"
              style={{ borderColor: theme.accentColor }}
            >
              {taskData &&
                taskData.length > 0 &&
                taskData.map((task, index) => (
                  <div
                    style={{
                      backgroundColor: index % 2 !== 0 && theme.primaryLight
                    }}
                    key={index}
                  >
                    <TaskItem
                      key={`${task.ID}${task.TimelineType}`}
                      theme={theme}
                      task={task}
                    />
                  </div>
                ))}
              {taskData.length === 0 && (
                <div className="empty-component-container">
                  <EmptyComponent
                    message={intl.formatMessage({
                      id: "emptyComponent.tasks",
                      defaultMessage: "tasks"
                    })}
                  />
                </div>
              )}
            </div>
          </div>

          <div
            className="meetings-modal"
            style={{
              borderColor: theme.accentColor
            }}
          >
            <div className="tasks">
              <MeetingsIcon style={{ fill: theme.primary }} />
              <div className="title">
                <FormattedMessage
                  id="sidenav.meetings"
                  defaultMessage="meeting"
                />
              </div>
            </div>
            <div
              className="meetings"
              style={{ borderColor: theme.accentColor }}
            >
              {meetingData &&
                meetingData.length > 0 &&
                meetingData.map((meeting, index) => (
                  <div
                    key={index}
                    style={{
                      borderColor: theme.accentColor,
                      backgroundColor:
                        index % 2 === 0 ? theme.primaryLight : "white"
                    }}
                    className="meeting-borders"
                  >
                    <TimelineMeeting
                      key={`${meeting.ID}${meeting.TimelineType}`}
                      post={meeting}
                    />
                  </div>
                ))}
              {meetingData.length === 0 && (
                <div className="empty-component-container">
                  <EmptyComponent
                    message={intl.formatMessage({
                      id: "emptyComponent.meetings",
                      defaultMessage: "meetings"
                    })}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ltr: state.locale.ltr,
    theme: state.theme
  };
};

const mapDispatchToProps = { hideModal };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(MeetingsTasksModal));
