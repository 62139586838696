import React, { Component } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import Modal from "../Modal";
import { adhocAction } from "../../actions";
import FormInput from "../FormInput";
import "./style.scss";
import Button from "../Button";
import Loading from "../Loading";

class AdhocActionModal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        Comment: "",
        ID: props.adhoc.ID
      }
    };
  }

  takeAction = data => {
    this._isMounted = true;

    this.props.adhocAction(data);
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      hideModal,
      locale,
      adhoc,
      loading,
      primaryColor,
      theme
    } = this.props;

    const action = adhoc.Steps.find(step => step.AssignedTo === adhoc.Stage)
      .Action;

    const adhocActions = `Adhoc_Action_${action}`;

    return (
      <Modal isOpen title="adhocAction" hideModal={hideModal}>
        {loading ? (
          <Loading color={primaryColor} />
        ) : (
          <Formik initialValues={this.state.initialValues}>
            {({ isSubmitting, values }) => (
              <Form autoComplete="off">
                <input
                  autoComplete="false"
                  name="hidden"
                  type="text"
                  style={{ display: "none" }}
                />
                <FormInput type="text" name="Comment" withoutLabel />
                <div
                  className={
                    this.props.locale.ltr
                      ? "adhoc-action-modal ltr"
                      : "adhoc-action-modal"
                  }
                >
                  {Object.keys(theme[adhocActions]).map((item, index) => (
                    <Button
                      type="button"
                      key={index}
                      loading={this.state.loading}
                      style={{
                        backgroundColor: theme[adhocActions][item].Color
                      }}
                      onClick={() =>
                        this.takeAction({
                          ...values,
                          Response: theme[adhocActions][item].Value
                        })
                      }
                    >
                      {theme[adhocActions][item][`Title${locale.suffix}`]}
                    </Button>
                  ))}
                </div>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    locale: state.locale,
    loading: state.adhoc.ActionLoading,
    primaryColor: state.theme.primary,
    theme: state.theme
  };
};

const mapDispatchToProps = { adhocAction };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdhocActionModal);
