import React, { Component } from "react";
import axios from "axios";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./style.scss";
import PropTypes from "prop-types";
import LogoText from "../LogoText";
import MenuButton from "../MenuButton";
import AddButtonSwitchCommittiees from "../AddButtonSwitchCommittiees";
import Tabs from "../Tabs";
import CommitteeDetailsLoadingCard from "../LoadingCards/CommitteeDetailsLoadingCard";
import AddCommittesModal from "../AddCommittesModal";
import CommitteeTabs from "../CommitteeTabs";
// import DaysSince from "../DaysSince";
import moment from "moment";
import { fetchCommittee, showModal } from "../../actions";
import committeeDefault from "../../assets/images/committee-default.png";
import { getPermission, getDisplayName } from "../../utils";

const tabs = ["members", "meetings", "files"];

class CommitteeDetails extends Component {
  componentDidMount() {
    const { match } = this.props;
    if (!match.params.params || !tabs.includes(match.params.params))
      this.props.history.replace(`/committee/${match.params.id}/members`);
    this.props.fetchCommittee(this.props.match.params.id);
  }

  list = [
    {
      id: 1,
      name: "edit",
      action: () => this.handleEdit()
    },
    {
      id: 2,
      name: "delete",
      warning: true,
      action: () => this.handleDelete()
    }
  ];

  getTabs = () => {
    const { committee } = this.props;
    return [
      { name: "members", count: committee.Members.length },
      { name: "meetings", count: committee.Meetings.length },
      { name: "files", count: committee.Attachment.length }
    ];
  };

  handleEdit() {
    this.props.showModal(<AddCommittesModal data={this.props.committee} />);
  }

  handleDelete() {
    axios.post(`/Committee/Delete/${this.props.match.params.id}`).then(res => {
      if (res.data.StatusCode === "Success") {
        this.props.history.goBack();
      }
    });
  }

  selectTab = tab => {
    const { match } = this.props;
    this.setState({
      open: false
    });
    if (match.params.params !== tab) {
      this.props.history.replace({
        pathname: `/committee/${match.params.id}/${tab}`,
        state: { tab: true }
      });
    }
  };

  render() {
    const {
      accentColor,
      match,
      intl,
      committee,
      loading,
      ltr,
      currentUser,
      theme,
      suffix
    } = this.props;
    if (loading || !committee) return <CommitteeDetailsLoadingCard />;

    const filesPermission = getPermission(currentUser, committee, [
      "Manager",
      "Secretary"
    ]);

    return (
      <div
        className={
          !committee.Manager && !committee.Secretary
            ? "committee-details-card without-manager-secratary"
            : "committee-details-card"
        }
      >
        <div className="committees-inner-card card">
          <div className="committe-title">
            <div className="committe-title-first-section">
              <LogoText
                name={committee.NameAr}
                text={committee.DescriptionAr}
                black={true}
                img={
                  committee.ProfilePic
                    ? committee.ProfilePic.AttachmentURL
                    : committeeDefault
                }
              />
            </div>

            {getPermission(currentUser, committee, [
              "Manager",
              "Creator",
              "Secretary"
            ]) ? (
              <div className="committe-title-second-section">
                <MenuButton options={this.list} title="Committee" />
              </div>
            ) : null}
          </div>

          <div className="committe-type" style={{ color: theme.textGray }}>
            <span>
              <FormattedMessage
                id="label.CommitteeType"
                defaultMessage="CommitteeType"
              />{" "}
              :
              <span>
                {
                  theme.Committee_Type[committee.CommitteeType][
                    `Title${suffix}`
                  ]
                }
              </span>
            </span>
          </div>

          <div
            className="committe-type padding"
            style={{ color: theme.textGray }}
          >
            <span>
              <FormattedMessage
                id="label.CommitteeCreatedDate"
                defaultMessage="CommitteeType"
              />{" "}
              :{" "}
              <span>
                {moment(new Date(committee.CommitteeCreatedDate) * 1000).format(
                  "DD MMMM YYYY"
                )}
              </span>
            </span>
          </div>

          <div className="committe-user" style={{ borderColor: accentColor }}>
            {committee.Manager && (
              <LogoText
                name={getDisplayName(committee.Manager, ltr)}
                img={committee.Manager.UserProfilePicture}
                text={intl.formatMessage({
                  id: `committees.headName`,
                  defaultMessage: "head name"
                })}
                isNameClickable={committee.Manager.Username}
              />
            )}
            {committee.Secretary && (
              <LogoText
                name={getDisplayName(committee.Secretary, ltr)}
                img={committee.Secretary.UserProfilePicture}
                text={intl.formatMessage({
                  id: `committees.secretaryName`,
                  defaultMessage: "secretary name"
                })}
                isNameClickable={committee.Secretary.Username}
              />
            )}
          </div>

          <div
            className="meeting-tabs-container"
            style={{ borderColor: accentColor }}
          >
            <div className="tabs">
              <Tabs
                tabs={this.getTabs()}
                selectTab={this.selectTab}
                selected={match.params.params}
              />
            </div>

            <div className="tabs-btn">
              <AddButtonSwitchCommittiees
                path={match.url}
                id={committee.ID}
                filesPermission={filesPermission}
              />
            </div>
          </div>
        </div>
        <div className="committee-tab card">
          <CommitteeTabs
            tab={match.params.params}
            committee={committee}
            ltr={ltr}
          />
        </div>
      </div>
    );
  }
}

CommitteeDetails.propTypes = {
  theme: PropTypes.object,
  match: PropTypes.object
};

const mapDispatchToProps = { fetchCommittee, showModal };

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loading: state.committee.Loading,
    committee: state.committee.Committee,
    accentColor: state.theme.accentColor,
    ltr: state.locale.ltr,
    currentUser: state.user.UserData.Username,
    theme: state.theme,
    suffix: state.locale.suffix
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(CommitteeDetails)));
