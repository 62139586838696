import React, * as react from "react";
import PropTypes from "prop-types";
import "./style.scss";

class SideNavItem extends react.Component {
  state = {
    isHovered: false
  };

  render() {
    const {
      text,
      path,
      Icon,
      color,
      selectedColor,
      selected,
      ltr,
      theme,
      history,
      intl
    } = this.props;

    const backgroundColor = this.state.isHovered ? theme.accentColor : "white";

    return (
      <div>
        <div
          onMouseEnter={() => this.setState({ isHovered: true })}
          onMouseLeave={() => this.setState({ isHovered: false })}
          onClick={() => history.push(path)}
          className={`side-navItem  ${ltr ? "ltr" : ""}`}
          style={
            selected
              ? {
                  color: selectedColor,

                  backgroundColor: theme.primaryLight
                }
              : {
                  backgroundColor: backgroundColor
                }
          }
        >
          <div className="icon-text-container">
            <div className="iconContainer">
              <Icon
                className="navIcon"
                style={{
                  fill: selected ? selectedColor : color
                }}
              />
            </div>
            <p
              className="navText"
              style={{ color: selected ? selectedColor : "" }}
            >
              {intl.formatMessage({
                id: `sidenav.${text}`,
                defaultMessage: text
              })}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

SideNavItem.propTypes = {
  text: PropTypes.string,
  Icon: PropTypes.object,
  color: PropTypes.string,
  selectedColor: PropTypes.string,
  selected: PropTypes.bool,
  ltr: PropTypes.bool,
  intl: PropTypes.object,
  history: PropTypes.object,
  theme: PropTypes.object
};

export default SideNavItem;
