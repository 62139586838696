import React, * as react from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import EmptyComponent from "../../components/EmptyComponents/EmptyComponent";
import SideNavBarChart from "../SideNavBarChart";
import StatusIndicatorFooter from "../StatusIndicatorFooter";

import moment from "moment";
import {
  calenderData,
  fetchUpcomingMeetings,
  fetchTaskChart
} from "../../actions";

import "./style.scss";

class SideNavBarChartContainer extends react.Component {
  state = {
    position: false
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll, false);
  }

  handleScroll = e => {
    if (window.innerHeight + window.scrollY >= 1600) {
      this.setState({ position: true });
    } else {
      this.setState({ position: false });
    }
  };
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, false);
  }

  data = () => {
    if (
      !this.props.task.taskChartLoading &&
      this.props.task.taskChart.length > 0
    ) {
      return this.props.task.taskChart.map(data => {
        return {
          name: moment.monthsShort(data.Month - 1),
          uv: data.CompletedCount,
          pv: data.NotCompletedCount
        };
      });
    }
  };

  render() {
    const { theme, intl, ltr, taskChartLoading } = this.props;
    const firstText = intl.formatMessage({
      id: "tasksChart.openedTask",
      defaultMessage: "Opened task"
    });
    const secondText = intl.formatMessage({
      id: "tasksChart.completedTask",
      defaultMessage: "Completed task"
    });
    const data = [
      { color: theme.primary, text: firstText },
      { color: theme.textGray, text: secondText }
    ];
    if (taskChartLoading) return null;
    return (
      <div
        className={`side-nav-bar-chart-container hidden card  ${this.state
          .position && "position"}`}
      >
        <div
          className="header-chart"
          style={{
            backgroundColor: theme.primaryLight,
            borderColor: theme.accentColor
          }}
        >
          <FormattedMessage
            id="tasksChart.title"
            defaultMessage="Tasks of this month"
          />
        </div>
        {!this.props.task.taskChartLoading &&
        this.props.task.taskChart.length === 0 ? (
          <div className="empty-component-bar-chart">
            <EmptyComponent
              message={intl.formatMessage({
                id: "emptyComponent.tasks",
                defaultMessage: "No task"
              })}
            />
          </div>
        ) : (
          <SideNavBarChart data={this.data()} ltr={ltr} />
        )}

        <StatusIndicatorFooter data={data} ltr={ltr} />
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    task: state.task,
    ltr: state.locale.ltr,
    taskChartLoading: state.task.taskChartLoading
  };
};

const mapDispatchToProps = {
  calenderData,
  fetchUpcomingMeetings,
  fetchTaskChart
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SideNavBarChartContainer));
