import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import locale from "./locale";
import user from "./user";
import theme from "./theme";
import modal from "./modal";
import task from "./task";
import news from "./news";
import meeting from "./meeting";
import adhoc from "./adhoc";
import committee from "./committee";
import meetingRequest from "./meetingRequest";
import confirmation from "./confirmation";
import project from "./project";
import login from "./login";
import toast from "./toast";
import users from "./users";
import calender from "./calender";
import timeline from "./timeline";

const app = history =>
  combineReducers({
    user,
    locale,
    theme,
    modal,
    task,
    meeting,
    news,
    adhoc,
    committee,
    meetingRequest,
    confirmation,
    project,
    login,
    toast,
    users,
    calender,
    timeline,
    router: connectRouter(history)
  });

export default app;
