import React, * as react from "react";

import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import "./style.scss";

import SmallCalender from "../SmallCalender";
import StatusIndicatorFooter from "../StatusIndicatorFooter";
class SmallCalenderContainer extends react.Component {
  render() {
    const { loading, calenderData } = this.props;
    let taskData = null;
    let meetingData = null;
    if (!loading && calenderData && calenderData.Tasks.length > 0) {
      taskData = calenderData.Tasks;
    }
    if (!loading && calenderData && calenderData.Meetings.length > 0) {
      meetingData = calenderData.Meetings;
    }

    const { theme, intl } = this.props;
    const firstText = intl.formatMessage({
      id: "sidenav.tasks",
      defaultMessage: "tasks"
    });

    const secondText = intl.formatMessage({
      id: "sidenav.meetings",
      defaultMessage: "tasks"
    });
    const data = [
      { color: "#98B7B3", text: firstText },
      { color: "#F99543", text: secondText }
    ];

    return (
      <div>
        <SmallCalender taskData={taskData} meetingData={meetingData} />
        <StatusIndicatorFooter data={data} />
        <div
          className="small-calender-footer-container"
          style={{
            backgroundColor: theme.primaryLight,
            borderColor: theme.accentColor
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    locale: state.locale,
    calenderData: state.calender.calenderData,
    loading: state.calender.Loading
  };
};

export default connect(
  mapStateToProps,
  null
)(injectIntl(SmallCalenderContainer));
