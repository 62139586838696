import axios from "axios";
import {
  meetingRequestFailed,
  meetingRequestLoading,
  meetingRequestActionLoading,
  meetingRequestActionAsync,
  fetchMeetingRequestAsync,
  hideModal
} from "../actions";
import { getDataFromRes } from "../utils";
import { call, put, takeLatest } from "redux-saga/effects";

function fetchMeetingRequestApi({ PagingInfo }) {
  const path = "/Meetings/Requests";
  return axios.get(path, { params: { PagingInfo } });
}

function* fetchMeetingRequestEffect(action) {
  yield put(meetingRequestLoading(action.PagingInfo === ""));
  try {
    let { data } = yield call(fetchMeetingRequestApi, action);

    if (data.StatusCode === "Success") {
      yield put(
        fetchMeetingRequestAsync(
          getDataFromRes(data),
          data.ListItemCollectionPosition
            ? data.ListItemCollectionPosition.PagingInfo
            : "noMore"
        )
      );
    } else {
      yield put(meetingRequestFailed(data.StatusMessage));
    }
  } catch (e) {}
}

export function* fetchMeetingRequestWatcher() {
  yield takeLatest("FETCH_MEETING_REQUEST", fetchMeetingRequestEffect);
}

function meetingRequestActionApi(
  requestId,
  action,
  attendeesStatus,
  SuggestedDate
) {
  const data = {
    ID: requestId,
    ResponseValue: action,
    AttendeesStatus: attendeesStatus ? attendeesStatus : "",
    SuggestedDate: SuggestedDate ? SuggestedDate : ""
  };

  return axios.post("/Meetings/Response", data);
}

function* meetingRequestActionEffect(action) {
  yield put(meetingRequestActionLoading());
  try {
    let { data } = yield call(
      meetingRequestActionApi,
      action.requestId,
      action.action,
      action.attendeesStatus,
      action.SuggestedDate
    );

    if (data.StatusCode === "Success") {
      yield put(
        meetingRequestActionAsync(
          action.requestId,
          action.action,
          action.attendeesStatus
        )
      );
      yield put(hideModal());
    } else {
      yield put(meetingRequestFailed(data.StatusMessage));
    }
  } catch (e) {}
}

export function* meetingRequestActionWatcher() {
  yield takeLatest("UPDATE_REQUEST_STATUS", meetingRequestActionEffect);
}
