export const USERS_LOADING = "USERS_LOADING";
export const USERS_FAILED = "USERS_FAILED";
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS_ASYNC = "FETCH_USERS_ASYNC";

export function fetchUsersAsync(users, userName) {
  return { type: FETCH_USERS_ASYNC, users, userName };
}

export function fetchUsers(userName) {
  return { type: FETCH_USERS, userName };
}

export function usersLoading() {
  return { type: USERS_LOADING };
}

export function usersFailed(error) {
  return { type: USERS_FAILED, error };
}
