import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import { Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import DatePicker from "../DatePicker";
import Select from "../Select";
import SingleSelect from "../SingleSelect";
import RadioInput from "../RadioInput";
import UploadInput from "../UploadInput";
import RichText from "../RichText";
import ActionItemsInput from "../ActionItemsInput";
import { limits } from "../../configs";
import "./style.scss";

const FormField = props => {
  const {
    theme,
    name,
    type,
    required,
    component,
    intl,
    options,
    locale,
    multi,
    headerImage,
    limit,
    handleCreate,
    time,
    timeOnly,
    withIcon,
    withoutLabel,
    noCreate,
    clearable,
    taskFilter,
    creatableMultiSelect,
    handleCreateMultiSelect,
    minDate,
    maxDate,
    module,
    excludeDates,
    imageOnly,
    disabled,
    errors,
    errorMessage,
    top,
    stopSelectLoading,
    isClearableDate
  } = props;

  const getInput = () => {
    switch (type) {
      case "dateTime":
        return (
          <DatePicker
            name={name}
            intl={intl}
            theme={theme}
            time={time}
            timeOnly={timeOnly}
            required={required}
            placeholder={intl.formatMessage({
              id: module
                ? `placeholder.${module}.${name}`
                : `placeholder.${name}`,
              defaultMessage: name
            })}
            minDate={minDate}
            maxDate={maxDate}
            excludeDates={excludeDates}
            errors={errors}
            isClearableDate={isClearableDate}
          />
        );
      case "select":
        return (
          <Select
            name={name}
            intl={intl}
            theme={theme}
            locale={locale}
            limit={limit}
            options={options}
            required={required}
            placeholder={intl.formatMessage({
              id: module
                ? `placeholder.${module}.${name}`
                : `placeholder.${name}`,
              defaultMessage: name
            })}
            creatableMultiSelect={creatableMultiSelect}
            handleCreateMultiSelect={handleCreateMultiSelect}
            errors={errors}
            top={top}
            stopSelectLoading={stopSelectLoading}
          />
        );
      case "singleSelect":
        return (
          <SingleSelect
            name={name}
            intl={intl}
            theme={theme}
            locale={locale}
            limit={limit}
            options={options}
            required={required}
            taskFilter={taskFilter}
            handleCreate={handleCreate}
            noCreate={noCreate}
            clearable={clearable}
            placeholder={intl.formatMessage({
              id: module
                ? `placeholder.${module}.${name}`
                : `placeholder.${name}`,
              defaultMessage: name
            })}
            disabled={disabled}
            errors={errors}
            top={top}
            stopSelectLoading={stopSelectLoading}
          />
        );
      case "radio":
        return (
          <RadioInput
            withIcon={withIcon}
            name={name}
            intl={intl}
            theme={theme}
            locale={locale}
            options={options}
            required={required}
          />
        );
      case "richText":
        return (
          <RichText
            name={name}
            intl={intl}
            theme={theme}
            locale={locale}
            required={required}
            errors={errors}
          />
        );
      case "upload":
        return (
          <UploadInput
            name={name}
            intl={intl}
            theme={theme}
            required={required}
            multi={multi}
            headerImage={headerImage}
            imageOnly={imageOnly}
            locale={locale}
            errors={errors}
          />
        );
      case "textarea":
        return (
          <Field
            type="text"
            component="textarea"
            maxLength={limits.description}
            name={name}
            placeholder={intl.formatMessage({
              id: module
                ? `placeholder.${module}.${name}`
                : `placeholder.${name}`,
              defaultMessage: name
            })}
            style={{
              borderColor: errors ? theme.errorColor : theme.accentColor,
              height: "100px"
            }}
          />
        );
      case "actionItems":
        return (
          <ActionItemsInput
            name={name}
            intl={intl}
            theme={theme}
            options={options}
          />
        );
      default:
        return (
          <Field
            type={type}
            name={name}
            maxLength={limits.title}
            placeholder={intl.formatMessage({
              id: module
                ? `placeholder.${module}.${name}`
                : `placeholder.${name}`,
              defaultMessage: name
            })}
            component={component ? component : "input"}
            style={{
              borderColor: errors ? theme.errorColor : theme.accentColor
            }}
          />
        );
    }
  };

  return (
    <div className="form-field">
      {!withoutLabel && (
        <p className="label">
          <FormattedMessage
            id={module ? `label.${module}.${name}` : `label.${name}`}
            defaultMessage={name}
          />
          {required ? (
            <span className="required" style={{ color: theme.errorColor }}>
              {" *"}
            </span>
          ) : null}
        </p>
      )}

      <div className="field" style={{ borderColor: theme.accentColor }}>
        {getInput()}
        {errorMessage && (
          <ErrorMessage
            className="fields-error-messege"
            style={{ color: theme.errorColor }}
            name={name}
            component="div"
          />
        )}
      </div>
    </div>
  );
};

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  component: PropTypes.string,
  options: PropTypes.array,
  required: PropTypes.bool,
  multi: PropTypes.bool,
  limit: PropTypes.number,
  headerImage: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ...state
  };
};

export default connect(mapStateToProps, null)(injectIntl(FormField));
