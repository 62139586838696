import React from "react";

import { connect } from "react-redux";

import "./style.scss";

const Badge = props => {
  const {
    text,
    color = props.theme.textGray,
    backgroundColor = props.theme.primaryLight,
    Icon,
    fixed,
    rounded
  } = props;
  return (
    <div
      className="Badge-container"
      style={{ color: color, minWidth: fixed ? 100 : null }}
    >
      <div
        style={{ backgroundColor: backgroundColor }}
        className={rounded ? "badge rounded" : "badge "}
      >
        {Icon && (
          <div className="badge-icon">
            <Icon style={{ fill: color }} />
          </div>
        )}
        <div className="badge-text" style={{ color: color }}>
          {text}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    locale: state.locale,
    theme: state.theme
  };
};

export default connect(
  mapStateToProps,
  null
)(Badge);
