import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./style.scss";
import DaysSince from "../DaysSince";
import LocaleAwareText from "../LocaleAwareText";
import AdhocStatus from "../AdhocStatus";

class AdhocInnerCardTitleWithDescription extends Component {
  render() {
    const { theme, data, suffix, types, withDescription } = this.props;

    return (
      <div className="adhoc-inner-card-title-with-description">
        <div className="header-container">
          <div className="title-section des-height">
            <p style={{ color: theme.primary }}>{data.TitleAr}</p>
          </div>
          <AdhocStatus
            name={types[data.Type][`Title${suffix}`]}
            color={theme.primary}
            id={data.ID}
          />
        </div>
        <div className="date-container">
          <DaysSince
            style={{ color: theme.accentColor }}
            startDate={data.CreatedDate}
          />
        </div>

        {withDescription && data.DescriptionAr && data.DescriptionAr !== "" && (
          <LocaleAwareText>
            <p className="text">{data.DescriptionAr}</p>
          </LocaleAwareText>
        )}
      </div>
    );
  }
}

AdhocInnerCardTitleWithDescription.propTypes = {
  theme: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    suffix: state.locale.suffix,
    loading: state.adhoc.CommentLoading,
    types: state.theme.Adhoc_Types
  };
};

export default connect(
  mapStateToProps,
  null
)(AdhocInnerCardTitleWithDescription);
