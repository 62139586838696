import React, * as react from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
// import ApiCalendar from "react-google-calendar-api";
import { ReactComponent as UserIcon } from "../../assets/images/user-committee-icon.svg";

import "./style.scss";

class LogOutDropDown extends react.Component {
  openProfile() {
    const { history } = this.props;
    history.push("/profile");
  }

  logout() {
    // ApiCalendar.gapi.auth2.getAuthInstance().disconnect();
    this.props.clearProjectsOnLogOut();
    this.props.logout(this.props.user.DeviceID);
    // this.props.emptyGoogleMeeting();
  }
  render() {
    const { locale, theme, intl } = this.props;

    return (
      <div
        className={locale.ltr ? "logout-drop-down ltr" : "logout-drop-down "}
      >
        <div className="option-ulcontainer-logout">
          <div className="logout-container">
            <div className="title">
              <UserIcon style={{ fill: theme.primary }} />
              <p className="text" onClick={() => this.openProfile()}>
                {intl.formatMessage({
                  id: "logout.title",
                  defaultMessage: "profile"
                })}
              </p>
            </div>

            <div className="more-info">
              <button
                className="log-out-btn"
                style={{ backgroundColor: theme.primary }}
                onClick={() => this.logout()}
              >
                {intl.formatMessage({
                  id: "logout.btn",
                  defaultMessage: "logout"
                })}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
LogOutDropDown.propTypes = {
  locale: PropTypes.object,
  theme: PropTypes.object,
  intl: PropTypes.object,
  logout: PropTypes.func
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ...state
  };
};

export default connect(mapStateToProps, null)(injectIntl(LogOutDropDown));
