import React from "react";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";

const NewsDetailsLoadingCard = props => {
  const { ltr } = props;
  return (
    <ContentLoader
      className="card loading-card"
      rtl={!ltr}
      width={400}
      height={250}
    >
      <circle cx="20" cy="20" r="10" />
      <rect x="40" y="12" rx="3" ry="3" width="70" height="6" />
      <rect x="40" y="22" rx="3" ry="3" width="50" height="6" />

      <rect x="0" y="40" width="400" height="1" />
      <rect x="340" y="50" rx="5" ry="5" width="50" height="10" />

      <rect x="0" y="70" width="400" height="1" />

      <rect x="10" y="80" rx="5" ry="5" width="380" height="100" />

      <rect x="10" y="190" rx="3" ry="3" width="150" height="6" />
      <rect x="10" y="206" rx="3" ry="3" width="380" height="6" />
      <rect x="10" y="216" rx="3" ry="3" width="380" height="6" />
      <rect x="10" y="226" rx="3" ry="3" width="300" height="6" />
    </ContentLoader>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ltr: state.locale.ltr
  };
};

export default connect(
  mapStateToProps,
  null
)(NewsDetailsLoadingCard);
