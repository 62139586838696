import { TOGGLE_LOCALE } from "../actions";

const locales = {
  ar: {
    lang: "en",
    ltr: true,
    direction: "ltr",
    suffix: "En"
  },
  en: {
    lang: "ar",
    ltr: false,
    direction: "rtl",
    suffix: "Ar"
  }
};

const initialState = {
  ...locales["en"]
};

function locale(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_LOCALE:
      return {
        ...locales[action.lang]
      };
    default:
      return state;
  }
}

export default locale;
