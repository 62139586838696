import {
  FETCH_TIMELINE_ASYNC,
  TIMELINE_LOADING,
  TIMELINE_COMPLETE_TASK,
  TIMELINE_UPDATE_PROGRESS,
  TIMELINE_ACCEPT_REQUEST,
  UPDATE_TIMELINE_TASK_STATUS,
  UPDATE_TIMELINE_MEETINGS
} from "../actions";
import sortBy from "lodash/sortBy";
import { getTaskStatus } from "../utils";

const initialState = {
  Loading: true,
  Error,
  Posts: [],
  NextPage: {}
};

function task(state = initialState, action) {
  switch (action.type) {
    case FETCH_TIMELINE_ASYNC:
      const meetingIDs = state.Posts.filter(
        post => post.TimelineType === "meeting"
      ).map(post => post.ID);
      const filteredPosts =
        meetingIDs.length > 0
          ? action.data.filter(
              post =>
                post.TimelineType === "meeting" && !meetingIDs.includes(post.ID)
            )
          : action.data;
      const sortedPosts = sortBy(
        [...state.Posts, ...filteredPosts],
        ["ModifiedDate"]
      ).reverse();
      return {
        ...state,
        Loading: false,
        Posts: sortedPosts,
        NextPage: action.nextPage
      };
    case TIMELINE_UPDATE_PROGRESS:
      const withNewProgress = state.Posts.map(post => {
        if (post.TimelineType === "tasks" && post.ID === action.id) {
          let newPost = { ...post, Progress: action.progress };
          newPost.Status = getTaskStatus(newPost);
          return newPost;
        }
        return post;
      });
      return {
        ...state,
        Loading: false,
        Posts: withNewProgress
      };
    case TIMELINE_COMPLETE_TASK:
      const withCompleteTask = state.Posts.map(post => {
        if (post.TimelineType === "tasks" && post.ID === action.id) {
          return { ...post, Status: "Completed" };
        }
        return post;
      });
      return {
        ...state,
        Loading: false,
        Posts: withCompleteTask
      };
    case TIMELINE_ACCEPT_REQUEST:
      const withAcceptedRequest = state.Posts.map(post => {
        if (post.TimelineType === "meeting-requests" && post.ID === action.id) {
          return { ...post, ID: post.MeetingID, TimelineType: "meeting" };
        }
        return post;
      });
      return {
        ...state,
        Loading: false,
        Posts: withAcceptedRequest
      };
    case TIMELINE_LOADING:
      const newState = action.keepState ? state : initialState;
      return {
        ...newState,
        Loading: true
      };
    case UPDATE_TIMELINE_TASK_STATUS:
      const newData =
        state.Posts.length > 0 &&
        state.Posts.map(data => {
          if (action.id === data.ID) {
            let newPost = {
              ...data,
              Progress: action.status
            };

            newPost.Status = getTaskStatus(newPost);
            return { ...newPost };
          } else {
            return data;
          }
        });
      return {
        ...state,
        Posts: state.Posts.length > 0 ? newData : state.Posts
      };
    case UPDATE_TIMELINE_MEETINGS:
      const newList = state.Posts.filter(data => {
        return action.id !== data.ID;
      });
      return {
        ...state,
        Posts: [...newList]
      };
    default:
      return state;
  }
}

export default task;
