import React from "react";
import { connect } from "react-redux";
import ReactModal from "react-modal";
import { FormattedMessage } from "react-intl";
import Button from "../Button";
import { hideConfirmation } from "../../actions";
import "./style.scss";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    background: "white",
    zIndex: 1500,
    borderRadius: 5,
    border: "none",
    boxShadow: "1px 1px 3px 0px rgba(0, 0, 0, 0.1)",
    padding: 0
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    paddingTop: "3em"
  }
};

ReactModal.setAppElement("#root");

const ConfirmationDialog = props => {
  const { gray, hideConfirmation, customMessage, onConfirm } = props;
  return (
    <ReactModal
      onRequestClose={hideConfirmation}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      style={customStyles}
      isOpen
    >
      <div className="confirmation-container">
        <h3 className="confirmation-message">
          {customMessage ? (
            customMessage
          ) : (
            <FormattedMessage
              id="confirmation.message"
              defaultMessage="Are you sure?"
            />
          )}
        </h3>
        <div className="confirmation-buttons">
          <Button
            onClick={() => {
              onConfirm();
              hideConfirmation();
            }}
          >
            <FormattedMessage
              id="confirmation.confirm"
              defaultMessage="Confirm"
            />
          </Button>
          <Button onClick={hideConfirmation} style={{ backgroundColor: gray }}>
            <FormattedMessage
              id="confirmation.cancel"
              defaultMessage="Cancel"
            />
          </Button>
        </div>
      </div>
    </ReactModal>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    customMessage: state.confirmation.Message,
    onConfirm: state.confirmation.onConfirm,
    gray: state.theme.textGray
  };
};

const mapDispatchToProps = { hideConfirmation };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationDialog);
