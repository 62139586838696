import React, * as react from "react";
import moment from "moment";
import "./style.scss";
import fromPairs from "lodash/fromPairs";

class RenderDay extends react.Component {
  dateStyle = {
    bottom: 0,
    right: 0,
    fontSize: 15
  };
  birthdayStyle = { fontSize: "0.8em" };
  cellStyle = {
    height: 31,
    width: 31,
    position: "relative",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  };

  filteredDateData = (data, type) => {
    let filteredData = null;
    let newData = null;

    if (data && data.length > 0) {
      newData = data.filter(el => {
        return (
          moment(
            type === "meeting" ? el.StartDate * 1000 : el.DueDate * 1000
          ).get("year") === this.props.year &&
          moment(
            type === "meeting" ? el.StartDate * 1000 : el.DueDate * 1000
          ).get("month") === this.props.month
        );
      });

      filteredData = fromPairs(
        newData.map(v => {
          return [
            moment(
              type === "meeting" ? v.StartDate * 1000 : v.DueDate * 1000
            ).get("date"),
            v.ID
          ];
        })
      );
    }

    return filteredData;
  };

  render() {
    const date = this.props.date.getDate();

    const { meetingData, taskData } = this.props;
    const meetingDateData = this.filteredDateData(meetingData, "meeting");
    const taskDateData = this.filteredDateData(taskData, "task");
    return (
      <div style={this.cellStyle} className="cell-small-calender ">
        <div style={this.dateStyle}>{date}</div>
        <div style={{ display: "flex" }}>
          {meetingDateData && meetingDateData[date] && (
            <div style={this.birthdayStyle}>
              <div
                className={
                  taskDateData && taskDateData[date]
                    ? "first-circle"
                    : "first-circle unsetPosition"
                }
              />
            </div>
          )}
          {taskDateData && taskDateData[date] && (
            <div style={this.birthdayStyle}>
              <div
                className={
                  meetingDateData && meetingDateData[date]
                    ? "second-circle"
                    : "second-circle unsetPosition"
                }
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default RenderDay;
