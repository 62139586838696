import React, * as react from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Loading from "../Loading";
import "./style.scss";

class Button extends react.Component {
  render() {
    const {
      children,
      className,
      style,
      onClick,
      theme,
      type,
      disabled,
      loading,
      progress
    } = this.props;

    return (
      <button
        className={`button ${className ? className : ""}`}
        type={type || "submit"}
        disabled={disabled || loading}
        style={{
          backgroundColor: theme.primary,
          ...style
        }}
        onClick={onClick}
      >
        {loading ? <Loading progress={progress} /> : children}
      </button>
    );
  }
}

Button.propTypes = {
  theme: PropTypes.object.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  loading: PropTypes.bool
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme
  };
};

export default connect(
  mapStateToProps,
  null
)(Button);
