import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import PropTypes from "prop-types";
import LogoText from "../LogoText";
import DeleteButton from "../DeleteButton";
import { removeCommitteeMember } from "../../actions";
import { getDisplayName } from "../../utils";
import "./style.scss";

class CommitteesCellProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.removeMember = this.removeMember.bind(this);
  }

  removeMember = () => {
    const { data, removeCommitteeMember } = this.props;
    this.setState({ loading: true }, () => {
      axios.post(`/Committee/DeleteMember/${data.MemberID}`).then(res => {
        if (res.data.StatusCode === "Success") {
          removeCommitteeMember(data.MemberID);
        }
        this.setState({ loading: false });
      });
    });
  };

  render() {
    const {
      accentColor,
      ltr,
      data,
      theme
      // committee,
      // currentUser
    } = this.props;
    const { loading } = this.state;

    return (
      <div
        className={
          ltr ? "cell-container-profile ltr" : "cell-container-profile"
        }
        style={{
          borderColor: accentColor
        }}
      >
        <LogoText
          name={getDisplayName(data.MemberInfo, ltr)}
          img={data.MemberInfo.UserProfilePicture}
          blackTitle={true}
          isNameClickable={
            data.MemberInfo.isActiveDirectoryUser && data.MemberInfo.Username
          }
        />

        {theme.allowDeleteMember ? (
          <DeleteButton deleteAction={this.removeMember} loading={loading} />
        ) : null}
      </div>
    );
  }
}

CommitteesCellProfile.propTypes = {
  ltr: PropTypes.bool,
  accentColor: PropTypes.string,
  data: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    accentColor: state.theme.accentColor,
    ltr: state.locale.ltr,
    committee: state.committee.Committee,
    currentUser: state.user.UserData.Username,
    theme: state.theme
  };
};

const mapDispatchToProps = { removeCommitteeMember };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommitteesCellProfile);
