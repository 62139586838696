import React, { Component } from "react";
import PropTypes from "prop-types";
import groupBy from "lodash/groupBy";
import { connect } from "react-redux";
import queryString from "query-string";
import TaskList from "../../components/TaskList";
import EmptyComponent from "../../components/EmptyComponents/EmptyComponent";
import TasksMembersLoadingCard from "../../components/LoadingCards/TasksMembersLoadingCard";
import TasksLoadingCard from "../../components/LoadingCards/TasksLoadingCard";

import { injectIntl } from "react-intl";
import TaskMembersList from "../../components/TaskMembersList";
import { fetchTask, fetchCompletedTask } from "../../actions";
import "./style.scss";
import { getDisplayName } from "../../utils";

class TasksPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }
  componentDidMount() {
    this.props.fetchTask();
  }

  getUserTasks = tasks => {
    const { match, history } = this.props;
    const { username } = match.params;
    if (username) {
      const userTasks = tasks.filter(
        task => task.Responsible && task.Responsible.Username === username
      );
      if (userTasks.length > 0) return userTasks;
      history.replace("/tasks/members");
    }
    return tasks;
  };

  filterTasks = tasks => {
    let newTasks = [...tasks];
    const { currentUser, location } = this.props;
    const params = queryString.parse(location.search);
    const { status, entity, priority, role, taskType } = params;

    if (!!taskType)
      newTasks = newTasks.filter(task => task.TaskType === taskType);
    if (!!status) newTasks = newTasks.filter(task => task.Status === status);
    if (!!entity)
      newTasks = newTasks.filter(
        task => JSON.stringify(task.EntityId) === entity
      );
    if (!!priority)
      newTasks = newTasks.filter(task => task.Priority === priority);
    if (!!role)
      newTasks = newTasks.filter(task => {
        if (task[role]) {
          if (Array.isArray(task[role])) {
            return task[role].map(item => item.Username).includes(currentUser);
          }
          return task[role].Username === currentUser;
        }
        return false;
      });
    return newTasks;
  };

  getMembers = Tasks => {
    const { suffix } = this.props;
    let { Tasks_Status } = this.props.theme;
    let statuses = { ...Tasks_Status };
    delete statuses.Completed;
    const groupedMembers = groupBy(Tasks, "Responsible.DisplayName");
    delete groupedMembers.undefined;
    let sortedGroupedMembers = {};
    Object.keys(groupedMembers)
      .sort()
      .forEach(function(key) {
        sortedGroupedMembers[groupedMembers[key][0].Responsible.Username] =
          groupedMembers[key];
      });
    const members = Object.keys(sortedGroupedMembers).map(key => {
      const tasks = groupBy(sortedGroupedMembers[key], "Status");
      const completedCount = tasks["Completed"] ? tasks["Completed"].length : 0;
      return {
        username: key,
        displayName:
          sortedGroupedMembers[key][0].Responsible &&
          getDisplayName(
            sortedGroupedMembers[key][0].Responsible,
            this.props.ltr
          ),
        img:
          sortedGroupedMembers[key][0].Responsible &&
          sortedGroupedMembers[key][0].Responsible.UserProfilePicture,
        tasksCount: sortedGroupedMembers[key].length - completedCount,
        completedCount,
        tasks: Object.keys(statuses).map(status => ({
          name: statuses[status][`Title${suffix}`],
          color: statuses[status].Color,
          value: tasks[status] ? tasks[status].length : 0
        }))
      };
    });
    return members;
  };

  completedTask = () => {
    this.props.fetchCompletedTask();
  };

  handleClick = (e, user, completed) => {
    e.stopPropagation();
    const { location, history } = this.props;
    let params = queryString.parse(location.search);
    if (completed) {
      params.status = "Completed";
    }
    history.push({
      pathname: `/tasks/user/${user}`,
      search: queryString.stringify(params)
    });
  };

  getView = () => {
    const { theme, task, match, intl } = this.props;
    if (task.Loading && match.path.includes("member"))
      return <TasksMembersLoadingCard />;
    if (task.Loading) return <TasksLoadingCard />;
    if (task.Tasks.length < 1 && !task.Loading) {
      return (
        <EmptyComponent
          title={intl.formatMessage({
            id: "emptyComponent.title.tasks",
            defaultMessage: "tasks"
          })}
        />
      );
    }
    const Tasks =
      match.path === "/tasks" ? task.Tasks : this.getUserTasks(task.Tasks);
    const filteredTasks = this.filterTasks(Tasks);
    const members = this.getMembers(filteredTasks);

    if (filteredTasks.length < 1) {
      return (
        <EmptyComponent
          title={intl.formatMessage({
            id: "emptyComponent.title.tasksFilter",
            defaultMessage: "tasks"
          })}
        />
      );
    }

    if (match.path === "/tasks/members")
      return (
        <TaskMembersList members={members} handleClick={this.handleClick} />
      );
    return (
      <TaskList theme={theme} tasks={filteredTasks} loading={task.Loading} />
    );
  };

  render() {
    return <div className="tasksPage">{this.getView()}</div>;
  }
}

TasksPage.propTypes = {
  task: PropTypes.object,
  theme: PropTypes.object,
  intl: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    task: state.task,
    theme: state.theme,
    intl: state.intl,
    suffix: state.locale.suffix,
    currentUser: state.user.UserData.Username,
    ltr: state.locale.ltr
  };
};

const mapDispatchToProps = { fetchTask, fetchCompletedTask };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(TasksPage));
