import React from "react";
import { FormattedMessage } from "react-intl";
import "./style.scss";

const RoundedButton = props => {
  const { onClick, title, Icon, color } = props;
  return (
    <button
      className="rounded-button"
      onClick={e => {
        onClick();
        e.stopPropagation();
      }}
      style={{ color }}
    >
      {Icon ? (
        <Icon className="rounded-button-icon" style={{ stroke: color }} />
      ) : null}
      <p className="rounded-button-text">
        <FormattedMessage id={`roundedButton.${title}`} default={title} />
      </p>
    </button>
  );
};

export default RoundedButton;
