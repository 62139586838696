import React from "react";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";

const CommitteeDetailsLoadingCard = props => {
  const { ltr } = props;
  return (
    <ContentLoader
      className="card loading-card"
      rtl={!ltr}
      width={400}
      height={200}
    >
      <rect x="40" y="12" rx="3" ry="3" width="70" height="6" />
      <rect x="40" y="25" rx="3" ry="3" width="350" height="6" />
      <rect x="40" y="36" rx="3" ry="3" width="270" height="6" />

      <circle cx="20" cy="20" r="10" />

      <circle cx="50" cy="65" r="10" />
      <rect x="70" y="57" rx="3" ry="3" width="70" height="6" />
      <rect x="70" y="67" rx="3" ry="3" width="40" height="6" />

      <rect x="0" y="85" rx="3" ry="3" width="400" height="1" />

      <rect x="10" y="95" rx="3" ry="3" width="30" height="6" />
      <rect x="50" y="95" rx="3" ry="3" width="30" height="6" />
      <rect x="90" y="95" rx="3" ry="3" width="30" height="6" />

      <rect x="0" y="110" rx="3" ry="3" width="400" height="1" />

      <rect x="200" y="111" width="1" height="90" />

      <rect x="0" y="154.5" width="400" height="1" />

      <circle cx="20" cy="133" r="10" />
      <rect x="40" y="130" width="60" rx="3" ry="3" height="6" />

      <circle cx="20" cy="176.5" r="10" />
      <rect x="40" y="173.5" width="60" rx="3" ry="3" height="6" />

      <circle cx="220" cy="176.5" r="10" />
      <rect x="240" y="173.5" width="60" rx="3" ry="3" height="6" />

      <circle cx="220" cy="133" r="10" />
      <rect x="240" y="130" width="60" rx="3" ry="3" height="6" />
    </ContentLoader>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ltr: state.locale.ltr
  };
};

export default connect(
  mapStateToProps,
  null
)(CommitteeDetailsLoadingCard);
