import {
  LOGIN_ASYNC,
  LOGOUT_ASYNC,
  SAVE_PROJECT_DATA,
  SAVE_PROJECTS_TOKEN,
  UPDATE_COUNTS_ASYNC,
  UPDATE_NOTIF_COUNTS_ASYNC,
  ADD_DEVICE_ID
} from "../actions";

const initialState = {
  LoggedIn: false,
  Token: "",
  ppToken: "",
  UserData: {},
  Counts: {
    ResponsibleCount: 0,
    AccountableCount: 0,
    ConsultedCount: 0,
    InformedCount: 0
  },
  NotifCount: 0,
  DeviceID: null
};

function user(state = initialState, action) {
  switch (action.type) {
    case LOGIN_ASYNC:
      return {
        ...initialState,
        LoggedIn: true,
        DeviceID: state.DeviceID,
        ...action.data
      };
    case LOGOUT_ASYNC:
      return {
        ...initialState,
        DeviceID: state.DeviceID
      };
    case UPDATE_COUNTS_ASYNC:
      return {
        ...state,
        DeviceID: state.DeviceID,
        Counts: action.counts
      };
    case UPDATE_NOTIF_COUNTS_ASYNC:
      return {
        ...state,
        DeviceID: state.DeviceID,
        NotifCount: action.counts
      };
    case ADD_DEVICE_ID:
      return {
        ...state,
        DeviceID: action.DeviceID
      };
    case SAVE_PROJECT_DATA:
      return {
        ...state,
        DeviceID: action.DeviceID,
        Projects: [...state.Projects, action.project]
      };
    case SAVE_PROJECTS_TOKEN:
      return {
        ...state,
        DeviceID: action.DeviceID,
        ppToken: action.token
      };
    default:
      return state;
  }
}

export default user;
