// Add
export const ADD_COMMITTEE = "ADD_COMMITTEE";
export const ADD_COMMITTEE_ASYNC = "ADD_COMMITTEE_ASYNC";
export const ADD_COMMITTEE_LOADING = "ADD_COMMITTEE_LOADING";
export const UPDATE_COMMITTEE_UPLOAD_PROGRESS =
  "UPDATE_COMMITTEE_UPLOAD_PROGRESS";
export function addCommittee(committee, committeeId, username) {
  return { type: ADD_COMMITTEE, committee, committeeId, username };
}
export function addCommitteeAsync(committee, username) {
  return { type: ADD_COMMITTEE_ASYNC, committee, username };
}
export function addCommitteeLoading() {
  return { type: ADD_COMMITTEE_LOADING };
}
export function updateCommitteeUploadProgress(progress) {
  return { type: UPDATE_COMMITTEE_UPLOAD_PROGRESS, progress };
}
// Fetch
export const FETCH_COMMITTEE = "FETCH_COMMITTEE";
export const FETCH_COMMITTEE_ASYNC = "FETCH_COMMITTEE_ASYNC";
export const COMMITTEE_LOADING = "COMMITTEE_LOADING";

export function fetchCommittee(committeeId, username) {
  return { type: FETCH_COMMITTEE, committeeId, username };
}

export function fetchCommitteeAsync(committee, username) {
  return { type: FETCH_COMMITTEE_ASYNC, committee, username };
}

export function committeeLoading() {
  return { type: COMMITTEE_LOADING };
}
// Add Item
export const ADD_COMMITTEE_MEMBERS = "ADD_COMMITTEE_MEMBER";
export const ADD_COMMITTEE_ATTACHMENTS = "ADD_COMMITTEE_ATTACHMENT";
export const ADD_COMMITTEE_FOLDER = "ADD_COMMITTEE_FOLDER";
export const ADD_COMMITTEE_MEETING = "ADD_COMMITTEE_MEETING";
export const ADD_COMMITTEE_ITEMS = "ADD_COMMITTEE_ITEM";
export const ADD_FILES_TO_FOLDER = "ADD_FILES_TO_FOLDER";

export function addCommitteeMembers(items) {
  return { type: ADD_COMMITTEE_MEMBERS, items, key: "Members" };
}

export function addCommitteeAttachments(items) {
  return { type: ADD_COMMITTEE_ATTACHMENTS, items, key: "Attachment" };
}

export function addCommitteeFolder(items) {
  return { type: ADD_COMMITTEE_FOLDER, items, key: "Folder" };
}

export function addCommitteeMeeting(items, committeeId) {
  return { type: ADD_COMMITTEE_MEETING, items, committeeId, key: "Meetings" };
}

export function addCommitteeItems(items, key) {
  return { type: ADD_COMMITTEE_ITEMS, items, key };
}

export function addFilesToFolder(items) {
  return { type: ADD_FILES_TO_FOLDER, items };
}
// Remove Item
export const REMOVE_COMMITTEE_MEMBER = "REMOVE_COMMITTEE_MEMBER";
export const REMOVE_COMMITTEE_ATTACHMENT = "REMOVE_COMMITTEE_ATTACHMENT";
export const REMOVE_COMMITTEE_FOLDER = "REMOVE_COMMITTEE_FOLDER";
export const REMOVE_COMMITTEE_MEETING = "REMOVE_COMMITTEE_MEETING";
export const REMOVE_FILE_FROM_FOLDER = "REMOVE_FILE_FROM_FOLDER";
export const RENAME_FOLDER = "RENAME_FOLDER";
export const CANCEL_COMMITTEE_MEETING = "CANCEL_COMMITTEE_MEETING";

export function renameFolder(id, name) {
  return { type: RENAME_FOLDER, id, name };
}

export function removeCommitteeMember(id) {
  return { type: REMOVE_COMMITTEE_MEMBER, id };
}

export function removeCommitteeAttachment(id) {
  return { type: REMOVE_COMMITTEE_ATTACHMENT, id };
}

export function removeCommitteeFolder(id) {
  return { type: REMOVE_COMMITTEE_FOLDER, id };
}

export function removeCommitteeMeeting(id) {
  return { type: REMOVE_COMMITTEE_MEETING, id };
}

export function removeFileFromFolder(id, folderId) {
  return { type: REMOVE_FILE_FROM_FOLDER, id, folderId };
}

export function cancelCommitteeMeeting(id) {
  return { type: CANCEL_COMMITTEE_MEETING, id };
}
// Error
export const COMMITTEE_FAILED = "ADD_COMMITTEE_ASYNC";

export function committeeFailed(error) {
  return { type: COMMITTEE_FAILED, error };
}
