import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import "./style.scss";

const NotFoundPage = props => {
  const { textGray } = props;
  return (
    <div className="not-found" style={{ color: textGray }}>
      <FormattedMessage
        id="notFound.pageNotFound"
        defaultMessage="The page is not found"
        className="text"
      />
    </div>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    textGray: state.theme.textGray
  };
};

export default connect(
  mapStateToProps,
  null
)(NotFoundPage);
