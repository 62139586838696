import React from "react";
import PropTypes from "prop-types";
import TaskItem from "../TaskItem";
import NewsCard from "../NewsCard";
import TimelineMeeting from "../TimelineMeeting";
import TasksLoadingCard from "../LoadingCards/TasksLoadingCard";
import EmptyComponent from "../EmptyComponents/EmptyComponent";
import "./style.scss";

function Timeline(props) {
  const { posts, loading, intl, theme } = props;
  const getItem = (post, index) => {
    switch (post.TimelineType) {
      case "tasks":
        return (
          <div className="card hidden" key={index}>
            <TaskItem
              key={`${post.ID}${post.TimelineType}`}
              theme={theme}
              task={post}
            />
          </div>
        );
      case "news":
        return <NewsCard key={`${post.ID}${post.TimelineType}`} News={post} />;
      case "meeting":
        return (
          <div className="card" key={index}>
            <TimelineMeeting
              key={`${post.ID}${post.TimelineType}`}
              post={post}
            />
          </div>
        );
      case "meeting-requests":
        return (
          <div className="card" key={index}>
            <TimelineMeeting
              key={`${post.ID}${post.TimelineType}`}
              post={post}
            />
          </div>
        );
      default:
        return null;
    }
  };
  if (posts.length < 1 && !loading) {
    return (
      <EmptyComponent
        title={intl.formatMessage({
          id: "emptyComponent.title.timeline",
          defaultMessage: "timeline"
        })}
      />
    );
  }
  return (
    <div className="tasks-sections">
      <div className="taskList">
        {posts.length > 0
          ? posts.map((post, index) => getItem(post, index))
          : null}
        {loading ? <TasksLoadingCard /> : null}
      </div>
    </div>
  );
}

Timeline.propTypes = {
  theme: PropTypes.object,
  tasks: PropTypes.array
};

export default Timeline;
