import React from "react";
import TaskMemberItem from "../TaskMemberItem";
import "./style.scss";

const TaskMembersList = props => {
  const { members, handleClick } = props;

  return (
    <div className="task-members-list">
      {members.map((member, index) => (
        <TaskMemberItem data={member} key={index} handleClick={handleClick} />
      ))}
    </div>
  );
};

export default TaskMembersList;
