import axios from "axios";
import { timelineLoading, fetchTimelineAsync } from "../actions";
import { call, put, takeLatest } from "redux-saga/effects";

// Fetch

function fetchTimelineApi(nextPage) {
  return axios.post("timeline", nextPage);
}

function* fetchTimelineEffect(action) {
  yield put(timelineLoading(!!action.nextPage));
  try {
    let { data } = yield call(fetchTimelineApi, action.nextPage);
    if (data.StatusCode === "Success") {
      const {
        Tasks,
        News,
        Meetings,
        MeetingRequests,
        MeetingRequestsListItemCollectionPosition,
        MeetingsListItemCollectionPosition,
        NewsListItemCollectionPosition,
        TasksListItemCollectionPosition
      } = data.Data;
      let realData = [];
      if (Tasks) realData = [...realData, ...Tasks];
      if (Meetings) realData = [...realData, ...Meetings];
      if (MeetingRequests) realData = [...realData, ...MeetingRequests];
      if (News) realData = [...realData, ...News];
      const nextPage = {
        MeetingsListItemCollectionPosition,
        MeetingRequestsListItemCollectionPosition,
        TasksListItemCollectionPosition,
        NewsListItemCollectionPosition
      };
      yield put(fetchTimelineAsync(realData, nextPage));
    } else {
      console.log("nooooooooo");
    }
  } catch (e) {}
}

export function* fetchTimelineWatcher() {
  yield takeLatest("FETCH_TIMELINE", fetchTimelineEffect);
}
