import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FormattedMessage, injectIntl } from "react-intl";
import Modal from "../Modal";
import LoadingComponent from "../Loading";
import AttachmentList from "../AttachmentList";

import TimelineMeeting from "../TimelineMeeting";

import MeetingDetailsMembersStatusLogoText from "../MeetingDetailsMembersStatusLogoText";
import MeetingModalSection from "../MeetingModalSection";
import GoogleMeetingDetailsModal from "../GoogleMeetingDetailsModal";
import AddMOMModal from "../AddMOMModal";
import CommentsContainer from "../CommentsContainer";
import { ReactComponent as CommitteesIcon } from "../../assets/images/nav/committees-icon.svg";
import { fetchMeeting, meetingAddComment } from "../../actions";
import "./style.scss";
import {
  getDisplayName,
  getMeetingUsers,
  cleanUsersForMention
} from "../../utils";

class MeetingDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addMOM: false
    };
    if (!props.match.params.id.includes("google")) {
      this.props.fetchMeeting(props.match.params.id);
    }
  }

  compare(a, b) {
    if (a.Response < b.Response) return -1;
    if (a.Response > b.Response) return 1;
    return 0;
  }
  render() {
    const {
      theme,
      Loading,
      Meeting,
      history,
      location,
      ltr,
      meetingAddComment,
      CommentLoading,
      Progress,
      intl,
      suffix
    } = this.props;

    if (this.props.match.params.id.includes("google")) {
      return <GoogleMeetingDetailsModal />;
    }
    if (Loading || Object.entries(Meeting).length === 0)
      return (
        <Modal
          hideModal={() =>
            location.state ? history.goBack() : history.push("/meetings")
          }
          isOpen
          title="noTitle"
          noTitle
        >
          <div className="modal-loading">
            <LoadingComponent color={theme.primary} />
          </div>
        </Modal>
      );
    const hasDetails =
      (Meeting.CancelationReason && Meeting.CancelationReason !== "") ||
      (Meeting.AgendaItems && Meeting.AgendaItems !== "") ||
      (Meeting.Notes && Meeting.Notes !== "") ||
      Meeting.HasMOM;
    const hasAttach = Meeting.Attachment && Meeting.Attachment.length > 0;
    const hasData = hasAttach || hasDetails;

    return (
      <Modal
        hideModal={() =>
          location.state ? history.goBack() : history.push("/meetings")
        }
        isOpen
        title="noTitle"
        noTitle
      >
        <div className="meeting-modal-summary">
          <TimelineMeeting
            key={`${Meeting.ID}${Meeting.TimelineType}`}
            post={Meeting}
            editable
            details
          />
        </div>

        {hasData ? (
          <div
            className="meeting-modal-content"
            style={{ borderColor: theme.accentColor }}
          >
            {hasDetails ? (
              <>
                <div>
                  <h4 className="section-header">
                    <FormattedMessage
                      id="meetingDetailsModal.meetingDetails"
                      defaultMessage="Meeting Details"
                    />
                  </h4>
                </div>
                <div
                  className="meeting-modal-content-container"
                  style={{ color: theme.textGray }}
                >
                  {Meeting.CancelationReason &&
                  Meeting.CancelationReason !== "" ? (
                    <MeetingModalSection
                      item={Meeting.CancelationReason}
                      title="CancelationReason"
                    />
                  ) : null}
                  {Meeting.AgendaItems && Meeting.AgendaItems !== "" ? (
                    <MeetingModalSection
                      item={Meeting.AgendaItems}
                      title="Agenda"
                    />
                  ) : null}
                  {Meeting.Notes && Meeting.Notes !== "" ? (
                    <MeetingModalSection item={Meeting.Notes} title="Notes" />
                  ) : null}
                  {Meeting.HasMOM ? (
                    <>
                      <MeetingModalSection
                        item={Meeting.MOM.Discussions}
                        title="Discussions"
                      />
                      <MeetingModalSection
                        item={Meeting.MOM.Decisions}
                        title="Decisions"
                      />
                      {Meeting.MOM.ActionItems.length > 0 ? (
                        <MeetingModalSection
                          item={Meeting.MOM.ActionItems}
                          title="ActionItems"
                          actions
                        />
                      ) : null}
                    </>
                  ) : null}
                </div>
              </>
            ) : null}

            {hasAttach ? (
              <>
                <div>
                  <h4 className="section-header">
                    <FormattedMessage
                      id="label.Attachment"
                      defaultMessage="Attachments"
                    />
                  </h4>
                </div>
                <AttachmentList
                  photos={Meeting.Attachment}
                  deletable={Meeting.CanEdit}
                  type="Meetings"
                />
              </>
            ) : null}
          </div>
        ) : null}

        <div
          className="meeting-members"
          style={{
            borderColor: theme.accentColor
          }}
        >
          <MeetingModalSection
            item={theme.Meeting_Type[Meeting.MeetingType][`Title${suffix}`]}
            title={intl.formatMessage({ id: "label.MeetingType" })}
          />
          <div className="members-title">
            <CommitteesIcon className="icon" style={{ fill: theme.primary }} />
            <h4>
              <FormattedMessage
                id="MeetingDetailsModal.invited"
                defaultMessage="Invited"
              />
              {" :"}
            </h4>
          </div>
          <div className="members">
            {Meeting.Members &&
              Meeting.Members.sort(this.compare)
                .reverse()
                .map((member, index) => (
                  <MeetingDetailsMembersStatusLogoText
                    key={index}
                    name={getDisplayName(member.UserInfo, ltr)}
                    img={member.UserInfo.UserProfilePicture}
                    response={member.Response}
                    attendeesStatus={member.AttendeesStatus}
                    SuggestedDate={member.SuggestedDate}
                    isNameClickable={
                      member.UserInfo.isActiveDirectoryUser &&
                      member.UserInfo.Username
                    }
                  />
                ))}
          </div>
        </div>
        <CommentsContainer
          addComment={meetingAddComment}
          loading={CommentLoading}
          id={Meeting.ID}
          comments={Meeting.Comments}
          uploadIcon={true}
          progress={Progress}
          users={cleanUsersForMention(getMeetingUsers(Meeting))}
          type="meetings"
        />
        {this.state.addMOM ? (
          <AddMOMModal
            hideModal={() => this.setState({ addMOM: false })}
            onCreate={this.onCreate}
            data={Meeting.MOM}
            id={Meeting.ID}
          />
        ) : null}
      </Modal>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    Loading: state.meeting.SingleLoading,
    Meeting: state.meeting.Meeting,
    CommentLoading: state.meeting.CommentLoading,
    Progress: state.meeting.Progress,
    ltr: state.locale.ltr,
    suffix: state.locale.suffix
  };
};

const mapDispatchToProps = { fetchMeeting, meetingAddComment };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(MeetingDetailsModal)));
