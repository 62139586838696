export const ADD_NEWS = "ADD_NEWS";
export const ADD_NEWS_ASYNC = "ADD_NEWS_ASYNC";
export const NEWS_LOADING = "NEWS_LOADING";
export const CREATE_NEWS_LOADING = "CREATE_NEWS_LOADING";
export const STOP_CREATE_NEWS_LOADING = "STOP_CREATE_NEWS_LOADING";
export const NEWS_FAILED = "NEWS_FAILED";
export const FETCH_NEWS = "FETCH_NEWS";
export const FETCH_NEWS_ASYNC = "FETCH_NEWS_ASYNC";
export const DELETE_NEWS = "DELETE_NEWS";
export const DELETE_NEWS_ASYNC = "DELETE_NEWS_ASYNC";
export const SET_NEWS_SOURCES = "SET_NEWS_SOURCES";
export const ON_CREATE_NEWS_SOURCES = "ON_CREATE_NEWS_SOURCES";
export const UPDATE_NEWS_UPLOAD_PROGRESS = "UPDATE_NEWS_UPLOAD_PROGRESS";
export const NEWS_ADD_COMMENT = "NEWS_ADD_COMMENT";
export const NEWS_ADD_COMMENT_ASYNC = "NEWS_ADD_COMMENT_ASYNC";
export const NEWS_COMMENT_LOADING = "NEWS_COMMENT_LOADING";
export const DELETE_NEWS_COMMENT = "DELETE_NEWS_COMMENT";

export function setNewsSources(sources) {
  return { type: SET_NEWS_SOURCES, sources };
}

export function onCreateNewsSources(source) {
  return { type: ON_CREATE_NEWS_SOURCES, source };
}

export function addNewsAsync(news) {
  return { type: ADD_NEWS_ASYNC, news };
}

export function addNews(news, newsId, sourceId) {
  return { type: ADD_NEWS, news, newsId, sourceId };
}

export function fetchNewsAsync(news, PagingInfo, sourceId) {
  return { type: FETCH_NEWS_ASYNC, news, PagingInfo, sourceId };
}

export function fetchNews(newsId, params, sourceId, fetchSources) {
  return { type: FETCH_NEWS, newsId, params, sourceId, fetchSources };
}

export function deleteNews(news, history) {
  return { type: DELETE_NEWS, news, history };
}

export function deleteNewsAsync(news, history) {
  return { type: DELETE_NEWS_ASYNC, news, history };
}

export function newsLoading(clear) {
  return { type: NEWS_LOADING, clear };
}

export function createNewsLoading() {
  return { type: CREATE_NEWS_LOADING };
}

export function stopCreateNewsLoading() {
  return { type: STOP_CREATE_NEWS_LOADING };
}

export function newsFailed(error) {
  return { type: NEWS_FAILED, error };
}

export function updateNewsUploadProgress(progress) {
  return { type: UPDATE_NEWS_UPLOAD_PROGRESS, progress };
}

export function newsAddCommentAsync(comment) {
  return { type: NEWS_ADD_COMMENT_ASYNC, comment };
}

export function newsAddComment(comment, newsId) {
  return { type: NEWS_ADD_COMMENT, comment, newsId };
}

export function newsCommentLoading() {
  return { type: NEWS_COMMENT_LOADING };
}

export function deleteNewsComment(commentId) {
  return { type: DELETE_NEWS_COMMENT, commentId };
}
