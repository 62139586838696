export const SHOW_CONFIRMATION = "SHOW_CONFIRMATION";
export const HIDE_CONFIRMATION = "HIDE_CONFIRMATION";

export function withConfirmation(onConfirm, message) {
  return { type: SHOW_CONFIRMATION, onConfirm, message };
}

export function hideConfirmation() {
  return { type: HIDE_CONFIRMATION };
}
