import axios from "axios";
import { replace, goBack } from "connected-react-router";
import {
  addMeetingAsync,
  meetingFailed,
  meetingLoading,
  meetingsLoading,
  createMeetingLoading,
  hideModal,
  fetchMeetingAsync,
  removeMeetingAction,
  removeCommitteeMeeting,
  cancelMeetingAction,
  cancelCommitteeMeeting,
  editCommitteeMettingDetails,
  updateRequestsCountLoading,
  updateRequestsCountAsync,
  updateRequestsCount,
  showToast,
  hideToast,
  updateMeetingUploadProgress,
  fetchUpcomingMeetingsAsync,
  upcomingMeetingsLoading,
  meetingCommentLoading,
  meetingAddCommentAsync,
  updateCalenderMeetings
} from "../actions";
import { getDataFromRes } from "../utils";
import { createUploader, watchOnProgress } from "./upload";
import { call, put, takeLatest, fork } from "redux-saga/effects";

function addMeetingApi(payload, onProgress) {
  const { data, id } = payload;
  let url = "";
  if (id) {
    url = "Meetings/Update";
  } else {
    url = "Meetings/GeneralMeeting";
  }

  return axios.post(url, data, {
    onUploadProgress: onProgress
  });
}

function* addMeetingEffect(action) {
  yield put(createMeetingLoading());
  const [uploadPromise, chan] = createUploader(
    { data: action.meeting, id: action.meetingId },
    addMeetingApi
  );
  yield fork(watchOnProgress, chan, updateMeetingUploadProgress);
  try {
    let { data } = yield call(() => uploadPromise);
    if (data.StatusCode === "Success") {
      yield put(updateCalenderMeetings(getDataFromRes(data)));
      yield put(addMeetingAsync(getDataFromRes(data)));
      yield put(updateRequestsCount());
      yield put(hideModal());
      yield put(hideToast());
      yield put(
        showToast(
          getDataFromRes(data).ID,
          (action.pageType = "meeting"),
          action.meetingId
        )
      );
      if (action.committee) {
        yield put(editCommitteeMettingDetails(getDataFromRes(data)));
      }
    } else {
      yield put(meetingFailed(data.StatusMessage));
    }
  } catch (err) {
    yield put(meetingFailed(err.message));
  }
}

export function* addMeetingWatcher() {
  yield takeLatest("ADD_MEETING", addMeetingEffect);
}

function fetchMeetingApi(data) {
  const path = `/Meetings/${data ? data : ""}`;
  return axios.get(path);
}

function fetchMOMApi(data) {
  const path = `/MOM/${data}`;
  return axios.get(path);
}

function* fetchMeetingEffect(action) {
  yield put(action.meetingId ? meetingLoading() : meetingsLoading());
  yield put(updateRequestsCount());
  let { data } = yield call(fetchMeetingApi, action.meetingId);

  if (data.StatusCode === "Success") {
    let meetingData = getDataFromRes(data);
    if (action.meetingId && meetingData.HasMOM) {
      let momdata = yield call(fetchMOMApi, action.meetingId);
      meetingData.MOM = getDataFromRes(momdata.data);
    }
    yield put(fetchMeetingAsync(meetingData));
  } else {
    yield put(replace("/meetings"));
    yield put(meetingFailed(data.StatusMessage));
  }
}

export function* fetchMeetingWatcher() {
  yield takeLatest("FETCH_MEETING", fetchMeetingEffect);
}

// Remove

function* removeMeetingEffect(action) {
  yield put(removeMeetingAction(action.meeting.ID));
  if (action.meeting.CommitteeID !== 0) {
    yield put(removeCommitteeMeeting(action.meeting.ID));
    yield put(replace(`/committee/${action.meeting.CommitteeID}/meetings`));
  } else {
    yield put(goBack());
    yield put(hideModal());
  }
  yield put(updateRequestsCount());
}

export function* removeMeetingWatcher() {
  yield takeLatest("REMOVE_MEETING", removeMeetingEffect);
}

// Cancel

function* cancelMeetingEffect(action) {
  yield put(cancelMeetingAction(action.meeting.ID, action.CancelationReason));
  yield put(updateRequestsCount());
  if (action.meeting.CommitteeID) {
    yield put(cancelCommitteeMeeting(action.meeting.ID));
  }
}

export function* cancelMeetingWatcher() {
  yield takeLatest("CANCEL_MEETING", cancelMeetingEffect);
}

// Count

function updateRequestsCountApi() {
  return axios.get("/Meetings/RequestsCount");
}

function* updateRequestsCountEffect(action) {
  yield put(updateRequestsCountLoading());

  let { data } = yield call(updateRequestsCountApi);

  if (data.StatusCode === "Success") {
    yield put(updateRequestsCountAsync(getDataFromRes(data)));
  } else {
    yield put(meetingFailed(data.StatusMessage));
  }
}

export function* updateRequestsCountWatcher() {
  yield takeLatest("UPDATE_REQUESTS_COUNT", updateRequestsCountEffect);
}

function fetchUpcomingMeetingsApi(data) {
  const path = `/Meetings/NextMeetings?PageSize=3`;
  return axios.get(path);
}

function* fetchUpcomingMeetingsEffect(action) {
  yield put(upcomingMeetingsLoading());
  let { data } = yield call(fetchUpcomingMeetingsApi, action);

  if (data.StatusCode === "Success") {
    let meetingData = getDataFromRes(data);

    yield put(fetchUpcomingMeetingsAsync(meetingData));
  } else {
    yield put(meetingFailed(data.StatusMessage));
  }
}

export function* fetchUpcomingMeetingsWatcher() {
  yield takeLatest("FETCH_UPCOMING_MEETINGS", fetchUpcomingMeetingsEffect);
}

// Comments

function meetingCommentApi(data, onProgress) {
  const path = "/Comments";
  return axios.post(path, data, {
    headers: { "content-type": "multipart/form-data" },
    onUploadProgress: onProgress
  });
}

function* meetingCommentEffect(action) {
  yield put(meetingCommentLoading());
  const [uploadPromise, chan] = createUploader(
    action.comment,
    meetingCommentApi
  );
  yield fork(watchOnProgress, chan, updateMeetingUploadProgress);
  try {
    let { data } = yield call(() => uploadPromise);

    if (data.StatusCode === "Success") {
      yield put(meetingAddCommentAsync(getDataFromRes(data)));
    } else {
      yield put(meetingFailed(data.StatusMessage));
    }
  } catch (e) {}
}

export function* meetingCommentWatcher() {
  yield takeLatest("MEETING_ADD_COMMENT", meetingCommentEffect);
}
