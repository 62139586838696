export const LOGIN_ASYNC = "LOGIN_ASYNC";
export const LOGOUT = "LOGOUT";
export const LOGOUT_ASYNC = "LOGOUT_ASYNC";
export const ADD_COUNTS = "ADD_COUNTS";
export const UPDATE_COUNTS = "UPDATE_COUNTS";
export const UPDATE_COUNTS_ASYNC = "UPDATE_COUNTS_ASYNC";
export const UPDATE_NOTIF_COUNTS = "UPDATE_NOTIF_COUNTS";
export const UPDATE_NOTIF_COUNTS_ASYNC = "UPDATE_NOTIF_COUNTS_ASYNC";
export const SAVE_PROJECT_DATA = "SAVE_PROJECT_DATA";
export const SAVE_PROJECTS_TOKEN = "SAVE_PROJECTS_TOKEN";
export const ADD_DEVICE_ID = "ADD_DEVICE_ID";

export function loginAsync(data) {
  return { type: LOGIN_ASYNC, data };
}

export function logout(DeviceID) {
  return { type: LOGOUT, DeviceID };
}

export function logoutAsync() {
  return { type: LOGOUT_ASYNC };
}

export function addCounts(tasks) {
  return { type: ADD_COUNTS, tasks };
}

export function updateCounts() {
  return { type: UPDATE_COUNTS };
}

export function updateCountsAsync(counts) {
  return { type: UPDATE_COUNTS_ASYNC, counts };
}

export function updateNotifCounts() {
  return { type: UPDATE_NOTIF_COUNTS };
}

export function updateNotifCountsAsync(counts) {
  return { type: UPDATE_NOTIF_COUNTS_ASYNC, counts };
}

export function saveProjectData(project) {
  return { type: SAVE_PROJECT_DATA, project };
}

export function saveProjectsToken(token) {
  return { type: SAVE_PROJECTS_TOKEN, token };
}

export function addDeviceID(DeviceID) {
  return { type: ADD_DEVICE_ID, DeviceID };
}
