import React, * as react from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ReactComponent as Excutive } from "../../assets/images/executive-icon.svg";
import MeetingsCell from "../MeetingsCell";
import MeetingRequestAction from "../MeetingRequestAction";
import AcceptMeetingRequestModal from "../AcceptMeetingRequestModal";
import Badge from "../Badge";
import TextLogo from "../TextLogo";
import CancelMeetingModal from "../CancelMeetingModal";
import AddMOMModal from "../AddMOMModal";
import AddMeetingModal from "../AddMeetingModal";
import axios from "axios";
// import DaysSince from "../DaysSince";
import MenuButton from "../MenuButton";
import "./style.scss";
import {
  rejectMeetingRequest,
  postponeMeetingRequest,
  showModal,
  acceptMeetingRequest,
  updateTimelineMeetings,
  hideModal
} from "../../actions";
import { getDisplayName, getPermission } from "../../utils";

class TimelineMeeting extends react.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      cancelMeetingModal: false,
      addMOM: false
    };
    this.deleteMeeting = this.deleteMeeting.bind(this);
    this.cancelMeeting = this.cancelMeeting.bind(this);
  }
  acceptMeeting = id => {
    this.props.showModal(<AcceptMeetingRequestModal id={id} timeline />);
  };

  getOptions = () => {
    const { post } = this.props;
    let list = [];
    if (post.Status === "2" && !post.HasMOM && post.CanEdit) {
      list.push({
        id: 1,
        name: "addMOM",
        action: () => this.setState({ addMOM: true })
      });
    }
    if (post.Status === "1") {
      list.push({
        id: 2,
        name: "edit",
        action: () => this.editMeeting()
      });
      list.push({
        id: 3,
        name: "cancel",
        // warning: true,
        action: () => this.cancelMeeting()
      });
    }
    list.push({
      id: 4,
      name: "delete",
      warning: true,
      action: () => this.deleteMeeting()
    });
    return list;
  };

  editMeeting = () => {
    this.props.showModal(
      <AddMeetingModal
        data={this.props.post}
        id={this.props.post.CommitteeID}
      />
    );
  };

  deleteMeeting = () => {
    this.setState({ loading: true }, () => {
      axios.post(`/Meetings/Delete/${this.props.post.ID}`).then(res => {
        if (res.data.StatusCode === "Success") {
          this.props.removeMeeting(
            this.props.post,
            this.props.location.pathname.split("/")[1]
          );
        }
        this.setState({ loading: false });
      });
    });
  };

  cancelMeeting = () => {
    this.props.showModal(
      <CancelMeetingModal
        meeting={this.props.post}
        hideModal={() => this.props.hideModal()}
      />
    );
  };

  routeMeeting = () => {
    const { post, details } = this.props;
    // this.props.showModal(<MeetingDetailsModal meeting={meeting} />)
    if (!details) {
      this.props.history.push({
        pathname: `/${post.TimelineType}/${post.ID}`,
        state: {
          modal: post.TimelineType === "meeting",
          location: this.props.location.pathname
        }
      });
    }
  };
  render() {
    const {
      post,
      locale,
      theme,
      disabled,
      ActionLoading,
      intl,
      editable,
      committee,
      currentUser,
      details
    } = this.props;
    let canEdit = false;
    if (editable) {
      canEdit =
        post.CommitteeID && committee
          ? getPermission(currentUser, post, ["Organizer", "Creator"]) ||
            getPermission(currentUser, committee, [
              "Creator",
              "Manager",
              "Secretary"
            ])
          : getPermission(currentUser, post, ["Organizer", "Creator"]) ||
            post.CanEdit;
    }

    return (
      <div
        className={`timeline-meeting  ${locale.ltr ? "ltr" : ""} ${
          details ? "" : "pointer"
        }`}
        onClick={() => this.routeMeeting()}
      >
        <div className="meeting-container">
          <MeetingsCell data={post} loacal={locale} />
        </div>
        <div>
          <div className="badges-container-timeline ">
            <div className="badge-sections">
              <div className="badge-section">
                <Badge
                  color={theme.textGray}
                  backgroundColor={theme.primaryLight}
                  text={
                    <div
                      className="meeting-organizer"
                      style={{ color: theme.textGray }}
                    >
                      <p className="text">
                        {intl.formatMessage({
                          id: "meeting-request-orginizer",
                          defaultMessage: "Orginizer"
                        })}
                        {" :"}
                      </p>
                      <p className="text">
                        {getDisplayName(post.Organizer, locale.ltr)}{" "}
                      </p>
                    </div>
                  }
                  Icon={Excutive}
                />
              </div>
              <div className="badge-section">
                <Badge
                  color={"white"}
                  backgroundColor={theme.Meeting_Priority[post.Priority].Color}
                  text={
                    theme.Meeting_Priority[post.Priority][
                      `Title${locale.suffix}`
                    ]
                  }
                  fixed
                />
              </div>
            </div>
          </div>
          <div className="timeline-meeting-action">
            {post.TimelineType === "meeting-requests" && (
              <div onClick={e => e.stopPropagation()}>
                <MeetingRequestAction
                  disabled={disabled}
                  acceptAction={() => this.props.acceptMeetingRequest(post.ID)}
                  rejectAction={() => {
                    this.props.rejectMeetingRequest(post.ID);
                    this.props.updateTimelineMeetings(post.ID);
                  }}
                  postponeAction={() =>
                    this.props.postponeMeetingRequest(post.ID)
                  }
                  id={post.ID}
                  ActionLoading={ActionLoading}
                  meetingDate={post.StartDate}
                  timeline
                />
              </div>
            )}

            {post.TimelineType !== "meeting-requests" && post.Creator && (
              <TextLogo
                text={`${intl.formatMessage({
                  id: "title.addBy",
                  defaultMessage: "Add by"
                })} : ${getDisplayName(post.Creator, locale.ltr)}`}
                img={post.Creator.UserProfilePicture}
              />
            )}
          </div>
        </div>
        {editable && canEdit ? (
          <div
            className={locale.ltr ? "arrow-container rtl" : "arrow-container"}
          >
            <MenuButton
              // loading={loading}
              options={this.getOptions()}
              title="meetingOptions"
            />
          </div>
        ) : null}

        {this.state.addMOM ? (
          <AddMOMModal
            hideModal={() => this.setState({ addMOM: false })}
            onCreate={this.onCreate}
            data={this.props.post.MOM}
            id={this.props.post.ID}
          />
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = {
  rejectMeetingRequest,
  postponeMeetingRequest,
  showModal,
  acceptMeetingRequest,
  updateTimelineMeetings,
  hideModal
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    disabled: state.meetingRequest.ActionLoading,
    ActionLoading: state.meetingRequest.ActionLoding,
    locale: state.locale,
    currentUser: state.user.UserData.Username
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(TimelineMeeting)));
