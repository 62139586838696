import React from "react";
import "./style.scss";

const Loading = props => {
  const { color, className, small, progress } = props;
  return (
    <div
      className={`loading-indicator ${className ? className : ""} ${
        small ? "la-sm" : ""
      }`}
      style={{ color: color }}
    >
      <div />
      {progress ? <p>{progress}%</p> : null}
    </div>
  );
};

export default Loading;
