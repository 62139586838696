import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { showModal, hideModal } from "../../actions";
import AddButton from "../AddButton";
import AddAttachmentsModal from "../AddAttachmentsModal";
import AddMembersModal from "../AddMembersModal";
import AddMeetingModal from "../AddMeetingModal";
import AddFolderModal from "../AddFolderModal";
import { extractPathParams } from "../../utils";

const components = {
  members: AddMembersModal,
  files: AddAttachmentsModal,
  meetings: AddMeetingModal
};

const AddButtonSwitch = props => {
  const { path, showModal, hideModal, id, theme, filesPermission } = props;
  const pathName = extractPathParams(path);
  const Component = components[pathName];

  const permissions = {
    members: theme.allowAddMember,
    files: theme.allowAddFile || filesPermission,
    meetings: theme.allowAddCommitteeMeeting
  };

  return (
    <>
      {pathName === "files" && (theme.allowAddFolder || filesPermission) ? (
        <AddButton
          title="folder"
          style={{ margin: "0 10px" }}
          onClick={() =>
            showModal(<AddFolderModal hideModal={hideModal} id={id} />)
          }
        />
      ) : null}
      {Object.keys(components).includes(pathName) && permissions[pathName] && (
        <AddButton
          title={pathName}
          onClick={() => showModal(<Component hideModal={hideModal} id={id} />)}
        />
      )}
    </>
  );
};

AddButtonSwitch.propTypes = {
  path: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

const mapDispatchToProps = { showModal, hideModal };

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddButtonSwitch);
