import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import "./style.scss";
import sortBy from "lodash/sortBy";
// import MeetingDetails from "../NewMeetingDetails";
import TimelineMeeting from "../TimelineMeeting";
import { withRouter } from "react-router";
import CommitteeMeetingsFilterEmptyComponent from "../EmptyComponents/CommitteeMeetingsFilterEmptyComponent";
import Tabs from "../Tabs";
import { filterCommitteeMeetings } from "../../utils";

const tabs = [{ name: "upcoming" }, { name: "previous" }];

class MeetingDetailsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      meetingFilter: null,
      selected: "upcoming"
    };
  }

  componentDidMount() {
    if (!this.props.committeesProfile) {
      this.setState({
        meetingFilter: this.props.list
      });
    } else {
      this.nextMeetings();
    }
  }

  selectTab = tab => {
    if (tab === "upcoming") {
      this.nextMeetings();
    } else {
      this.previousMeetings();
    }
  };

  nextMeetings = () => {
    const filtered = filterCommitteeMeetings(this.props.list, true);
    this.setState({
      meetingFilter: sortBy(filtered, ["StartDate"]),
      selected: "upcoming"
    });
  };

  previousMeetings = () => {
    const filtered = filterCommitteeMeetings(this.props.list, false);
    this.setState({
      meetingFilter: sortBy(filtered, ["StartDate"]),
      selected: "previous"
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.list !== prevProps.list) {
      if (this.props.committeesProfile) {
        if (this.state.selected === "upcoming") {
          this.nextMeetings();
        } else {
          this.previousMeetings();
        }
      } else {
        this.setState({
          meetingFilter: this.props.list
        });
      }
    }
  }

  render() {
    const {
      committeesProfile,
      request,
      // ActionLoading,
      theme,
      intl
      // meetingrequest
    } = this.props;

    return (
      <div className="meeting-details-list card">
        {committeesProfile && (
          <Tabs
            tabs={tabs}
            selectTab={this.selectTab}
            selected={this.state.selected}
            backgroundColor={theme.primaryLight}
          />
        )}

        {this.state.meetingFilter && this.state.meetingFilter.length > 0 ? (
          this.state.meetingFilter.map((meeting, index) => (
            <div
              className="committee-meeting"
              key={`${meeting.ID}`}
              style={{
                borderColor: theme.accentColor,
                backgroundColor: index % 2 !== 0 && theme.primaryLight
              }}
            >
              <TimelineMeeting
                post={{ ...meeting, TimelineType: "meeting" }}
                committe
                editable
              />
            </div>
          ))
        ) : request ? null : (
          <CommitteeMeetingsFilterEmptyComponent
            title={intl.formatMessage({
              id: `committeeMeetingsFilterEmptyComponent.title.${
                this.state.selected
              }`,
              defaultMessage: this.state.selected
            })}
            theme={theme}
          />
        )}
      </div>
    );
  }
}

MeetingDetailsList.propTypes = {
  intl: PropTypes.object,
  locale: PropTypes.object,
  Meetings: PropTypes.array,
  committiesProfile: PropTypes.bool
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme
  };
};

export default connect(
  mapStateToProps,
  null
)(injectIntl(withRouter(MeetingDetailsList)));
