import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Formik, Form, ErrorMessage } from "formik";
// import { string, object } from "yup";
// import TextareaAutosize from "react-autosize-textarea";
import { MentionsInput, Mention } from "react-mentions";
import "./style.scss";
import { ReactComponent as CommentsIcon } from "../../assets/images/send-icon.svg";
import Comments from "../Comments";
import Loading from "../Loading";
import CommentUpload from "../CommentUpload";
import CommentAttachmentList from "../CommentAttachmentList";
import LogoText from "../LogoText";
import defaultStyle from "./defaultStyle";
import { cleanApiDataForm } from "../../utils";

class CommentsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      users: [],
      focus: false
    };
    this._form = React.createRef();
    this.mention = React.createRef();
  }

  style = data => {
    return {
      suggestions: {
        list: {
          position: "absolute",
          top:
            this.props.comments && this.props.comments.length < 2 ? -200 : 20,
          right: !this.props.locale.ltr && 30,
          minHeight: data.length < 3 ? 70 : 189,
          maxHeight: 200,
          overflowY: "auto",
          backgroundColor: "white",
          border: `1px solid ${this.props.theme.accentColor}`,
          fontSize: 14,
          bottom: 20,
          zIndex: 9999,
          width: 250
        },

        item: {
          padding: "5px 15px",
          borderBottom: `1px solid ${this.props.theme.accentColor}`,

          "&focused": {
            backgroundColor: this.props.theme.primaryLight
          }
        }
      }
    };
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.loading && !this.props.loading) {
      this.setState({ id: "" });
      this._form.current.resetForm(this.initialValues);
    }
  }

  mentionDisplayTransform = (id, display, type) => {
    let list = [];
    if (display) {
      list = this.props.users.find(e => {
        return e.id === display;
      });
    }

    if (list && list.display) {
      const text = list.display;

      return "@" + text;
    }
    return "@" + display;
  };

  onEnterPress = e => {
    this.setState({ id: this.props.id });
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      this.mention.current.blur();
      this._form.current.submitForm();
    }

    this.mention.current.rows = 10;
  };

  renderUserSuggestion = (id, display, search, highlightedDisplay) => {
    return (
      <div className="suggestion-item">
        <LogoText img={id.img} text={id.display} black />
      </div>
    );
  };

  filterUsers = () => {
    if (
      this.props.users.length > 0 &&
      this._form.current &&
      this._form.current.state.values.Comment.length > 0 &&
      this._form.current.state.values.Comment.includes("*[")
    ) {
      return this.props.users.filter(user => {
        return !this._form.current.state.values.Comment.includes(user.id);
      });
    } else {
      return this.props.users;
    }
  };

  initialValues = {
    Comment: "",
    Attachments: []
  };

  render() {
    const {
      theme,
      intl,
      comments,
      locale,
      uploadIcon,
      loading,
      addComment,
      id,
      progress,
      disabled,
      type,
      users
    } = this.props;
    return (
      <div className="notes-main-container">
        {!disabled ? (
          <Formik
            initialValues={this.initialValues}
            validationSchema={this.addCommentSchema}
            validate={values => {
              let errors = {};
              if (values.Comment === "" && values.Attachments.length === 0) {
                errors.Comment = "Required";
              }
              return errors;
            }}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize
            ref={this._form}
            onSubmit={(values, { setSubmitting }) => {
              const apiData = {
                Comment: values.Comment,
                Attachments: values.Attachments,
                TaskID: id,
                AdhocID: id,
                RelatedID: id
              };
              if (type) apiData.Type = type;
              addComment(cleanApiDataForm(apiData), id);
            }}
            render={props => {
              return (
                <Form
                  className="notes-container"
                  style={{ borderColor: theme.accentColor }}
                >
                  <div
                    className={
                      uploadIcon
                        ? "input-container"
                        : "input-container adhoc-class"
                    }
                  >
                    {uploadIcon && (
                      <div className="attach-icon">
                        <CommentUpload name="Attachments" theme={theme} />
                      </div>
                    )}
                    <div
                      className="comment-content"
                      style={{
                        backgroundColor: theme.lightGray,
                        borderColor: props.errors.Comment
                          ? theme.errorColor
                          : theme.accentColor
                      }}
                    >
                      {props.values.Attachments.length > 0 ? (
                        <div
                          className="comment-attachments"
                          style={{ borderColor: theme.accentColor }}
                        >
                          <CommentAttachmentList
                            attachments={props.values.Attachments}
                            removeAttachment={index => {
                              const Attachments = props.values.Attachments;
                              Attachments.splice(index, 1);
                              props.setFieldValue("Attachments", Attachments);
                            }}
                          />
                        </div>
                      ) : null}
                      <div
                        className={
                          locale.ltr ? "comment-input ltr" : "comment-input"
                        }
                      >
                        <div className="mention">
                          <MentionsInput
                            name="Comment"
                            type="text"
                            component="textarea"
                            value={props.values.Comment}
                            onChange={props.handleChange("Comment")}
                            style={{
                              ...defaultStyle,
                              ...this.style(this.filterUsers())
                            }}
                            placeholder={intl.formatMessage({
                              id: "adhoc.comments",
                              defaultMessage: "write notes"
                            })}
                            onKeyDown={this.onEnterPress}
                            inputRef={this.mention}
                            rows={20}
                            onFocus={() => this.setState({ focus: true })}
                            onBlur={() => this.setState({ focus: false })}
                          >
                            <Mention
                              markup=" *[__id__]* "
                              trigger="@"
                              data={this.filterUsers()}
                              displayTransform={this.mentionDisplayTransform}
                              className="mentions__mention"
                              // onAdd={(id, display) =>
                              //   console.log(id, display, "kkkkkkk")
                              // }
                              renderSuggestion={this.renderUserSuggestion}
                              disable
                            />
                          </MentionsInput>
                        </div>
                        <div className="comment-button-container">
                          <button
                            type="submit"
                            disabled={loading}
                            className="notes-icon"
                          >
                            {loading ? (
                              <Loading
                                color={theme.primary}
                                progress={progress}
                              />
                            ) : (
                              <CommentsIcon />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ErrorMessage
                    className="fields-error-messege"
                    style={{ color: theme.errorColor }}
                    name="Attachments"
                    component="div"
                  />
                </Form>
              );
            }}
          />
        ) : null}
        {comments &&
          comments.length > 0 &&
          comments.map((comment, index) => (
            <Comments comment={comment} key={index} type={type} users={users} />
          ))}
      </div>
    );
  }
}

CommentsContainer.propTypes = {
  intl: PropTypes.object,
  theme: PropTypes.object,
  notes: PropTypes.array
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    locale: state.locale
  };
};

export default connect(
  mapStateToProps,
  null
)(injectIntl(CommentsContainer));
