import React, { Component } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import Modal from "../Modal";
import {
  hideModal,
  cancelMeeting,
  acceptMeetingRequest,
  timelineAcceptRequest
} from "../../actions";
import FormInput from "../FormInput";
import { cleanHandshakeForSelect } from "../../utils";
import "./style.scss";
import Button from "../Button";
import { FormattedMessage } from "react-intl";

class AcceptMeetingRequestModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        AttendeesStatus: props.theme.Meeting_Attendees_Status["1"].Value
      },
      loading: false
    };
  }

  componentDidMount() {
    const { theme, locale } = this.props;

    this.setState({
      AttendeesStatus: cleanHandshakeForSelect(
        theme.Meeting_Attendees_Status,
        null,
        locale.suffix
      )
    });
  }

  acceptMeeting = values => {
    this.setState({ loading: true }, () => {
      this.props.acceptMeetingRequest(this.props.id, values.AttendeesStatus);
      if (this.props.timeline) {
        this.props.timelineAcceptRequest(this.props.id);
      }
    });
  };

  render() {
    const { hideModal } = this.props;

    return (
      <Modal isOpen title="AcceptMeeting" hideModal={hideModal}>
        <Formik
          initialValues={this.state.initialValues}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, values }) => (
            <Form autoComplete="off">
              <input
                autoComplete="false"
                name="hidden"
                type="text"
                style={{ display: "none" }}
              />
              <FormInput
                withIcon={true}
                name="AttendeesStatus"
                type="radio"
                options={this.state.AttendeesStatus}
                required
              />

              <div
                className={
                  this.props.locale.ltr
                    ? "accept-reject-buttons-modal ltr"
                    : "accept-reject-buttons-modal"
                }
              >
                <Button
                  loading={this.state.loading}
                  onClick={() => this.acceptMeeting(values)}
                >
                  <FormattedMessage
                    id="confirmation.confirm"
                    defaultMessage="Confirm"
                  />
                </Button>
                <Button onClick={() => this.props.hideModal()}>
                  <FormattedMessage
                    id="confirmation.cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    locale: state.locale
  };
};

const mapDispatchToProps = {
  hideModal,
  cancelMeeting,
  acceptMeetingRequest,
  timelineAcceptRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcceptMeetingRequestModal);
