import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CommentAttachmentImage from "../CommentAttachmentImage";
import { getAttachmentType } from "../../utils";
import "./style.scss";

const CommentAttachmentList = props => {
  const { ltr, attachments, theme, removeAttachment } = props;
  return (
    <div className="photos-light-box">
      <div className="photos-container">
        {attachments &&
          attachments.map((attachment, index) => (
            <CommentAttachmentImage
              key={index}
              index={index}
              attachment={attachment}
              type={getAttachmentType(attachment.name)}
              theme={theme}
              ltr={ltr}
              removeAttachment={removeAttachment}
            />
          ))}
      </div>
    </div>
  );
};

CommentAttachmentList.propTypes = {
  ltr: PropTypes.bool
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ltr: state.locale.ltr,
    theme: state.theme
  };
};

export default connect(
  mapStateToProps,
  null
)(CommentAttachmentList);
