import React, * as react from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import moment from "moment";

import "./style.scss";

class CalenderIcon extends react.Component {
  render() {
    const { theme, date } = this.props;

    return (
      <div className="calender-icon card">
        <div
          className="calender-icon-header"
          style={{ backgroundColor: theme.errorColor }}
        >
          {moment(date * 1000).format("MMM")}
        </div>
        <div className="calender-icon-date">
          {moment(date * 1000).get("date")}
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  null
)(injectIntl(CalenderIcon));
