import React from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import "./style.scss";
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";

ReactModal.setAppElement("#root");

const Modal = props => {
  const {
    children,
    isOpen,
    theme,
    title,
    hideModal,
    noTitle,
    height,
    customTitle,
    customButton,
    blackout
  } = props;
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "60%",
      maxHeight: "90vh",
      background: "white",
      zIndex: 1500,
      borderRadius: 5,
      border: "none",
      boxShadow: "1px 1px 3px 0px rgba(0, 0, 0, 0.1)",
      padding: 0,
      minHeight: height ? height : "unset"
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: blackout ? "rgba(0, 0, 0, 0.9)" : "rgba(0, 0, 0, 0.4)",
      paddingTop: "3em"
    }
  };
  return (
    <ReactModal
      onRequestClose={hideModal}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      style={customStyles}
      isOpen={isOpen}
    >
      <div
        className="modal-header"
        style={{
          borderColor: theme.accentColor,
          backgroundColor: theme.primary
        }}
      >
        <h4 className="modal-title">
          {customTitle ? (
            customTitle
          ) : (
            <FormattedMessage id={`modal.${title}`} defaultMessage={title} />
          )}
        </h4>

        {customButton ? (
          customButton
        ) : (
          <CloseIcon className="close-icon" onClick={hideModal} />
        )}
      </div>

      <div className={noTitle ? "no-title-content" : "modal-content"}>
        {children}
      </div>
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  theme: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme
  };
};

export default connect(
  mapStateToProps,
  null
)(Modal);
