import axios from "axios";
import {
  loginAsync,
  loginFailed,
  verifyFailed,
  loginLoading,
  handshake,
  updateCountsAsync,
  updateNotifCountsAsync,
  userBlock,
  needVerify,
  clearLogin,
  logoutAsync,
  userLastAttempt,
} from "../actions";
import { getDataFromRes } from "../utils";

import { call, put, takeLatest } from "redux-saga/effects";

function loginApi(data) {
  // return axios.post("/login", data);
  return axios.post("Login", data);
}

function* loginEffect(action) {
  yield put(loginLoading());
  try {
    // data is obtained after axios call is resolved
    let { data } = yield call(loginApi, action.data);

    if (data.StatusCode === "Success") {
      if (!data.Data.Enable2FactorAuth) {
        yield put(loginAsync(getDataFromRes(data)));
        yield put(handshake(true, action.data.DeviceID));
        yield put(clearLogin());
        // if (
        //   data.Data.UserDeviceLoginInfoObj &&
        //   data.Data.UserDeviceLoginInfoObj.length > 0
        // )
        //   yield put(showModal(<OpenSessionsModal />));
      } else {
        yield put(needVerify());
      }
    } else {
      yield put(loginFailed(data.StatusCode));
      if (data.Data.RemainingAttempts === 0) {
        yield put(userLastAttempt());
      }
      if (data.Data.BlockedUntil) {
        yield put(userBlock(data.Data.BlockedUntil));
      }
    }
    // redirect to home route after successful login
  } catch (e) {
    // catch error on a bad axios call
    // alert using an alert library
  }
}

export function* loginWatcher() {
  yield takeLatest("LOGIN", loginEffect);
}

// Verification

function verifyApi(data) {
  // return axios.post("/login", data);
  return axios.post("Login/Verification", data);
}

function* verifyEffect(action) {
  yield put(loginLoading());
  try {
    // data is obtained after axios call is resolved
    let { data } = yield call(verifyApi, action.data);

    if (data.StatusCode === "Success") {
      if (!data.ErrorCode) {
        yield put(loginAsync(getDataFromRes(data)));
        yield put(handshake(true, action.data.DeviceID));
        yield put(clearLogin());
        // if (
        //   data.Data.UserDeviceLoginInfoObj &&
        //   data.Data.UserDeviceLoginInfoObj.length > 0
        // )
        //   yield put(showModal(<OpenSessionsModal />));
      } else {
        if (data.Data.BlockedUntil) {
          yield put(userBlock(data.Data.BlockedUntil));
        } else {
          yield put(needVerify());
        }
      }
    } else {
      yield put(verifyFailed(data.Data.Message));
    }
    // redirect to home route after successful login
  } catch (e) {
    // catch error on a bad axios call
    // alert using an alert library
  }
}

export function* verifyWatcher() {
  yield takeLatest("VERIFY", verifyEffect);
}

// counts

function countsApi() {
  return axios.get("/Tasks/Statistics");
}

function* countsEffect(action) {
  try {
    // data is obtained after axios call is resolved
    let { data } = yield call(countsApi);

    if (data.StatusCode === "Success") {
      yield put(updateCountsAsync(getDataFromRes(data)));
    }
    // redirect to home route after successful login
  } catch (e) {
    // catch error on a bad axios call
    // alert using an alert library
  }
}

export function* countsWatcher() {
  yield takeLatest("UPDATE_COUNTS", countsEffect);
}

// notif counts

function notifCountsApi() {
  return axios.get("/Notification/Count");
}

function* notifCountsEffect(action) {
  try {
    // data is obtained after axios call is resolved
    let { data } = yield call(notifCountsApi);

    if (data.StatusCode === "Success") {
      yield put(updateNotifCountsAsync(getDataFromRes(data)));
    }
    // redirect to home route after successful login
  } catch (e) {
    // catch error on a bad axios call
    // alert using an alert library
  }
}

export function* notifCountsWatcher() {
  yield takeLatest("UPDATE_NOTIF_COUNTS", notifCountsEffect);
}

// Logout

function logoutApi({ DeviceID }) {
  return axios.post("Logout", { DeviceID });
}

function* logoutEffect(action) {
  // yield put(loginLoading());
  try {
    // data is obtained after axios call is resolved
    yield put(logoutAsync());
    yield put(clearLogin());
    // yield call(logoutApi, action);
    // let { data } = yield call(logoutApi, action);

    // if (data.StatusCode === "Success") {
    //   // yield call(addDeviceApi);
    //   yield put(logoutAsync());
    //   yield put(clearLogin());
    // } else {
    //   yield put(loginFailed(data.StatusCode));
    // }
    // redirect to home route after successful login
  } catch (e) {
    // catch error on a bad axios call
    // alert using an alert library
  }
}

export function* logoutWatcher() {
  yield takeLatest("LOGOUT", logoutEffect);
}
