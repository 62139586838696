import React from "react";
import { connect } from "react-redux";

import AsyncImg from "../AsyncImg";

const MediaItem = ({ item = {}, remaining = 0, theme, ...otherProps }) => {
  return (
    <div className="grid-item" {...otherProps}>
      <AsyncImg img={item.AttachmentURL} alt={item.AttachmentName} />

      {remaining > 0 ? (
        <div className="grid-overlay">
          <h2>+ {remaining}</h2>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme
  };
};

export default connect(mapStateToProps, null)(MediaItem);
