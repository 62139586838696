import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import DropDownItem from "../DropDownItem";
import "./style.scss";

class DropDown extends Component {
  constructor() {
    super();
    this.state = {
      selected: 0
    };
  }
  _handleOnSelect(index) {
    this.setState({ selected: index });
  }

  render() {
    const {
      options,
      title,
      textGray,
      accentColor,
      ltr,
      style,
      id,
      status,
      noTranslate,
      modal
    } = this.props;
    return (
      <div
        className={`drop-down ${ltr ? "ltr" : ""} ${
          modal ? "drop-down-modal" : ""
        }`}
        style={style}
      >
        <div
          className="dropdown-title"
          style={{ color: textGray, borderColor: accentColor }}
        >
          {noTranslate ? (
            <span>{title}</span>
          ) : (
            <FormattedMessage
              id={`dropdownTitle.${title}`}
              defaultMessage={title}
            />
          )}
        </div>
        <ul>
          {options.map((item, i) => (
            <DropDownItem
              key={i}
              item={item}
              id={id}
              status={status}
              noTranslate={noTranslate}
            />
          ))}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    textGray: state.theme.textGray,
    accentColor: state.theme.accentColor,
    ltr: state.locale.ltr
  };
};

export default connect(
  mapStateToProps,
  null
)(DropDown);
