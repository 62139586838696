import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";
import "./style.scss";
import { toggleLocale } from "../../actions";
import ArabicIcon from "../../assets/images/arabic.svg";
import EnglishIcon from "../../assets/images/english.svg";
import DropDownLang from "../DropDownLang";
import { ReactComponent as LoclaeIcon } from "../../assets/images/locale-icon.svg";

import { ReactComponent as ArrowDown } from "../../assets/images/arrow-down.svg";
class LocaleToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.showMenu = this.showMenu.bind(this);
    this.handleLocaleSelect = this.handleLocaleSelect.bind(this);
  }

  showMenu = event => {
    event.preventDefault();

    this.setState({ open: true }, () => {
      document.addEventListener("click", this.closeMenu);
    });
  };

  closeMenu = () => {
    this.setState({ open: false }, () => {
      document.removeEventListener("click", this.closeMenu);
    });
  };

  handleLocaleSelect(lang) {
    this.props.toggleLocale(lang);
  }

  options = [
    {
      id: 1,
      name: "ar",
      action: () => this.handleLocaleSelect("en")
    },
    {
      id: 2,
      name: "en",
      action: () => this.handleLocaleSelect("ar")
    }
  ];

  showMenuList = () => {
    return (
      <DropDownLang
        list={this.options}
        theTitle={this.props.intl.formatMessage({
          id: "dropdown-languages",
          defaultMessage: "Languages"
        })}
        selected={this.props.locale.ltr ? 2 : 1}
      />
    );
  };

  render() {
    const { intl, locale, theme, loginPage } = this.props;
    const { open } = this.state;
    return (
      <>
        {loginPage ? (
          <div className="login-page-locale" onClick={e => this.showMenu(e)}>
            <div className={`toggleIconContainer ${locale.ltr ? "ltr" : ""}`}>
              <LoclaeIcon className="toggleIcon" />
              {open && this.showMenuList()}
            </div>
            <div className="loclae-text">
              <FormattedMessage
                id={`header.language-btn.${locale.lang}`}
                defaultMessage="Arabic"
              />
            </div>
          </div>
        ) : (
          <div>
            <div
              className="localeToggle"
              onClick={e => this.showMenu(e)}
              style={{ borderColor: theme.accentColor }}
            >
              <img
                className="localeImage"
                src={locale.ltr ? ArabicIcon : EnglishIcon}
                alt={intl.formatMessage({
                  id: `header.language-btn.${locale.lang}`,
                  defaultMessage: "Arabic"
                })}
              />
              <div className={`toggleIconContainer ${locale.ltr ? "ltr" : ""}`}>
                <ArrowDown
                  className="toggleIcon"
                  style={{ fill: theme.textColor }}
                />
              </div>
              {open && this.showMenuList()}
            </div>
          </div>
        )}
      </>
    );
  }
}

LocaleToggle.propTypes = {
  intl: PropTypes.object,
  theme: PropTypes.object,
  locale: PropTypes.object,
  toggleLocale: PropTypes.func
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ...state
  };
};

const mapDispatchToProps = { toggleLocale };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(LocaleToggle));
