import { SHOW_MODAL, HIDE_MODAL } from "../actions";

const initialState = null;

function modal(state = initialState, action) {
  switch (action.type) {
    case SHOW_MODAL:
      return action.modal;
    case HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
}

export default modal;
