import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./style.scss";
import { ReactComponent as UserIcon } from "../../assets/images/user-committee-icon.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/arrow-down.svg";
import committeeDefault from "../../assets/images/committee-default.png";
import AsyncImg from "../AsyncImg";

class CommitteesCell extends Component {
  handleRoute(committe) {
    const { history } = this.props;
    history.push({
      pathname: `/committee/${committe.ID}/members`
    });
  }

  render() {
    const { theme, ltr, data } = this.props;
    return (
      <div
        onClick={() => this.handleRoute(data)}
        className={ltr ? "cell-container ltr" : "cell-container"}
        style={{
          borderColor: theme.accentColor
        }}
      >
        <div style={{ width: "70%", display: "flex", padding: "0px 20px" }}>
          <div className="photo-container">
            <AsyncImg
              alt="SourceImg"
              img={
                data.ProfilePic
                  ? data.ProfilePic.AttachmentURL
                  : committeeDefault
              }
              style={{
                borderColor: theme.accentColor
              }}
            />
          </div>
          <div className={ltr ? "text-container ltr" : "text-container"}>
            <p> {data.NameAr}</p>
            <div className="members-number-container">
              <UserIcon
                style={{
                  width: 20,
                  height: 20,
                  fill: theme.primary
                }}
                alt="userImg"
              />
              <p className="text" style={{ color: theme.textGray }}>
                {data.MembersCount}
              </p>
              <p style={{ color: theme.textGray }}>
                <FormattedMessage
                  id={`committees.members`}
                  defaultMessage="profile view"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="arrow-container">
          <ArrowDown
            style={{
              fill: theme.textGray,
              transform: ltr ? "rotate(270deg)" : "rotate(90deg)"
            }}
            alt="SourceImg"
          />
        </div>
      </div>
    );
  }
}

CommitteesCell.propTypes = {
  theme: PropTypes.object,
  ltr: PropTypes.bool,
  data: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ltr: state.locale.ltr,
    theme: state.theme
  };
};

export default connect(mapStateToProps, null)(withRouter(CommitteesCell));
