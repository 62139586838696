import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import DOMPurify from "dompurify";
import "./style.scss";
import LogoText from "../LogoText";
import DeleteButton from "../DeleteButton";
import AttachmentList from "../AttachmentList";
import { getPermission, getDisplayName } from "../../utils";
import LocaleAwareText from "../../components/LocaleAwareText";
import {
  deleteTaskComment,
  deleteAdhocComment,
  deleteMeetingComment,
  deleteNewsComment
} from "../../actions";

class Comments extends Component {
  _isMounted = false;
  state = {
    loading: false
  };

  list = [
    {
      id: 1,
      name: "delete",
      warning: true,
      action: () => this.deleteComment()
    }
  ];

  deleteComment = () => {
    this._isMounted = true;
    const { comment, type } = this.props;
    const realType = !type && this.props.comment.TaskID ? "Tasks" : "Adhoc";

    this.setState({ loading: true }, () => {
      if (type) {
        axios
          .post("/Comments/DeleteComment", { ID: comment.ID, Type: type })
          .then(res => {
            if (res.data.StatusCode === "Success") {
              if (type === "Tasks") {
                this.props.deleteTaskComment(comment.ID);
              } else {
                type === "news"
                  ? this.props.deleteNewsComment(comment.ID)
                  : this.props.deleteMeetingComment(comment.ID);
              }
            }
            if (this._isMounted) {
              this.setState({ loading: false });
            }
          });
      } else {
        axios.post(`/${realType}/DeleteComment/${comment.ID}`).then(res => {
          if (res.data.StatusCode === "Success") {
            realType === "Tasks"
              ? this.props.deleteTaskComment(comment.ID)
              : this.props.deleteAdhocComment(comment.ID, comment.AdhocID);
          }
          if (this._isMounted) {
            this.setState({ loading: false });
          }
        });
      }
    });
  };

  setComment = comment => {
    if (!comment.includes("]*") && !comment.includes("]*")) {
      return comment;
    } else {
      const { users, theme } = this.props;
      if (users && users.length > 0) {
        const newUsersList = users.map(data => {
          return data.id;
        });

        const newComment = comment
          .split("]*")
          .join("")
          .split("*[")
          .join("")
          .split(" ");

        return newComment
          .map((e, index) => {
            if (newUsersList.includes(e)) {
              const disaplyName = users.find(user => {
                return user.id === e;
              });
              return `<span style="color: ${theme.primary}">@${disaplyName.display}</span>`;
            } else {
              return e;
            }
          })
          .join(" ");
      }
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { theme, comment, locale, key, currentUser } = this.props;
    const { loading } = this.state;
    return (
      <div
        className="notes-container-card"
        style={{
          borderColor: theme.accentColor,
          backgroundColor: theme.lightGray
        }}
        key={key}
      >
        <div className="note-section">
          <LogoText
            img={comment.CommentBy && comment.CommentBy.UserProfilePicture}
            name={
              comment.CommentBy && getDisplayName(comment.CommentBy, locale.ltr)
            }
            date={comment.CreatedDate}
            note={comment.Comment}
            blackTitle={true}
            withTime
            isNameClickable={
              comment.CommentBy.isActiveDirectoryUser &&
              comment.CommentBy.Username
            }
          />

          <div
            className={locale.ltr ? "comment-section  ltr" : "comment-section "}
          >
            {comment.Comment ? (
              <LocaleAwareText
                text={this.setComment(this.props.comment.Comment)}
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      this.setComment(this.props.comment.Comment)
                    )
                  }}
                  className={locale.ltr ? "description ltr" : "description"}
                />
              </LocaleAwareText>
            ) : null}

            {comment.Attachments && comment.Attachments.length > 0 ? (
              <AttachmentList photos={comment.Attachments} key={comment.ID} />
            ) : null}
          </div>
        </div>
        {getPermission(currentUser, comment, ["CommentBy"]) ? (
          <div className="delete-icon-section">
            <DeleteButton deleteAction={this.deleteComment} loading={loading} />
          </div>
        ) : null}
      </div>
    );
  }
}

Comments.propTypes = {
  theme: PropTypes.object,
  notes: PropTypes.array
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    locale: state.locale,
    currentUser: state.user.UserData.Username
  };
};

const mapDispatchToProps = {
  deleteTaskComment,
  deleteAdhocComment,
  deleteMeetingComment,
  deleteNewsComment
};

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
