import sortBy from "lodash/sortBy";
import {
  PROJECT_ERROR,
  CLEAR_PROJECT_ERRORS,
  STOP_PROJECT_LOADING,
  PROJECT_LOADING,
  PROJECT_DETAILS_LOADING,
  FETCH_PROJECTS_ASYNC,
  FETCH_PROJECT_DETAILS_ASYNC,
  SAVE_PP_HANDSHAKE,
  CLEAR_PROJECTS_ON_LOGOUT
} from "../actions";

const initialState = {
  Loading: false,
  DetailsLoading: false,
  AddLoading: false,
  Error: "",
  Projects: [],
  Project: {},
  HighPriority: [],
  Handshake: null
};

const addEntityToProjects = portfolios => {
  const newPortfolios = portfolios.map(portfolio => {
    portfolio.Projects = portfolio.Projects.map(project => {
      return {
        ...project,
        Entity: {
          NameEnglish: portfolio.NameEnglish,
          NameArabic: portfolio.NameArabic,
          Logo: portfolio.Logo
        }
      };
    });
    return portfolio;
  });
  return newPortfolios;
};

const getHighPriority = portfolios => {
  let projects = [];
  portfolios.forEach(portfolio => {
    projects = [...projects, ...portfolio.Projects];
  });

  projects = sortBy(
    projects.filter(project => project.Priority.toLowerCase() === "yes"),
    ["FinishDate"]
  );

  return projects;
};

function project(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROJECT_DETAILS_ASYNC:
      return {
        ...state,
        Project: action.project,
        DetailsLoading: false
      };
    case FETCH_PROJECTS_ASYNC:
      const withEntities = addEntityToProjects(action.projects);
      return {
        ...state,
        Projects: action.projects,
        HighPriority: getHighPriority(withEntities),
        Loading: false
      };
    case PROJECT_ERROR:
      return {
        ...state,
        Loading: false,
        AddLoading: false,
        DetailsLoading: false
      };
    case CLEAR_PROJECT_ERRORS:
      return {
        ...state
      };

    case PROJECT_LOADING:
      return {
        ...state,
        Loading: true
      };
    case PROJECT_DETAILS_LOADING:
      return {
        ...state,
        DetailsLoading: true
      };
    case STOP_PROJECT_LOADING:
      return {
        ...state,
        AddLoading: false,
        Loading: false
      };
    case SAVE_PP_HANDSHAKE:
      return {
        ...state,
        Handshake: action.handshake
      };

    case CLEAR_PROJECTS_ON_LOGOUT:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export default project;
