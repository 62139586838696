import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from "query-string";
import { injectIntl } from "react-intl";
import "./style.scss";
import { fetchNews } from "../../actions";
import NewsLoadingCard from "../../components/LoadingCards/NewsLoadingCard";
import NewsCard from "../../components/NewsCard";
import EmptyComponent from "../../components/EmptyComponents/EmptyComponent";

class NewsPage extends Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, false);
    const { fetchNews, match } = this.props;
    const filters = queryString.parse(this.props.location.search);
    const status = filters && filters.status ? filters.status : null;
    const date =
      filters && filters.publishedDate ? filters.publishedDate : null;
    fetchNews(
      null,
      {
        PagingInfo: "",
        PublishingStatus: parseInt(status),
        PublishingDate: parseInt(date)
      },
      match.params.filter,
      true
    );
  }

  componentDidUpdate(prevProps) {
    const { fetchNews, match } = this.props;
    const filters = queryString.parse(this.props.location.search);
    const oldFilters = queryString.parse(prevProps.location.search);
    const status = filters && filters.status ? filters.status : null;
    const oldStatus =
      oldFilters && oldFilters.status ? oldFilters.status : null;

    const date =
      filters && filters.publishedDate ? filters.publishedDate : null;
    const oldDate =
      oldFilters && oldFilters.publishedDate ? oldFilters.publishedDate : null;
    if (
      prevProps.match.params.filter !== match.params.filter ||
      status !== oldStatus ||
      date !== oldDate
    ) {
      fetchNews(
        null,
        {
          PagingInfo: "",
          PublishingStatus: parseInt(status),
          PublishingDate: parseInt(date)
        },
        match.params.filter
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll, false);
  }

  handleScroll() {
    const offset = 600;
    const { news, fetchNews, match } = this.props;
    if (
      !news.Loading &&
      news.PagingInfo !== "noMore" &&
      window.innerHeight + window.scrollY >= document.body.offsetHeight - offset
    ) {
      const filters = queryString.parse(this.props.location.search);
      const status = filters && filters.status ? filters.status : null;
      const date =
        filters && filters.publishedDate ? filters.publishedDate : null;
      fetchNews(
        null,
        {
          PagingInfo: news.PagingInfo,
          PublishingStatus: status,
          PublishingDate: date
        },
        match.params.filter
      );
    }
  }

  filter = news => {
    if (news.NewsList.length > 0) {
      const newNewsList = news.NewsList.filter(item => {
        return JSON.stringify(item.SourceID) === this.props.match.params.filter;
      });

      return newNewsList;
    } else {
      return [];
    }
  };

  getNewsList = news => {
    let list = news.map((newsItem, i) => <NewsCard News={newsItem} key={i} />);
    if (this.props.news.Loading)
      list = [
        ...list,
        ...[...Array(4)].map((item, index) => (
          <NewsLoadingCard key={`loading-${index}`} />
        ))
      ];
    return list;
  };

  render() {
    const { news, intl } = this.props;

    return !news.Loading && news.NewsList.length < 1 ? (
      <EmptyComponent
        title={
          this.props.match.params.filter
            ? intl.formatMessage({
                id: "emptyComponent.title.filter-source-news",
                defaultMessage: "Source news"
              })
            : intl.formatMessage({
                id: "emptyComponent.title.news",
                defaultMessage: "news"
              })
        }
      />
    ) : (
      <div className="news-list">{this.getNewsList(news.NewsList)}</div>
    );
  }
}

NewsPage.propTypes = {
  news: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    news: state.news,
    intl: state.intl,
    locale: state.locale
  };
};

const mapDispatchToProps = { fetchNews };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(NewsPage));
