import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import "./style.scss";

class EmptyComponent extends React.Component {
  render() {
    const { textGray, title, message } = this.props;
    return (
      <div className="empty-component" style={{ color: textGray }}>
        {message ? (
          message
        ) : (
          <FormattedMessage
            id="emptyComponent.title"
            defaultMessage="there is no data in {title}"
            values={{ title: title }}
            className="text"
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    textGray: state.theme.textGray
  };
};

export default connect(
  mapStateToProps,
  null
)(EmptyComponent);
