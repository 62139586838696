import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FormattedMessage } from "react-intl";
import queryString from "query-string";
import DropDownTasksFilter from "../../components/DropDownTasksFilter";
import { ReactComponent as FilterIcon } from "../../assets/images/news-filter-source-icon.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/arrow-down.svg";

class FilterButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.setFilters = this.setFilters.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
  }

  showMenu = event => {
    this.setState({ isOpen: true }, () => {
      document.addEventListener("click", e => {
        const paths = e.composedPath().map(path => path.className);
        if (!paths.includes("task-filter")) {
          this.closeMenu();
        }
      });
    });
  };

  closeMenu = () => {
    this.setState({ isOpen: false }, () => {
      document.removeEventListener("click", this.closeMenu);
    });
  };

  setFilters = filters => {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: queryString.stringify(filters)
    });
  };

  clearFilter = () => {
    this.closeMenu();
    this.props.history.push(this.props.location.pathname);
  };

  render() {
    const { textGray } = this.props;
    const filters = queryString.parse(this.props.location.search);

    return (
      <div className="task-filter">
        <div
          onClick={this.showMenu}
          className="filter-button"
          style={{ borderColor: textGray }}
        >
          <FilterIcon style={{ fill: textGray }} />
          <p className="filter-text" style={{ color: textGray }}>
            <FormattedMessage
              id={`tasksPage.taskFilter`}
              defaultMessage="Tasks Filter"
            />
          </p>
          <ArrowDown className="arrow-icon" style={{ fill: textGray }} />
        </div>
        {this.state.isOpen ? (
          <DropDownTasksFilter
            setFilters={this.setFilters}
            data={filters}
            clearFilter={this.clearFilter}
            hideFilter={this.hideFilter}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    textGray: state.theme.textGray
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(FilterButton));
