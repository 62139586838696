import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { object, array } from "yup";
import { injectIntl } from "react-intl";
import Modal from "../Modal";
import { hideModal, addCommitteeAttachments } from "../../actions";
import FormInput from "../FormInput";
import FormButton from "../FormButton";
import { cleanApiDataForm } from "../../utils";

class AddAttachmentsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        attachments: []
      }
    };
  }

  validationSchema = object().shape({
    attachments: array()
      .min(
        1,
        this.props.intl.formatMessage({
          id: "validation.attachments",
          defaultMessage: "attachments is required"
        })
      )
      .required(
        this.props.intl.formatMessage({
          id: "validation.attachments",
          defaultMessage: "attachments is required"
        })
      )
  });
  render() {
    const { hideModal, id, loading, progress, folderId } = this.props;

    return (
      <Modal isOpen title="upload" hideModal={hideModal}>
        <Formik
          validationSchema={this.validationSchema}
          initialValues={this.state.initialValues}
          onSubmit={(values, { setSubmitting }) => {
            const apiData = {
              FolderID: folderId,
              CommitteeID: id,
              Attachments: values.attachments
            };
            this.props.addCommitteeAttachments(cleanApiDataForm(apiData));
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormInput name="attachments" type="upload" multi />
              <FormButton loading={loading} progress={progress} />
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

AddAttachmentsModal.propTypes = {
  loading: PropTypes.bool
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loading: state.committee.CreateLoading,
    progress: state.committee.Progress
  };
};

const mapDispatchToProps = { hideModal, addCommitteeAttachments };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AddAttachmentsModal));
