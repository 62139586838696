import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ReactComponent as NotificationIcon } from "../../assets/images/notifications-icon.svg";
import "./style.scss";
import NotificationDropDownList from "../NotificationDropDownList";
import { updateNotifCounts } from "../../actions";

class NotificationsButton extends Component {
  state = {
    isHovered: false,
    open: false
  };

  showMenu = event => {
    event.preventDefault();

    this.setState({ open: true }, () => {
      document.addEventListener("click", this.closeMenu);
    });
  };

  closeMenu = () => {
    this.setState({ open: false }, () => {
      document.removeEventListener("click", this.closeMenu);
      this.props.updateNotifCounts();
    });
  };

  render() {
    const { intl, locale, theme, notifCount } = this.props;
    const { isHovered, open } = this.state;
    return (
      <div>
        <div
          className="notificationsButton"
          onMouseEnter={() => this.setState({ isHovered: true })}
          onMouseLeave={() => this.setState({ isHovered: false })}
          style={isHovered ? { backgroundColor: theme.accentColor } : {}}
          onClick={this.showMenu}
        >
          <NotificationIcon className="notificationIcon" />
          <p>
            {intl.formatMessage({
              id: "header.notifications",
              defaultMessage: "Notifications"
            })}
          </p>
          <div
            className={`badge ${locale.ltr ? "ltr" : ""}`}
            style={{ backgroundColor: theme.primary }}
          >
            <p>{notifCount < 100 ? notifCount : "99+"}</p>
          </div>
        </div>
        {open ? (
          <NotificationDropDownList
            theTitle={intl.formatMessage({
              id: "notification.title",
              defaultMessage: "notification"
            })}
            theme={this.props.theme}
            locale={this.props.locale}
            clickable={false}
          />
        ) : null}
      </div>
    );
  }
}

NotificationsButton.propTypes = {
  intl: PropTypes.object,
  theme: PropTypes.object,
  locale: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    notifCount: state.user.NotifCount
  };
};

const mapDispatchToProps = { updateNotifCounts };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsButton);
