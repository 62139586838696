import React from "react";
import { connect } from "formik";
import Dropzone from "react-dropzone";
import { injectIntl } from "react-intl";
import { ReactComponent as Attachment } from "../../assets/images/attachment-icon.svg";
import { attachmentsWhitelist } from "../../configs";
import "./style.scss";

const CommentUpload = props => {
  const { name, formik, theme, intl } = props;

  const checkDuplicate = (files, attachments) => {
    // TO_DO logic needs to be fixed
    let noDup = [];
    attachments.forEach(attachment => {
      noDup = files.filter(
        item =>
          attachment.name !== item.name &&
          attachment.size !== item.size &&
          attachment.lastModified !== item.lastModified
      );
    });
    return noDup;
  };

  const getPreviews = files => {
    return files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    );
  };

  const checkSize = files => {
    if (files.reduce((acc, file) => acc + file.size, 0) / 1000000000 > 2) {
      formik.setErrors({
        ...formik.errors,
        [name]: intl.formatMessage({
          id: "uploadInput.maxSize",
          defaultMessage: "Size of all files must not exceed 2GB"
        })
      });
      return true;
    }
    return false;
  };

  const handleChange = files => {
    formik.setFieldTouched(name, true, false);
    formik.setErrors({
      ...formik.errors,
      [name]: null
    });
    let acceptedFiles = [...files];
    const attachments = formik.values[name];
    if (attachments.length > 0) {
      acceptedFiles = checkDuplicate(acceptedFiles, attachments);
    }
    if (checkSize([...attachments, ...acceptedFiles])) {
      acceptedFiles = [];
    } else {
      acceptedFiles = getPreviews(acceptedFiles);
    }
    formik.setFieldValue(name, [...attachments, ...acceptedFiles]);
  };

  const handleReject = files => {
    let message = {
      id: "uploadInput.notAllowed",
      defaultMessage: "File type is not allawed"
    };
    formik.setErrors({
      ...formik.errors,
      [name]: intl.formatMessage(message)
    });
  };

  const attachments = formik.values[name];
  return (
    <Dropzone
      name={name}
      accept={attachmentsWhitelist}
      selectedValue={attachments}
      onDrop={handleChange}
      onBlur={formik.onBlur}
      onDropRejected={handleReject}
      multiple
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles,
        rejectedFiles
      }) => {
        return (
          <div {...getRootProps()}>
            <input {...getInputProps()} name={name} />
            <Attachment style={{ fill: theme.textGray }} />
          </div>
        );
      }}
    </Dropzone>
  );
};

export default connect(injectIntl(CommentUpload));
