import { all } from "redux-saga/effects";
import {
  loginWatcher,
  countsWatcher,
  notifCountsWatcher,
  verifyWatcher,
  logoutWatcher
} from "./user";
import {
  addTaskWatcher,
  fetchTaskWatcher,
  taskCommentWatcher,
  fetchCompletedTaskWatcher,
  fetchTaskChartWatcher
} from "./task";
import {
  addNewsWatcher,
  fetchNewsWatcher,
  deleteNewsWatcher,
  newsCommentWatcher
} from "./news";
import { deleteAttachmentWatcher } from "./attachment";
import { fetchProjectsWatcher, fetchProjectDetailsWatcher } from "./project";
import {
  addAdhocWatcher,
  fetchAdhocWatcher,
  adhocActionWatcher,
  adhocCommentWatcher,
  updateAdhocWatcher
} from "./adhoc";
import { handshakeWatcher } from "./theme";
import {
  addMeetingWatcher,
  fetchMeetingWatcher,
  removeMeetingWatcher,
  cancelMeetingWatcher,
  updateRequestsCountWatcher,
  fetchUpcomingMeetingsWatcher,
  meetingCommentWatcher
} from "./meeting";
import {
  fetchCommitteeWatcher,
  addCommitteeWatcher,
  addCommitteeItemsWatcher
} from "./committee";
import {
  fetchMeetingRequestWatcher,
  meetingRequestActionWatcher
} from "./meetingRequest";

import { fetchUsersWatcher } from "./users";

import { calenderWatcher } from "./calender";
import { fetchTimelineWatcher } from "./timeline";
// import watchers from other files
export default function* rootSaga() {
  yield all([
    loginWatcher(),
    addTaskWatcher(),
    taskCommentWatcher(),
    fetchTaskWatcher(),
    addNewsWatcher(),
    fetchNewsWatcher(),
    addMeetingWatcher(),
    fetchMeetingWatcher(),
    addAdhocWatcher(),
    fetchAdhocWatcher(),
    handshakeWatcher(),
    fetchCommitteeWatcher(),
    addCommitteeWatcher(),
    addCommitteeItemsWatcher(),
    adhocActionWatcher(),
    adhocCommentWatcher(),
    handshakeWatcher(),
    fetchMeetingRequestWatcher(),
    meetingRequestActionWatcher(),
    deleteNewsWatcher(),
    removeMeetingWatcher(),
    cancelMeetingWatcher(),
    deleteAttachmentWatcher(),
    fetchProjectsWatcher(),
    fetchCompletedTaskWatcher(),
    updateAdhocWatcher(),
    countsWatcher(),
    notifCountsWatcher(),
    fetchProjectDetailsWatcher(),
    verifyWatcher(),
    updateRequestsCountWatcher(),
    logoutWatcher(),
    fetchUsersWatcher(),
    calenderWatcher(),
    fetchUpcomingMeetingsWatcher(),
    fetchTaskChartWatcher(),
    fetchTimelineWatcher(),
    newsCommentWatcher(),
    meetingCommentWatcher()
    // add other watchers to the array
  ]);
}
