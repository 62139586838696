import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { string, object } from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { login, clearLoginError, verify } from "../../actions";
import LocaleToggle from "../../components/LocaleToggle";
import Button from "../../components/Button";
import { limits } from "../../configs";

import { ReactComponent as LogoTransparent } from "../../assets/images/diwan-logo-transparent.svg";

import "./style.scss";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation_g_token: ""
    };
    this.form = React.createRef();
  }

  initialValues = {
    Username: "",
    Password: "",
    Code: "",
    DeviceID: this.props.user.DeviceID
  };

  loginSchema = () => {
    const { loginState } = this.props;
    if (loginState.Verify) {
      return object()
        .shape({
          Code: string().required(
            this.props.intl.formatMessage({
              id: "validation.codeRequired",
              defaultMessage: "Code is required"
            })
          )
        })
        .max(
          limits.title,
          this.props.intl.formatMessage(
            {
              id: "validation.charLimit",
              defaultMessage: "should not exceed {limit} characters"
            },
            { limit: limits.title }
          )
        );
    }
    return object().shape({
      Username: string()
        .max(
          limits.title,
          this.props.intl.formatMessage(
            {
              id: "validation.charLimit",
              defaultMessage: "should not exceed {limit} characters"
            },
            { limit: limits.title }
          )
        )
        .trim()
        .required(
          this.props.intl.formatMessage({
            id: "validation.userNameRequired",
            defaultMessage: "Username is required"
          })
        ),
      Password: string()
        .max(
          limits.title,
          this.props.intl.formatMessage(
            {
              id: "validation.charLimit",
              defaultMessage: "should not exceed {limit} characters"
            },
            { limit: limits.title }
          )
        )
        .trim()
        .required(
          this.props.intl.formatMessage({
            id: "validation.passwordRequired",
            defaultMessage: "Password is required"
          })
        )
    });
  };

  componentDidMount() {
    this.props.clearLoginError();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.loginState.Verify !== this.props.loginState.Verify) {
      const form = this.form.current;
      form.resetForm({
        ...this.initialValues,
        Username: form.state.values.Username
      });
    }
    if (prevProps.locale.ltr !== this.props.locale.ltr) {
      this.form.current.validateForm(this.form.current.state.values);
    }
  }

  setErrorMessage = () => {
    const { loginState } = this.props;
    if (loginState.BlockedUntil) {
      return {
        id: "validation.userBlocked",
        defaultMessage:
          "This user is blocked for {time} {time, plural, one {minute} other {minutes}}"
      };
    }
    if (loginState.LastAttempt) {
      return {
        id: "validation.lastAttempt",
        defaultMessage: "Only one try left"
      };
    }
    if (loginState.Error !== "fail") {
      return {
        id: `validation.${loginState.Error}`,
        defaultMessage: loginState.Error
      };
    }
    return {
      id: "validation.wrongUserPassowrd",
      defaultMessage: "wrong username or password"
    };
  };

  render() {
    const { theme, user, intl, login, verify, loginState, locale } = this.props;

    const { validation_g_token } = this.state;

    if (user.LoggedIn) return <Redirect to="/timeline" />;

    return (
      <div className={locale.ltr ? "login-container ltr" : "login-container"}>
        <div className="left-side">
          <div className="lang-container">
            <LocaleToggle />
          </div>
          <div className="left-side-content">
            <div className="text-btin-logo-container">
              <img src={theme.logo} alt="logo" className="img-logo" />
              <div className="title-container">
                <div className="office-text">{theme.appTitle}</div>
              </div>
              <Formik
                ref={this.form}
                initialValues={this.initialValues}
                validationSchema={this.loginSchema}
                validate={() => {
                  if (loginState.Error !== "") {
                    this.props.clearLoginError();
                  }
                }}
                onSubmit={(values, { setSubmitting }) => {
                  if (loginState.Verify) {
                    verify({
                      Username: values.Username,
                      Code: values.Code,
                      DeviceID: values.DeviceID
                    });
                  } else {
                    login(values);
                  }
                }}
              >
                {({ isSubmitting, errors, touched }) => (
                  <Form>
                    {loginState.Error !== "" ? (
                      <div
                        className="error-text"
                        style={{ color: theme.errorColor }}
                      >
                        {intl.formatMessage(this.setErrorMessage(), {
                          time: loginState.BlockedUntil,
                          atempts: loginState.Atempts
                        })}
                      </div>
                    ) : null}
                    {loginState.Verify ? (
                      <div className="text-color">
                        {intl.formatMessage({
                          id: "login.verificationCodeSent",
                          defaultMessage:
                            "the verification code has been sent to your phone"
                        })}
                      </div>
                    ) : null}
                    {loginState.Verify ? (
                      <>
                        <Field
                          className="login-email-text login-input"
                          placeholder={intl.formatMessage({
                            id: "login-page-code-placeholder"
                          })}
                          style={{ borderColor: theme.accentColor }}
                          type="text"
                          name="Code"
                        />
                        <ErrorMessage
                          name="Code"
                          style={{ color: theme.errorColor }}
                          component="div"
                          className="error-text"
                        />
                      </>
                    ) : (
                      <>
                        <Field
                          className="login-email-text login-input"
                          placeholder={intl.formatMessage({
                            id: "login-page-email-placeholder"
                          })}
                          style={{ borderColor: theme.accentColor }}
                          type="text"
                          name="Username"
                        />
                        <ErrorMessage
                          name="Username"
                          style={{ color: theme.errorColor }}
                          component="div"
                          className="error-text"
                        />
                        <Field
                          className="login-email-password login-input"
                          placeholder={intl.formatMessage({
                            id: "login-page-password-placeholder"
                          })}
                          style={{ borderColor: theme.accentColor }}
                          type="password"
                          name="Password"
                        />
                        <ErrorMessage
                          name="Password"
                          style={{ color: theme.errorColor }}
                          component="div"
                          className="error-text"
                        />
                      </>
                    )}

                    <div className="recaptcha-container">
                      <ReCAPTCHA
                        sitekey={window.env.RECAPTCHA_KEY}
                        onChange={value =>
                          this.setState({ validation_g_token: value })
                        }
                      />
                    </div>
                    <Button
                      type="submit"
                      loading={loginState.Loading}
                      className="login-btn"
                      style={{ background: theme.primary }}
                      disabled={!validation_g_token}
                    >
                      {loginState.Verify
                        ? intl.formatMessage({
                            id: "login-page-btn-verify",
                            defaultMessage: "Verify"
                          })
                        : intl.formatMessage({
                            id: "login-page-btn-login",
                            defaultMessage: "Login"
                          })}
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        <div className="right-side" style={{ backgroundColor: theme.primary }}>
          <LogoTransparent style={{ fill: "#b5c2bc", stroke: "#b5c2bc" }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    user: state.user,
    loginState: state.login,
    locale: state.locale
  };
};

const mapDispatchToProps = { login, clearLoginError, verify };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(LoginPage));
