export const ADD_TASK = "ADD_TASK";
export const ADD_TASK_ASYNC = "ADD_TASK_ASYNC";
export const TASK_LOADING = "TASK_LOADING";
export const LOADING_TASK_CHART = "LOADING_TASK_CHART";

export const CREATE_TASK_LOADING = "CREATE_TASK_LOADING";
export const TASK_FAILED = "TASK_FAILED";
export const FETCH_TASK = "FETCH_TASK";
export const FETCH_TASK_CHART = "FETCH_TASK_CHART";
export const FETCH_TASK_CHART_ASYNC = "FETCH_TASK_CHART_ASYNC";
export const FETCH_TASK_ASYNC = "FETCH_TASK_ASYNC";
export const FETCH_COMPLETED_TASK_ASYNC = "FETCH_COMPLETED_TASK_ASYNC";
export const FETCH_COMPLETED_TASK = "FETCH_COMPLETED_TASK";
export const TASK_ADD_COMMENT = "TASK_ADD_COMMENT";
export const TASK_ADD_COMMENT_ASYNC = "TASK_ADD_COMMENT_ASYNC";
export const TASK_COMMENT_LOADING = "TASK_COMMENT_LOADING";
export const DELETE_TASK_COMMENT = "DELETE_TASK_COMMENT";
export const DELETE_TASK_ATTACHMENT = "DELETE_TASK_ATTACHMENT";
export const UPDATE_TASK_PROGRESS = "UPDATE_TASK_PROGRESS";
export const UPDATE_TASK_UPLOAD_PROGRESS = "UPDATE_TASK_UPLOAD_PROGRESS";
export const UPDATE_TASK_STATUS = "UPDATE_TASK_STATUS";

export function updateTaskStatus(id, status) {
  return { type: UPDATE_TASK_STATUS, id, status };
}

export function addTaskAsync(task) {
  return { type: ADD_TASK_ASYNC, task };
}

export function addTask(task, taskId) {
  return { type: ADD_TASK, task, taskId };
}

export function fetchTaskChartAsync(taskChart) {
  return { type: FETCH_TASK_CHART_ASYNC, taskChart };
}

export function fetchTaskChart(taskChart) {
  return { type: FETCH_TASK_CHART, taskChart };
}

export function taskChartLoading() {
  return { type: LOADING_TASK_CHART };
}

export function fetchTaskAsync(tasks) {
  return { type: FETCH_TASK_ASYNC, tasks };
}

export function fetchTask(taskId) {
  return { type: FETCH_TASK, taskId };
}

export function fetchCompletedTaskAsync(completedTasks, allTasks) {
  return { type: FETCH_COMPLETED_TASK_ASYNC, completedTasks, allTasks };
}

export function fetchCompletedTask(completedTasks, allTasks) {
  return { type: FETCH_COMPLETED_TASK, completedTasks, allTasks };
}

export function taskLoading() {
  return { type: TASK_LOADING };
}

export function createTaskLoading() {
  return { type: CREATE_TASK_LOADING };
}

export function taskFailed(error) {
  return { type: TASK_FAILED, error };
}

export function taskAddCommentAsync(comment) {
  return { type: TASK_ADD_COMMENT_ASYNC, comment };
}

export function taskAddComment(comment, taskId) {
  return { type: TASK_ADD_COMMENT, comment, taskId };
}

export function taskCommentLoading() {
  return { type: TASK_COMMENT_LOADING };
}

export function deleteTaskComment(commentId) {
  return { type: DELETE_TASK_COMMENT, commentId };
}

export function deleteTaskAttachment(id) {
  return { type: DELETE_TASK_ATTACHMENT, id };
}

export function updateTaskProgress(id, progress) {
  return { type: UPDATE_TASK_PROGRESS, id, progress };
}

export function updateTaskUploadProgress(progress) {
  return { type: UPDATE_TASK_UPLOAD_PROGRESS, progress };
}
