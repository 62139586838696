import React, * as react from "react";
import { connect } from "react-redux";
import { ReactComponent as SearchIcon } from "../../assets/images/search-icon.svg";
import { injectIntl } from "react-intl";
import "./style.scss";

import PropTypes from "prop-types";

class SearchInput extends react.Component {
  state = {
    filter: ""
  };

  render() {
    const { intl, theme, location } = this.props;

    return (
      <div className="search-input-container">
        <input
          placeholder={intl.formatMessage({
            id: "placeholder.usersSearch",
            defaultMessage: "Search for users"
          })}
          className="search-input"
          onChange={e => {
            if (e.target.value === "") {
              this.props.history.push({
                pathname: `/users`,
                search: ""
              });
            }
            this.setState({ filter: e.target.value });
          }}
          style={{
            borderColor: theme.textGray
          }}
          value={this.state.filter || decodeURI(location.search).substr(1)}
        />

        <button
          onClick={() =>
            this.props.history.push({
              pathname: `/users`,
              search: this.state.filter
            })
          }
        >
          <SearchIcon style={{ fill: theme.textGray }} />
        </button>
      </div>
    );
  }
}

SearchInput.propTypes = {
  theme: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme
  };
};

export default connect(mapStateToProps, null)(injectIntl(SearchInput));
