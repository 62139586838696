import axios from "axios";
import {
  handshakeAsync,
  handshakeFailed,
  updateCounts,
  updateNotifCounts
} from "../actions";
import { getDataFromRes } from "../utils";
import { call, put, takeLatest } from "redux-saga/effects";

function handshakeApi() {
  return axios.get("/Handshake");
}

function workflowApi() {
  return axios.get("/Login/MemberType");
}

function lookups() {
  return axios.get("/Lookups");
}

function apiCall(action) {
  return axios.all([handshakeApi(), workflowApi(), lookups()]);
}

function refreshSessionApi({ DeviceID }) {
  return axios.post("/Login/RefreshSession", { DeviceID });
}

function* handshakeEffect(action) {
  try {
    // data is obtained after axios call is resolved
    if (action.workflow) {
      let data = yield call(apiCall, action);
      const fail = data.filter(item => item.data.StatusCode !== "Success");

      if (fail.length === 0) {
        yield put(
          handshakeAsync(
            getDataFromRes(data[0].data),
            getDataFromRes(data[1].data),
            getDataFromRes(data[2].data)
          )
        );
        yield put(updateCounts());
        yield put(updateNotifCounts());
      } else {
        yield put(handshakeFailed(fail[0].data.StatusMessage));
      }
    } else {
      let { data } = yield call(handshakeApi, action);

      if (data.StatusCode === "Success") {
        yield put(handshakeAsync(getDataFromRes(data), {}));
      } else {
        yield put(handshakeFailed(data.StatusMessage));
      }
    }
    if (action.workflow && action.DeviceID) {
      yield call(refreshSessionApi, action);
    }
    // redirect to home route after successful login
  } catch (e) {
    // catch error on a bad axios call
    // alert using an alert library
  }
}

export function* handshakeWatcher() {
  yield takeLatest("HANDSHAKE", handshakeEffect);
}
