import { put, take } from "redux-saga/effects";
import { END, eventChannel } from "redux-saga";

export function createUploader(payload, upload) {
  let emit;
  const chan = eventChannel(emitter => {
    emit = emitter;
    return () => {}; // it's necessarily. event channel should
    // return unsubscribe function. In our case
    // it's empty function
  });

  const uploadPromise = upload(payload, event => {
    if (Math.round((event.loaded * 100) / event.total) === 100) {
      emit(END);
    }

    emit(Math.round((event.loaded * 100) / event.total));
  });

  return [uploadPromise, chan];
}

export function* watchOnProgress(chan, updateProgress) {
  while (true) {
    const data = yield take(chan);
    yield put(updateProgress(data));
  }
}
