export default {
  control: {
    // backgroundColor: "#fff",
    // fontSize: 14,
    // fontWeight: "normal"
  },

  highlighter: {
    overflow: "hidden"
  },

  input: {
    margin: 0,
    outline: "none",
    lineHeight: "1.4em",
    minHeight: 42,
    overflow: "hidden"
  },

  "&singleLine": {
    control: {
      display: "inline-block",

      width: 130
    },

    highlighter: {
      // padding: 1,
      border: "2px inset transparent"
    },

    input: {
      // padding: 1,
      overflow: "hidden",

      border: "2px inset"
    }
  },

  "&multiLine": {
    control: {},

    highlighter: {
      padding: 9
    },

    input: {
      // paddingBottom: 5,
      // paddingTop: 13,
      // paddingLeft: 13,
      // paddingRight: 13,
      overflowY: "hidden",
      minHeight: 42,
      // maxHeight: 110,

      border: 0
    }
  }
};
