import {
  ADD_ADHOC_ASYNC,
  ADHOC_LOADING,
  CREATE_ADHOC_LOADING,
  FETCH_ADHOC_ASYNC,
  ADHOC_ACTION_ASYNC,
  ADHOC_ACTION_LOADING,
  ADHOC_FAILED,
  ADHOC_ADD_COMMENT_ASYNC,
  DELETE_ADHOC_COMMENT,
  ADHOC_COMMENT_LOADING,
  DELETE_ADHOC,
  STOP_ADHOC_ACTION_LOADING,
  UPDATE_ADHOC_ASYNC,
  UPDATE_ADHOC_UPLOAD_PROGRESS
} from "../actions";
import { getNewList, filterList } from "../utils";

const initialState = {
  Loading: true,
  CreateLoading: false,
  ActionLoading: false,
  CommentLoading: false,
  PagingInfo: "",
  Error,
  AdhocList: [],
  Adhoc: null,
  Progress: ""
};

function adhoc(state = initialState, action) {
  switch (action.type) {
    case ADD_ADHOC_ASYNC:
      return {
        ...state,
        CreateLoading: false,
        Error: "",
        Progress: "",
        AdhocList: getNewList(state.AdhocList, action.adhoc)
      };
    case UPDATE_ADHOC_ASYNC:
      return {
        ...state,
        CreateLoading: false,
        Error: "",
        Progress: "",
        Adhoc: {
          ...action.adhoc,
          workflowHistories: [
            {
              AdhocID: action.adhoc.ID,
              Stage: "",
              Action: "Updated",
              Comment: "",
              Date: +new Date() / 1000
            },
            ...action.adhoc.workflowHistories
          ]
        }
      };
    case FETCH_ADHOC_ASYNC:
      let newState = {
        ...state,
        Loading: false,
        PagingInfo: action.PagingInfo ? action.PagingInfo : "",
        Error: ""
      };
      if (Array.isArray(action.adhocs)) {
        newState.AdhocList =
          state.AdhocList.length > 0
            ? [...state.AdhocList, ...action.adhocs]
            : action.adhocs;
      } else {
        newState.Adhoc = action.adhocs;
      }
      return newState;
    case ADHOC_ACTION_ASYNC:
      const newList = state.AdhocList.map(item =>
        item.ID === action.adhocId ? { ...item, Status: action.action } : item
      );
      return {
        ...state,
        ActionLoading: false,
        Error: "",
        AdhocList: newList
      };
    case ADHOC_ADD_COMMENT_ASYNC:
      return {
        ...state,
        CommentLoading: false,
        Error: "",
        Progress: "",
        Adhoc: {
          ...state.Adhoc,
          Comments: state.Adhoc.Comments
            ? [action.comment, ...state.Adhoc.Comments]
            : [action.comment]
        }
      };
    case DELETE_ADHOC_COMMENT:
      return {
        ...state,
        Adhoc: {
          ...state.Adhoc,
          Comments: state.Adhoc.Comments.filter(
            comment => comment.ID !== action.commentId
          )
        }
      };

    case DELETE_ADHOC:
      return {
        ...state,
        AdhocList: filterList(action.adhocId, state.AdhocList)
      };
    case ADHOC_LOADING:
      if (action.clear) return { ...initialState, Loading: true };
      return {
        ...state,
        Loading: true
      };
    case UPDATE_ADHOC_UPLOAD_PROGRESS:
      return {
        ...state,
        Progress: action.progress
      };
    case CREATE_ADHOC_LOADING:
      return {
        ...state,
        CreateLoading: true
      };
    case ADHOC_COMMENT_LOADING:
      return {
        ...state,
        CommentLoading: true
      };
    case ADHOC_ACTION_LOADING:
      return {
        ...state,
        ActionLoading: true
      };
    case STOP_ADHOC_ACTION_LOADING:
      return {
        ...state,
        ActionLoading: false
      };
    case ADHOC_FAILED:
      return {
        ...state,
        Error: action.error,
        Progress: "",
        Loading: false,
        CreateLoading: false,
        ActionLoding: false,
        CommentLoding: false
      };
    default:
      return state;
  }
}

export default adhoc;
