import React from "react";

const LocaleAwareText = props => {
  const { children, text } = props;
  const arabic = /[\u0600-\u06FF]/;
  const child = React.Children.only(children);
  let testText = text ? text : child.props.children;
  if (!testText) {
    return <div>{child}</div>;
  }
  testText = testText.substr(0, 100);
  testText = testText.replace(/<\/?[^>]+(>|$)/g, "");
  const direction = arabic.test(testText.trim().split(" ")[0]) ? "rtl" : "ltr";
  return (
    <div style={{ direction }} className="des-height">
      {child}
    </div>
  );
};

export default LocaleAwareText;
