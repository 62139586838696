import React from "react";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";

class AdhocListLoadingCard extends React.Component {
  constructor() {
    super();

    this.state = {
      width: window.innerWidth
    };

    window.addEventListener("resize", this.update);
  }

  update = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.update);
  }

  _renderItem = () => {
    if (this.props.isWorkflowMember)
      return (
        <ContentLoader
          className="card loading-card"
          rtl={!this.props.ltr}
          width={400}
          height={200}
        >
          <rect x="10" y="17" rx="5" ry="5" width="120" height="10" />
          <rect x="320" y="10" rx="10" ry="10" width="60" height="20" />

          <rect x="10" y="45" rx="5" ry="5" width="40" height="10" />
          <rect x="10" y="70" rx="5" ry="5" width="370" height="10" />

          <rect x="0" y="160" rx="3" ry="3" width="400" height="1" />
          <circle cx="30" cy="125" r="20" />
          <rect x="60" y="110" rx="5" ry="5" width="50" height="10" />
          <rect x="60" y="127" rx="5" ry="5" width="40" height="10" />

          <rect x="10" y="173" rx="5" ry="5" width="20" height="10" />
          <rect x="45" y="173" rx="5" ry="5" width="20" height="10" />
        </ContentLoader>
      );
    return (
      <ContentLoader
        className="card loading-card"
        rtl={!this.props.ltr}
        width={400}
        height={140}
      >
        <rect x="10" y="15" rx="5" ry="3" width="120" height="10" />
        <rect x="320" y="10" rx="10" ry="10" width="60" height="20" />

        <rect x="10" y="40" rx="5" ry="5" width="50" height="10" />
        <rect x="10" y="70" rx="5" ry="5" width="370" height="10" />

        <rect x="0" y="100" rx="3" ry="3" width="400" height="1" />

        <rect x="10" y="115" rx="5" ry="5" width="15" height="10" />
        <rect x="30" y="115" rx="5" ry="5" width="15" height="10" />

        <circle cx="340" cy="120" r="6" />
        <rect x="350" y="115" rx="5" ry="5" width="30" height="10" />
      </ContentLoader>
    );
  };

  _renderItem2 = () => {
    if (this.props.isWorkflowMember)
      return (
        <ContentLoader
          className="card loading-card"
          rtl={!this.props.ltr}
          width={400}
          height={120}
        >
          <rect x="10" y="10" rx="3" ry="3" width="120" height="6" />
          <rect x="340" y="10" rx="5" ry="5" width="40" height="10" />

          <rect x="10" y="35" rx="3" ry="3" width="30" height="6" />
          <rect x="10" y="50" rx="3" ry="3" width="370" height="6" />

          <rect x="0" y="100" rx="3" ry="3" width="400" height="1" />
          <circle cx="20" cy="85" r="10" />
          <rect x="40" y="77" rx="3" ry="3" width="30" height="6" />
          <rect x="40" y="87" rx="3" ry="3" width="40" height="6" />

          <rect x="10" y="107" rx="3" ry="3" width="10" height="6" />
          <rect x="30" y="107" rx="3" ry="3" width="10" height="6" />
        </ContentLoader>
      );
    return (
      <ContentLoader
        className="card loading-card"
        rtl={!this.props.ltr}
        width={400}
        height={100}
      >
        <rect x="10" y="10" rx="3" ry="3" width="120" height="6" />
        <rect x="340" y="10" rx="5" ry="5" width="40" height="10" />

        <rect x="10" y="30" rx="3" ry="3" width="30" height="6" />
        <rect x="10" y="45" rx="3" ry="3" width="370" height="6" />

        <rect x="0" y="75" rx="3" ry="3" width="400" height="1" />

        <rect x="10" y="85" rx="3" ry="3" width="10" height="6" />
        <rect x="30" y="85" rx="3" ry="3" width="10" height="6" />

        <circle cx="340" cy="88" r="4" />
        <rect x="350" y="85" rx="3" ry="3" width="30" height="6" />
      </ContentLoader>
    );
  };
  render() {
    if (this.state.width >= 1200) {
      return this._renderItem();
    } else {
      return this._renderItem2();
    }
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ltr: state.locale.ltr,
    isWorkflowMember: state.theme.isWorkflowMember
  };
};

export default connect(
  mapStateToProps,
  null
)(AdhocListLoadingCard);
