import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Loading from "../Loading";
// import userImage from "../../assets/images/profile-image.jpg";

const AsyncImg = props => {
  const [loading, setLoading] = useState(true);
  const [attachmentUrl, setAttachmentUrl] = useState("");

  useEffect(() => {
    axios
      .get(props.img, { responseType: "blob" })
      .then(res => {
        const buffer = URL.createObjectURL(new Blob([res.data]));

        setAttachmentUrl(buffer);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  }, [props.img]);

  return (
    <>
      {loading ? (
        <Loading color={props.theme.primary} />
      ) : (
        <img
          alt="SourceImg"
          src={attachmentUrl}
          onError={e =>
            props.fallbackImage ? (e.target.src = props.fallbackImage) : null
          }
          {...props}
        />
      )}
    </>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme
  };
};

export default connect(mapStateToProps, null)(AsyncImg);
