import {
  CALENDER_ASYNC,
  CALENDER_ERROR,
  UPDATE_CALENDER_PROGRESS,
  UPDATE_CALENDER_TASKS,
  UPDATE_CALENDER_MEETINGS
} from "../actions";

const initialState = {
  Loading: true,
  calenderData: null
};

function calender(state = initialState, action) {
  switch (action.type) {
    case CALENDER_ASYNC:
      return {
        ...state,
        Loading: false,
        Error: "",
        calenderData: action.calender
      };
    case CALENDER_ERROR:
      return {
        Loading: false,
        Error: action.error
      };
    case UPDATE_CALENDER_PROGRESS:
      const newData =
        state.calenderData.Tasks.length > 0 &&
        state.calenderData.Tasks.map(data => {
          if (action.id === data.ID) {
            return { ...data, Progress: action.progress };
          } else {
            return data;
          }
        });
      return {
        ...state,
        calenderData: {
          Meetings: [...state.calenderData.Meetings],
          Tasks: newData
        }
      };

    case UPDATE_CALENDER_TASKS:
      return {
        ...state,
        calenderData: {
          Meetings: state.calenderData.Meetings,
          Tasks:
            state.calenderData.Tasks.length > 0
              ? [...state.calenderData.Tasks, { ...action.data }]
              : [...action.data]
        }
      };

    case UPDATE_CALENDER_MEETINGS:
      return {
        ...state,
        calenderData: {
          Meetings:
            state.calenderData.Meetings.length > 0
              ? [...state.calenderData.Meetings, { ...action.data }]
              : [...action.data],
          Tasks: state.calenderData.Tasks
        }
      };
    default:
      return state;
  }
}

export default calender;
