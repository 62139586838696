import React from "react";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";

const TaskDetailsLoadingCard = props => {
  const { ltr } = props;
  return (
    <ContentLoader
      className="card loading-card"
      rtl={!ltr}
      width={400}
      height={250}
    >
      <rect x="10" y="10" rx="5" ry="5" width="270" height="80" />
      <rect x="290" y="0" width="1" height="250" />
      <rect x="302" y="10" width="40" rx="3" ry="3" height="6" />
      <rect x="10" y="100" rx="3" ry="3" width="100" height="6" />
      <rect x="10" y="118" rx="3" ry="3" width="270" height="6" />
      <rect x="10" y="128" rx="3" ry="3" width="270" height="6" />
      <rect x="10" y="138" rx="3" ry="3" width="200" height="6" />

      <circle cx="20" cy="161" r="10" />
      <rect x="40" y="153" rx="3" ry="3" width="90" height="6" />
      <rect x="40" y="163" rx="3" ry="3" width="50" height="6" />

      <rect x="10" y="180" rx="3" ry="3" width="40" height="40" />
      <rect x="60" y="180" rx="3" ry="3" width="40" height="40" />
      <rect x="110" y="180" rx="3" ry="3" width="40" height="40" />
      <rect x="160" y="180" rx="3" ry="3" width="40" height="40" />

      <circle cx="312" cy="40" r="10" />
      <rect x="330" y="33" width="60" rx="3" ry="3" height="6" />
      <circle cx="334" cy="46" r="4" />
      <rect x="340" y="43" width="30" rx="3" ry="3" height="6" />

      <circle cx="312" cy="70" r="10" />
      <rect x="330" y="63" width="60" rx="3" ry="3" height="6" />
      <circle cx="334" cy="76" r="4" />
      <rect x="340" y="73" width="30" rx="3" ry="3" height="6" />

      <circle cx="312" cy="100" r="10" />
      <rect x="330" y="93" width="60" rx="3" ry="3" height="6" />
      <circle cx="334" cy="106" r="4" />
      <rect x="340" y="103" width="30" rx="3" ry="3" height="6" />
    </ContentLoader>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ltr: state.locale.ltr
  };
};

export default connect(
  mapStateToProps,
  null
)(TaskDetailsLoadingCard);
