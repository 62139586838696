import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ReactComponent as ExclamationIcon } from "../../assets/images/exclamation-icon.svg";
import "./style.scss";

function PriorityIndicator(props) {
  const { theme, value, locale } = props;
  const priority = theme.Tasks_Priority[value];

  if (!priority) return null;
  return (
    <div className="taskPriority">
      <div className="priorityIndicator">
        {[...Array(parseInt(priority.Value))].map((item, index) => (
          <ExclamationIcon
            className="priorityIcon"
            style={{ fill: theme.primary }}
            key={index}
          />
        ))}
      </div>
      <p className="priorityText">{priority[`Title${locale.suffix}`]}</p>
    </div>
  );
}

PriorityIndicator.propTypes = {
  theme: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    locale: state.locale
  };
};

export default connect(
  mapStateToProps,
  null
)(PriorityIndicator);
