import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Timeline from "../../components/Timeline";
import { injectIntl } from "react-intl";
import { fetchTimeline } from "../../actions";
import "./style.scss";

class TimelinePage extends Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, false);
    this.props.fetchTimeline();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll, false);
  }

  handleScroll() {
    const offset = 600;
    const { loading, fetchTimeline, nextPage } = this.props;
    if (
      !loading &&
      Object.keys(nextPage).filter(key => nextPage[key].PagingInfo !== "-1")
        .length !== 0 &&
      window.innerHeight + window.scrollY >= document.body.offsetHeight - offset
    ) {
      fetchTimeline(nextPage);
    }
  }

  render() {
    const { theme, posts, loading, intl } = this.props;
    return (
      <Timeline theme={theme} posts={posts} loading={loading} intl={intl} />
    );
  }
}

TimelinePage.propTypes = {
  task: PropTypes.object,
  theme: PropTypes.object,
  intl: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    posts: state.timeline.Posts,
    loading: state.timeline.Loading,
    nextPage: state.timeline.NextPage,
    theme: state.theme
  };
};

const mapDispatchToProps = { fetchTimeline };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(TimelinePage));
