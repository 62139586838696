import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { FormattedMessage } from "react-intl";
import groupBy from "lodash/groupBy";
import "./style.scss";
import { getDisplayName } from "../../utils";

const pages = [
  "tasks",
  "news",
  "projects",
  "project",
  "committees",
  "committee",
  "profile",
  "meetings",
  "meeting-requests",
  "adhoc",
  "users",
  "timeline"
];

const Breadcrumb = props => {
  const { ltr, location, tasks, committee, projects } = props;
  const locations = location.pathname.split("/");
  const suffix = ltr ? "English" : "Arabic";

  const isGray = () => {
    return (
      locations[2] &&
      (["committee", "news", "projects", "users"].includes(locations[1]) ||
        (locations[1] === "tasks" &&
          !["status", "members"].includes(locations[2])))
    );
  };
  const getTasks = () => {
    if (!["status", "members"].includes(locations[2])) {
      if (locations[2] !== "user") {
        return _secondPart(
          <FormattedMessage
            id="breadCrumbs.taskDetails"
            defaultMessage="Task Details"
          />
        );
      } else {
        const group = groupBy(tasks.Tasks, `Responsible.Username`);
        return _secondPart(
          tasks.Loading || tasks.Tasks.length === 0
            ? "..."
            : getDisplayName(group[locations[3]][0].Responsible, ltr)
        );
      }
    }
  };

  const getCommittee = () => {
    return _secondPart(
      committee.Loading || !committee.Committee
        ? "..."
        : committee.Committee.NameAr
    );
  };

  const getProject = () => {
    return _secondPart(
      projects.Projects.length > 0 &&
        !!projects.Projects.find(project => project.ProjectUID === locations[2])
        ? projects.Projects.find(
            project => project.ProjectUID === locations[2]
          )[`Name${suffix}`]
        : "..."
    );
  };

  const getSecondPart = () => {
    switch (locations[1]) {
      case "tasks":
        return getTasks();
      case "projects":
        return getProject();
      case "news":
        return _secondPart(
          <>
            {locations[2] === "source" ? (
              props.sourceName !== "" ? (
                props.sourceName
              ) : (
                "..."
              )
            ) : (
              <FormattedMessage
                id="breadCrumbs.newsDetails"
                defaultMessage="News Details"
              />
            )}
          </>
        );

      case "users":
        return _secondPart(
          <FormattedMessage
            id="breadCrumbs.usersDetails"
            defaultMessage="Users Details"
          />
        );
      case "committee":
        return getCommittee();
      default:
        return null;
    }
  };

  const _secondPart = text => {
    return (
      <>
        <span className="separator">/</span>
        <span>{text}</span>
      </>
    );
  };

  if (!pages.includes(locations[1])) {
    return null;
  }

  const isSub = isGray();
  return (
    <div className="breadCrumbs">
      <span
        style={isSub ? { color: props.textGray, cursor: "pointer" } : null}
        onClick={() =>
          isSub
            ? props.history.push(
                `/${locations[1]}${locations[1].slice(-1) !== "s" ? "s" : ""}`
              )
            : null
        }
      >
        <FormattedMessage
          id={`breadCrumbs.${locations[1]}`}
          defaultMessage={locations[1]}
        />
      </span>
      {locations[2] ? getSecondPart() : null}
    </div>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    tasks: state.task,
    committee: state.committee,
    sourceName: state.news.sourceName,
    news: state.news,
    projects: state.project,
    ltr: state.locale.ltr,
    textGray: state.theme.textGray
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(Breadcrumb));
