import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { FormattedMessage, injectIntl } from "react-intl";
import "./style.scss";
import { ReactComponent as More } from "../../assets/images/more-icon.svg";
import StatusIndicator from "../StatusIndicator";

class NewsBar extends Component {
  render() {
    const { theme, ltr, data, details, suffix, intl } = this.props;

    return (
      <div
        className={details ? "news-bar details" : "news-bar"}
        style={{
          background: details ? theme.backgroundColor : null,
          borderColor: theme.accentColor
        }}
      >
        {details ? (
          <div className="news-bar-section">
            <a
              className="more-section more-icon-rotate"
              href={data.SourceURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="margin">
                <FormattedMessage
                  id="more-news-source-view"
                  defaultMessage="More"
                />
              </p>
              <More
                alt="more"
                style={{
                  stroke: theme.primary,
                  transform: ltr ? "rotate(180deg)" : "rotate(0deg)",
                  cursor: "pointer"
                }}
              />
            </a>
          </div>
        ) : (
          <div className="arrow-container">
            <StatusIndicator
              color={theme.News_Status[data.Status].Color}
              text={
                data.PublishingDate !== 0
                  ? intl.formatMessage(
                      {
                        id: "newsBar.status",
                        defaultMessage: "{stauts} on ({day})"
                      },
                      {
                        status:
                          theme.News_Status[data.Status][`Title${suffix}`],
                        day: moment(data.PublishingDate * 1000).format(
                          "D-MMMM-YYYY"
                        )
                      }
                    )
                  : theme.News_Status[data.Status][`Title${suffix}`]
              }
            />
          </div>
        )}
      </div>
    );
  }
}

NewsBar.propTypes = {
  theme: PropTypes.object,
  ltr: PropTypes.bool
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    ltr: state.locale.ltr,
    suffix: state.locale.suffix
  };
};

export default connect(
  mapStateToProps,
  null
)(injectIntl(NewsBar));
