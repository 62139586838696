import axios from "axios";
import { replace } from "connected-react-router";
import {
  addNewsAsync,
  newsFailed,
  newsLoading,
  createNewsLoading,
  hideModal,
  fetchNewsAsync,
  deleteNewsAsync,
  showToast,
  setNewsSources,
  stopCreateNewsLoading,
  hideToast,
  updateNewsUploadProgress,
  newsCommentLoading,
  newsAddCommentAsync
} from "../actions";
import { getDataFromRes, cleanSourcesForSelect } from "../utils";
import { createUploader, watchOnProgress } from "./upload";
import { call, put, takeLatest, select, fork } from "redux-saga/effects";

function addNewsApi(payload, onProgress) {
  const { data, id } = payload;
  const params = id ? "/Update" : "";
  return axios.post(`/News${params}`, data, {
    onUploadProgress: onProgress
  });
}

function* addNewsEffect(action) {
  yield put(createNewsLoading());
  const [uploadPromise, chan] = createUploader(
    { data: action.news, id: action.newsId },
    addNewsApi
  );
  yield fork(watchOnProgress, chan, updateNewsUploadProgress);

  try {
    let { data } = yield call(() => uploadPromise);

    if (data.StatusCode === "Success") {
      if (
        !action.sourceId ||
        data.Data.SourceID.toString() === action.sourceId
      ) {
        yield put(addNewsAsync(getDataFromRes(data)));
      } else {
        yield put(stopCreateNewsLoading());
      }
      yield put(hideModal());
      yield put(hideToast());
      yield put(
        showToast(
          getDataFromRes(data).ID,
          (action.pageType = "news"),
          action.news.ID
        )
      );
    } else {
      yield put(newsFailed(data.StatusMessage));
    }
    // redirect to home route after successful login
  } catch (err) {
    // catch error on a bad axios call
    // alert using an alert library
    yield put(newsFailed(err.message));
  }
}

export function* addNewsWatcher() {
  yield takeLatest("ADD_NEWS", addNewsEffect);
}

function fetchNewsApi({ newsId, params, sourceId }) {
  let path = "";
  if (sourceId) {
    path = `/News/NewsSource/${sourceId}`;
  } else {
    path = `/News${newsId ? "/" + newsId : ""}`;
  }

  return axios.get(path, { params });
}

function fetchNewsSourcesApi() {
  return axios.get("/News/Sources");
}

function* fetchNewsEffect(action) {
  yield put(newsLoading(action.params.PagingInfo === ""));
  try {
    if (action.fetchSources) {
      let suffix = yield select(state => state.locale.suffix);
      let response = yield call(fetchNewsSourcesApi);
      if (response.data.StatusCode === "Success")
        yield put(setNewsSources(cleanSourcesForSelect(response, suffix)));
    }
    // data is obtained after axios call is resolved
    let { data } = yield call(fetchNewsApi, action);

    if (data.StatusCode === "Success") {
      yield put(
        fetchNewsAsync(
          getDataFromRes(data),
          data.ListItemCollectionPosition
            ? data.ListItemCollectionPosition.PagingInfo
            : "noMore",
          parseInt(action.sourceId)
        )
      );
    } else {
      yield put(replace("/news"));
    }
    // redirect to home route after successful login
  } catch (e) {
    // catch error on a bad axios call
    // alert using an alert library
  }
}

export function* fetchNewsWatcher() {
  yield takeLatest("FETCH_NEWS", fetchNewsEffect);
}

//delete

function deleteNewsApi(data) {
  return axios.post(`/News/Delete/${data}`, data);
}

function* deleteNewsEffect(action) {
  yield put(createNewsLoading());

  // data is obtained after axios call is resolved
  let { data } = yield call(deleteNewsApi, action.news);

  if (data.StatusCode === "Success") {
    yield put(deleteNewsAsync(getDataFromRes(data)));
    action.history.replace("/news");
  } else {
    yield put(newsFailed(data.StatusMessage));
  }
  // redirect to home route after successful login
}

export function* deleteNewsWatcher() {
  yield takeLatest("DELETE_NEWS", deleteNewsEffect);
}

// Comments

function newsCommentApi(data, onProgress) {
  const path = "/Comments";
  return axios.post(path, data, {
    headers: { "content-type": "multipart/form-data" },
    onUploadProgress: onProgress
  });
}

function* newsCommentEffect(action) {
  yield put(newsCommentLoading());
  const [uploadPromise, chan] = createUploader(action.comment, newsCommentApi);
  yield fork(watchOnProgress, chan, updateNewsUploadProgress);
  try {
    let { data } = yield call(() => uploadPromise);

    if (data.StatusCode === "Success") {
      yield put(newsAddCommentAsync(getDataFromRes(data)));
    } else {
      yield put(newsFailed(data.StatusMessage));
    }
  } catch (e) {}
}

export function* newsCommentWatcher() {
  yield takeLatest("NEWS_ADD_COMMENT", newsCommentEffect);
}
