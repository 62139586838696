import React from "react";

import Avatar from "../../components/Avatar";

import "./style.scss";

const TextLogo = props => {
  return (
    <div className="text-logo-cont">
      <div className="text">{props.text}</div>
      <Avatar img={props.img} />
    </div>
  );
};

export default TextLogo;
