import axios from "axios";
import { calenderAsync, calenderFailed } from "../actions";

import { call, put, takeLatest } from "redux-saga/effects";

function calenderApi() {
  return axios.get("/Statistics/Calendar");
}

function* calenderEffect(action) {
  try {
    let data = yield call(calenderApi, action);

    if (data.data.StatusCode === "Success") {
      yield put(calenderAsync(data.data.Data));
    } else {
      yield put(calenderFailed(data.StatusMessage));
    }
  } catch (e) {
    yield put(calenderFailed(e));
  }
}

export function* calenderWatcher() {
  yield takeLatest("CALENDER", calenderEffect);
}
