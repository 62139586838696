import axios from "axios";
import {
  // projectError,
  // clearProjectErrors,

  stopProjectLoading,
  projectLoading,
  projectDetailsLoading,
  fetchProjectsAsync,
  savePPHandshake,
  fetchProjectDetailsAsync
} from "../actions";
import { checkSuccess, cleanHandshakeData } from "../utils";
import { api } from "../configs";
import { call, put, takeLatest } from "redux-saga/effects";

const config = {
  headers: {
    Authorization: api.ppBasic
  }
};

const instance = axios.create({
  headers: {
    common: {
      Authorization: api.ppBasic
    }
  }
});

function projectHandshakeApi(data, ppBaseUrl) {
  return instance.get(`${ppBaseUrl}${api.ppHandshake}`, config);
}

function getProgramsApi(data, token, ppBaseUrl) {
  return instance.get(`${ppBaseUrl}${api.ppPortfolios}`, null, {
    common: {
      headers: {
        Authorization: api.ppBasic,
        Token: token
      }
    }
  });
}

// function getProjectsApi(data) {
//   const promises = data.map(item => {
//     instance.defaults.headers.common["Token"] = item.token;
//     return instance.get(`${item.url}${api.ppProject}`, null, {
//       common: {
//         headers: {
//           Authorization: api.ppBasic,
//           Token: item.token
//         }
//       }
//     });
//   });
//   return Promise.all(promises);
// }

function* fetchProjectsEffect(action) {
  const { data } = action;
  yield put(projectLoading());

  try {
    let handshakeData = yield call(projectHandshakeApi, data, action.ppBaseUrl);

    if (checkSuccess(handshakeData)) {
      yield put(savePPHandshake(cleanHandshakeData(handshakeData.data.Data)));
      const portfolioData = yield call(
        getProgramsApi,
        data,
        action.token,
        action.ppBaseUrl
      );

      yield put(fetchProjectsAsync(portfolioData.data.Data));
    }
    yield put(stopProjectLoading());
  } catch (e) {
    yield put(stopProjectLoading());
    // do some stuff
  }
}

export function* fetchProjectsWatcher() {
  yield takeLatest("FETCH_PROJECTS", fetchProjectsEffect);
}

//fetch details

function getProjectDetailsApi(projectId, token, ppBaseUrl) {
  // instance.defaults.headers.common["Token"] = token;
  return instance.get(
    `${ppBaseUrl}${api.ppProject}${projectId}/Progress`,
    null,
    {
      common: {
        headers: {
          Authorization: api.ppBasic,
          Token: token
        }
      }
    }
  );
}

function* fetchProjectDetailsEffect(action) {
  const { projectId, token, ppBaseUrl } = action;
  yield put(projectDetailsLoading());
  try {
    const projectsDetailsData = yield call(
      getProjectDetailsApi,
      projectId,
      token,
      ppBaseUrl
    );

    yield put(fetchProjectDetailsAsync(projectsDetailsData.data.Data));
  } catch (e) {
    yield put(stopProjectLoading());
    // do some stuff
  }
}

export function* fetchProjectDetailsWatcher() {
  yield takeLatest("FETCH_PROJECT_DETAILS", fetchProjectDetailsEffect);
}
