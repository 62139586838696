import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import axios from "axios";
import * as yup from "yup";
import { injectIntl, FormattedMessage } from "react-intl";
import Modal from "../Modal";
import { addMOM } from "../../actions";
import FormInput from "../FormInput";
import FormButton from "../FormButton";
import { cleanApiDataForm, getSelectUsers, getUnixDate } from "../../utils";
import { limits } from "../../configs";
import "./style.scss";

class AddMOMModal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      progress: "",
      users: [],
      ActionItems: [],
      initialValues: {
        Decisions: "",
        Discussions: "",
        Attachment: [],
        ActionItems: [
          {
            DescriptionAr: "",
            DueDate: null,
            Responsible: ""
          }
        ]
      }
    };
  }

  componentDidMount() {
    this._isMounted = true;
    getSelectUsers(this.props.ltr).then(users => {
      if (this._isMounted) {
        this.setState({ users });
      }
    });
  }

  addMOMSchema = () => {
    return yup.object().shape({
      Discussions: yup
        .string()
        .required(
          this.props.intl.formatMessage({
            id: "validation.discussionsRequired",
            defaultMessage: "Discussions is required"
          })
        )
        .max(
          limits.description,
          this.props.intl.formatMessage(
            {
              id: "validation.charLimit",
              defaultMessage: "should not exceed {limit} characters"
            },
            { limit: limits.description }
          )
        ),
      Decisions: yup
        .string()
        .required(
          this.props.intl.formatMessage({
            id: "validation.decisionsRequired",
            defaultMessage: "Decisions is required"
          })
        )
        .max(
          limits.description,
          this.props.intl.formatMessage(
            {
              id: "validation.charLimit",
              defaultMessage: "should not exceed {limit} characters"
            },
            { limit: limits.description }
          )
        )
    });
  };

  cutLongString = () => {
    if (this.state.users.length > 0) {
      return this.state.users.map(user => {
        if (user.label.length > 21) {
          return {
            ...user,
            label: `${user.label.substring(0, 18)}...`
          };
        }
        return user;
      });
    }

    return [];
  };

  checkActionItems = ActionItems => {
    const ActionItemsNew = ActionItems.ActionItems;
    const newList = ActionItemsNew.map(e => {
      if (e.DescriptionAr === "" && !e.DueDate && e.Responsible === "") {
        return "false";
      } else if (e.DescriptionAr === "" || !e.DueDate || e.Responsible === "") {
        return "true";
      } else {
        return "false";
      }
    });

    this.setState({ ActionItems: newList });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { hideModal, data, id, errorColor, ltr } = this.props;
    return (
      <Modal isOpen title={"addMOM"} hideModal={hideModal}>
        <Formik
          validate={values => this.checkActionItems(values)}
          initialValues={
            data
              ? { ...this.state.initialValues, ...data }
              : this.state.initialValues
          }
          validationSchema={this.addMOMSchema}
          onSubmit={(values, { setSubmitting }) => {
            const apiData = {
              ...values,
              Attachment: values.Attachment,
              MeetingID: id ? id : null,
              ActionItems: values.ActionItems.filter(
                item =>
                  item.DescriptionAr !== "" &&
                  item.DueDate &&
                  item.Responsible !== ""
              ).map(item => ({
                DueDate: getUnixDate(item.DueDate),
                Responsible: !item.Responsible.IsExternal
                  ? { Username: item.Responsible.value }
                  : null,
                Email: item.Email,
                IsExternal: item.IsExternal,
                DescriptionAr: item.DescriptionAr
              }))
            };
            if (!this.state.ActionItems.includes("true")) {
              this.setState({ loading: true }, () => {
                axios
                  .post("/MOM/", cleanApiDataForm(apiData), {
                    headers: { "content-type": "multipart/form-data" },
                    onUploadProgress: progressEvent => {
                      this.setState({
                        progress: Math.round(
                          (progressEvent.loaded * 100) / progressEvent.total
                        )
                      });
                    }
                  })
                  .then(res => {
                    if (res.data.StatusCode === "Success") {
                      this.props.addMOM(res.data.Data);
                    }
                    this.setState({ loading: false, progress: "" });
                    hideModal();
                  });
              });
            }
          }}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form autoComplete="off">
              <input
                autoComplete="false"
                name="hidden"
                type="text"
                style={{ display: "none" }}
              />
              <FormInput
                type="richText"
                name="Discussions"
                required
                errors={touched["Discussions"] && errors["Discussions"]}
              />
              <FormInput
                type="richText"
                name="Decisions"
                required
                errors={touched["Decisions"] && errors["Decisions"]}
              />
              <FormInput type="upload" name="Attachment" multi />
              <FormInput
                type="actionItems"
                name="ActionItems"
                options={this.cutLongString()}
              />
              {this.state.ActionItems.includes("true") && (
                <div
                  className={
                    ltr ? "error-action-items ltr" : "error-action-items"
                  }
                  style={{ color: errorColor }}
                >
                  <FormattedMessage
                    id="validation.ActionItems"
                    defaultMessage="ActionItems must be filled"
                  />
                </div>
              )}
              <FormButton
                loading={this.state.loading}
                progress={this.state.progress}
              />
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

AddMOMModal.propTypes = {
  intl: PropTypes.object,
  addMOM: PropTypes.func
};

const mapDispatchToProps = { addMOM };

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    errorColor: state.theme.errorColor,
    ltr: state.locale.ltr
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AddMOMModal));
