import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import Button from "../Button";
import { ReactComponent as PlusIcon } from "../../assets/images/plus-icon.svg";
import { ReactComponent as UploadIcon } from "../../assets/images/upload-icon.svg";
import "./style.scss";

const AddButton = props => {
  const { title, className, style, onClick } = props;
  return (
    <Button
      className={`add-button ${className}`}
      style={style}
      onClick={onClick}
    >
      {title === "files" ? (
        <UploadIcon className="add-button-icon" />
      ) : (
        <PlusIcon className="add-button-icon" />
      )}

      <p className="add-button-text">
        <FormattedMessage id={`addButton.${title}`} default={`Add ${title}`} />
      </p>
    </Button>
  );
};

AddButton.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    locale: state.locale
  };
};

export default connect(
  mapStateToProps,
  null
)(AddButton);
