import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { fetchMeetingRequest } from "../../actions";
import MeetingRequestsLoadingCard from "../../components/LoadingCards/MeetingRequestsLoadingCard";
import TimelineMeeting from "../../components/TimelineMeeting";
import EmptyComponent from "../../components/EmptyComponents/EmptyComponent";

class MeetingRequestsPage extends Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, false);
    this.props.fetchMeetingRequest("");
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll, false);
  }

  handleScroll() {
    const offset = 600;
    const { loading, fetchMeetingRequest, PagingInfo } = this.props;
    if (
      !loading &&
      PagingInfo !== "noMore" &&
      window.innerHeight + window.scrollY >= document.body.offsetHeight - offset
    ) {
      fetchMeetingRequest(PagingInfo);
    }
  }

  render() {
    const { loading, requests, intl } = this.props;

    if (requests.length === 0 && !loading) {
      return (
        <EmptyComponent
          title={intl.formatMessage({
            id: "emptyComponent.title.meetingRequests",
            defaultMessage: "meeting request"
          })}
        />
      );
    }
    return (
      <div>
        {requests.map(request => (
          <div key={request.ID} className="card">
            <TimelineMeeting
              post={{ ...request, TimelineType: "meeting-requests" }}
              details
            />
          </div>
        ))}
        {loading ? <MeetingRequestsLoadingCard /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loading: state.meetingRequest.Loading,
    requests: state.meetingRequest.Requests,
    PagingInfo: state.meetingRequest.PagingInfo,
    ActionLoading: state.meetingRequest.ActionLoding,
    intl: state.intl
  };
};

const mapDispatchToProps = { fetchMeetingRequest };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(MeetingRequestsPage));
