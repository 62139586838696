import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Modal from "../Modal";
import { hideModal, addAdhoc, updateAdhoc } from "../../actions";
import FormInput from "../FormInput";
import FormButton from "../FormButton";
import { string, object } from "yup";
import { injectIntl } from "react-intl";
import { limits } from "../../configs";
import {
  cleanApiDataForm,
  cleanHandshakeForSingleSelect,
  cleanHandshakeForSelect
} from "../../utils";

class AddAdhocModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      initialValues: {
        TitleAr: props.data ? props.data.TitleAr : "",
        DescriptionAr: props.data ? props.data.DescriptionAr : "",
        Type: props.data
          ? cleanHandshakeForSingleSelect(
              props.types,
              props.data.Type,
              props.suffix
            )
          : "",
        Attachments: []
      }
    };
  }

  addAdhocSchema = object().shape({
    TitleAr: string()
      .required(
        this.props.intl.formatMessage({
          id: "validation.nameRequired",
          defaultMessage: "Name is required"
        })
      )

      .max(
        limits.title,
        this.props.intl.formatMessage(
          {
            id: "validation.charLimit",
            defaultMessage: "should not exceed {limit} characters"
          },
          { limit: limits.title }
        )
      ),
    DescriptionAr: string()
      .required(
        this.props.intl.formatMessage({
          id: "validation.descriptionRequired",
          defaultMessage: "Details is required"
        })
      )
      .max(
        limits.description,
        this.props.intl.formatMessage(
          {
            id: "validation.charLimit",
            defaultMessage: "should not exceed {limit} characters"
          },
          { limit: limits.description }
        )
      ),

    Type: object().required(
      this.props.intl.formatMessage({
        id: "validation.typeRequired",
        defaultMessage: "Type is required"
      })
    )
  });

  render() {
    const { hideModal, loading, progress, data, types, suffix } = this.props;
    return (
      <Modal
        isOpen
        title={data ? "editAdhoc" : "addAdhoc"}
        hideModal={hideModal}
      >
        <Formik
          validationSchema={this.addAdhocSchema}
          initialValues={this.state.initialValues}
          onSubmit={(values, { setSubmitting }) => {
            const apiData = {
              ...values,
              TitleAr: values.TitleAr,
              TitleEn: values.TitleAr,
              DescriptionAr: values.DescriptionAr,
              DescriptionEn: values.DescriptionAr,
              Type: values.Type.value,
              ID: data ? data.ID : null
            };

            if (apiData.ID) {
              this.props.updateAdhoc(cleanApiDataForm(apiData));
            } else {
              this.props.addAdhoc(cleanApiDataForm(apiData));
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form autoComplete="off">
              <input
                autoComplete="false"
                name="hidden"
                type="text"
                style={{ display: "none" }}
              />
              <FormInput type="text" name="TitleAr" required />
              <FormInput type="textarea" name="DescriptionAr" required />
              <FormInput
                type="singleSelect"
                name="Type"
                options={cleanHandshakeForSelect(types, null, suffix)}
                noCreate
                required
                disabled={!!data}
              />

              <FormInput type="upload" name="Attachments" multi />

              <FormButton loading={loading} edit={!!data} progress={progress} />
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

AddAdhocModal.propTypes = {
  intl: PropTypes.object,
  loading: PropTypes.bool
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loading: state.adhoc.CreateLoading,
    progress: state.adhoc.Progress,
    suffix: state.locale.suffix,
    types: state.theme.Adhoc_Types
  };
};

const mapDispatchToProps = { hideModal, addAdhoc, updateAdhoc };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(AddAdhocModal)));
