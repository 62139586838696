import React, * as react from "react";
import { connect } from "react-redux";
import "./style.scss";
import Circles from "../Circles";
import AsyncImg from "../AsyncImg";
import PropTypes from "prop-types";
import { getDisplayName } from "../../utils";

class UserProfilecard extends react.Component {
  render() {
    const { textGray, user, ltr } = this.props;
    return (
      <div className="profile-card card">
        <div className="content-container">
          <AsyncImg alt="SourceImg" img={user.UserProfilePicture} />
          <p className="text">{getDisplayName(user, ltr)}</p>
          <p className="text" style={{ color: textGray }}>
            {user.Email}
          </p>
          <p className="text" style={{ color: textGray }}>
            {user.PhoneNumber}
          </p>
          <div />
          <Circles big={true} />
        </div>
      </div>
    );
  }
}

UserProfilecard.propTypes = {
  theme: PropTypes.object,
  user: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    textGray: state.theme.textGray,
    user: state.user.UserData,
    ltr: state.locale.ltr
  };
};

export default connect(mapStateToProps, null)(UserProfilecard);
