import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import "./style.scss";

const CalendarEvent = props => {
  const { event, ltr, view, MeetingStatus } = props;

  return (
    <div
      className={
        view === "month" ? "calendar-event month" : "calendar-event notMonth"
      }
    >
      <div className="event-time-container">
        <div
          className={ltr ? "event-circle ltr" : "event-circle "}
          style={{ backgroundColor: MeetingStatus[event.Status].Color }}
        />
        <div className="event-time">
          {view === "month"
            ? moment(event.StartDate * 1000).format("hh:mma")
            : `${moment(event.StartDate * 1000).format("hh:mma")} - ${moment(
                event.EndDate * 1000
              ).format("hh:mma")}`}
        </div>
      </div>
      <div className={ltr ? "event-title ltr" : "event-title"}>
        {event.TitleAr}
      </div>
    </div>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    priorityColors: state.theme.Meeting_Priority,
    ltr: state.locale.ltr,
    MeetingStatus: state.theme.Meeting_Status
  };
};

export default connect(
  mapStateToProps,
  null
)(CalendarEvent);
