import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Tabs from "../Tabs";
import Table from "../Table";
import CommitteesCell from "../CommitteesCell";
import { filterCommittees } from "../../utils";

const tabs = ["all", "committeehead", "committeemember", "secretary"];

class CommitteesList extends Component {
  getTabs() {
    return tabs.map(tab => {
      return { name: tab, count: this.props.counts[tab] };
    });
  }

  selectTab = tab => {
    const { history, match } = this.props;

    if (tab !== match.params.filter) {
      history.push({ pathname: `/committees/${tab}`, state: { tab: true } });
    }
  };

  render() {
    const { match, data, username } = this.props;

    return (
      <div className="committees-card card">
        <Tabs
          tabs={this.getTabs()}
          selectTab={this.selectTab}
          selected={match.params.filter}
          data={data}
        />

        <Table
          data={
            match.params && match.params.filter === "all"
              ? data
              : filterCommittees(
                  match.params.filter,
                  data,
                  username,
                  match.params.filter && match.params.filter.includes("head")
                )
          }
          Component={CommitteesCell}
        />
      </div>
    );
  }
}

CommitteesList.propTypes = {
  match: PropTypes.object,
  data: PropTypes.array,
  username: PropTypes.string
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    username: state.user.UserData.Username
  };
};

export default connect(mapStateToProps, null)(CommitteesList);
