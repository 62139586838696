import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import DeleteButton from "../DeleteButton";
import { removeCommitteeAttachment, removeFileFromFolder } from "../../actions";
import "./style.scss";
import DaysSince from "../DaysSince";
import AttachmentType from "../AttachmentType";
import AsyncImg from "../AsyncImg";

class CommitteesAttachmentItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.removeAttachment = this.removeAttachment.bind(this);
  }

  ImagesType = ["png", "jpg", "gif", "jpeg", "svg"];
  removeAttachment = () => {
    const {
      attachment,
      removeCommitteeAttachment,
      removeFileFromFolder
    } = this.props;
    this.setState({ loading: true }, () => {
      axios
        .post(`/Committee/DeleteAttachment/${attachment.AttachmentID}`)
        .then(res => {
          if (res.data.StatusCode === "Success") {
            if (attachment.FolderID) {
              removeFileFromFolder(
                attachment.AttachmentID,
                attachment.FolderID
              );
            } else {
              removeCommitteeAttachment(attachment.AttachmentID);
            }
          }
          this.setState({ loading: false });
        });
    });
  };

  openFile(file) {
    window.open(file);
  }

  render() {
    const {
      attachment,
      ltr,
      theme,
      //  committee,
      //  currentUser,
      accentColor
    } = this.props;
    const { loading } = this.state;

    return (
      <div
        className={
          ltr ? "committee-attachment-item ltr" : "committee-attachment-item"
        }
      >
        <div className="photo-container" style={{ borderColor: accentColor }}>
          {this.ImagesType.includes(attachment.AttachmentType) ? (
            <AsyncImg
              alt="sourceimage"
              img={attachment.AttachmentURL}
              style={{ borderColor: accentColor }}
            />
          ) : (
            <div className="files-img">
              <AttachmentType
                type={
                  attachment.attachments ? "folder" : attachment.AttachmentType
                }
                accentColor={accentColor}
              />
            </div>
          )}
        </div>
        <div
          className="file-text-container"
          style={{ borderColor: accentColor }}
        >
          <div className="text-files">
            <p
              className="file-name"
              onClick={() => this.openFile(attachment.AttachmentURL)}
            >
              {attachment.AttachmentName}
            </p>
            <div className="second-text">
              <DaysSince
                style={{ color: accentColor }}
                startDate={attachment.CreatedDate}
              />
            </div>
          </div>
          {theme.allowDeleteFile ? (
            <DeleteButton
              deleteAction={this.removeAttachment}
              loading={loading}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    committee: state.committee.Committee,
    currentUser: state.user.UserData.Username,
    accentColor: state.theme.accentColor,
    theme: state.theme
  };
};

const mapDispatchToProps = { removeCommitteeAttachment, removeFileFromFolder };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommitteesAttachmentItem);
