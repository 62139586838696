import React from "react";
import PropTypes from "prop-types";

import "./style.scss";

const AdhocStatus = props => {
  const cutString = name => {
    return name.length > 30 ? `${name.substring(0, 27)}...` : name;
  };

  const { name, color } = props;

  return (
    <div className="meeting-request-status">
      <div className="confirmed-btn" style={{ color: color }}>
        <p>{cutString(name)}</p>
      </div>
    </div>
  );
};

AdhocStatus.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string
};

export default AdhocStatus;
