import React, * as react from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { ReactComponent as NewsIcon } from "../../assets/images/nav/news-icon.svg";
import { ReactComponent as TasksIcon } from "../../assets/images/nav/tasks-icon.svg";
import { ReactComponent as MeetingsIcon } from "../../assets/images/nav/meetings-icon.svg";
// import { ReactComponent as ProjectsIcon } from "../../assets/images/nav/projects-icon.svg";
import { ReactComponent as CommitteesIcon } from "../../assets/images/nav/committees-icon.svg";
import { ReactComponent as TimelineIcon } from "../../assets/images/nav/timeline-icon.svg";
// import { ReactComponent as DBIcon } from "../../assets/images/nav/signal-icon.svg";

import SideNavItem from "../SideNavItem";

import { extractPathName } from "../../utils";
import "./side-nav.scss";

const routes = [
  { path: "/timeline", name: "timeline", icon: TimelineIcon },
  { path: "/tasks", name: "tasks", icon: TasksIcon },
  { path: "/news", name: "news", icon: NewsIcon },
  // { path: "/projects", name: "projects", icon: ProjectsIcon },
  { path: "/committees/all", name: "committees", icon: CommitteesIcon },
  { path: "/meetings", name: "meetings", icon: MeetingsIcon },
  { path: "/users", name: "users", icon: CommitteesIcon }
  // { path: "/dashboard", name: "dashboard", icon: DBIcon }
];

class SideNav extends react.Component {
  render() {
    const { theme, intl, locale, history, current } = this.props;
    const pathName = extractPathName(current);
    const url =
      history.location.pathname === "/meeting-requests"
        ? "meetings"
        : history.location.pathname.split("/")[1];

    return (
      <nav className="side-nav-container">
        <div
          className={`side-nav-title ${locale.ltr ? "ltr" : ""}`}
          style={{ color: theme.textGray }}
        />
        <div className="nav-items-container">
          {routes.map((item, index) => (
            <SideNavItem
              text={item.name}
              path={item.path}
              Icon={item.icon}
              intl={intl}
              color={theme.textColor}
              selectedColor={theme.primary}
              badgeColor={theme.primary}
              key={index}
              onClick={this._handleSelect}
              selected={item.name.includes(url) || item.name.includes(pathName)}
              index={index}
              ltr={locale.ltr}
              count={item.count}
              SideNav={true}
              theme={theme}
              history={history}
            />
          ))}
        </div>
      </nav>
    );
  }
}

SideNav.propTypes = {
  intl: PropTypes.object,
  theme: PropTypes.object,
  locale: PropTypes.object,
  history: PropTypes.object,
  current: PropTypes.string
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ...state
  };
};

export default connect(mapStateToProps, null)(injectIntl(withRouter(SideNav)));
