import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { string, object, date, array } from "yup";
import Modal from "../Modal";
import { hideModal, addTask } from "../../actions";
import "./style.scss";
import FormInput from "../FormInput";
import PriorityIndicator from "../PriorityIndicator";
import FormButton from "../FormButton";
import {
  getUsername,
  getUnixDate,
  setSelectMultiOptions,
  setSelectSingleOptions,
  getSelectUsers,
  sortUsers,
  cleanLookupsForSelect,
  cleanObjectOptionsForSelect
} from "../../utils";
import { limits } from "../../configs";

const priorities = [
  { value: "1", label: "low", component: PriorityIndicator },
  { value: "2", label: "medium", component: PriorityIndicator },
  { value: "3", label: "high", component: PriorityIndicator }
];

class AddTaskModal extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    const sectorItem =
      props.data &&
      props.data.SectorId &&
      props.Sectors.find(item => item.ID === props.data.SectorId);

    const entityItem =
      props.data &&
      props.data.EntityId &&
      props.Entities.find(item => item.ID === props.data.EntityId);

    const departmentItem =
      props.data &&
      props.data.DepartmentId &&
      props.Departments.find(item => item.ID === props.data.DepartmentId);

    const tasksItem =
      props.data && props.data.TaskType && props.TaskType[props.data.TaskType];

    this.state = {
      users: [],
      Sector: props.data ? cleanLookupsForSelect(sectorItem) : [],
      Entity: props.data ? cleanLookupsForSelect(entityItem) : [],
      sectors: cleanLookupsForSelect(props.Sectors),
      entities: [],
      departments: [],
      initialValues: {
        NameAr: props.data ? props.data.NameAr : "",
        DescriptionAr: props.data ? props.data.DescriptionAr : "",
        DueDate: props.data ? new Date(props.data.DueDate * 1000) : null,
        Responsible:
          props.data && props.data.Responsible
            ? setSelectSingleOptions("Responsible", props.data)
            : [],

        Accountable:
          props.data && props.data.Accountable
            ? setSelectSingleOptions("Accountable", props.data)
            : [],
        Consulted:
          props.data && props.data.Consulted
            ? setSelectMultiOptions(props.data.Consulted)
            : [],
        Informed:
          props.data && props.data.Informed
            ? setSelectMultiOptions(props.data.Informed)
            : [],
        Attachments: [],
        MainImage: null,
        Priority: props.data ? props.data.Priority : "1",
        Sector: props.data ? cleanLookupsForSelect(sectorItem) : "",
        Entity: props.data ? cleanLookupsForSelect(entityItem) : "",
        Department: props.data ? cleanLookupsForSelect(departmentItem) : "",
        TaskType:
          props.data && props.data.TaskType
            ? {
                value: tasksItem.Value,
                label: tasksItem[`Title${props.suffix}`],
                ...tasksItem
              }
            : ""
      }
    };
    this.ref = React.createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    getSelectUsers(this.props.locale.ltr).then(users => {
      if (this._isMounted) {
        this.setState({ users: sortUsers(users) });
      }
    });
  }

  addTaskSchema = () => {
    const theDate = new Date(new Date().setDate(new Date().getDate() - 1));
    return object().shape({
      NameAr: string()
        .required(
          this.props.intl.formatMessage({
            id: "validation.nameRequired",
            defaultMessage: "Name is required"
          })
        )
        .max(
          limits.title,
          this.props.intl.formatMessage(
            {
              id: "validation.charLimit",
              defaultMessage: "should not exceed {limit} characters"
            },
            { limit: limits.title }
          )
        ),
      DescriptionAr: string().max(
        limits.description,
        this.props.intl.formatMessage(
          {
            id: "validation.charLimit",
            defaultMessage: "should not exceed {limit} characters"
          },
          { limit: limits.description }
        )
      ),
      DueDate: date()
        .nullable()
        .min(
          theDate,
          this.props.intl.formatMessage({
            id: "validation.dueDateInFuture",
            defaultMessage: "Due date can't be older than today"
          })
        )
        .required(
          this.props.intl.formatMessage({
            id: "validation.dateRequired",
            defaultMessage: "date is required"
          })
        ),
      Responsible: array()
        .of(
          object().shape({
            Username: string()
          })
        )
        .max(1)
        .required(
          this.props.intl.formatMessage({
            id: "validation.responsible",
            defaultMessage: "Responsible is required"
          })
        ),

      Accountable: array()
        .of(
          object().shape({
            Username: string()
          })
        )
        .max(1)
        .required(
          this.props.intl.formatMessage({
            id: "validation.accountable",
            defaultMessage: "Accountable is required"
          })
        ),
      Consulted: array().of(
        object().shape({
          Username: string()
        })
      ),
      Informed: array().of(
        object().shape({
          Username: string()
        })
      ),
      Priority: string().required(
        this.props.intl.formatMessage({
          id: "validation.priority",
          defaultMessage: "priority is required"
        })
      ),

      Sector: object().required(
        this.props.intl.formatMessage({
          id: "validation.SourceID",
          defaultMessage: "Source news is required"
        })
      ),

      Entity: object().required(
        this.props.intl.formatMessage({
          id: "validation.SourceID",
          defaultMessage: "Source news is required"
        })
      ),

      Department: object().required(
        this.props.intl.formatMessage({
          id: "validation.SourceID",
          defaultMessage: "Source news is required"
        })
      ),

      TaskType: object().required()
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      hideModal,
      data,
      task,
      Entities,
      Departments,
      TaskType,
      suffix
    } = this.props;
    const { users } = this.state;
    return (
      <Modal isOpen title={data ? "editTask" : "addTask"} hideModal={hideModal}>
        <Formik
          ref={this.ref}
          initialValues={this.state.initialValues}
          validationSchema={this.addTaskSchema}
          onSubmit={(values, { setSubmitting }) => {
            const ID = data ? data.ID : null;
            const apiData = {
              ...values,
              Responsible: getUsername(values.Responsible, true),

              Accountable: getUsername(values.Accountable, true),
              Consulted: getUsername(values.Consulted),
              Informed: getUsername(values.Informed),
              DueDate: getUnixDate(values.DueDate),
              ID,

              Creator: data && data.Creator,
              currentUser: this.props.currentUser,

              DepartmentId: values.Department.value,
              SectorId: values.Sector.value,
              EntityId: values.Entity.value,
              TaskType: values.TaskType.value
            };

            this.props.addTask(apiData, ID);
          }}
          validate={({ Sector, Entity }) => {
            if (Sector.value !== this.state.Sector.value) {
              this.ref.current.resetForm({
                ...this.ref.current.state.values,
                Entity: "",
                Department: ""
              });
            }

            if (Entity.value !== this.state.Entity.value) {
              this.ref.current.resetForm({
                ...this.ref.current.state.values,
                Department: ""
              });
            }
            const filterdEntitiesData = Entities.filter(item => {
              return item.ParentID === Sector.ID;
            });
            const filterdDepartmentsData = Departments.filter(item => {
              return item.ParentID === Entity.ID;
            });

            this.setState({
              entities: cleanLookupsForSelect(filterdEntitiesData),
              departments: cleanLookupsForSelect(filterdDepartmentsData),
              Entity,
              Sector
            });
          }}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form autoComplete="off">
              <input
                autoComplete="false"
                name="hidden"
                type="text"
                style={{ display: "none" }}
              />

              <FormInput
                type="text"
                name="NameAr"
                required
                errors={touched["NameAr"] && errors["NameAr"]}
              />
              <FormInput type="textarea" name="DescriptionAr" />

              <FormInput
                required
                name="Sector"
                type="singleSelect"
                options={this.state.sectors}
                errors={touched["Sector"] && errors["Sector"]}
                noCreate
              />

              <FormInput
                required
                name="Entity"
                type="singleSelect"
                options={this.state.entities}
                stopSelectLoading
                errors={touched["Entity"] && errors["Entity"]}
                noCreate
              />

              <FormInput
                required
                name="Department"
                type="singleSelect"
                options={this.state.departments}
                stopSelectLoading
                errors={touched["Department"] && errors["Department"]}
                noCreate
              />

              <FormInput
                required
                name="TaskType"
                type="singleSelect"
                options={cleanObjectOptionsForSelect(TaskType, suffix)}
                stopSelectLoading
                errors={touched["TaskType"] && errors["TaskType"]}
                noCreate
              />
              <FormInput
                name="DueDate"
                type="dateTime"
                required
                minDate={new Date()}
                errors={touched["DueDate"] && errors["DueDate"]}
              />
              <div className="form-two-section">
                <div className="first-section">
                  <FormInput
                    name="Responsible"
                    options={users}
                    type="select"
                    limit={1}
                    required
                    errors={touched["Responsible"] && errors["Responsible"]}
                  />
                </div>
                <div className="second-section">
                  <FormInput
                    name="Accountable"
                    type="select"
                    options={users}
                    limit={1}
                    required
                    errors={touched["Accountable"] && errors["Accountable"]}
                  />
                </div>
              </div>
              <FormInput name="Informed" type="select" options={users} top />
              <FormInput name="Consulted" type="select" options={users} top />
              <FormInput
                name="Priority"
                type="radio"
                options={priorities}
                required
              />

              <FormInput name="Attachments" type="upload" multi headerImage />

              <FormButton
                loading={task.CreateLoading}
                edit={!!data}
                progress={task.Progress}
              />
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }
}

AddTaskModal.propTypes = {
  intl: PropTypes.object,
  task: PropTypes.object,
  hideModal: PropTypes.func,
  data: PropTypes.object,
  user: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    task: state.task,
    user: state.user,
    theme: state.theme,
    locale: state.locale,
    currentUser: state.user.UserData.Username,
    Sectors: state.theme.lookups.Sectors,
    Entities: state.theme.lookups.Entities,
    Departments: state.theme.lookups.Departments,
    TaskType: state.theme.Task_Type,
    suffix: state.locale.suffix
  };
};

const mapDispatchToProps = { hideModal, addTask };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(AddTaskModal));
