import React from "react";
import CommitteeDetails from "../../components/CommitteeDetails";

const CommitteeDetailsPage = props => {
  return (
    <div>
      <CommitteeDetails />
    </div>
  );
};

export default CommitteeDetailsPage;
