import React from "react";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import "./style.scss";

const CommitteesLoadingCard = props => {
  const { ltr } = props;
  return (
    <ContentLoader
      className="card loading-card"
      rtl={!ltr}
      width={400}
      height={100}
    >
      <rect x="10" y="10" rx="3" ry="3" width="30" height="6" />
      <rect x="60" y="10" rx="3" ry="3" width="30" height="6" />
      <rect x="110" y="10" rx="3" ry="3" width="30" height="6" />
      <rect x="160" y="10" rx="3" ry="3" width="30" height="6" />
      <rect x="210" y="10" rx="3" ry="3" width="30" height="6" />

      <rect x="0" y="26" rx="3" ry="3" width="400" height="1" />

      <rect x="200" y="27" width="1" height="73" />

      <rect x="0" y="62.5" width="200" height="1" />

      <circle cx="20" cy="45" r="10" />
      <rect x="40" y="37" rx="3" ry="3" width="100" height="6" />
      <rect x="40" y="48" rx="3" ry="3" width="50" height="6" />

      <circle cx="20" cy="81" r="10" />
      <rect x="40" y="73" rx="3" ry="3" width="100" height="6" />
      <rect x="40" y="84" rx="3" ry="3" width="50" height="6" />

      <circle cx="220" cy="45" r="10" />
      <rect x="240" y="37" rx="3" ry="3" width="100" height="6" />
      <rect x="240" y="48" rx="3" ry="3" width="50" height="6" />

      <circle cx="220" cy="81" r="10" />
      <rect x="240" y="73" rx="3" ry="3" width="100" height="6" />
      <rect x="240" y="84" rx="3" ry="3" width="50" height="6" />

      <rect x="200" y="62.5" width="200" height="1" />
    </ContentLoader>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ltr: state.locale.ltr
  };
};

export default connect(
  mapStateToProps,
  null
)(CommitteesLoadingCard);
