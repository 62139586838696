import { css } from "emotion";

export const DatePickerStyle = theme => {
  return css`
    .react-datepicker__time-container:hover
    .react-datepicker__time:hover
    .react-datepicker__time-box:hover ul.react-datepicker__time-list:hover
    li.react-datepicker__time-list-item--selected:hover {
    background-color: ${theme.primary};

  }

  

    .react-datepicker__time-container .react-datepicker__time
    .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {

        background-color: ${theme.primaryLight};
    }
    .react-datepicker__time-container .react-datepicker__time
    .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: ${theme.primary};

  }

      .react-datepicker__day--keyboard-selected,
      .react-datepicker__month-text--keyboard-selected {
        background-color: ${theme.primary};
      }
      .react-datepicker__day--selected,
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--selected,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__month-text--in-range {
        background-color: ${theme.primary};
        font-weight: bold;
      }
      .react-datepicker__day:hover,
      .react-datepicker__month-text:hover {
        background-color: ${theme.primaryLight};
      }
      .react-datepicker__day--keyboard-selected:hover,
      .react-datepicker__month-text--keyboard-selected:hover {
        background-color: ${theme.primary};
      }
      .react-datepicker__day--selected:hover,
      .react-datepicker__day--in-selecting-range:hover,
      .react-datepicker__day--in-range:hover,
      .react-datepicker__month-text--selected:hover,
      .react-datepicker__month-text--in-selecting-range:hover,
      .react-datepicker__month-text--in-range:hover {
        background-color: ${theme.primary};
        font-weight: bold;


    `;
};

export const DatePickerContainerStyle = locale => {
  return css`
    .react-datepicker__close-icon {
      left: ${locale === "ar" ? "0" : "unset"};
      right: ${locale === "ar" ? "unset" : "0"};
      padding: 6px;
    }
  `;
};
