import React, * as react from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import queryString from "query-string";
import FormInput from "../FormInput";
import { FormattedMessage } from "react-intl";
import { cleanHandshakeForSelect } from "../../utils";

import "./style.scss";

class OptionList extends react.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    const statusFilter = queryString.parse(props.location.search);
    const statuses = cleanHandshakeForSelect(
      props.theme.News_Status,
      null,
      props.locale.suffix
    );
    this.state = {
      statuses,
      sources:
        props.news.newsSources && props.news.newsSources.length > 0
          ? props.news.newsSources
          : [],
      initialValues: {
        source:
          props.match.params.filter &&
          props.news.newsSources &&
          props.news.newsSources.length > 0
            ? this.setValue()
            : "",
        status:
          statusFilter && statusFilter.status
            ? this.setStatus(statusFilter.status, statuses)
            : "",
        publishedDate:
          statusFilter && statusFilter.publishedDate
            ? new Date(Number(statusFilter.publishedDate))
            : ""
      }
    };
    this.form = React.createRef();
  }

  setValue = () => {
    const value = this.props.news.newsSources.filter(source => {
      return source.value.toString() === this.props.match.params.filter;
    });

    if (value.length > 0) {
      return {
        value: value[0].value,
        label: value[0].label,
        img: value[0].img
      };
    }
    return "";
  };

  setStatus = (status, statuses) => {
    return statuses.find(item => item.value === status);
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.news.newsSources.length < 1 &&
      this.props.news.newsSources.length > 0 &&
      this.props.match.params.filter
    ) {
      this.form.current.setValues(this.setValue());
    }
  }

  setFilters = values => {
    const { history } = this.props;

    history.push({
      pathname: !!values.source
        ? `/news/source/${values.source.value}`
        : "/news",
      search:
        !!values.status || !!values.publishedDate
          ? queryString.stringify({
              status: values.status ? parseInt(values.status.value) : "",
              publishedDate:
                values.publishedDate && new Date(values.publishedDate).getTime()
            })
          : null
    });
  };

  render() {
    const { locale, theme, style, closeNewsFilterMenu, news } = this.props;

    return (
      <div
        className={
          locale.ltr
            ? "options-list-component-news-filter ltr"
            : "options-list-component-news-filter"
        }
        style={style}
      >
        <div className="clickable-ulcontainer">
          <ul>
            <li className="titles" style={{ borderColor: theme.accentColor }}>
              <span style={{ color: theme.textGray }}>
                <FormattedMessage
                  id="dropdown.news-filter-title"
                  defaultMessage="Filter by"
                />
              </span>
              <span className="close-filter" onClick={closeNewsFilterMenu}>
                <FormattedMessage
                  id="dropdown.close-news-filter"
                  defaultMessage="Close filter"
                />
              </span>
            </li>
            <div>
              <Formik
                initialValues={this.state.initialValues}
                ref={this.form}
                validate={values => this.setFilters(values)}
              >
                {({ isSubmitting, values }) => (
                  <>
                    <Form>
                      <p className="title">
                        <FormattedMessage
                          id="dropdown.news-source-title"
                          defaultMessage="Source"
                        />
                      </p>
                      <FormInput
                        type="singleSelect"
                        name="source"
                        options={news.newsSources}
                        withoutLabel
                        taskFilter
                        clearable
                        noCreate
                      />
                      <p className="title">
                        <FormattedMessage
                          id="dropdown.news-status"
                          defaultMessage="News Status"
                        />
                      </p>
                      <FormInput
                        type="singleSelect"
                        name="status"
                        options={this.state.statuses}
                        withoutLabel
                        taskFilter
                        clearable
                        noCreate
                      />

                      <p className="title">
                        <FormattedMessage
                          id="label.publishedDate"
                          defaultMessage="Published Date"
                        />
                      </p>
                      <div className="date-filter">
                        <FormInput
                          type="dateTime"
                          name="publishedDate"
                          withoutLabel
                          isClearableDate
                        />
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </ul>
        </div>
      </div>
    );
  }
}
OptionList.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
  theme: PropTypes.object,
  style: PropTypes.object,
  className: PropTypes.string,
  clickable: PropTypes.bool
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    locale: state.locale,
    theme: state.theme,
    news: state.news
  };
};

export default connect(
  mapStateToProps,
  null
)(injectIntl(withRouter(OptionList)));
