import React from "react";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";

const MeetingsLoadingCard = props => {
  const { ltr } = props;
  return (
    <ContentLoader
      className="card loading-card"
      rtl={!ltr}
      width={400}
      height={300}
    >
      <rect x="175" y="10" rx="3" ry="3" width="50" height="6" />

      <rect x="10" y="30" width="380" height="1" />
      <rect x="10" y="60" width="380" height="1" />

      <rect x="10" y="110" width="380" height="1" />
      <rect x="10" y="160" width="380" height="1" />
      <rect x="10" y="210" width="380" height="1" />
      <rect x="10" y="260" width="380" height="1" />

      <rect x="10" y="110" width="380" height="1" />

      <rect x="10" y="30" width="1" height="231" />
      <rect x="390" y="30" width="1" height="231" />

      <rect x="86" y="60" width="1" height="200" />
      <rect x="162" y="60" width="1" height="200" />
      <rect x="238" y="60" width="1" height="200" />
      <rect x="314" y="60" width="1" height="200" />

      <rect x="96" y="70" rx="3" ry="3" width="40" height="6" />
      <rect x="96" y="85" rx="3" ry="3" width="20" height="6" />

      <rect x="248" y="220" rx="3" ry="3" width="40" height="6" />

      <rect x="38" y="42" rx="3" ry="3" width="20" height="6" />
      <rect x="114" y="42" rx="3" ry="3" width="20" height="6" />
      <rect x="190" y="42" rx="3" ry="3" width="20" height="6" />
      <rect x="190" y="42" rx="3" ry="3" width="20" height="6" />
      <rect x="266" y="42" rx="3" ry="3" width="20" height="6" />
      <rect x="342" y="42" rx="3" ry="3" width="20" height="6" />

      <circle cx="175.5" cy="120" r="5" />
    </ContentLoader>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ltr: state.locale.ltr
  };
};

export default connect(
  mapStateToProps,
  null
)(MeetingsLoadingCard);
