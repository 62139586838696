import axios from "axios";
import { replace } from "connected-react-router";
import {
  addAdhocAsync,
  adhocFailed,
  adhocLoading,
  createAdhocLoading,
  hideModal,
  fetchAdhocAsync,
  // fetchAdhoc,
  adhocActionLoading,
  adhocAddCommentAsync,
  adhocCommentLoading,
  stopAdhocActionLoading,
  updateAdhocAsync,
  showToast,
  hideToast,
  updateAdhocUploadProgress
} from "../actions";
import { getDataFromRes } from "../utils";
import { createUploader, watchOnProgress } from "./upload";
import { call, put, takeLatest, fork } from "redux-saga/effects";

// Create

function addAdhocApi(data, onProgress) {
  return axios.post("/Adhoc", data, {
    onUploadProgress: onProgress
  });
}

function* addAdhocEffect(action) {
  yield put(createAdhocLoading());
  const [uploadPromise, chan] = createUploader(action.adhoc, addAdhocApi);
  yield fork(watchOnProgress, chan, updateAdhocUploadProgress);

  try {
    let { data } = yield call(() => uploadPromise);

    if (data.StatusCode === "Success") {
      yield put(addAdhocAsync(getDataFromRes(data)));
      yield put(hideModal());
      yield put(hideToast());
      yield put(
        showToast(
          getDataFromRes(data).ID,
          (action.pageType = "adhoc")
          // action.adhoc.ID
        )
      );
    } else {
      yield put(adhocFailed(data.StatusMessage));
    }
  } catch (err) {
    yield put(adhocFailed(err.message));
  }
}

export function* addAdhocWatcher() {
  yield takeLatest("ADD_ADHOC", addAdhocEffect);
}

// Update

function updateAdhocApi(data, onProgress) {
  return axios.post("/Adhoc/Update", data, {
    onUploadProgress: onProgress
  });
}

function* updateAdhocEffect(action) {
  yield put(createAdhocLoading());
  const [uploadPromise, chan] = createUploader(action.adhoc, updateAdhocApi);
  yield fork(watchOnProgress, chan, updateAdhocUploadProgress);

  try {
    let { data } = yield call(() => uploadPromise);

    if (data.StatusCode === "Success") {
      yield put(updateAdhocAsync(getDataFromRes(data)));
      yield put(hideModal());
    } else {
      yield put(adhocFailed(data.StatusMessage));
    }
  } catch (e) {}
}

export function* updateAdhocWatcher() {
  yield takeLatest("UPDATE_ADHOC", updateAdhocEffect);
}

// Fetch

function fetchAdhocApi({ PagingInfo, id, kind }) {
  let url = "";
  switch (kind) {
    case "history":
      url = "/Adhoc/MyAdhocHistory";
      break;
    case "member":
      url = "/Adhoc/MyAdhocTasks";
      break;
    case "details":
      url = `/Adhoc/${id}`;
      break;
    default:
      url = "/Adhoc/MyAdhocRequests";
      break;
  }
  // const path = `/Adhoc/${data ? data : ""}`;
  return axios.get(url, { params: { PagingInfo } });
}

function* fetchAdhocEffect(action) {
  yield put(adhocLoading(action.PagingInfo === ""));
  try {
    let { data } = yield call(fetchAdhocApi, action);

    if (data.StatusCode === "Success") {
      yield put(
        fetchAdhocAsync(
          getDataFromRes(data),
          data.ListItemCollectionPosition
            ? data.ListItemCollectionPosition.PagingInfo
            : "noMore"
        )
      );
    } else {
      yield put(adhocFailed(data.StatusMessage));
    }
  } catch (e) {}
}

export function* fetchAdhocWatcher() {
  yield takeLatest("FETCH_ADHOC", fetchAdhocEffect);
}

// Actions

function adhocActionApi(data) {
  const path = "/Adhoc/Response/";
  return axios.post(path, data);
}

function* adhocActionEffect(action) {
  yield put(adhocActionLoading());
  try {
    let { data } = yield call(adhocActionApi, action.data);

    if (data.StatusCode === "Success") {
      yield put(stopAdhocActionLoading());
      yield put(replace("/adhoc"));
      // yield put(fetchAdhoc());
    } else {
      yield put(adhocFailed(data.StatusMessage));
    }
  } catch (e) {}
}

export function* adhocActionWatcher() {
  yield takeLatest("ADHOC_ACTION", adhocActionEffect);
}

// Comments

function adhocCommentApi(data, onProgress) {
  const path = `/Adhoc/AddComment`;
  return axios.post(path, data, {
    onUploadProgress: onProgress
  });
}

function* adhocCommentEffect(action) {
  yield put(adhocCommentLoading());
  const [uploadPromise, chan] = createUploader(action.comment, adhocCommentApi);
  yield fork(watchOnProgress, chan, updateAdhocUploadProgress);

  try {
    let { data } = yield call(() => uploadPromise);

    if (data.StatusCode === "Success") {
      yield put(adhocAddCommentAsync(getDataFromRes(data)));
    } else {
      yield put(adhocFailed(data.StatusMessage));
    }
  } catch (e) {}
}

export function* adhocCommentWatcher() {
  yield takeLatest("ADHOC_ADD_COMMENT", adhocCommentEffect);
}
