import React, * as react from "react";
import { connect } from "react-redux";
import "./style.scss";
import { fetchUsers } from "../../actions";
import PropTypes from "prop-types";
import { getDisplayName } from "../../utils";
import UserProfileLoadingCard from "../../components/LoadingCards/UserProfileLoadingCard";
import AsyncImg from "../../components/AsyncImg";

class UserProfilePage extends react.Component {
  componentDidMount() {
    this.props.fetchUsers(this.props.match.params.userName);
  }

  render() {
    const { textGray, users } = this.props;

    if (users.Loading || !users.User) return <UserProfileLoadingCard />;
    return (
      <div className="profile-card card">
        <div className="content-container">
          <AsyncImg alt="SourceImg" img={users.User.UserProfilePicture} />

          <p className="text" style={{ color: textGray }}>
            {getDisplayName(users.User, this.props.ltr)}
          </p>
          {users.User.Email && (
            <a href={`mailto:${users.User.Email}`} className="text">
              {users.User.Email}
            </a>
          )}
          {users.User.PhoneNumber && (
            <p className="text" style={{ color: textGray }}>
              {users.User.PhoneNumber}
            </p>
          )}

          {users.User.Department && (
            <p className="text" style={{ color: textGray }}>
              {users.User.Department}
            </p>
          )}

          <div />
        </div>
      </div>
    );
  }
}

UserProfilePage.propTypes = {
  theme: PropTypes.object,
  user: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ...state,
    textGray: state.theme.textGray,

    ltr: state.locale.ltr
  };
};

const mapDispatchToProps = {
  fetchUsers
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfilePage);
