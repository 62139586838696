import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import Button from "../Button";
import "./style.scss";

const FormButton = props => {
  const { ltr, edit, publish } = props;
  return (
    <div className="form-button-container">
      <Button
        className={ltr ? "form-button ltr" : "form-button"}
        type="submit"
        {...props}
      >
        {publish ? (
          <FormattedMessage
            id={edit ? "button.publish" : "formButton.add"}
            defaultMessage="Add"
          />
        ) : (
          <FormattedMessage
            id={edit ? "button.edit" : "formButton.add"}
            defaultMessage="Add"
          />
        )}
      </Button>
    </div>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ltr: state.locale.ltr
  };
};

export default connect(
  mapStateToProps,
  null
)(FormButton);
