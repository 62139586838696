import React from "react";
import { connect } from "react-redux";
import { ReactComponent as ApprovedIcon } from "../../assets/images/approved-check.svg";
import { ReactComponent as PendingIcon } from "../../assets/images/pending-clock.svg";
import { ReactComponent as RejectedIcon } from "../../assets/images/rejected-cross.svg";
import "./style.scss";

const StepIcon = props => {
  const { step, currentStep, statuses } = props;

  const defaultIcon = () => {
    return (
      <div
        className="workflow-circle-step"
        style={{
          color: props.theme.accentColor
        }}
      >
        {step.Order}
      </div>
    );
  };

  const getIcon = () => {
    switch (step.status) {
      case "Pending":
        if (step.AssignedTo === currentStep) {
          return (
            <div
              className="step-icon-container"
              style={{
                backgroundColor: statuses[step.status].Color,
                boxShadow: `0px 0px 15px 0px ${statuses[step.status].Color}80`
              }}
            >
              <PendingIcon className="step-icon" />
            </div>
          );
        }
        return defaultIcon();
      case "Approved":
        return (
          <div
            className="step-icon-container"
            style={{
              backgroundColor: statuses[step.status].Color,
              boxShadow: `0px 0px 15px 0px ${statuses[step.status].Color}80`
            }}
          >
            <ApprovedIcon
              className="step-icon"
              style={{ height: 15, width: 15 }}
            />
          </div>
        );
      case "Rejected":
      case "SendBack":
        return (
          <div
            className="step-icon-container"
            style={{
              backgroundColor: statuses[step.status].Color,
              boxShadow: `0px 0px 15px 0px ${statuses[step.status].Color}80`
            }}
          >
            <RejectedIcon
              className="step-icon"
              style={{ height: 13, width: 13 }}
            />
          </div>
        );
      default:
        return defaultIcon();
    }
  };

  return (
    <div
      className="workflow-circle-step-container"
      style={{
        borderColor:
          step.status === "Pending" && step.AssignedTo !== currentStep
            ? props.theme.accentColor
            : "transparent",
        color: props.theme.accentColor
      }}
    >
      {getIcon()}
    </div>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    suffix: state.locale.suffix,
    statuses: state.theme.Adhoc_Status
  };
};

export default connect(
  mapStateToProps,
  null
)(StepIcon);
