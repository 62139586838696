import React, { Component } from "react";
import UserProfileCard from "../../components/UserProfileCard";

class UserProfile extends Component {
  render() {
    return (
      <div>
        <UserProfileCard />
      </div>
    );
  }
}

export default UserProfile;
