import React, * as react from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import MeetingsCell from "../MeetingsCell";
import { withRouter } from "react-router";
import EmptyComponent from "../../components/EmptyComponents/EmptyComponent";
import "./style.scss";

class UpcomingMeeting extends react.Component {
  state = {
    position: false
  };
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll, false);
  }

  handleScroll = () => {
    if (window.innerHeight + window.scrollY >= 1600) {
      this.setState({ position: true });
    } else {
      this.setState({ position: false });
    }
  };
  render() {
    const { theme, upcomingMeetings, loading, locale, intl } = this.props;
    if (loading) return null;

    return (
      <div
        className={`upcoming-meeting card hidden ${this.state.position &&
          "position"}`}
      >
        <div
          className="header-card"
          style={{
            backgroundColor: theme.primaryLight,
            borderColor: theme.accentColor
          }}
        >
          <div className="title">
            <FormattedMessage
              id="title.upcomingMeetings"
              FormattedMessage="Upcoming meeting"
            />
          </div>
          <div
            className="more"
            style={{ color: theme.primary }}
            onClick={() => this.props.history.push("/meetings")}
          >
            <FormattedMessage
              id="upcomingMeeting.more"
              FormattedMessage="more"
            />
          </div>
        </div>

        {upcomingMeetings && upcomingMeetings.length === 0 && !loading ? (
          <div className="empty-component-container">
            <EmptyComponent
              message={intl.formatMessage({
                id: "emptyComponent.upcomingMeetings",
                defaultMessage: "Upcoming meeting"
              })}
            />
          </div>
        ) : (
          <>
            {upcomingMeetings &&
              upcomingMeetings.map((upcomingMeeting, index) => (
                <div
                  key={index}
                  className="upcoming-meetings-cell-container"
                  style={{ borderColor: theme.accentColor }}
                >
                  <MeetingsCell
                    data={upcomingMeeting}
                    loacal={locale}
                    upcomingMeetingsprop
                  />
                </div>
              ))}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    upcomingMeetings: state.meeting.upcomingMeetings,
    loading: state.meeting.upcomingMeetingsLoading
  };
};

export default connect(
  mapStateToProps,
  null
)(injectIntl(withRouter(UpcomingMeeting)));
