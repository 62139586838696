import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  hideModal,
  updateCounts,
  updateNotifCounts,
  hideToast,
  calenderData,
} from "../../actions";
import Header from "../../components/Header";
import Breadcrumb from "../../components/Breadcrumb";
import TaskDetailsPage from "../TaskDetailsPage";
import SideNav from "../../components/SideNav";

import TasksFilterButton from "../../components/TasksFilterButton";
import NewsFilterButton from "../../components/NewsFilterButton";
import NewsDetailsPage from "../NewsDetailsPage";
import AdhocDetailsPage from "../AdhocDetailsPage";
import MeetingsPage from "../MeetingsPage";
import MeetingsrequestsPage from "../MeetingRequestsPage";
import MeetingDetailsModal from "../../components/MeetingDetailsModal";
// import ProjectsPage from "../ProjectsPage";
// import ProjectPage from "../ProjectPage";
import TasksPage from "../TasksPage";
import TimelinePage from "../TimelinePage";
import UserProfilePage from "../UserProfilePage";
import NewsPage from "../NewsPage";
import CommitteesPage from "../CommitteesPage";
import CommitteeDetailsPage from "../CommitteeDetailsPage";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import AdhocPage from "../AdhocPage";
import UsersPage from "../UsersPage";
import UsersProfilePage from "../UsersProfilePage";
import NotFoundPage from "../NotFoundPage";
// import DashboardPage from "../DashboardPage";

import AddButtonSwitch from "../../components/AddButtonSwitch";
import Toast from "../../components/Toast";
import SearchInput from "../../components/SearchInput";
import EventsSideNav from "../../components/EventsSideNav";
import GoogleMeetingDetailsModal from "../../components/GoogleMeetingDetailsModal";

import "./style.scss";

class Main extends Component {
  componentDidMount() {
    this.backListener = this.props.history.listen((location, action) => {
      if (this.props.modal) {
        this.props.hideModal();
      }
      if (!location.state) {
        window.scrollTo(0, 0);
      }
    });
    calenderData();
  }

  previousLocation = this.props.location;
  componentWillUpdate(nextProps) {
    const { location } = this.props;
    // set previousLocation if props.location is not modal
    if (
      nextProps.history.action !== "POP" &&
      (!location.state || !location.state.modal)
    ) {
      this.previousLocation = this.props.location;
    }
  }

  componentDidUpdate(prevProps) {
    const { pathname, updateCounts, updateNotifCounts } = this.props;
    if (prevProps.pathname !== pathname) {
      updateCounts();
      updateNotifCounts();
    }
  }

  componentWillUnmount() {
    this.backListener();
  }

  render() {
    const {
      locale,
      location,
      modal,
      history,
      confirmation,
      // isCommitteeCreatorMemebr,
      toast,
    } = this.props;

    let isModal = !!(
      location.state &&
      location.state.modal &&
      this.previousLocation !== location
    );

    return (
      <>
        <Header history={history} />
        <div className="mainContainer">
          <aside
            className={`sidePanel ${locale.ltr ? "ltr" : ""}`}
            style={locale.ltr ? { paddingRight: 10 } : { paddingLeft: 10 }}
          >
            <SideNav current={location.pathname} history={history} />
          </aside>

          <Switch>
            <Route
              path={[
                "/login",
                //  "/dashboard"
              ]}
              // render={() => (
              //   <div className={`mainPanel ${locale.ltr ? "ltr" : ""} no-side`}>
              //     <Switch>
              //       <Route path="/dashboard" component={DashboardPage} />
              //     </Switch>
              //   </div>
              // )}
            />
            <Route
              render={() => (
                <div className={`mainPanel ${locale.ltr ? "ltr" : ""}`}>
                  <header className="mainHeader">
                    <div className="breadCrumbsContainer">
                      <Breadcrumb />
                      <Switch>
                        <Route
                          exact
                          path="/tasks"
                          component={TasksFilterButton}
                        />
                        <Route
                          path="/tasks/user/:id"
                          component={TasksFilterButton}
                        />
                        <Route
                          path="/tasks/members"
                          component={TasksFilterButton}
                        />
                      </Switch>
                      <Switch>
                        <Route
                          path="/news/source/:filter?"
                          component={NewsFilterButton}
                        />
                        <Route
                          exact
                          path="/news"
                          component={NewsFilterButton}
                        />
                      </Switch>

                      <Switch>
                        <Route exact path="/users" component={SearchInput} />
                      </Switch>
                    </div>

                    <AddButtonSwitch path={location.pathname} />
                  </header>
                  <Switch location={isModal ? this.previousLocation : location}>
                    <Route
                      exact
                      path="/"
                      render={(props) => <Redirect to="/timeline" {...props} />}
                    />
                    <Route path="/timeline" component={TimelinePage} />
                    <Route path="/tasks/members" component={TasksPage} />
                    <Route path="/tasks/user/:username" component={TasksPage} />
                    <Route path="/tasks/:id" component={TaskDetailsPage} />
                    <Route path="/tasks" component={TasksPage} />
                    {/*
                    <Route path="/projects/:id" component={ProjectPage} />
                    <Route path="/projects" component={ProjectsPage} />
                    */}

                    <Route
                      path="/meeting/:id"
                      component={MeetingDetailsModal}
                    />
                    <Route
                      path="/google-meeting:id"
                      component={GoogleMeetingDetailsModal}
                    />
                    <Route path="/meetings" component={MeetingsPage} />
                    <Route path="/news/source/:filter?" component={NewsPage} />
                    <Route path="/news/:id" component={NewsDetailsPage} />
                    <Route path="/news" component={NewsPage} />

                    <Route
                      path="/meeting-requests"
                      component={MeetingsrequestsPage}
                    />
                    <Route
                      path="/committees/:filter"
                      component={CommitteesPage}
                    />
                    <Route path="/committees" component={CommitteesPage} />
                    <Route
                      path="/committee/:id/:params?"
                      component={CommitteeDetailsPage}
                    />
                    <Route path="/committee" component={CommitteesPage} />
                    <Route path="/profile" component={UserProfilePage} />
                    <Route path="/adhoc/history" component={AdhocPage} />
                    <Route path="/adhoc/:id" component={AdhocDetailsPage} />
                    <Route path="/adhoc" component={AdhocPage} />
                    <Route
                      path="/users/:userName"
                      component={UsersProfilePage}
                    />
                    <Route path="/users" component={UsersPage} />

                    <Route component={NotFoundPage} />
                  </Switch>
                </div>
              )}
            />
          </Switch>
          {isModal ? (
            <Route
              path="/meeting/:id"
              component={MeetingDetailsModal}
              state={{ modal: true }}
            />
          ) : null}

          {isModal ? (
            <Route
              path="/google-meeting/:id"
              component={GoogleMeetingDetailsModal}
              state={{ modal: true }}
            />
          ) : null}
          {modal ? modal : null}
          {confirmation ? <ConfirmationDialog /> : null}
          {toast ? <Toast /> : null}
          <Switch>
            {/*
            <Route path={["/login", "/dashboard"]} />
            */}
            <Route
              render={() => (
                <aside
                  className={`EventsSidePanel ${locale.ltr ? "ltr" : ""}`}
                  style={
                    locale.ltr ? { paddingLeft: 10 } : { paddingRight: 10 }
                  }
                >
                  <EventsSideNav />
                </aside>
              )}
            />
          </Switch>
        </div>
      </>
    );
  }
}

Main.propTypes = {
  location: PropTypes.object,
  locale: PropTypes.object,
  modal: PropTypes.object,
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    locale: state.locale,
    modal: state.modal,
    confirmation: state.confirmation.isOpen,
    isCommitteeCreatorMemebr: state.theme.isCommitteeCreatorMemebr,
    pathname: state.router.location.pathname,
    toast: state.toast.toast,
  };
};

const mapDispatchToProps = {
  hideModal,
  updateCounts,
  updateNotifCounts,
  hideToast,
  calenderData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
