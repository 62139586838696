// Add
export const ADD_MEETING = "ADD_MEETING";
export const ADD_MEETING_ASYNC = "ADD_MEETING_ASYNC";
export const CREATE_MEETING_LOADING = "CREATE_MEETING_LOADING";
export const EDIT_COMMITTEE_MEETING_DETAILS = "EDIT_COMMITTEE_MEETING_DETAILS";
export const UPDATE_MEETING_UPLOAD_PROGRESS = "UPDATE_MEETING_UPLOAD_PROGRESS";
export const MEETING_ADD_COMMENT = "MEETING_ADD_COMMENT";
export const MEETING_ADD_COMMENT_ASYNC = "MEETING_ADD_COMMENT_ASYNC";
export const MEETING_COMMENT_LOADING = "MEETING_COMMENT_LOADING";
export const DELETE_MEETING_COMMENT = "DELETE_MEETING_COMMENT";

export const ADD_MOM = "ADD_MOM";

export function addMeetingAsync(meeting, name, url) {
  return { type: ADD_MEETING_ASYNC, meeting, name, url };
}

export function addMeeting(meeting, meetingId, name, committee) {
  return { type: ADD_MEETING, meeting, meetingId, name, committee };
}

export function createMeetingLoading() {
  return { type: CREATE_MEETING_LOADING };
}

export function addMOM(mom) {
  return { type: ADD_MOM, mom };
}

export function updateMeetingUploadProgress(progress) {
  return { type: UPDATE_MEETING_UPLOAD_PROGRESS, progress };
}
// Fetch
export const MEETING_LOADING = "MEETING_LOADING";
export const MEETINGS_LOADING = "MEETINGS_LOADING";
export const UPCOMING_MEETINGS_LOADING = "UPCOMING_MEETINGS_LOADING";
export const FETCH_MEETING = "FETCH_MEETING";
export const FETCH_GOOGLE_MEETING = "FETCH_GOOGLE_MEETING";
export const EMPTY_GOOGLE_MEETING = "EMPTY_GOOGLE_MEETING";
export const FETCH_MEETING_ASYNC = "FETCH_MEETING_ASYNC";
export const FETCH_UPCOMING_MEETINGS = "FETCH_UPCOMING_MEETINGS";
export const FETCH_UPCOMING_MEETINGS_ASYNC = "FETCH_UPCOMING_MEETINGS_ASYNC";

export function fetchGoogleMeeting(googleMeeting) {
  return { type: FETCH_GOOGLE_MEETING, googleMeeting };
}

export function emptyGoogleMeeting() {
  return { type: EMPTY_GOOGLE_MEETING };
}

export function fetchUpcomingMeetingsAsync(upcomingMeetings) {
  return { type: FETCH_UPCOMING_MEETINGS_ASYNC, upcomingMeetings };
}

export function fetchUpcomingMeetings(upcomingMeetings) {
  return { type: FETCH_UPCOMING_MEETINGS, upcomingMeetings };
}

export function fetchMeetingAsync(meetings) {
  return { type: FETCH_MEETING_ASYNC, meetings };
}

export function fetchMeeting(meetingId) {
  return { type: FETCH_MEETING, meetingId };
}

export function meetingsLoading() {
  return { type: MEETINGS_LOADING };
}

export function meetingLoading() {
  return { type: MEETING_LOADING };
}

export function upcomingMeetingsLoading() {
  return { type: UPCOMING_MEETINGS_LOADING };
}
// Actions
export const REMOVE_MEETING = "REMOVE_MEETING";
export const CANCEL_MEETING = "CANCEL_MEETING";
export const REMOVE_MEETING_ACTION = "REMOVE_MEETING_ACTION";
export const CANCEL_MEETING_ACTION = "CANCEL_MEETING_ACTION";
export const DELETE_MEETING_ATTACHMENT = "DELETE_MEETING_ATTACHMENT";

export function removeMeeting(meeting, url) {
  return { type: REMOVE_MEETING, meeting, url };
}

export function cancelMeeting(meeting, CancelationReason) {
  return { type: CANCEL_MEETING, meeting, CancelationReason };
}

export function removeMeetingAction(id, url) {
  return { type: REMOVE_MEETING_ACTION, id, url };
}

export function cancelMeetingAction(id, CancelationReason) {
  return { type: CANCEL_MEETING_ACTION, id, CancelationReason };
}

export function deleteMeetingAttachment(id) {
  return { type: DELETE_MEETING_ATTACHMENT, id };
}
// Error
export const MEETING_FAILED = "MEETING_FAILED";

export function meetingFailed(error) {
  return { type: MEETING_FAILED, error };
}

// Requsets Counter
export const UPDATE_REQUESTS_COUNT = "UPDATE_REQUESTS_COUNT";
export const UPDATE_REQUESTS_COUNT_ASYNC = "UPDATE_REQUESTS_COUNT_ASYNC";
export const UPDATE_REQUESTS_COUNT_LOADING = "UPDATE_REQUESTS_COUNT_LOADING";

export function updateRequestsCount() {
  return { type: UPDATE_REQUESTS_COUNT };
}

export function updateRequestsCountAsync(count) {
  return { type: UPDATE_REQUESTS_COUNT_ASYNC, count };
}

export function updateRequestsCountLoading() {
  return { type: UPDATE_REQUESTS_COUNT_LOADING };
}

export function editCommitteeMettingDetails(data, committiee) {
  return { type: EDIT_COMMITTEE_MEETING_DETAILS, data, committiee };
}

export function meetingAddCommentAsync(comment) {
  return { type: MEETING_ADD_COMMENT_ASYNC, comment };
}

export function meetingAddComment(comment, meetingId) {
  return { type: MEETING_ADD_COMMENT, comment, meetingId };
}

export function meetingCommentLoading() {
  return { type: MEETING_COMMENT_LOADING };
}

export function deleteMeetingComment(commentId) {
  return { type: DELETE_MEETING_COMMENT, commentId };
}
