import React from "react";
import { connect } from "react-redux";
import "./style.scss";
import FilterButton from "./FilterButton";
import ViewButton from "./ViewButton";

const TasksFilterButton = props => {
  const { textGray } = props;

  return (
    <div
      className="tasks-filter-button-container"
      style={{ borderColor: textGray }}
    >
      <ViewButton />
      <FilterButton />
    </div>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    textGray: state.theme.textGray
  };
};

export default connect(
  mapStateToProps,
  null
)(TasksFilterButton);
