import React, * as react from "react";
import { ReactComponent as ArrowDown } from "../../assets/images/arrow-down.svg";

class Navbar extends react.Component {
  render() {
    return (
      <div className="nav-bar">
        <button
          className="next-button"
          onClick={() => this.props.onNextClick()}
        >
          <ArrowDown
            style={{
              fill: this.props.theme.primary
            }}
          />
        </button>
        <button
          className="prev-button"
          onClick={() => this.props.onPreviousClick()}
        >
          <ArrowDown style={{ fill: this.props.theme.primary }} />
        </button>
      </div>
    );
  }
}

export default Navbar;
