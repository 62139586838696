import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import uniqid from "uniqid";
import "moment/locale/ar";
import "./App.scss";
import LoginPage from "./containers/LoginPage";
import Main from "./containers/Main";
import Loading from "./components/Loading";
import {
  handshake,
  addDeviceID,
  logout,
  clearProjectsOnLogOut,
  emptyGoogleMeeting,
} from "./actions";
import { api } from "./configs";

class App extends Component {
  componentDidMount() {
    const { handshake, addDeviceID, user } = this.props;
    if (!user.DeviceID) {
      addDeviceID(uniqid());
    }
    handshake(user.LoggedIn, user.DeviceID);
  }

  render() {
    axios.defaults.headers.common["Authorization"] = api.basic;
    // if (this.props.user.Token !== "")
    axios.defaults.headers.common["Token"] = this.props.user.Token;
    axios.defaults.baseURL = api.baseUrl;
    const { theme, locale, user } = this.props;
    document.body.style.direction = locale.direction;
    moment.locale(locale.lang);

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        if (err.response?.status === 401) {
          this.props.clearProjectsOnLogOut();
          this.props.logout(this.props.user.DeviceID);
          window.location.reload();
        }
      }
    );

    return (
      <div
        className="App"
        style={{
          backgroundColor: theme.backgroundColor,
          color: theme.textColor,
        }}
      >
        {theme.Loading ? (
          <div className="loading-container">
            <Loading color={theme.primary} />
          </div>
        ) : (
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            {/* <Route path="/" component={Main} /> */}
            <Route
              path="/"
              render={(props) =>
                user.LoggedIn ? <Main {...props} /> : <Redirect to="/login" />
              }
            />
          </Switch>
        )}
      </div>
    );
  }
}

// export default App;
const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = {
  handshake,
  addDeviceID,
  logout,
  clearProjectsOnLogOut,
  emptyGoogleMeeting,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
