import React from "react";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";

const MeetingRequestsLoadingCard = props => {
  const { ltr } = props;
  const _renderItem = () => (
    <ContentLoader
      className="card loading-card"
      rtl={!ltr}
      width={400}
      height={100}
    >
      <rect x="10" y="10" rx="3" ry="3" width="115" height="6" />

      <rect x="300" y="7.5" rx="3" ry="3" width="40" height="15" />
      <rect x="350" y="7.5" rx="3" ry="3" width="40" height="15" />
      <rect x="250" y="7.5" rx="3" ry="3" width="40" height="15" />

      <rect x="25" y="32" rx="3" ry="3" width="120" height="6" />
      <rect x="25" y="46" rx="3" ry="3" width="110" height="6" />
      <rect x="25" y="60" rx="3" ry="3" width="80" height="6" />

      <circle cx="15" cy="35" r="5" />
      <circle cx="15" cy="48.5" r="5" />
      <circle cx="15" cy="62" r="5" />

      <rect x="10" y="78" rx="8" ry="8" width="50" height="16" />
    </ContentLoader>
  );
  return (
    <>
      {_renderItem()}
      {_renderItem()}
    </>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ltr: state.locale.ltr
  };
};

export default connect(
  mapStateToProps,
  null
)(MeetingRequestsLoadingCard);
