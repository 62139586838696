import sortBy from "lodash/sortBy";
import {
  ADD_NEWS_ASYNC,
  NEWS_LOADING,
  CREATE_NEWS_LOADING,
  FETCH_NEWS_ASYNC,
  NEWS_FAILED,
  DELETE_NEWS_ASYNC,
  SET_NEWS_SOURCES,
  STOP_CREATE_NEWS_LOADING,
  ON_CREATE_NEWS_SOURCES,
  UPDATE_NEWS_UPLOAD_PROGRESS,
  NEWS_ADD_COMMENT_ASYNC,
  NEWS_COMMENT_LOADING,
  DELETE_NEWS_COMMENT
} from "../actions";

import { getNewList, filterList } from "../utils";

const initialState = {
  Loading: false,
  CreateLoading: false,
  CommentLoading: false,
  Error,
  NewsList: [],
  PagingInfo: "",
  News: null,
  sourceName: "",
  newsSources: [],
  Progress: ""
};

function news(state = initialState, action) {
  switch (action.type) {
    case ADD_NEWS_ASYNC:
      const newNewsList = getNewList(state.NewsList, action.news);
      return {
        ...state,
        CreateLoading: false,
        Error: "",
        Progress: "",
        NewsList: sortBy(newNewsList, ["ArticleDate"]).reverse(),
        News:
          state.News && state.News.ID === action.news.ID
            ? action.news
            : state.News
      };

    case DELETE_NEWS_ASYNC:
      return {
        ...state,
        CreateLoading: false,
        Error: "",
        NewsList: filterList(state.News.ID, state.NewsList),
        News: null
      };

    case FETCH_NEWS_ASYNC:
      const data = Array.isArray(action.news)
        ? {
            NewsList:
              state.NewsList.length > 0
                ? [...state.NewsList, ...action.news]
                : action.news
          }
        : { News: action.news };
      return {
        ...state,
        Loading: false,
        Error: "",
        PagingInfo: action.PagingInfo ? action.PagingInfo : "",
        sourceName: action.sourceId
          ? state.newsSources.find(item => item.value === action.sourceId).label
          : "",
        ...data
      };
    case NEWS_LOADING:
      if (action.clear)
        return {
          ...initialState,
          Loading: true,
          newsSources: state.newsSources
        };
      return {
        ...state,
        Loading: true,
        PagingInfo: ""
      };
    case NEWS_FAILED:
      return {
        ...state,
        Progress: "",
        Error: action.error,
        CreateLoading: false,
        CommentLoading: false
      };
    case CREATE_NEWS_LOADING:
      return {
        ...state,
        CreateLoading: true
      };
    case UPDATE_NEWS_UPLOAD_PROGRESS:
      return {
        ...state,
        Progress: action.progress
      };
    case STOP_CREATE_NEWS_LOADING:
      return {
        ...state,
        CreateLoading: false
      };
    case SET_NEWS_SOURCES:
      return {
        ...state,
        newsSources: action.sources
      };
    case ON_CREATE_NEWS_SOURCES:
      const sortList = sortBy([...state.newsSources, action.source], ["label"]);
      return {
        ...state,
        newsSources: sortList
      };
    case NEWS_ADD_COMMENT_ASYNC:
      return {
        ...state,
        CommentLoading: false,
        Error: "",
        Progress: "",
        News: {
          ...state.News,
          Comments: [action.comment, ...state.News.Comments],
          CommentsCount: state.News.CommentsCount + 1
        }
      };
    case DELETE_NEWS_COMMENT:
      const newComments = state.News.Comments.filter(
        comment => comment.ID !== action.commentId
      );
      return {
        ...state,
        News: {
          ...state.News,
          Comments: newComments,
          CommentsCount: state.News.CommentsCount - 1
        }
      };
    case NEWS_COMMENT_LOADING:
      return {
        ...state,
        CommentLoading: true
      };

    default:
      return state;
  }
}

export default news;
