import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Modal from "../Modal";
import { hideModal, addNews, withConfirmation } from "../../actions";

import AddNewsSourceModal from "../AddNewsSourceModal";
import { injectIntl } from "react-intl";
import { string, object, date, array } from "yup";
import FormInput from "../FormInput";
import FormButton from "../FormButton";
import {
  cleanSourcesForSelect,
  getUnixDate,
  cleanApiDataForm,
  getSelectUsers,
  cleanLookupsForSelect,
  cleanObjectOptionsForSelect
} from "../../utils";
import { limits } from "../../configs";

class AddNewsModal extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    const sectorItem =
      props.data && props.Sectors.find(item => item.ID === props.data.SectorId);

    const entityItem =
      props.data &&
      props.Entities.find(item => item.ID === props.data.EntityId);

    const departmentItem =
      props.data &&
      props.Departments.find(item => item.ID === props.data.DepartmentId);

    const newsItem = props.data && props.NewsType[props.data.NewsType];

    this.state = {
      users: [],
      sources: [],
      addSource: false,
      value: "",
      changeHandler: null,
      sectors: cleanLookupsForSelect(props.Sectors),
      entities: [],
      Sector: props.data ? cleanLookupsForSelect(sectorItem) : [],
      Entity: props.data ? cleanLookupsForSelect(entityItem) : [],
      departments: [],
      initialValues: {
        SourceID: props.data
          ? {
              img:
                props.data.Source.Attachment &&
                props.data.Source.Attachment.AttachmentURL,
              label: props.data.Source.TitleAr,
              value: props.data.Source.ID
            }
          : "",
        TitleAr: props.data ? props.data.TitleAr : "",
        DescriptionAr: props.data ? props.data.DescriptionAr : "",
        Attachment: [],
        ArticleDate: props.data
          ? new Date(props.data.ArticleDate * 1000)
          : null,
        SourceURL: props.data ? props.data.SourceURL : "",
        Summary: props.data ? props.data.Summary : "",
        Sector: props.data ? cleanLookupsForSelect(sectorItem) : "",
        Entity: props.data ? cleanLookupsForSelect(entityItem) : "",
        Department: props.data ? cleanLookupsForSelect(departmentItem) : "",
        NewsType: props.data
          ? {
              value: newsItem.Value,
              label: newsItem[`Title${props.suffix}`],
              ...newsItem
            }
          : "",
        Natural: props.data ? props.data.Natural : "Neutral"
      }
    };
    this.handleCreate = this.handleCreate.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.ref = React.createRef();
  }

  handleCreate = (value, handleChange) => {
    this.setState({ value, addSource: true, changeHandler: handleChange });
  };

  onCreate = source => {
    this.setState({
      sources: [source, ...this.state.sources],
      addSource: false
    });
    this.state.changeHandler(source);
  };

  componentDidMount() {
    this._isMounted = true;

    axios.get("/News/Sources").then(response => {
      const sources = cleanSourcesForSelect(response, this.props.locale.suffix);

      if (this._isMounted) {
        this.setState({ sources });
      }
    });
    getSelectUsers(this.props.locale.ltr).then(users => {
      this.setState({ users });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  addNewsSchema = () => {
    const theDate = new Date();

    return object().shape({
      TitleAr: string()
        .required(
          this.props.intl.formatMessage({
            id: "validation.nameRequired",
            defaultMessage: "Address is required"
          })
        )
        .max(
          limits.title,
          this.props.intl.formatMessage(
            {
              id: "validation.charLimit",
              defaultMessage: "should not exceed {limit} characters"
            },
            { limit: limits.title }
          )
        ),
      DescriptionAr: string()
        .required(
          this.props.intl.formatMessage({
            id: "validation.descriptionRequired",
            defaultMessage: "Description is required"
          })
        )
        .max(
          limits.description,
          this.props.intl.formatMessage(
            {
              id: "validation.charLimit",
              defaultMessage: "should not exceed {limit} characters"
            },
            { limit: limits.description }
          )
        ),
      Summary: string()
        .required(
          this.props.intl.formatMessage({
            id: "validation.Summary",
            defaultMessage: "Summary is required"
          })
        )
        .max(
          limits.description,
          this.props.intl.formatMessage(
            {
              id: "validation.charLimit",
              defaultMessage: "should not exceed {limit} characters"
            },
            { limit: limits.description }
          )
        ),
      ArticleDate: date()
        .nullable()
        .max(
          theDate,
          this.props.intl.formatMessage({
            id: "validation.pastDate",
            defaultMessage: "Due date can't be older than today"
          })
        )
        .required(
          this.props.intl.formatMessage({
            id: "validation.dateRequired",
            defaultMessage: "Description is required"
          })
        ),
      SourceID: object().required(
        this.props.intl.formatMessage({
          id: "validation.SourceID",
          defaultMessage: "Source news is required"
        })
      ),

      Sector: object().required(
        this.props.intl.formatMessage({
          id: "validation.SourceID",
          defaultMessage: "Source news is required"
        })
      ),

      Entity: object().required(
        this.props.intl.formatMessage({
          id: "validation.SourceID",
          defaultMessage: "Source news is required"
        })
      ),

      Department: object().required(
        this.props.intl.formatMessage({
          id: "validation.SourceID",
          defaultMessage: "Source news is required"
        })
      ),

      NewsType: object().required(
        this.props.intl.formatMessage({
          id: "validation.SourceID",
          defaultMessage: "Source news is required"
        })
      ),

      Natural: string().required(),

      SourceURL: string()
        .url(
          this.props.intl.formatMessage({
            id: "validation.ShapeSourceURL",
            defaultMessage: "must be url"
          })
        )
        .required(
          this.props.intl.formatMessage({
            id: "validation.SourceURL",
            defaultMessage: "Source news is required"
          })
        )
        .max(
          limits.title,
          this.props.intl.formatMessage(
            {
              id: "validation.charLimit",
              defaultMessage: "should not exceed {limit} characters"
            },
            { limit: limits.title }
          )
        ),
      Attachment: !this.props.data
        ? array().required(
            this.props.intl.formatMessage({
              id: "validation.imageRequired",
              defaultMessage: "News Image is required"
            })
          )
        : null
    });
  };

  getPage() {
    const { location } = this.props;
    return location.pathname.split("/")[3];
  }

  onConfirmAddNews = apiData => {
    this.props.addNews(cleanApiDataForm(apiData), null, this.getPage());
  };

  render() {
    const {
      hideModal,
      loading,
      data,
      intl,
      progress,
      Entities,
      Departments,
      NewsType,
      suffix,
      NewsNatural
    } = this.props;
    const confirmationMessege = intl.formatMessage({
      id: "confirmation.messege",
      defaultMessage: "confirmation.messege"
    });

    return (
      <Modal
        isOpen
        title={data ? "publishNews" : "addNews"}
        hideModal={hideModal}
      >
        <Formik
          ref={this.ref}
          initialValues={this.state.initialValues}
          validationSchema={this.addNewsSchema}
          onSubmit={(values, { setSubmitting }) => {
            const ID = data ? data.ID : null;
            const apiData = {
              TitleAr: values.TitleAr,
              TitleEn: values.TitleAr,
              DescriptionAr: values.DescriptionAr,
              DescriptionEn: values.DescriptionAr,
              SourceID: values.SourceID.value,
              SourceURL: values.SourceURL,
              ArticleDate: getUnixDate(values.ArticleDate),
              Attachments: values.Attachment,
              ID,
              Summary: values.Summary,
              DepartmentId: values.Department.value,
              SectorId: values.Sector.value,
              EntityId: values.Entity.value,
              NewsType: values.NewsType.value,
              Natural: values.Natural
            };
            if (ID) {
              this.props.addNews(cleanApiDataForm(apiData), ID, this.getPage());
            } else {
              this.props.withConfirmation(
                () => this.onConfirmAddNews(apiData),
                confirmationMessege
              );
            }
          }}
          validate={({ Sector, Entity }) => {
            if (Sector.value !== this.state.Sector.value) {
              this.ref.current.resetForm({
                ...this.ref.current.state.values,
                Entity: "",
                Department: ""
              });
            }

            if (Entity.value !== this.state.Entity.value) {
              this.ref.current.resetForm({
                ...this.ref.current.state.values,
                Department: ""
              });
            }
            const filterdEntitiesData = Entities.filter(item => {
              return item.ParentID === Sector.ID;
            });
            const filterdDepartmentsData = Departments.filter(item => {
              return item.ParentID === Entity.ID;
            });
            this.setState({
              entities: cleanLookupsForSelect(filterdEntitiesData),
              departments: cleanLookupsForSelect(filterdDepartmentsData),
              Entity,
              Sector
            });
          }}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form autoComplete="off">
              <input
                autoComplete="false"
                name="hidden"
                type="text"
                style={{ display: "none" }}
              />
              <FormInput
                required
                type="singleSelect"
                name="SourceID"
                options={this.state.sources}
                handleCreate={this.handleCreate}
                clearable
                errors={touched["SourceID"] && errors["SourceID"]}
              />
              <FormInput
                type="text"
                name="TitleAr"
                required
                errors={touched["TitleAr"] && errors["TitleAr"]}
              />

              <FormInput
                required
                name="Sector"
                type="singleSelect"
                options={this.state.sectors}
                errors={touched["Sector"] && errors["Sector"]}
                noCreate
              />

              <FormInput
                required
                name="Entity"
                type="singleSelect"
                options={this.state.entities}
                stopSelectLoading
                errors={touched["Entity"] && errors["Entity"]}
                noCreate
              />

              <FormInput
                required
                name="Department"
                type="singleSelect"
                options={this.state.departments}
                stopSelectLoading
                errors={touched["Department"] && errors["Department"]}
                noCreate
              />

              <FormInput
                required
                name="NewsType"
                type="singleSelect"
                options={cleanObjectOptionsForSelect(NewsType, suffix)}
                stopSelectLoading
                errors={touched["NewsType"] && errors["NewsType"]}
                noCreate
              />

              <FormInput
                type="dateTime"
                name="ArticleDate"
                required
                maxDate={new Date()}
                errors={touched["ArticleDate"] && errors["ArticleDate"]}
              />
              <FormInput
                type="textarea"
                name="Summary"
                required
                errors={touched["Summary"] && errors["Summary"]}
              />

              <FormInput
                type="textarea"
                name="DescriptionAr"
                required
                errors={touched["DescriptionAr"] && errors["DescriptionAr"]}
              />

              <FormInput
                type="text"
                name="SourceURL"
                required
                errors={touched["SourceURL"] && errors["SourceURL"]}
              />

              <FormInput
                required
                name="Natural"
                type="radio"
                options={cleanObjectOptionsForSelect(NewsNatural, suffix)}
                withIcon
              />
              <FormInput
                type="upload"
                module="news"
                name="Attachment"
                imageOnly
                required={!data}
                errors={touched["Attachment"] && errors["Attachment"]}
              />
              <FormButton
                loading={loading}
                edit={!!data}
                progress={progress}
                publish
              />
            </Form>
          )}
        </Formik>
        {this.state.addSource ? (
          <AddNewsSourceModal
            hideModal={() => this.setState({ addSource: false })}
            onCreate={this.onCreate}
            value={this.state.value}
          />
        ) : null}
      </Modal>
    );
  }
}

AddNewsModal.propTypes = {
  intl: PropTypes.object,
  locale: PropTypes.object,
  loading: PropTypes.bool
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    loading: state.news.CreateLoading,
    progress: state.news.Progress,
    locale: state.locale,
    suffix: state.locale.suffix,
    Sectors: state.theme.lookups.Sectors,
    Entities: state.theme.lookups.Entities,
    Departments: state.theme.lookups.Departments,
    NewsType: state.theme.News_Type,
    NewsNatural: state.theme.News_Natural
  };
};

const mapDispatchToProps = { hideModal, addNews, withConfirmation };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(AddNewsModal)));
