import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

import "./style.scss";

class PreviousProcedure extends Component {
  render() {
    const { data, theme, ltr, suffix, stageTheme, statusTheme } = this.props;

    return (
      <div
        className={
          ltr ? "previous-procedure card ltr" : "previous-procedure card"
        }
      >
        <div
          className="titles-header"
          style={{ borderColor: theme.accentColor }}
        >
          <div
            className="title first-title"
            style={{ borderColor: theme.accentColor, color: theme.primary }}
          >
            <FormattedMessage
              id="adhoc.previousProcedure.date"
              defaultMessage="date"
            />
          </div>
          <div
            className=" second-title"
            style={{ borderColor: theme.accentColor, color: theme.primary }}
          >
            <FormattedMessage
              id="adhoc.previousProcedure.status"
              defaultMessage="date"
            />
          </div>

          <div
            className=" second-title"
            style={{ borderColor: theme.accentColor, color: theme.primary }}
          >
            <FormattedMessage
              id="meeting-request-creator"
              defaultMessage="Created"
            />
          </div>
          <div
            className="third-title"
            style={{ borderColor: theme.accentColor, color: theme.primary }}
          >
            <FormattedMessage
              id="adhoc.previousProcedure.entity"
              defaultMessage="date"
            />
          </div>
          <div
            className="forth-title"
            style={{ borderColor: theme.accentColor, color: theme.primary }}
          >
            <FormattedMessage
              id="adhoc.previousProcedure.comments"
              defaultMessage="date"
            />
          </div>
        </div>

        {[...data].reverse().map((item, index) => (
          <div
            key={index}
            className="titles-header"
            style={{
              borderColor: theme.accentColor,
              backgroundColor: index % 2 !== 0 ? theme.primaryLight : null
            }}
          >
            <div
              className="title first-title"
              style={{ borderColor: theme.accentColor }}
            >
              <div>
                {moment(item.ActionDate * 1000).format("dddd - DD MMM YYYY")}
              </div>
              <div className="time" style={{ color: theme.textGray }}>
                {moment(item.ActionDate * 1000).format("hh:mm A")}
              </div>
            </div>
            <div
              className=" second-title"
              style={{
                borderColor: theme.accentColor,
                color: statusTheme[item.Action]
                  ? statusTheme[item.Action].Color
                  : theme.textGray
              }}
            >
              {statusTheme[item.Action]
                ? statusTheme[item.Action][`Title${suffix}`]
                : "-"}
            </div>

            <div
              className="second-title"
              style={{
                borderColor: theme.accentColor
              }}
            >
              {item.ActionBy && item.ActionBy.DisplayName}
            </div>
            <div
              className="third-title"
              style={{ borderColor: theme.accentColor }}
            >
              {item.Stage !== ""
                ? stageTheme[item.Stage][`Title${suffix}`]
                : "-"}
            </div>
            <div
              className="forth-title"
              style={{ borderColor: theme.accentColor }}
            >
              {item.Comment !== "" ? item.Comment : "-"}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

PreviousProcedure.propTypes = {
  theme: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    ltr: state.locale.ltr,
    suffix: state.locale.suffix
  };
};

export default connect(mapStateToProps, null)(PreviousProcedure);
