import axios from "axios";
import { replace } from "connected-react-router";
import {
  committeeLoading,
  fetchCommitteeAsync,
  committeeFailed,
  addCommitteeLoading,
  addCommitteeAsync,
  addCommitteeItems,
  addFilesToFolder,
  hideModal,
  showToast,
  hideToast,
  updateCommitteeUploadProgress
} from "../actions";
import { getDataFromRes } from "../utils";
import { createUploader, watchOnProgress } from "./upload";
import { call, put, takeLatest, fork } from "redux-saga/effects";

function addCommitteeApi(payload, onProgress) {
  const { data, id } = payload;
  const params = id ? `/Update` : "";
  return axios.post(`/Committee${params}`, data, {
    onUploadProgress: onProgress
  });
}

function* addCommitteeEffect(action) {
  yield put(addCommitteeLoading());

  const [uploadPromise, chan] = createUploader(
    { data: action.committee, id: action.committeeId },
    addCommitteeApi
  );

  yield fork(watchOnProgress, chan, updateCommitteeUploadProgress);
  try {
    let { data } = yield call(() => uploadPromise);

    if (data.StatusCode === "Success") {
      yield put(addCommitteeAsync(getDataFromRes(data), action.username));
      yield put(hideModal());
      yield put(hideToast());
      yield put(
        showToast(
          getDataFromRes(data).ID,
          (action.pageType = "committee"),
          action.committeeId
        )
      );
    } else {
      yield put(committeeFailed(data.StatusMessage));
    }
  } catch (err) {
    yield put(committeeFailed(err.message));
  }
}

export function* addCommitteeWatcher() {
  yield takeLatest("ADD_COMMITTEE", addCommitteeEffect);
}

function fetchCommitteeApi(id) {
  const path = id ? `/Committee/${id}` : "/Committee/";
  return axios.get(path);
}

function* fetchCommitteeEffect(action) {
  yield put(committeeLoading());

  let { data } = yield call(fetchCommitteeApi, action.committeeId);

  if (data.StatusCode === "Success") {
    yield put(fetchCommitteeAsync(getDataFromRes(data), action.username));
  } else {
    yield put(replace("/committees/all"));
  }
}

export function* fetchCommitteeWatcher() {
  yield takeLatest("FETCH_COMMITTEE", fetchCommitteeEffect);
}

function addItemsApi(payload, onProgress) {
  const { items, key, id } = payload;
  switch (key) {
    case "Folder":
      return axios.post("/CommitteeFolders/", items, {
        onUploadProgress: onProgress
      });
    case "Attachment":
      return axios.post("/Committee/AddAttachment/", items, {
        onUploadProgress: onProgress
      });
    case "Members":
      return axios.post("/Committee/AddMember/", items, {
        onUploadProgress: onProgress
      });
    default:
      if (id) {
        return axios.post("/Meetings/Update/", items, {
          onUploadProgress: onProgress
        });
      } else {
        return axios.post("/Meetings/CommitteeMeeting/", items, {
          onUploadProgress: onProgress
        });
      }
  }
}

function* addItemsEffect(action) {
  yield put(addCommitteeLoading());
  const [uploadPromise, chan] = createUploader(
    { items: action.items, key: action.key, id: action.committeeId },
    addItemsApi
  );
  yield fork(watchOnProgress, chan, updateCommitteeUploadProgress);

  let { data } = yield call(() => uploadPromise);

  if (data.StatusCode === "Success") {
    yield put(hideToast());
    const resData = getDataFromRes(data);
    if (
      (action.key === "Folder" || action.key === "Attachment") &&
      Array.isArray(resData)
        ? resData[0].FolderID
        : resData.FolderID
    ) {
      yield put(addFilesToFolder(resData));
    } else {
      yield put(addCommitteeItems(resData, action.key));

      // yield put(
      //   showToast(
      //     getDataFromRes(data).ID,
      //     (action.key === "Attachment" || action.key === "Folder") &&
      //       Array.isArray(resData)
      //       ? "uploadFile"
      //       : action.key,
      //     action.key === "Attachment" || action.key === "Folder"
      //       ? true
      //       : action.items.ID,
      //     action.key === "Meetings" ? true : false,
      //     action.items.ID ? true : false
      //   )
      // );
    }
    yield put(hideModal());
  } else {
    yield put(committeeFailed(data.StatusMessage));
  }
}

export function* addCommitteeItemsWatcher() {
  yield takeLatest(
    [
      "ADD_COMMITTEE_MEETING",
      "ADD_COMMITTEE_ATTACHMENT",
      "ADD_COMMITTEE_MEMBER",
      "ADD_COMMITTEE_FOLDER"
    ],
    addItemsEffect
  );
}
