import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "../Loading";
import Docx from "../../assets/images/docx.svg";
import Pdf from "../../assets/images/pdf.svg";
import DefaultImage from "../../assets/images/default-image.svg";
import Ppt from "../../assets/images/ppt.svg";
import Xls from "../../assets/images/xls.svg";
import Folder from "../../assets/images/folder.svg";

class AttachmentType extends Component {
  ImagesType = ["png", "jpg", "gif", "jpeg", "svg"];

  switchPhotos = type => {
    const style = {
      borderColor: this.props.accentColor ? this.props.accentColor : null
    };
    switch (type) {
      case "pdf":
        return <img src={Pdf} alt="sourceImage" style={style} />;
      case "doc":
      case "docx":
        return <img src={Docx} alt="sourceImage" style={style} />;
      case "ppt":
      case "pptx":
        return <img src={Ppt} alt="sourceImage" style={style} />;
      case "xls":
      case "xlsx":
        return <img src={Xls} alt="sourceImage" style={style} />;
      case "folder":
        return <img src={Folder} alt="sourceImage" style={style} />;
      default:
        return <img src={DefaultImage} alt="sourceImage" style={style} />;
    }
  };

  render() {
    const { type, themeColor, loading } = this.props;
    return (
      <div>
        <div>
          {loading ? (
            <div className="loading-img">
              <Loading color={themeColor} />
            </div>
          ) : (
            this.switchPhotos(type)
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    themeColor: state.theme.primary
  };
};

export default connect(mapStateToProps, null)(AttachmentType);
