import React, { Component } from "react";
import BigCalendar from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import CalendarToolbar from "../CalendarToolbar";
import CalendarEvent from "../CalendarEvent";
import { css } from "emotion";
import "./style.scss";

const localizer = BigCalendar.momentLocalizer(moment);

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0
    };
    this._handleEventProps = this._handleEventProps.bind(this);
    this._getMessages = this._getMessages.bind(this);
    this.myRef = React.createRef();
  }

  _handleEventProps = (event, start, end, isSelected) => {
    let newStyle = {
      backgroundColor:
        this.props.view === "month" ? "transparent" : this.props.theme.primary,
      color:
        event.Status === "2"
          ? this.props.theme.accentColor
          : this.props.view === "month"
          ? this.props.theme.textColor
          : "white",
      textDecoration: event.Status === "3" ? "line-through" : null
    };

    return {
      className: "",
      style: newStyle
    };
  };

  _getMessages = intl => {
    return {
      allDay: intl.formatMessage({
        id: "calendar.allDay",
        defaultMessage: "All day"
      }),
      previous: intl.formatMessage({
        id: "calendar.previous",
        defaultMessage: "Previous"
      }),
      next: intl.formatMessage({
        id: "calendar.next",
        defaultMessage: "Next"
      }),
      today: intl.formatMessage({
        id: "calendar.today",
        defaultMessage: "Today"
      }),
      month: intl.formatMessage({
        id: "calendar.month",
        defaultMessage: "Month"
      }),
      week: intl.formatMessage({
        id: "calendar.week",
        defaultMessage: "Week"
      }),
      day: intl.formatMessage({
        id: "calendar.day",
        defaultMessage: "Day"
      }),
      agenda: intl.formatMessage({
        id: "calendar.agenda",
        defaultMessage: "Agenda"
      }),
      date: intl.formatMessage({
        id: "calendar.date",
        defaultMessage: "Date"
      }),
      time: intl.formatMessage({
        id: "calendar.time",
        defaultMessage: "Time"
      }),
      event: intl.formatMessage({
        id: "calendar.event",
        defaultMessage: "Event"
      }),
      showMore: total =>
        `+ ${intl.formatMessage({
          id: "calendar.showMore",
          defaultMessage: "More"
        })} (${total})`
    };
  };

  render() {
    const {
      intl,
      locale,
      handleSelect,
      theme,
      handleSelectEvent,
      view
    } = this.props;

    return (
      <div
        className={css`
          width: 100%;
          height: 100%;
          .calendarContainer .rbc-date-cell.rbc-now a {
            background: ${theme.primary};
          }
          .rbc-off-range-bg {
            background: ${theme.primaryLight};
          }
        `}
      >
        <div
          className={locale.ltr ? "calendarContainer" : "calendarContainer rtl"}
        >
          <BigCalendar
            ref={this.myRef}
            localizer={localizer}
            events={this.props.events}
            startAccessor={meeting => new Date(meeting.StartDate * 1000)}
            endAccessor={meeting => new Date(meeting.EndDate * 1000)}
            titleAccessor={meeting => meeting.TitleAr}
            tooltipAccessor={meeting =>
              theme.Meeting_Status[meeting.Status][`Title${locale.suffix}`]
            }
            selectable
            popup
            views={["month", "week", "day"]}
            onView={() => {}}
            view={view || "month"}
            toolbar={true}
            slotPropGetter={date => ({
              style: {
                color: theme.accentColor,
                borderColor: theme.accentColor
              }
            })}
            dayPropGetter={date => ({
              style: {
                color: theme.accentColor,
                borderColor: theme.accentColor
              }
            })}
            rtl={!locale.ltr}
            messages={this._getMessages(intl)}
            eventPropGetter={(event, start, end, isSelected) =>
              this._handleEventProps(event, start, end, isSelected)
            }
            onSelectSlot={slotInfo => handleSelect(slotInfo)}
            onSelectEvent={slotInfo => handleSelectEvent(slotInfo)}
            step={30}
            components={{
              toolbar: CalendarToolbar,
              event: props => <CalendarEvent {...props} view={view} />
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ...state
  };
};

export default connect(
  mapStateToProps,
  null
)(injectIntl(Calendar));
