import React from "react";
import { connect } from "react-redux";

// import userImage from "../../assets/images/profile-image.jpg";
import "./style.scss";
import AsyncImg from "../AsyncImg";

const Avatar = props => {
  return (
    <div className="avatar-container">
      <AsyncImg
        alt="SourceImg"
        img={props.img}
        style={{
          borderColor: props.theme.accentColor
        }}
        onError={e =>
          props.fallbackImage ? (e.target.src = props.fallbackImage) : null
        }
      />
    </div>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme
  };
};

export default connect(mapStateToProps, null)(Avatar);
