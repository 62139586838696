import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { ReactComponent as ZoomIN } from "../../assets/images/zoom-icon.svg";
import { ReactComponent as DownloadIcon } from "../../assets/images/download-icon.svg";
import AttachmentType from "../AttachmentType";
import DeleteButton from "../DeleteButton";
import { deleteAttachment } from "../../actions";
import "./style.scss";

const ImagesType = ["png", "jpg", "gif", "jpeg", "svg"];

class AttachmentImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.deleteItem = this.deleteItem.bind(this);
  }

  openFile = attachment => {
    this.setState({ loading: true });
    axios.get(attachment.AttachmentURL, { responseType: "blob" }).then(res => {
      this.setState({ loading: false });
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = URL.createObjectURL(res.data);
      a.download = attachment.AttachmentName;
      document.body.appendChild(a);
      return a.click();
    });
  };

  deleteItem = () => {
    const { type, attachment, deleteAttachment } = this.props;
    this.setState({ loading: true }, () => {
      axios
        .post(`${type}/DeleteAttachment/${attachment.AttachmentID}`)
        .then(res => {
          if (res.data.StatusCode === "Success") {
            deleteAttachment(type, attachment.AttachmentID);
          }
          this.setState({ loading: false });
        });
    });
  };

  render() {
    const { attachment, onClick, ltr, theme, deletable, type } = this.props;
    const { loading } = this.state;

    if (ImagesType.includes(attachment.AttachmentType.toLowerCase()))
      return (
        <div
          className={ltr ? "image-box rtl tooltip" : "image-box tooltip"}
          onClick={onClick}
        >
          {deletable && type ? (
            <div className="delete-attach-button">
              <DeleteButton
                deleteAction={this.deleteItem}
                loading={loading}
                attachment
              />
            </div>
          ) : null}
          <img src={attachment.AttachmentURL} alt="SourceImg" />

          <div
            className="tooltiptext"
            style={{ backgroundColor: theme.accentColor }}
          >
            <ZoomIN style={{ fill: theme.primary }} />
          </div>
        </div>
      );
    return (
      <div
        className={
          ltr
            ? "image-box rtl tooltip file-image"
            : "image-box tooltip file-image"
        }
      >
        {deletable && type && !loading ? (
          <div className="delete-attach-button">
            <DeleteButton
              deleteAction={this.deleteItem}
              loading={loading}
              attachment
            />
          </div>
        ) : null}
        <div className="default-images">
          <AttachmentType type={attachment.AttachmentType} loading={loading} />
        </div>
        {!loading && (
          <div
            onClick={() => this.openFile(attachment)}
            className="tooltiptext"
            style={{ backgroundColor: theme.accentColor }}
          >
            <DownloadIcon style={{ fill: theme.primary }} />
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = { deleteAttachment };

export default connect(null, mapDispatchToProps)(AttachmentImage);
