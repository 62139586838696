import React, { PureComponent } from "react";
import { connect } from "react-redux";
import "./style.scss";

import { BarChart, Bar, XAxis, Tooltip } from "recharts";

const CustomTooltip = ({ active, payload, label, ltr, theme }) => {
  if (active) {
    return (
      <div className={ltr ? "chartWrapper card" : "chartWrapper card ltr"}>
        <div className="tool-tip-labels-container">
          <div className="tool-tip-label">
            <div
              className="tool-tip-circle"
              style={{ backgroundColor: theme.primary }}
            />
            <div className="tool-tip-title">: {payload[0].payload.pv}</div>
          </div>
          <div className="second-tool-tip-label">
            <div
              className="tool-tip-circle"
              style={{ backgroundColor: "rgb(142, 142, 142)" }}
            />
            <div className="tool-tip-title">: {payload[0].payload.uv}</div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

class SideNavBarChart extends PureComponent {
  render() {
    return (
      <BarChart
        width={340}
        height={200}
        data={this.props.data}
        margin={{
          top: 10,
          right: 5,
          left: 5,
          bottom: 10
        }}
        barSize={10}
        barGap={0}
      >
        <text fontSize="8" />
        <XAxis
          dataKey="name"
          minTickGap={0}
          tickSize={0}
          stroke={this.props.theme.accentColor}
          tick={{ fill: "unset" }}
          dy={15}
        />
        <Tooltip
          content={
            <CustomTooltip ltr={this.props.ltr} theme={this.props.theme} />
          }
        />
        <Bar dataKey="pv" fill={this.props.theme.primary} />
        <Bar dataKey="uv" fill="#535353" />
      </BarChart>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    ltr: state.locale.ltr
  };
};

export default connect(
  mapStateToProps,
  null
)(SideNavBarChart);
