import React, { Component } from "react";
import { css } from "emotion";
import { RadioGroup, Radio } from "react-radio-group";
import { connect } from "formik";
import { FormattedMessage } from "react-intl";
import "./style.scss";

class RadioInput extends Component {
  customTheme = theme => ({
    ...theme,
    borderRadius: 5,
    colors: {
      ...theme.colors,
      primary: this.props.theme.primary,
      primary75: this.props.theme.primaryLight,
      primary50: this.props.theme.primaryLight,
      primary25: this.props.theme.primaryLight
    }
  });

  customStyles = {
    option: (provided, state) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      height: 40
    }),
    input: (provided, state) => ({
      height: 40,
      borderColor: this.props.theme.accentColor
    }),
    multiValue: (provided, state) => ({
      ...provided,
      background: this.props.theme.primary,
      color: "white",
      height: 30,
      paddingLeft: 5,
      paddingRight: 5,
      display: "flex",
      alignItems: "center",
      borderRadius: 5
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      fontSize: 20,
      cursor: "pointer",
      "&:hover": { background: this.props.theme.primary, color: "white" }
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: "white",
      paddingRight: this.props.locale.ltr ? 20 : 5,
      paddingLeft: this.props.locale.ltr ? 5 : 20
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: this.props.theme.accentColor,
      boxShadow: "none",
      "&:hover": { borderColor: this.props.theme.accentColor }
    }),
    indicatorsContainer: (provided, state) => ({
      display: "none"
    })
  };

  handleChange = value => {
    this.props.formik.setFieldValue(this.props.name, value);
  };

  handleBlur = () => {
    this.props.handleBlur(this.props.name, true);
  };

  componentDidMount() {
    this.props.formik.registerField(this.props.name, this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.name !== prevProps.name) {
      this.props.formik.unregisterField(prevProps.name);
      this.props.formik.registerField(this.props.name, this);
    }

    if (this.props.validate !== prevProps.validate) {
      this.props.formik.registerField(this.props.name, this);
    }
  }

  componentWillUnmount() {
    this.props.formik.unregisterField(this.props.name);
  }

  render() {
    const { name, formik, options, theme, withIcon, locale } = this.props;
    return (
      <RadioGroup
        name={name}
        selectedValue={formik.values[name]}
        onChange={this.handleChange}
        className={locale.ltr ? "radio-group ltr" : "radio-group"}
      >
        {options.map((option, index) => {
          return (
            <label className="radio-option" key={index}>
              <Radio value={option.value} style={{}} />
              <div
                className={
                  css`
                    &:before {
                      border: 1px solid ${theme.accentColor};
                    }
                    &:after {
                      background: ${theme.primary};
                    }
                  ` + " componentContainer"
                }
              >
                {option.component ? (
                  <option.component theme={theme} value={option.value} />
                ) : (
                  <div className="label-container">
                    {withIcon ? (
                      <p className="label-text">{option.label}</p>
                    ) : (
                      <p className="label-text">
                        <FormattedMessage
                          id={`radio-input.${option.value}`}
                          defaultMessage={`radio-input.${option.value}`}
                        />
                      </p>
                    )}
                  </div>
                )}
              </div>
            </label>
          );
        })}
      </RadioGroup>
    );
  }
}

export default connect(RadioInput);
