import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./style.scss";
import Circles from "../Circles";
import { getDisplayName } from "../../utils";
import AsyncImg from "../AsyncImg";
import { ReactComponent as ArrowIcon } from "../../assets/images/arrow-down.svg";

const userInfo = {
  name: "عبدالله العنيزي",
  responsibleNum: 15,
  informedNum: 100,
  accountableNum: 4,
  consultedNum: 3,
};

class SideNavHeader extends Component {
  openProfile() {
    const { history } = this.props;
    history.push("/profile");
  }
  render() {
    const { theme, locale, user } = this.props;
    return (
      <div
        className={`header-nav-side-page card hidden ${locale.ltr && "ltr"}`}
        style={{ borderColor: theme.accentColor }}
      >
        <div
          className={locale.ltr ? "header-container ltr" : "header-container"}
        >
          <div
            className="logo-text-container"
            onClick={() => this.openProfile()}
          >
            <ArrowIcon style={{ fill: theme.textGray }} />
            <div className="photo-container">
              <AsyncImg
                alt="SourceImg"
                img={user.UserData.UserProfilePicture}
                style={{
                  borderColor: theme.accentColor,
                }}
              />
            </div>
            <div
              className={locale.ltr ? "text-container ltr" : "text-container"}
            >
              <p className="bold" style={{ color: theme.primary }}>
                {" "}
                {getDisplayName(user.UserData, locale.ltr)}
              </p>
              <p style={{ color: theme.textGray }}>
                <FormattedMessage
                  id="sidenave.watchprofile"
                  defaultMessage="Watch profile"
                />
              </p>
            </div>
          </div>
          <Circles userInfo={userInfo} big={false} />
        </div>
      </div>
    );
  }
}

SideNavHeader.propTypes = {
  intl: PropTypes.object,
  theme: PropTypes.object,
  locale: PropTypes.object,
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ...state,
  };
};

export default connect(
  mapStateToProps,
  null
)(injectIntl(withRouter(SideNavHeader)));
