import React, { Component } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { FormattedMessage } from "react-intl";
import { connect } from "formik";

import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";
import { attachmentsWhitelist } from "../../configs";
import { ReactComponent as UploadIcon } from "../../assets/images/upload-image.svg";

import "./style.scss";

const allwedHeaderTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

class UploadInput extends Component {
  state = {
    openModal: false
  };
  baseStyle = {
    width: "100%",
    height: 38,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: this.props.theme.accentColor,
    borderStyle: "solid",
    borderRadius: 5,
    cursor: "pointer"
  };
  activeStyle = {
    borderStyle: "solid",
    borderColor: this.props.theme.primary,
    backgroundColor: this.props.theme.backgroundColor
  };
  rejectStyle = {
    borderStyle: "solid",
    borderColor: "#c66",
    backgroundColor: "#eee"
  };

  checkDuplicate = (files, attachments) => {
    // TO_DO logic needs to be fixed
    let noDup = [];
    attachments.forEach(attachment => {
      noDup = files.filter(
        item =>
          attachment.name !== item.name &&
          attachment.size !== item.size &&
          attachment.lastModified !== item.lastModified
      );
    });
    return noDup;
  };

  checkSize = files => {
    const { formik, name, intl } = this.props;
    if (files.reduce((acc, file) => acc + file.size, 0) / 1000000000 > 2) {
      formik.setErrors({
        ...formik.errors,
        [name]: intl.formatMessage({
          id: "uploadInput.maxSize",
          defaultMessage: "Size of all files must not exceed 2GB"
        })
      });
      return true;
    }
    return false;
  };

  getAttachmentsList = () => {
    const attachments = this.props.formik.values[this.props.name];
    return attachments.map((attachment, index) => {
      return (
        <div
          className="uploaded-attachment"
          style={{
            background: this.props.theme.backgroundColor,
            borderColor: this.props.theme.accentColor
          }}
          key={index}
        >
          <p>{attachment.name}</p>
          <div className="attachment-actions">
            {this.props.headerImage ? this.headerSelector(attachment) : null}
            <CloseIcon
              className="remove-icon"
              style={{ fill: this.props.theme.textGray }}
              onClick={() => this.removeItem(index)}
            />
          </div>
        </div>
      );
    });
  };

  handleChange = files => {
    const { formik, name, multi } = this.props;
    formik.setFieldTouched(name, true, false);
    formik.setErrors({
      ...formik.errors,
      [name]: null
    });
    if (files.length < 1) return [];
    let acceptedFiles = [...files];
    const attachments = formik.values[name];
    if (multi && attachments.length > 0) {
      acceptedFiles = this.checkDuplicate(acceptedFiles, attachments);
    }
    if (this.checkSize([...attachments, ...acceptedFiles])) {
      acceptedFiles = [];
    }
    formik.setFieldValue(
      name,
      multi
        ? [...attachments, ...acceptedFiles]
        : acceptedFiles.length > 0
        ? [acceptedFiles[0]]
        : [],
      false
    );
  };

  removeItem = index => {
    const newAttachments = [...this.props.formik.values[this.props.name]];
    newAttachments.splice(index, 1);
    this.props.formik.setFieldValue(this.props.name, newAttachments);
  };

  makeHeader = attachment => {
    this.props.formik.setFieldValue("MainImage", attachment);
  };

  removeHeader = () => {
    this.props.formik.setFieldValue("MainImage", null);
  };

  headerSelector = attachment => {
    if (!allwedHeaderTypes.includes(attachment.type)) return null;
    const MainImage = this.props.formik.values.MainImage;
    if (
      MainImage &&
      MainImage.name === attachment.name &&
      MainImage.size === attachment.size &&
      MainImage.lastModified === attachment.lastModified
    ) {
      return (
        <p
          className="set-header-text"
          style={{ color: this.props.theme.textGray }}
          onClick={this.removeHeader}
        >
          <FormattedMessage
            id="uploadInput.removeHeader"
            defaultMessage="Remove main image"
          />
        </p>
      );
    } else {
      return (
        <p
          className="set-header-text"
          style={{ color: this.props.theme.primary }}
          onClick={() => this.makeHeader(attachment)}
        >
          <FormattedMessage
            id="uploadInput.setHeader"
            defaultMessage="Set as main image"
          />
        </p>
      );
    }
  };

  handleReject = files => {
    const { formik, name, intl, multi, imageOnly } = this.props;
    let message = {
      id: "uploadInput.notAllowed",
      defaultMessage: "File type is not allawed"
    };
    if (!multi && files.length > 1) {
      message = {
        id: "uploadInput.oneFile",
        defaultMessage: "Only one file is allowed"
      };
    } else if (imageOnly) {
      message = {
        id: "uploadInput.onlyImages",
        defaultMessage: "Only images are allowed"
      };
    }
    formik.setErrors({
      ...formik.errors,
      [name]: intl.formatMessage(message)
    });
  };

  componentDidMount() {
    this.props.formik.registerField(this.props.name, this);
  }
  componentDidUpdate(prevProps) {
    if (this.props.name !== prevProps.name) {
      this.props.formik.unregisterField(prevProps.name);
      this.props.formik.registerField(this.props.name, this);
    }

    if (this.props.validate !== prevProps.validate) {
      this.props.formik.registerField(this.props.name, this);
    }
  }

  componentWillUnmount() {
    this.props.formik.unregisterField(this.props.name);
  }

  render() {
    const {
      name,
      formik,
      theme,
      multi,

      imageOnly,
      locale,

      errors
    } = this.props;

    const attachments = formik.values[name];

    return (
      <div className={locale.ltr ? "upload-input ltr" : "upload-input"}>
        {!multi && attachments.length > 0 ? null : (
          <Dropzone
            accept={imageOnly ? allwedHeaderTypes : attachmentsWhitelist}
            name={name}
            selectedValue={attachments}
            onDrop={this.handleChange}
            onBlur={formik.onBlur}
            multiple={!!multi}
            onDropRejected={this.handleReject}
          >
            {({
              getRootProps,
              getInputProps,
              isDragActive,
              isDragAccept,
              isDragReject,
              acceptedFiles,
              rejectedFiles,
              files
            }) => {
              let styles = { ...this.baseStyle };
              styles = isDragActive
                ? { ...styles, ...this.activeStyle }
                : styles;
              styles = isDragReject
                ? { ...styles, ...this.rejectStyle }
                : styles;

              return (
                <div {...getRootProps()} style={styles}>
                  <input {...getInputProps()} name={name} />
                  <div
                    className={
                      errors
                        ? "upload-text-container error"
                        : "upload-text-container"
                    }
                    style={{
                      borderColor: errors ? theme.errorColor : theme.accentColor
                    }}
                  >
                    <div className="upload-image-container"> </div>
                    <div
                      className="upload-sub-title"
                      style={{ color: theme.textGray }}
                    >
                      <UploadIcon />
                      <div>
                        {imageOnly ? (
                          <FormattedMessage
                            id="uploadInput.allowedFilesImages"
                            defaultMessage="You can upload only images"
                          />
                        ) : (
                          <FormattedMessage
                            id="uploadInput.allowedFiles"
                            defaultMessage="You can upload images, videos and documents only"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          </Dropzone>
        )}

        {attachments.length > 0 && (
          <div className="attachments">{this.getAttachmentsList()}</div>
        )}
      </div>
    );
  }
}

UploadInput.propTypes = {
  theme: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired,
  multi: PropTypes.bool,
  headerImage: PropTypes.bool
};

export default connect(UploadInput);
