import React, * as react from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import DaysSince from "../DaysSince";
import LoadingComponent from "../Loading";
import { ReactComponent as Clock } from "../../assets/images/clock-icon.svg";
import { ReactComponent as Users } from "../../assets/images/users-icon.svg";
import { ReactComponent as Arrow } from "../../assets/images/arrow-down.svg";
import { ReactComponent as News } from "../../assets/images/nav/news-icon.svg";
import { ReactComponent as Tasks } from "../../assets/images/nav/tasks-icon.svg";
import { ReactComponent as Meetings } from "../../assets/images/nav/meetings-icon.svg";
import { ReactComponent as Projects } from "../../assets/images/nav/projects-icon.svg";
import { ReactComponent as Committees } from "../../assets/images/nav/committees-icon.svg";
import { ReactComponent as Adhoc } from "../../assets/images/nav/adhoc-icon.svg";
import EmptyComponent from "../../components/EmptyComponents/EmptyComponent";

import "./style.scss";

const types = {
  task: { path: "/tasks/", name: "tasks", icon: Tasks },
  news: { path: "/news/", name: "news", icon: News },
  project: { path: "/projects/", name: "projects", icon: Projects },
  committee: { path: "/committee/", name: "committees", icon: Committees },
  meeting: { path: "/meeting/", name: "meetings", icon: Meetings, modal: true },
  meeting_request: {
    path: "/meeting-requests/",
    name: "meetings",
    icon: Meetings
  },
  adhoc: { path: "/adhoc/", name: "adhoc", icon: Adhoc }
};

class NotificationDropDownList extends react.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      notifications: [],
      noMore: false,
      PagingInfo: ""
    };
    this.openNotification = this.openNotification.bind(this);
    this.getNotifications = this.getNotifications.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    this.getNotifications();
  }

  getNotifications() {
    const { PagingInfo, notifications } = this.state;
    this.setState({ loading: true }, () => {
      axios
        .get("Notification", { params: { PagingInfo } })
        .then(res => {
          if (res.data.Data.length > 0) {
            this.setState({
              notifications: [...notifications, ...res.data.Data],
              PagingInfo: res.data.ListItemCollectionPosition
                ? res.data.ListItemCollectionPosition.PagingInfo
                : "",
              noMore: !res.data.ListItemCollectionPosition,
              loading: false
            });
          } else {
            this.setState({ loading: false });
          }
        })
        .catch(err => this.setState({ loading: false }));
    });
  }

  getIcon(type) {
    if (Object.keys(types).includes(type.toLowerCase())) {
      const Icon = types[type.toLowerCase()].icon;
      return <Icon className="notif-icon" />;
    }
    return <Users />;
  }

  openNotification(item) {
    const { history } = this.props;
    axios.post(`Notification/Read/${item.ID}`);
    history.push({
      pathname: `${types[item.Type.toLowerCase()].path}${item.SourceID}`,
      state: { modal: types[item.Type.toLowerCase()].modal }
    });
  }

  handleScroll(e) {
    const current = e.target;
    const { loading, noMore } = this.state;
    const offset = 400;
    if (
      !loading &&
      !noMore &&
      current.scrollHeight - current.scrollTop <= current.offsetHeight + offset
    ) {
      this.getNotifications();
    }
  }

  render() {
    const { locale, theme, style, theTitle, intl } = this.props;
    const { loading, notifications } = this.state;

    return (
      <div
        className={
          locale.ltr ? "notification-drop-down ltr" : "notification-drop-down"
        }
        style={style}
      >
        <div className="option-ulcontainer-notification">
          <div className="notifications" onScroll={this.handleScroll}>
            <div className="title">
              <p>{theTitle}</p>
            </div>

            {notifications.length === 0 && !loading ? (
              <EmptyComponent
                message={intl.formatMessage({
                  id: "emptyComponent.title.notification",
                  defaultMessage: "Notification"
                })}
              />
            ) : (
              notifications.map((item, i) => (
                <div
                  key={i}
                  className="options"
                  style={{
                    borderColor: theme.accentColor,
                    backgroundColor: item.isRead ? "white" : theme.primaryLight
                  }}
                  onClick={() => this.openNotification(item)}
                >
                  <div className="content">
                    <div className="circle-container">
                      <div
                        className="circle"
                        style={{ backgroundColor: theme.primary }}
                      >
                        {this.getIcon(item.Type)}
                      </div>
                    </div>

                    <div className="notification-container">
                      <p>
                        {item[`Body${locale.suffix}`]
                          ? item[`Body${locale.suffix}`]
                          : item[`Title{locale.suffix}`]}
                      </p>
                      <div className="date-container">
                        <div
                          className={
                            locale.ltr ? "clock-icon ltr" : "clock-icon"
                          }
                        >
                          <Clock style={{ fill: theme.accentColor }} />
                        </div>
                        <DaysSince
                          startDate={item.Date}
                          style={{ color: theme.accentColor }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      locale.ltr ? "arrow-container ltr" : "arrow-container"
                    }
                  >
                    <Arrow style={{ fill: theme.accentColor }} />
                  </div>
                </div>
              ))
            )}
            {loading ? (
              <div className="notif-loading-container">
                <LoadingComponent color={theme.primary} small />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
NotificationDropDownList.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
  theme: PropTypes.object,
  style: PropTypes.object,
  className: PropTypes.string,
  clickable: PropTypes.bool
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ...state
  };
};

export default connect(
  mapStateToProps,
  null
)(injectIntl(withRouter(NotificationDropDownList)));
