import React, * as react from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./style.scss";

class OptionList extends react.Component {
  render() {
    const { list, locale, theme, style, theTitle, selected, intl } = this.props;
    return (
      <div
        className={
          locale.ltr
            ? "options-list-component-lang ltr"
            : "options-list-component-lang"
        }
        style={style}
      >
        <div className="clickable-ulcontainer">
          <ul>
            <li>
              <span className="title" style={{ color: theme.textGray }}>
                {theTitle}
              </span>
              <div className="line" />
            </li>
            {list.map((item, i) => (
              <li
                key={i}
                onClick={() => item.action(item.name)}
                style={{
                  backgroundColor: selected === item.id ? theme.primary : "",
                  color: selected === item.id ? "white" : ""
                }}
              >
                <div className="ul-container">
                  <div className="title">
                    <span>
                      {intl.formatMessage({
                        id: `language.${item.name}`,
                        defaultMessage: item.name
                      })}
                    </span>
                  </div>
                  {selected === item.id ? (
                    <div className="sign">
                      <span>&#10003;</span>
                    </div>
                  ) : null}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
OptionList.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
  theme: PropTypes.object,
  style: PropTypes.object,
  className: PropTypes.string,
  clickable: PropTypes.bool
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ...state
  };
};

export default connect(
  mapStateToProps,
  null
)(injectIntl(withRouter(OptionList)));
