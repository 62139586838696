import React, { Component } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import Modal from "../Modal";
import {
  hideModal,
  postponeMeetingRequest,
  updateTimelineMeetings
} from "../../actions";
import FormInput from "../FormInput";

import Button from "../Button";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";
import "./style.scss";

const targets = [
  "react-datepicker__day-name",
  "react-datepicker__navigation",
  "react-datepicker__week",
  "react-datepicker__day-names",
  "react-datepicker",
  "react-datepicker__current-month",
  "react-datepicker__header",
  "react-datepicker__triangle",
  "react-datepicker__month-container",
  "react-datepicker__day",
  "react-datepicker-time__header",
  "form-field"
];
class PostponeMeetingRequestModal extends Component {
  submitAction = undefined;
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorSameDate: false,
      errorMessege: "",
      targetList: "",
      height: false,
      SuggestedDate: "",
      initialValues: {
        SuggestedDate: ""
      },
      target: "",

      loading: false
    };
    this.ref = React.createRef();
    this.refs = React.createRef();
  }

  postponeMeeting = (values, errors) => {
    if (values.SuggestedDate === "" || !values.SuggestedDate) {
      this.setState({
        error: true,
        errorMessege: "dateRequired"
      });
    }
    if (!this.state.error && values.SuggestedDate !== "") {
      this.setState({ loading: true }, () => {
        this.props.postponeMeetingRequest(
          this.props.id,
          new Date(values.SuggestedDate).getTime()
        );
        if (this.props.timeline)
          this.props.updateTimelineMeetings(this.props.id);
      });
    }
  };

  classLists = e => {
    window.addEventListener("click", e => {
      const paths = e.composedPath().map(path => path.className);

      if (
        !paths.includes("react-datepicker-wrapper") ||
        e.target.className === "react-datepicker__input-container"
      ) {
        this.setState({
          height: false,
          target: e.target.className,
          targetList: e.target.classList[0]
        });
      } else {
        this.setState({
          height: true,
          target: e.target.className,
          targetList: e.target.classList[0]
        });
      }
    });

    window.addEventListener("keydown", e => {
      if (e.key === "Tab") {
        this.setState({
          height: false,
          targetList: "",
          target: ""
        });
      }
    });
  };

  validateErrors = values => {
    const date = new Date(this.props.meetingDate * 1000);

    if (values.SuggestedDate === "" || !values.SuggestedDate) {
      this.setState({
        error: true,
        errorMessege: "dateRequired"
      });
    }

    if (date.getTime() === values.SuggestedDate.getTime()) {
      this.setState({
        error: true,
        errorMessege: "SameDate"
      });
    } else if (
      values.SuggestedDate.getTime() < new Date(new Date().getTime())
    ) {
      this.setState({
        error: true,
        errorMessege: "dueDateInFuture"
      });
    } else {
      this.setState({
        error: false
      });
    }
  };

  render() {
    const { hideModal } = this.props;

    return (
      <div onClick={e => this.classLists(e)}>
        <Modal
          isOpen
          title="PostponeMeeting"
          hideModal={hideModal}
          height={
            this.state.height ||
            targets.includes(this.state.target) ||
            targets.includes(this.state.targetList)
              ? "50%"
              : null
          }
        >
          <Formik
            validate={values => {
              this.validateErrors(values);
            }}
            ref={this.ref}
            initialValues={this.state.initialValues}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
            }}
          >
            {({ isSubmitting, values, errors, touched }) => (
              <Form autoComplete="off">
                <input
                  autoComplete="false"
                  name="hidden"
                  type="text"
                  style={{ display: "none" }}
                />
                <FormInput
                  type="dateTime"
                  time
                  name="SuggestedDate"
                  required
                  errorSameDate={this.state.errorSameDate}
                  minDate={new Date()}
                  errors={touched["SuggestedDate"] && this.state.error}
                />
                {this.state.error ? (
                  <div
                    className={
                      this.props.locale.ltr
                        ? "error-postpone-messege ltr"
                        : "error-postpone-messege"
                    }
                  >
                    {this.props.intl.formatMessage({
                      id: `validation.${this.state.errorMessege}`,
                      defaultMessage: "Due date can't be older than today"
                    })}
                  </div>
                ) : null}

                <div
                  className={
                    this.state.height ||
                    targets.includes(this.state.target) ||
                    targets.includes(this.state.targetList)
                      ? "postpone-buttons-modal-container on-open-date-picker"
                      : "postpone-buttons-modal-container"
                  }
                >
                  <div
                    className={
                      this.props.locale.ltr
                        ? "postpone-buttons-modal ltr"
                        : "postpone-buttons-modal"
                    }
                  >
                    <Button
                      loading={this.state.loading}
                      onClick={() => {
                        this.postponeMeeting(values, errors);
                      }}
                    >
                      <FormattedMessage
                        id="confirmation.confirm"
                        defaultMessage="Confirm"
                      />
                    </Button>
                    <Button onClick={() => this.props.hideModal()}>
                      <FormattedMessage
                        id="confirmation.cancel"
                        defaultMessage="Cancel"
                      />
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    locale: state.locale
  };
};

const mapDispatchToProps = {
  hideModal,
  postponeMeetingRequest,
  updateTimelineMeetings
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PostponeMeetingRequestModal));
