import React, * as react from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl, FormattedMessage } from "react-intl";
import { hideToast } from "../../actions";
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";
import Button from "../Button";
import "./style.scss";

class Toast extends react.Component {
  timer = null;
  time = 5000;

  componentDidMount() {
    this.timer = setTimeout(() => this.removeToast(), this.time);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  removeToast = () => {
    this.props.hideToast();
  };

  onMouseEnter = () => {
    clearTimeout(this.timer);
  };

  routeTo = () => {
    if (this.props.toast.pageType === "meeting") {
      this.props.history.push({
        pathname: `/${this.props.toast.pageType}/${this.props.toast.ID}`,
        state: { modal: true }
      });
    } else {
      this.props.history.push(
        `/${this.props.toast.pageType}/${this.props.toast.ID}`
      );
    }
    this.props.hideToast();
  };

  onMouseOut = () => {
    this.timer = setTimeout(() => this.removeToast(), this.time);
  };

  appearRouteButton = () => {
    const { toast } = this.props;
    if (toast.pageType === "Members") {
      return false;
    } else if (!toast.isUpdate) {
      return true;
    } else if (toast.isCommittee && toast.pageType === "meeting") {
      return true;
    } else {
      return false;
    }
  };

  setMesseges = () => {
    const { toast } = this.props;
    if (
      toast.pageType === "Members" ||
      toast.pageType === "Attachment" ||
      toast.pageType === "uploadFile"
    ) {
      return "toast.add";
    } else if (!toast.isUpdate) {
      return "toast.titles";
    } else {
      return "toast.edit";
    }
  };

  render() {
    const { theme, intl } = this.props;

    return (
      <div
        onMouseEnter={event => this.onMouseEnter()}
        onMouseLeave={event => this.onMouseOut()}
      >
        <div className="toast">
          <div className="text-container">
            <FormattedMessage
              id={this.setMesseges()}
              values={{
                title: intl.formatMessage({
                  id: `toast.${this.props.toast.pageType}`,
                  defaultMessage: `toast.${this.props.toast.pageType}`
                })
              }}
              defaultMessage={this.setMesseges()}
            />
          </div>
          <div className="buttons-container">
            {this.appearRouteButton() ? (
              <Button
                className="buttons route-button"
                onClick={() => this.routeTo()}
                style={{
                  backgroundColor: theme.primary
                }}
              >
                <FormattedMessage
                  id="toast.buttonRouteTo"
                  values={{
                    title: intl.formatMessage({
                      id: `toast.${this.props.toast.pageType}`,
                      defaultMessage: `toast.${this.props.toast.pageType}`
                    })
                  }}
                  defaultMessage="toast.buttonRouteTo"
                />
              </Button>
            ) : null}
            <button
              className=" buttons close-button"
              style={{
                backgroundColor: "transparent"
              }}
              onClick={() => this.props.hideToast()}
            >
              <CloseIcon style={{ fill: theme.textColor }} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    toast: state.toast
  };
};
const mapDispatchToProps = { hideToast };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(Toast)));
