export const PROJECT_ERROR = "PROJECT_ERROR";
export const CLEAR_PROJECT_ERRORS = "CLEAR_PROJECT_ERRORS";
export const STOP_PROJECT_LOADING = "STOP_PROJECT_LOADING";
export const FETCH_PROJECTS = "FETCH_PROJECTS";
export const FETCH_PROJECTS_ASYNC = "FETCH_PROJECTS_ASYNC";
export const FETCH_PROJECT_DETAILS = "FETCH_PROJECT_DETAILS";
export const FETCH_PROJECT_DETAILS_ASYNC = "FETCH_PROJECT_DETAILS_ASYNC";
export const PROJECT_LOADING = "PROJECT_LOADING";
export const PROJECT_DETAILS_LOADING = "PROJECT_DETAILS_LOADING";
export const SAVE_PP_HANDSHAKE = "SAVE_PP_HANDSHAKE";
export const CLEAR_PROJECTS_ON_LOGOUT = "CLEAR_PROJECTS_ON_LOGOUT";

export function projectError(reason) {
  return { type: PROJECT_ERROR, reason };
}

export function clearProjectErrors(reason) {
  return { type: CLEAR_PROJECT_ERRORS, reason };
}

export function stopProjectLoading() {
  return { type: STOP_PROJECT_LOADING };
}

// Fetch Projects

export function fetchProjects(token, ppBaseUrl) {
  return { type: FETCH_PROJECTS, token, ppBaseUrl };
}

export function fetchProjectsAsync(projects, ppBaseUrl) {
  return { type: FETCH_PROJECTS_ASYNC, projects, ppBaseUrl };
}

export function fetchProjectDetails(projectId, token, ppBaseUrl) {
  return { type: FETCH_PROJECT_DETAILS, projectId, token, ppBaseUrl };
}

export function fetchProjectDetailsAsync(project, ppBaseUrl) {
  return { type: FETCH_PROJECT_DETAILS_ASYNC, project, ppBaseUrl };
}

export function projectLoading() {
  return { type: PROJECT_LOADING };
}

export function projectDetailsLoading() {
  return { type: PROJECT_DETAILS_LOADING };
}

export function savePPHandshake(handshake) {
  return { type: SAVE_PP_HANDSHAKE, handshake };
}

// clear projects

export function clearProjectsOnLogOut() {
  return { type: CLEAR_PROJECTS_ON_LOGOUT };
}
