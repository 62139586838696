import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

function StatusIndicator(props) {
  const { color, text } = props;

  return (
    <div className="taskStatus">
      <div
        className="statusIndicator"
        style={{
          backgroundColor: color
        }}
      />
      <p className="statusText">{text}</p>
    </div>
  );
}

StatusIndicator.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default StatusIndicator;
