import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

import "./style.scss";

class FolderEmptyComponent extends Component {
  render() {
    const { color } = this.props;
    return (
      <div className="folder-empty-component" style={{ color }}>
        <FormattedMessage
          id="folderEmptyComponent.title"
          defaultMessage="The folder is empty"
          className="text"
        />
      </div>
    );
  }
}

export default FolderEmptyComponent;
