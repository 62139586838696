import {
  ADD_MEETING_ASYNC,
  MEETING_LOADING,
  MEETINGS_LOADING,
  CREATE_MEETING_LOADING,
  FETCH_MEETING_ASYNC,
  MEETING_FAILED,
  REMOVE_MEETING_ACTION,
  CANCEL_MEETING_ACTION,
  DELETE_MEETING_ATTACHMENT,
  UPDATE_REQUESTS_COUNT_LOADING,
  UPDATE_REQUESTS_COUNT_ASYNC,
  ADD_MOM,
  UPDATE_MEETING_UPLOAD_PROGRESS,
  FETCH_UPCOMING_MEETINGS_ASYNC,
  UPCOMING_MEETINGS_LOADING,
  FETCH_GOOGLE_MEETING,
  EMPTY_GOOGLE_MEETING,
  MEETING_ADD_COMMENT_ASYNC,
  MEETING_COMMENT_LOADING,
  DELETE_MEETING_COMMENT
} from "../actions";
import { filterList, arrangeGoogleData } from "../utils";

const initialState = {
  Loading: true,
  SingleLoading: true,
  CreateLoading: false,
  CommentLoading: false,
  RequestsCount: 0,
  RequestsLoading: false,
  Error,
  Meetings: [],
  Meeting: {},
  Progress: "",
  upcomingMeetings: [],
  upcomingMeetingsLoading: false,
  googleMeeting: []
};

function meeting(state = initialState, action) {
  switch (action.type) {
    case ADD_MEETING_ASYNC:
      return {
        ...state,
        CreateLoading: false,
        Error: "",
        Progress: "",
        Meetings: action.meeting.ID
          ? [...filterList(action.meeting.ID, state.Meetings), action.meeting]
          : [{ ...action.meeting }, ...state.Meetings],
        Meeting: action.meeting
      };
    case FETCH_MEETING_ASYNC:
      const data = Array.isArray(action.meetings)
        ? {
            Meetings: action.meetings.map(meeting => ({
              ...meeting
            }))
          }
        : {
            Meeting: {
              ...action.meetings
            }
          };
      return {
        ...state,
        Loading: false,
        SingleLoading: false,
        Error: "",
        ...data
      };

    case FETCH_UPCOMING_MEETINGS_ASYNC:
      return {
        ...state,
        upcomingMeetingsLoading: false,
        upcomingMeetings: action.upcomingMeetings
      };
    case UPDATE_REQUESTS_COUNT_ASYNC:
      return {
        ...state,
        RequestsLoading: false,
        RequestsCount: action.count
      };
    case REMOVE_MEETING_ACTION:
      const Meetings = state.Meetings.filter(
        meeting => meeting.ID !== action.id
      );
      return {
        ...state,
        Meetings
      };
    case ADD_MOM:
      return {
        ...state,
        Meeting: {
          ...state.Meeting,
          MOM: action.mom,
          HasMOM: true,
          Attachment: [...state.Meeting.Attachment, ...action.mom.MOMAttachment]
        },
        Meetings:
          state.Meetings.length > 0 &&
          state.Meetings.map(meeting =>
            meeting.ID === action.mom.MeetingID
              ? { ...meeting, HasMOM: true }
              : meeting
          )
      };
    case CANCEL_MEETING_ACTION:
      const newMeetings = state.Meetings.map(meeting =>
        meeting.ID === action.id
          ? {
              ...meeting,
              Status: "3",
              CancelationReason: action.CancelationReason
            }
          : meeting
      );
      const newMeeting =
        state.Meeting && state.Meeting.ID === action.id
          ? {
              ...state.Meeting,
              Status: "3",
              CancelationReason: action.CancelationReason
            }
          : state.Meeting;
      return {
        ...state,
        Meetings: newMeetings,
        Meeting: newMeeting
      };
    case DELETE_MEETING_ATTACHMENT:
      const newAttachments = state.Meeting.Attachment.filter(
        attachment => attachment.AttachmentID !== action.id
      );
      return {
        ...state,
        Meeting: {
          ...state.Meeting,
          Attachment: newAttachments
        }
      };
    case UPDATE_MEETING_UPLOAD_PROGRESS:
      return {
        ...state,
        Progress: action.progress
      };
    case MEETINGS_LOADING:
      return {
        ...state,
        Loading: true
      };
    case UPCOMING_MEETINGS_LOADING:
      return {
        ...state,
        upcomingMeetingsLoading: true
      };
    case MEETING_LOADING:
      return {
        ...state,
        SingleLoading: true
      };
    case UPDATE_REQUESTS_COUNT_LOADING:
      return {
        ...state,
        RequestsLoading: true
      };
    case CREATE_MEETING_LOADING:
      return {
        ...state,
        CreateLoading: true
      };
    case MEETING_FAILED:
      return {
        ...state,
        Progress: "",
        CreateLoading: false,
        CommentLoading: false
      };
    case FETCH_GOOGLE_MEETING:
      return {
        ...state,
        googleMeeting: arrangeGoogleData(action.googleMeeting)
      };
    case EMPTY_GOOGLE_MEETING:
      return {
        ...state,
        googleMeeting: []
      };
    case MEETING_ADD_COMMENT_ASYNC:
      return {
        ...state,
        CommentLoading: false,
        Error: "",
        Progress: "",
        Meeting: {
          ...state.Meeting,
          Comments: [action.comment, ...state.Meeting.Comments],
          CommentsCount: state.Meeting.CommentsCount + 1
        }
      };
    case DELETE_MEETING_COMMENT:
      const newComments = state.Meeting.Comments.filter(
        comment => comment.ID !== action.commentId
      );
      return {
        ...state,
        Meeting: {
          ...state.Meeting,
          Comments: newComments,
          CommentsCount: state.Meeting.CommentsCount - 1
        }
      };
    case MEETING_COMMENT_LOADING:
      return {
        ...state,
        CommentLoading: true
      };
    default:
      return state;
  }
}

export default meeting;
