import React from "react";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import "./style.scss";

class TasksLoadingCard extends React.Component {
  constructor() {
    super();

    const width = window.innerWidth;
    this.state = {
      width,
      count: this.getCount(width)
    };
    window.addEventListener("resize", this.update);
  }

  update = () => {
    const width = window.innerWidth;
    this.setState({
      width,
      count: this.getCount(width)
    });
  };

  getCount = width => {
    if (width >= 2600) {
      return 8;
    }
    if (width >= 1700) {
      return 6;
    }
    if (width >= 1440) {
      return 4;
    }
    return 2;
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.update);
  }

  _renderItem = key => (
    <ContentLoader
      className="card loading-card"
      rtl={!this.ltr}
      key={key}
      width={400}
      height={215}
    >
      <rect x="10" y="15" rx="5" ry="5" width="90" height="10" />

      <rect x="110" y="10" rx="10" ry="10" width="40" height="20" />
      <rect x="160" y="15" rx="5" ry="5" width="60" height="10" />

      <rect x="0" y="40" width="400" height="1" />

      <rect x="10" y="60" rx="5" ry="5" width="70" height="10" />
      <rect x="10" y="90" rx="5" ry="5" width="200" height="10" />

      <circle cx="30" cy="140" r="20" />
      <rect x="65" y="125" rx="5" ry="5" width="70" height="10" />
      <rect x="65" y="145" rx="5" ry="5" width="100" height="10" />

      <rect x="0" y="180" width="400" height="1" />
      <rect x="10" y="190" rx="5" ry="5" width="20" height="10" />
      <rect x="40" y="190" rx="5" ry="5" width="20" height="10" />
      <rect x="350" y="190" rx="5" ry="5" width="40" height="10" />
      <rect x="280" y="190" rx="5" ry="5" width="30" height="10" />
      <circle cx="335" cy="195" r="5" />
    </ContentLoader>
  );
  _renderItem2 = key => (
    <ContentLoader
      className="card loading-card"
      rtl={!this.ltr}
      key={key}
      width={400}
      height={150}
    >
      <rect x="10" y="15" rx="3" ry="3" width="100" height="6" />
      <rect x="120" y="10" rx="8" ry="8" width="40" height="16" />
      <rect x="170" y="15" rx="3" ry="3" width="40" height="6" />
      <rect x="0" y="35" width="400" height="1" />
      <rect x="10" y="50" rx="3" ry="3" width="100" height="6" />
      <rect x="10" y="70" rx="3" ry="3" width="200" height="6" />;
      <rect x="45" y="93" rx="3" ry="3" width="50" height="6" />
      <rect x="45" y="107" rx="3" ry="3" width="50" height="6" />
      <circle cx="25" cy="105" r="15" />
      <rect x="0" y="125" width="400" height="1" />
      <rect x="10" y="135" rx="3" ry="3" width="20" height="6" />
      <rect x="40" y="135" rx="3" ry="3" width="20" height="6" />
      <rect x="350" y="135" rx="3" ry="3" width="40" height="6" />
      <rect x="280" y="135" rx="3" ry="3" width="30" height="6" />
      <circle cx="335" cy="138" r="5" />
    </ContentLoader>
  );
  render() {
    const { count } = this.state;

    return (
      <div className="task-list-loading">
        {[...Array(count)].map((element, index) => {
          return this._renderItem(index);
        })}
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ltr: state.locale.ltr
  };
};

export default connect(
  mapStateToProps,
  null
)(TasksLoadingCard);
