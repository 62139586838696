import React from "react";
import PropTypes from "prop-types";
import CircularProgressbar from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./style.scss";
import { withRouter } from "react-router";
import AsyncImg from "../AsyncImg";

function ProgressAvatar(props) {
  const {
    progress,
    image,
    alt,
    trailColor,
    pathColor,
    isNameClickable
  } = props;

  return (
    <div
      className={isNameClickable ? "userAvatar cursor" : "userAvatar"}
      onClick={() =>
        isNameClickable && props.history.push(`/users/${isNameClickable}`)
      }
    >
      <CircularProgressbar
        percentage={progress}
        className="avatarProgress"
        strokeWidth={6}
        initialAnimation
        styles={{
          path: { strokeLinecap: "butt", stroke: pathColor },
          trail: { stroke: trailColor }
        }}
      />
      <AsyncImg img={image} alt={alt} />
    </div>
  );
}

ProgressAvatar.propTypes = {
  progress: PropTypes.number,
  image: PropTypes.string,
  alt: PropTypes.string,
  trailColor: PropTypes.string,
  pathColor: PropTypes.string
};

export default withRouter(ProgressAvatar);
