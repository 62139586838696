import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./style.scss";
import PropTypes from "prop-types";

class Tabs extends Component {
  render() {
    const {
      theme,
      intl,
      tabs,
      selectTab,
      locale,
      selected,
      backgroundColor
    } = this.props;

    return (
      <div className="tabs-card" style={{ backgroundColor }}>
        <div
          className="tab-container"
          style={{ borderColor: theme.accentColor }}
        >
          {tabs.map((tab, index) => (
            <div
              key={index}
              onClick={() => selectTab(tab.name)}
              className="tabs"
              style={{
                borderColor: selected === tab.name ? theme.primary : null,
                color: selected === tab.name ? theme.textColor : theme.textGray
              }}
            >
              <p>
                {intl.formatMessage({
                  id: `tab.${tab.name}`,
                  defaultMessage: tab.name
                })}
              </p>

              {tab.count ? (
                <div
                  className={locale.ltr ? "circle ltr" : "circle"}
                  style={{
                    backgroundColor:
                      selected === tab.name ? theme.primary : theme.textGray
                  }}
                >
                  <p>{tab.count}</p>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

Tabs.propTypes = {
  theme: PropTypes.object,
  intl: PropTypes.object,
  tabs: PropTypes.array,
  selectTab: PropTypes.func
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ...state
  };
};

export default connect(
  mapStateToProps,
  null
)(injectIntl(withRouter(Tabs)));
