import { put, takeEvery } from "redux-saga/effects";
import { deleteTaskAttachment, deleteMeetingAttachment } from "../actions";

const selectAction = module => {
  switch (module) {
    case "Tasks":
      return deleteTaskAttachment;
    case "Meetings":
      return deleteMeetingAttachment;
    default:
      return () => {};
  }
};

function* deleteAttachmentEffect(action) {
  const method = yield selectAction(action.module);
  yield put(method(action.id));
}

export function* deleteAttachmentWatcher() {
  yield takeEvery("DELETE_ATTACHMENT", deleteAttachmentEffect);
}
