import React from "react";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import "./style.scss";

class TasksMembersLoadingCard extends React.Component {
  _renderItem = () => (
    <ContentLoader
      className="card loading-card"
      rtl={!this.props.ltr}
      width={400}
      height={250}
    >
      <circle cx="30" cy="30" r="20" />
      <rect x="60" y="25" rx="5" ry="5" width="70" height="10" />
      <rect x="0" y="60" width="400" height="1" />

      <rect x="120" y="150" width="50" height="1" />
      <rect x="80" y="152" rx="2" ry="2" width="30" height="4" />
      <rect x="85" y="145" rx="2" ry="2" width="25" height="4" />
      <circle cx="120" cy="150.5" r="1.5" />
      <circle cx="200" cy="140" r="40" />
      <rect x="215" y="130" width="60" height="1" />
      <circle cx="275" cy="130.5" r="1.5" />
      <rect x="290" y="125" rx="2" ry="2" width="25" height="4" />
      <rect x="290" y="132" rx="2" ry="2" width="20" height="4" />
      <rect x="0" y="210" width="400" height="1" />
      <rect x="155" y="225" rx="5" ry="5" width="90" height="10" />
    </ContentLoader>
  );

  render() {
    return (
      <div className="loading-card-members">
        {[...Array(6)].map((element, index) => {
          return (
            <div key={index} className="member-item">
              {this._renderItem(index)}
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ltr: state.locale.ltr
  };
};

export default connect(
  mapStateToProps,
  null
)(TasksMembersLoadingCard);

// <circle cx="200" cy="130" r="40" />
// <rect x="230" y="130" width="30" height="1" />
// <circle cx="260" cy="130.5" r="3" />
// <rect x="270" y="120" rx="5" ry="5" width="50" height="10" />
// <rect x="270" y="135" rx="5" ry="5" width="45" height="10" />
// <rect x="0" y="200" width="400" height="1" />
// <rect x="160" y="220" rx="5" ry="5" width="80" height="10" />
