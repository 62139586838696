import axios from "axios";
import { replace } from "connected-react-router";
import {
  addTaskAsync,
  taskFailed,
  taskLoading,
  createTaskLoading,
  hideModal,
  fetchTaskAsync,
  updateCounts,
  taskAddCommentAsync,
  taskCommentLoading,
  fetchCompletedTaskAsync,
  showToast,
  hideToast,
  updateTaskUploadProgress,
  fetchTaskChartAsync,
  taskChartLoading,
  updateCalenderTasks
} from "../actions";

import { createUploader, watchOnProgress } from "./upload";
import { getDataFromRes, getPermission, cleanApiDataForm } from "../utils";
import { call, put, takeLatest, all, fork } from "redux-saga/effects";

// Create

function addTaskApi(payload, onProgress) {
  const { data, id } = payload;
  const params = id ? `/Update` : "";

  return axios.post(`/Tasks${params}`, data, {
    headers: { "content-type": "multipart/form-data" },
    onUploadProgress: onProgress
  });
}

function* addTaskEffect(action) {
  yield put(createTaskLoading());
  const [uploadPromise, chan] = createUploader(
    {
      data: cleanApiDataForm({ ...action.task, currentUser: null }),
      id: action.taskId
    },
    addTaskApi
  );
  yield fork(watchOnProgress, chan, updateTaskUploadProgress);

  try {
    let { data } = yield call(() => uploadPromise);

    const permission = getPermission(action.task.currentUser, action.task, [
      "Accountable",
      "AssignedBy",
      "Creator",
      "Responsible",
      "Informed",
      "Consulted"
    ]);
    if (!permission && action.task.ID) {
      yield put(replace("/tasks"));
    }
    if (data.StatusCode === "Success") {
      yield put(addTaskAsync(getDataFromRes(data)));
      yield put(updateCounts());
      yield put(hideModal());
      yield put(hideToast());

      yield put(
        showToast(
          getDataFromRes(data).ID,
          (action.pageType = "tasks"),
          action.task.ID
        )
      );

      return action.task.currentUser === action.task.Responsible.Username &&
        !action.task.ID
        ? yield put(updateCalenderTasks(getDataFromRes(data)))
        : null;
    } else {
      yield put(taskFailed(data.StatusMessage));
    }
  } catch (err) {
    yield put(taskFailed(err.message));
  }
}

export function* addTaskWatcher() {
  yield takeLatest("ADD_TASK", addTaskEffect);
}

// Fetch

function fetchTaskApi(data) {
  const path = `/Tasks${data ? "/" + data : ""}`;
  return axios.get(path);
}

function* fetchTaskEffect(action) {
  yield put(taskLoading());
  try {
    let { data } = yield call(fetchTaskApi, action.taskId);
    if (data.StatusCode === "Success") {
      yield put(fetchTaskAsync(getDataFromRes(data)));
    } else {
      yield put(replace("/tasks"));
    }
  } catch (e) {}
}

export function* fetchTaskWatcher() {
  yield takeLatest("FETCH_TASK", fetchTaskEffect);
}

// Comments

function taskCommentApi(data, onProgress) {
  const path = "/Tasks/AddComment/";
  return axios.post(path, data, {
    headers: { "content-type": "multipart/form-data" },
    onUploadProgress: onProgress
  });
}

function* taskCommentEffect(action) {
  yield put(taskCommentLoading());
  const [uploadPromise, chan] = createUploader(action.comment, taskCommentApi);
  yield fork(watchOnProgress, chan, updateTaskUploadProgress);
  try {
    let { data } = yield call(() => uploadPromise);

    if (data.StatusCode === "Success") {
      yield put(taskAddCommentAsync(getDataFromRes(data)));
    } else {
      yield put(taskFailed(data.StatusMessage));
    }
  } catch (e) {}
}

export function* taskCommentWatcher() {
  yield takeLatest("TASK_ADD_COMMENT", taskCommentEffect);
}

//fetch completed Tasks

function fetchCompletedTaskApi(data) {
  const path = "/Tasks/Completed";

  return axios.get(path);
}

function* fetchCompletedTaskEffect(action) {
  yield put(taskLoading());
  try {
    let [completedTask, allTasks] = yield all([
      call(fetchCompletedTaskApi),
      call(fetchTaskApi)
    ]);

    if (
      completedTask.data.StatusCode === "Success" &&
      allTasks.data.StatusCode === "Success"
    ) {
      yield put(
        fetchCompletedTaskAsync(
          getDataFromRes(completedTask.data),
          getDataFromRes(allTasks.data)
        )
      );
      yield put(updateCounts());
    } else {
      yield put(replace("/tasks"));
    }
  } catch (e) {}
}

export function* fetchCompletedTaskWatcher() {
  yield takeLatest("FETCH_COMPLETED_TASK", fetchCompletedTaskEffect);
}

function fetchTaskChartApi(data) {
  const path = `/Statistics/Tasks`;
  return axios.get(path);
}

function* fetchTaskChartEffect(action) {
  yield put(taskChartLoading());
  try {
    let { data } = yield call(fetchTaskChartApi, action);

    if (data.StatusCode === "Success") {
      yield put(fetchTaskChartAsync(getDataFromRes(data)));
    } else {
      yield put(taskFailed(data.StatusMessage));
    }
  } catch (e) {
    yield put(taskFailed(e));
  }
}

export function* fetchTaskChartWatcher() {
  yield takeLatest("FETCH_TASK_CHART", fetchTaskChartEffect);
}
