import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import { connect } from "formik";
import "react-quill/dist/quill.snow.css";
import "./style.scss";

class RichText extends Component {
  handleChange = value => {
    this.props.formik.setFieldValue(this.props.name, value);
  };

  componentDidMount() {
    this.props.formik.registerField(this.props.name, this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.name !== prevProps.name) {
      this.props.formik.unregisterField(prevProps.name);
      this.props.formik.registerField(this.props.name, this);
    }

    if (this.props.validate !== prevProps.validate) {
      this.props.formik.registerField(this.props.name, this);
    }
  }

  componentWillUnmount() {
    this.props.formik.unregisterField(this.props.name);
  }

  quillModules = {
    toolbar: [[{ list: "bullet" }]]
  };

  render() {
    const { name, formik, locale, theme, errors } = this.props;

    return (
      <ReactQuill
        id={name}
        className={locale.ltr ? "" : "rtl"}
        style={{ borderColor: errors ? theme.errorColor : theme.accentColor }}
        modules={this.quillModules}
        theme="snow"
        isRtl={!locale.ltr}
        value={formik.values[name]}
        placeholder=""
        onChange={this.handleChange}
        onBlur={formik.onBlur}
      />
    );
  }
}

RichText.propTypes = {
  theme: PropTypes.object.isRequired,
  locale: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  formik: PropTypes.object.isRequired
};

export default connect(RichText);
