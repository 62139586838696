import React from "react";
import PropTypes from "prop-types";
// import { FormattedMessage } from "react-intl";
import TaskItem from "../TaskItem";
// import { getSections } from "../../utils";
import "./style.scss";

function TaskList(props) {
  const { tasks, theme } = props;
  // const sections = getSections(tasks, "ModifiedDate");

  return (
    <div className="tasks-sections">
      <div className="taskList">
        {tasks.map((task, index) => (
          <div className="card" key={index}>
            <TaskItem theme={theme} task={task} key={task.ID} />
          </div>
        ))}
      </div>
    </div>
  );
}

TaskList.propTypes = {
  theme: PropTypes.object,
  tasks: PropTypes.array
};

export default TaskList;
