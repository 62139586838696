import React, * as react from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FormInput from "../FormInput";
import { Formik, Form } from "formik";
import { FormattedMessage } from "react-intl";
import { cleanHandshakeForSelect, cleanLookupsForSelect } from "../../utils";
import "./style.scss";

const initialValues = {
  entity: "",
  priority: "",
  status: "",
  role: "",
  taskType: ""
};

class DropDownTasksFilter extends react.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    const nonRACI = [
      {
        value: "Creator",
        label: props.intl.formatMessage({
          id: "role.Creator",
          defaultMessage: "Creator"
        })
      }
    ];
    const filters = {
      Priorities: cleanHandshakeForSelect(
        props.theme.Tasks_Priority,
        null,
        props.locale.suffix
      ),
      Statuses: cleanHandshakeForSelect(
        props.theme.Tasks_Status,
        null,
        props.locale.suffix
      ),
      Roles: [
        ...nonRACI,
        ...cleanHandshakeForSelect(props.theme.RACI, null, props.locale.suffix)
      ],
      Entities: cleanLookupsForSelect(props.theme.lookups.Entities),

      TaskType: cleanHandshakeForSelect(
        props.theme.Task_Type,
        null,
        props.locale.suffix
      )
    };

    this.state = {
      ...filters,
      initialValues: props.data ? this.setValue(filters) : initialValues
    };
  }

  setValue = filters => {
    const { data } = this.props;

    return {
      priority:
        data.priority !== ""
          ? filters.Priorities.find(item => item.value === data.priority)
          : "",
      status:
        data.status !== ""
          ? filters.Statuses.find(item => item.value === data.status)
          : "",
      role:
        data.role !== ""
          ? filters.Roles.find(item => item.value === data.role)
          : "",

      entity:
        data.entity !== ""
          ? filters.Entities.find(
              item => JSON.stringify(item.value) === data.entity
            )
          : "",

      taskType:
        data.taskType !== ""
          ? filters.TaskType.find(item => item.value === data.taskType)
          : ""
    };
  };

  render() {
    const { locale, theme, style, clearFilter } = this.props;

    return (
      <div
        className={
          locale.ltr
            ? "options-list-component-news-filter ltr"
            : "options-list-component-news-filter"
        }
        style={style}
      >
        <div className="clickable-ulcontainer">
          <ul>
            <li className="titles" style={{ borderColor: theme.accentColor }}>
              <span style={{ color: theme.textGray }}>
                <FormattedMessage
                  id="dropdown.news-filter-title"
                  defaultMessage="Filter by"
                />
              </span>
              <span className="close-filter" onClick={clearFilter}>
                <FormattedMessage
                  id="dropdown.close-news-filter"
                  defaultMessage="Close filter"
                />
              </span>
            </li>
            <div>
              <Formik
                initialValues={this.state.initialValues}
                validate={values => {
                  return this.props.setFilters({
                    entity: values.entity ? values.entity.value : "",
                    priority: values.priority ? values.priority.value : "",
                    status: values.status ? values.status.value : "",
                    role: values.role ? values.role.value : "",
                    taskType: values.taskType ? values.taskType.value : ""
                  });
                }}
              >
                {({ isSubmitting, values }) => (
                  <>
                    <Form>
                      <p className="title">
                        <FormattedMessage
                          id="label.Priority"
                          defaultMessage="Priority"
                        />
                      </p>
                      <FormInput
                        type="singleSelect"
                        name="priority"
                        options={this.state.Priorities}
                        withoutLabel
                        noCreate
                        clearable
                        taskFilter
                      />

                      <p className="title">
                        <FormattedMessage
                          id="label.Status"
                          defaultMessage="Status"
                        />
                      </p>
                      <FormInput
                        type="singleSelect"
                        name="status"
                        options={this.state.Statuses}
                        withoutLabel
                        noCreate
                        clearable
                        taskFilter
                      />
                      <p className="title">
                        <FormattedMessage
                          id="label.Role"
                          defaultMessage="Role"
                        />
                      </p>
                      <FormInput
                        type="singleSelect"
                        name="role"
                        options={this.state.Roles}
                        withoutLabel
                        noCreate
                        clearable
                        taskFilter
                      />

                      <p className="title">
                        <FormattedMessage
                          id="label.Entity"
                          defaultMessage="Entity"
                        />
                      </p>
                      <FormInput
                        type="singleSelect"
                        name="entity"
                        options={this.state.Entities}
                        withoutLabel
                        noCreate
                        clearable
                        taskFilter
                      />

                      <p className="title">
                        <FormattedMessage
                          id="label.taskType"
                          defaultMessage="Role"
                        />
                      </p>
                      <FormInput
                        type="singleSelect"
                        name="taskType"
                        options={this.state.TaskType}
                        withoutLabel
                        noCreate
                        clearable
                        taskFilter
                      />
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </ul>
        </div>
      </div>
    );
  }
}
DropDownTasksFilter.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
  theme: PropTypes.object,
  style: PropTypes.object,
  className: PropTypes.string,
  clickable: PropTypes.bool
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    locale: state.locale,
    theme: state.theme
  };
};

export default connect(
  mapStateToProps,
  null
)(injectIntl(withRouter(DropDownTasksFilter)));
