import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ReactComponent as ArrowDown } from "../../assets/images/arrow-down.svg";
import {
  logout,
  clearProjectsOnLogOut,
  emptyGoogleMeeting
} from "../../actions";

import "./style.scss";
import LogOutDropDown from "../LogOutDropDown";
import AsyncImg from "../AsyncImg";

class ProfileButton extends Component {
  _isMounted = false;
  state = {
    isHovered: false,
    open: false
  };

  showMenu = event => {
    this._isMounted = true;

    if (this._isMounted) {
      this.setState({ open: true }, () => {
        document.addEventListener("click", this.closeMenu);
      });
    }
  };

  closeMenu = () => {
    if (this._isMounted) {
      this.setState({ open: false }, () => {
        document.removeEventListener("click", this.closeMenu);
      });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { theme, history, user } = this.props;
    const { isHovered, open } = this.state;
    return (
      <div>
        <div
          className="profileButton"
          onMouseEnter={() => this.setState({ isHovered: true })}
          onMouseLeave={() => this.setState({ isHovered: false })}
          style={isHovered ? { backgroundColor: theme.accentColor } : {}}
          onClick={this.showMenu}
        >
          <AsyncImg
            img={user.UserData.UserProfilePicture}
            alt="profilePicture"
            className="profilePicture"
          />
          <div />
          <ArrowDown className="arrowIcon" />
        </div>
        {open ? (
          <LogOutDropDown
            logout={this.props.logout}
            clearProjectsOnLogOut={this.props.clearProjectsOnLogOut}
            history={history}
            emptyGoogleMeeting={this.props.emptyGoogleMeeting}
          />
        ) : null}
      </div>
    );
  }
}

ProfileButton.propTypes = {
  theme: PropTypes.object,
  userImage: PropTypes.string
};

const mapDispatchToProps = {
  logout,
  clearProjectsOnLogOut,
  emptyGoogleMeeting
};

export default connect(null, mapDispatchToProps)(ProfileButton);
