import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import "./style.scss";

const RenderActiveShape = props => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    percent,
    ltr,
    value,
    name,
    textColor,
    textGray
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 4) * cos;
  const sy = cy + (outerRadius + 4) * sin;
  const mx = cx + (outerRadius + 10) * cos;
  const my = cy + (outerRadius + 10) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 25;
  const ey = my;
  const realPercent = percent * 100;
  let textAnchor = cos >= 0 ? "end" : "start";
  if (ltr) {
    textAnchor = cos >= 0 ? "start" : "end";
  }

  if (value === 0) return null;
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 2}
        outerRadius={outerRadius + 4}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 5}
        y={ey}
        dy={6}
        textAnchor={textAnchor}
        fill={textColor}
        fontSize="10"
      >
        {`${value} ${name}`}{" "}
        {`${
          Number.isInteger(realPercent) ? realPercent : realPercent.toFixed(2)
        }%`}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 5}
        y={ey}
        dy={-5}
        textAnchor={textAnchor}
        fill={textGray}
        fontSize="14"
      ></text>
    </g>
  );
};

class RingChart extends React.Component {
  constructor(props) {
    super(props);
    this.onPieEnter = this.onPieEnter.bind(this);
    this.onPieLeave = this.onPieLeave.bind(this);
  }

  onPieEnter(data, index) {
    this.setState({
      activeIndex: index
    });
  }

  onPieLeave(data, index) {
    this.setState({
      activeIndex: null
    });
  }

  render() {
    const { data, intl, ltr, textGray, textColor } = this.props;
    const total = data.reduce((acc, curr) => acc + curr.value, 0);
    return (
      <div className="ring-chart">
        <ResponsiveContainer height={150} width="100%">
          {total > 0 ? (
            <PieChart>
              <Pie
                data={data}
                labelLine={false}
                label={props => (
                  <RenderActiveShape
                    {...props}
                    intl={intl}
                    ltr={ltr}
                    textColor={textColor}
                    textGray={textGray}
                  />
                )}
                isAnimationActive={false}
                dataKey="value"
                nameKey="name"
                cx={"50%"}
                cy={"50%"}
                innerRadius={30}
                outerRadius={40}
                onMouseEnter={this.onPieEnter}
                onMouseLeave={this.onPieLeave}
              >
                {data.map((entry, index) => (
                  <Cell key={index} fill={entry.color} />
                ))}
              </Pie>
              <text
                x="50%"
                y={68}
                textAnchor="middle"
                dominantBaseline="middle"
                fill={textColor}
              >
                {total}
              </text>
              <text
                x="50%"
                y={82}
                fill={textColor}
                textAnchor="middle"
                dominantBaseline="middle"
              >
                {intl.formatMessage(
                  {
                    id: "chart.task",
                    defaultMessage: "{tasks, plural, one {Task} other {Tasks}}"
                  },
                  { tasks: total }
                )}
              </text>
            </PieChart>
          ) : (
            <div className="chart-empty" style={{ color: textGray }}>
              {intl.formatMessage({
                id: "chart.noTasks",
                defaultMessage: "No Tasks"
              })}
            </div>
          )}
        </ResponsiveContainer>
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ltr: state.locale.ltr,
    textColor: state.theme.textColor,
    textGray: state.theme.textGray
  };
};

export default connect(mapStateToProps, null)(injectIntl(RingChart));
