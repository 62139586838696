import axios from "axios";

import { usersFailed, usersLoading, fetchUsersAsync } from "../actions";
import { getDataFromRes } from "../utils";
import { call, put, takeLatest } from "redux-saga/effects";

function fetchUsersApi(userName) {
  let path = "";
  if (userName) {
    path = `${window.env.SERVICE_URL}/ActiveDirectory/User?username=${userName}`;
  } else {
    path = `/ActiveDirectory/Users`;
  }

  return axios.get(path);
}

function* fetchUsersEffect(action) {
  yield put(usersLoading());

  try {
    // data is obtained after axios call is resolved
    let { data } = yield call(fetchUsersApi, action.userName);

    if (data.StatusCode === "Success") {
      yield put(fetchUsersAsync(getDataFromRes(data)));
    } else {
      yield put(usersFailed(data.StatusMessage));
    }
    // redirect to home route after successful login
  } catch (err) {
    yield put(usersFailed(err.message));
  }
}

export function* fetchUsersWatcher() {
  yield takeLatest("FETCH_USERS", fetchUsersEffect);
}

//delete
