import React from "react";
import { connect } from "react-redux";
import Loading from "../Loading";
import { withConfirmation } from "../../actions";
import { ReactComponent as CloseIcon } from "../../assets/images/close-icon.svg";
import "./style.scss";

class DeleteButton extends React.Component {
  render() {
    const {
      primaryColor,
      textGray,
      loading,
      deleteAction,
      attachment,
      withConfirmation,
      noConfirmation
    } = this.props;

    return (
      <button
        type="button"
        className={`delete-button ${attachment ? "attachment" : ""}`}
        style={attachment ? { backgroundColor: primaryColor } : {}}
        onClick={e => {
          e.stopPropagation();
          if (noConfirmation) {
            deleteAction();
          } else {
            withConfirmation(() => deleteAction());
          }
        }}
        disabled={loading}
      >
        {loading ? (
          <Loading
            color={attachment ? "white" : primaryColor}
            small={attachment}
          />
        ) : (
          <CloseIcon style={{ fill: attachment ? "white" : textGray }} />
        )}
      </button>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    primaryColor: state.theme.primary,
    textGray: state.theme.textGray
  };
};

const mapDispatchToProps = {
  withConfirmation
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteButton);
