import {
  LOGIN_FAILED,
  VERIFY_FAILED,
  LOGIN_LOADING,
  CLEAR_LOGIN_ERROR,
  USER_BLOCK,
  USER_LAST_ATTEMPT,
  NEED_VERIFY,
  CLEAR_LOGIN
} from "../actions";

const initialState = {
  Loading: false,
  Error: "",
  BlockedUntil: null,
  Verify: false,
  LastAttempt: false
};

const redirects = ["TimeOut", "BlockedUser"];

function login(state = initialState, action) {
  switch (action.type) {
    case LOGIN_FAILED:
      return {
        ...state,
        BlockedUntil: null,
        LastAttempt: false,
        Loading: false,
        Error: action.error
      };
    case VERIFY_FAILED:
      return {
        ...state,
        BlockedUntil: null,
        Loading: false,
        LastAttempt: false,
        Error: action.error,
        Verify: !redirects.includes(action.error)
      };
    case LOGIN_LOADING:
      return {
        ...state,
        Loading: true
      };
    case USER_BLOCK:
      return {
        ...state,
        BlockedUntil: action.blockedUntil
      };
    case USER_LAST_ATTEMPT:
      return {
        ...state,
        LastAttempt: true
      };
    case NEED_VERIFY:
      return {
        ...state,
        Loading: false,
        Verify: true
      };
    case CLEAR_LOGIN_ERROR:
      return {
        ...state,
        Error: ""
      };
    case CLEAR_LOGIN:
      return {
        ...initialState
      };
    default:
      return state;
  }
}

export default login;
