import React from "react";
// import moment from "moment";
import { connect } from "react-redux";
import { ReactComponent as ArrowDown } from "../../assets/images/arrow-down.svg";
import "./style.scss";

const CalendarToolbar = toolbar => {
  const goToBack = () => {
    switch (toolbar.view) {
      case "day":
        toolbar.date.setDate(toolbar.date.getDate() - 1);
        break;
      case "week":
        toolbar.date.setDate(toolbar.date.getDate() - 7);
        break;
      default:
        toolbar.date.setMonth(toolbar.date.getMonth() - 1);
    }
    toolbar.onNavigate("prev");
  };

  const goToNext = () => {
    switch (toolbar.view) {
      case "day":
        toolbar.date.setDate(toolbar.date.getDate() + 1);
        break;
      case "week":
        toolbar.date.setDate(toolbar.date.getDate() + 7);
        break;
      default:
        toolbar.date.setMonth(toolbar.date.getMonth() + 1);
    }
    toolbar.onNavigate("next");
  };

  // const label = () => {
  //   const date = moment(toolbar.date);
  //   switch (toolbar.view) {
  //     case "day":
  //       return (
  //         <span>
  //           <span>{date.format("DD")}</span>
  //           <b>{date.format("MMMM")}</b>
  //           <span> {date.format("YYYY")}</span>
  //         </span>
  //       );
  //     case "week":
  //       return (
  //         <span>
  //           <b>{date.format("MMMM")}</b>
  //           <span> {date.format("YYYY")}</span>
  //         </span>
  //       );
  //     default:
  //       return (
  //         <span>
  //           <b>{date.format("MMMM")}</b>
  //           <span> {date.format("YYYY")}</span>
  //         </span>
  //       );
  //   }
  // };

  return (
    <div className="calendar-toolbar">
      <button onClick={goToBack}>
        <ArrowDown
          className={toolbar.ltr ? "arrow right" : "arrow left"}
          style={{ fill: toolbar.accentColor }}
        />
      </button>
      {/* <label className={toolbar.ltr ? "ltr" : ""}>{label()}</label> */}
      <label className={toolbar.ltr ? "ltr" : ""}>{toolbar.label}</label>
      <button onClick={goToNext}>
        <ArrowDown
          className={toolbar.ltr ? "arrow left" : "arrow right"}
          style={{ fill: toolbar.accentColor }}
        />
      </button>
    </div>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ltr: state.locale.ltr,
    accentColor: state.theme.accentColor
  };
};

export default connect(
  mapStateToProps,
  null
)(CalendarToolbar);
