export const ADD_ADHOC = "ADD_ADHOC";
export const ADD_ADHOC_ASYNC = "ADD_ADHOC_ASYNC";
export const UPDATE_ADHOC = "UPDATE_ADHOC";
export const UPDATE_ADHOC_ASYNC = "UPDATE_ADHOC_ASYNC";
export const ADHOC_LOADING = "ADHOC_LOADING";
export const CREATE_ADHOC_LOADING = "CREATE_ADHOC_LOADING";
export const ADHOC_FAILED = "ADHOC_FAILED";
export const FETCH_ADHOC = "FETCH_ADHOC";
export const FETCH_ADHOC_ASYNC = "FETCH_ADHOC_ASYNC";
export const ADHOC_ACTION = "ADHOC_ACTION";
export const ADHOC_ACTION_ASYNC = "ADHOC_ACTION_ASYNC";
export const ADHOC_ACTION_LOADING = "ADHOC_ACTION_LOADING";
export const ADHOC_ADD_COMMENT = "ADHOC_ADD_COMMENT";
export const ADHOC_ADD_COMMENT_ASYNC = "ADHOC_ADD_COMMENT_ASYNC";
export const ADHOC_COMMENT_LOADING = "ADHOC_COMMENT_LOADING";
export const DELETE_ADHOC_COMMENT = "DELETE_ADHOC_COMMENT";
export const DELETE_ADHOC = "DELETE_ADHOC";
export const STOP_ADHOC_ACTION_LOADING = "STOP_ADHOC_ACTION_LOADING";
export const UPDATE_ADHOC_UPLOAD_PROGRESS = "UPDATE_ADHOC_UPLOAD_PROGRESS";

export function addAdhocAsync(adhoc) {
  return { type: ADD_ADHOC_ASYNC, adhoc };
}

export function addAdhoc(adhoc) {
  return { type: ADD_ADHOC, adhoc };
}

export function updateAdhocAsync(adhoc) {
  return { type: UPDATE_ADHOC_ASYNC, adhoc };
}

export function updateAdhoc(adhoc) {
  return { type: UPDATE_ADHOC, adhoc };
}

export function fetchAdhocAsync(adhocs, PagingInfo) {
  return { type: FETCH_ADHOC_ASYNC, adhocs, PagingInfo };
}

export function fetchAdhoc(PagingInfo) {
  return { type: FETCH_ADHOC, PagingInfo, kind: "normal" };
}

export function fetchMemberAdhoc(PagingInfo) {
  return { type: FETCH_ADHOC, PagingInfo, kind: "member" };
}

export function fetchAdhocHistory(PagingInfo) {
  return { type: FETCH_ADHOC, PagingInfo, kind: "history" };
}

export function fetchAdhocDetails(id) {
  return { type: FETCH_ADHOC, id, kind: "details" };
}

export function adhocActionAsync(adhocId, action) {
  return { type: ADHOC_ACTION_ASYNC, adhocId, action };
}

export function adhocAction(data) {
  return { type: ADHOC_ACTION, data };
}

export function adhocLoading(clear) {
  return { type: ADHOC_LOADING, clear };
}

export function createAdhocLoading() {
  return { type: CREATE_ADHOC_LOADING };
}

export function adhocActionLoading() {
  return { type: ADHOC_ACTION_LOADING };
}

export function adhocFailed(error) {
  return { type: ADHOC_FAILED, error };
}

export function adhocAddCommentAsync(comment) {
  return { type: ADHOC_ADD_COMMENT_ASYNC, comment };
}

export function adhocAddComment(comment, adhocId) {
  return { type: ADHOC_ADD_COMMENT, comment, adhocId };
}

export function adhocCommentLoading() {
  return { type: ADHOC_COMMENT_LOADING };
}

export function deleteAdhocComment(commentId, adhocId) {
  return { type: DELETE_ADHOC_COMMENT, commentId, adhocId };
}

export function deleteAdhoc(adhocId) {
  return { type: DELETE_ADHOC, adhocId };
}

export function stopAdhocActionLoading() {
  return { type: STOP_ADHOC_ACTION_LOADING };
}

export function updateAdhocUploadProgress(progress) {
  return { type: UPDATE_ADHOC_UPLOAD_PROGRESS, progress };
}
