import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import Button from "../Button";
import { ReactComponent as CommentIcon } from "../../assets/images/comment-icon.svg";
import { ReactComponent as AttachmentIcon } from "../../assets/images/attachment-icon.svg";
import AcceptMeetingRequestModal from "../AcceptMeetingRequestModal";
import {
  showModal,
  withConfirmation,
  timelineCompleteTask
} from "../../actions";
import "./style.scss";

function TimelineActionBar(props) {
  const { theme, post, done, intl, canComplete } = props;

  const acceptMeeting = id => {
    props.showModal(<AcceptMeetingRequestModal id={id} timeline />);
  };

  const completeTask = id => {
    props.withConfirmation(() => {
      axios.post(`/Tasks/Completed/${id}`).then(({ data }) => {
        if (data.StatusCode === "Success") {
          props.timelineCompleteTask(id);
          // this.props.history.replace("/tasks");
        }
      });
    });
  };

  return (
    <div
      className="timelineActionBar"
      style={{
        borderColor: theme.accentColor,
        backgroundColor: done ? theme.primaryLight : "white"
      }}
    >
      <div className="taskActions">
        {post.TimelineType !== "meeting-requests" ? (
          <div className="action" style={{ color: theme.textGray }}>
            <CommentIcon
              className="actionIcon"
              style={{
                fill: "transparent",
                stroke: theme.textGray
              }}
            />
            <p>{post.CommentsCount || 0}</p>
          </div>
        ) : null}
        {post.TimelineType === "tasks" || post.TimelineType === "meeting" ? (
          <div className="action" style={{ color: theme.textGray }}>
            <AttachmentIcon
              className="actionIcon"
              style={{ fill: theme.textGray }}
            />
            <p>{post.AttachmentCount || 0}</p>
          </div>
        ) : null}
      </div>
      {post.TimelineType === "meeting-requests" || canComplete ? (
        <div className="taskStatusContainer" onClick={e => e.stopPropagation()}>
          <Button
            onClick={() =>
              canComplete ? completeTask(post.ID) : acceptMeeting(post.ID)
            }
          >
            {intl.formatMessage({
              id: `timelineAction.${post.TimelineType}`,
              defaultMessage: post.TimelineType
            })}
          </Button>
        </div>
      ) : null}
    </div>
  );
}

TimelineActionBar.propTypes = {
  theme: PropTypes.object,
  task: PropTypes.object
};

const mapDispatchToProps = {
  showModal,
  withConfirmation,
  timelineCompleteTask
};

export default connect(
  null,
  mapDispatchToProps
)(TimelineActionBar);
