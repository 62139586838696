import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextTruncate from "react-text-truncate";
import "./style.scss";

import LocaleAwareText from "../../components/LocaleAwareText";
import MediaGrid from "../../components/MediaGrid";
import Badge from "../../components/Badge";
import Avatar from "../../components/Avatar";
import DaysSince from "../../components/DaysSince";
import StatusIndicator from "../../components/StatusIndicator";
import { ReactComponent as Clock } from "../../assets/images/clock-icon.svg";

import { showModal } from "../../actions";

class NewsCard extends Component {
  handleDetails = (id, News) => {
    const { history } = this.props;
    history.push({
      pathname: `/news/${id}`
    });
  };

  render() {
    const { theme, locale, News, intl, suffix, NewsNatural } = this.props;

    return (
      <div
        className={`news-page card ${locale.ltr ? "ltr" : ""}`}
        onClick={() => this.handleDetails(News.ID, News)}
      >
        <div
          className="news-container"
          style={{
            borderBottom: "solid 1px",
            borderColor: theme.accentColor
          }}
        >
          <div className="content">
            <div className="newsDescriptionContainer">
              <div className="header-container">
                <div className="title-container">
                  <LocaleAwareText>
                    <h4 className="newsName bold">{News.TitleAr}</h4>
                  </LocaleAwareText>
                </div>
                <div className="badges-container">
                  <div className="badge">
                    <Badge
                      color={theme.textGray}
                      backgroundColor={theme.primaryLight}
                      text={<DaysSince startDate={News.ArticleDate} />}
                      Icon={Clock}
                      fixed
                    />
                  </div>

                  {News.TimelineType === "news" ? (
                    <div className="badge">
                      <Badge
                        color={theme.textGray}
                        backgroundColor={theme.primaryLight}
                        text={intl.formatMessage({
                          id: "title.news",
                          defaultMessage: "news"
                        })}
                        fixed
                      />
                    </div>
                  ) : null}

                  <div className="avatar-badge">
                    <Avatar
                      img={
                        News.Source.Attachment &&
                        News.Source.Attachment.AttachmentURL
                      }
                    />
                  </div>

                  {News.Natural && (
                    <StatusIndicator
                      color={NewsNatural[News.Natural].Color}
                      text={NewsNatural[News.Natural][`Title${suffix}`]}
                    />
                  )}
                </div>
              </div>
              <div className="des-height">
                <LocaleAwareText
                  text={News.Summary === "" ? News.DescriptionAr : News.Summary}
                >
                  <TextTruncate
                    line={6}
                    truncateText="…"
                    text={
                      News.Summary === "" ? News.DescriptionAr : News.Summary
                    }
                  />
                </LocaleAwareText>
              </div>
            </div>

            <div
              className={
                News.Attachment ? "detailsContainer min" : "detailsContainer"
              }
            >
              {News.Attachment && News.Attachment.AttachmentURL && (
                <MediaGrid media={[News.Attachment]} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NewsCard.propTypes = {
  theme: PropTypes.object,
  locale: PropTypes.object,
  News: PropTypes.object
};

const mapDispatchToProps = { showModal };

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    locale: state.locale,
    NewsNatural: state.theme.News_Natural,
    suffix: state.locale.suffix
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(NewsCard)));
