import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import "./style.scss";
import DropDownNewsFilter from "../DropDownNewsFilter";
import { ReactComponent as NewsFilterIcon } from "../../assets/images/news-filter-source-icon.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/arrow-down.svg";

class NewsFilterButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu = event => {
    this.setState({ isOpen: true }, () => {
      document.addEventListener("click", e => {
        const paths = e.composedPath().map(path => path.className);
        if (!paths.includes("news-filter-button-container")) {
          this.closeMenu();
        }
      });
    });
  };

  closeMenu = () => {
    this.setState({ isOpen: false }, () => {
      document.removeEventListener("click", this.closeMenu);
    });
  };

  closeNewsFilterMenu = () => {
    this.setState({ isOpen: false }, () => {
      this.props.history.push("/news");

      document.removeEventListener("click", this.closeMenu);
    });
  };

  render() {
    const { theme } = this.props;

    return (
      <div className="news-filter-button-container">
        <div
          onClick={this.showMenu}
          className="filter-button"
          style={{ borderColor: theme.textGray }}
        >
          <NewsFilterIcon
            className="filter-icon"
            style={{ stroke: theme.textGray }}
          />
          <p className="filter-text" style={{ color: theme.textGray }}>
            <FormattedMessage
              id={"filterButton.filterBynews"}
              defaultMessage="Filter by"
            />
          </p>
          <ArrowDown className="arrow-icon" style={{ fill: theme.textGray }} />
        </div>
        {this.state.isOpen ? (
          <DropDownNewsFilter closeNewsFilterMenu={this.closeNewsFilterMenu} />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme
  };
};

export default connect(
  mapStateToProps,
  null
)(NewsFilterButton);
