import { HANDSHAKE_ASYNC, HANDSHAKE_ERROR } from "../actions";
import { themeData } from "../configs";
import { cleanHandshakeData } from "../utils";

const initialState = {
  Loading: true,
  ...themeData
};

function theme(state = initialState, action) {
  switch (action.type) {
    case HANDSHAKE_ASYNC:
      const handshake = cleanHandshakeData(action.handshake);
      return {
        ...state,
        Loading: false,
        Error: "",
        ...handshake,
        ...action.workflow,
        lookups: action.lookups
      };
    case HANDSHAKE_ERROR:
      return {
        Loading: false,
        Error: action.error
      };
    default:
      return state;
  }
}

export default theme;
