export const FETCH_TIMELINE = "FETCH_TIMELINE";
export const FETCH_TIMELINE_ASYNC = "FETCH_TIMELINE_ASYNC";
export const TIMELINE_LOADING = "TIMELINE_LOADING";
export const TIMELINE_UPDATE_PROGRESS = "TIMELINE_UPDATE_PROGRESS";
export const TIMELINE_COMPLETE_TASK = "TIMELINE_COMPLETE_TASK";
export const TIMELINE_ACCEPT_REQUEST = "TIMELINE_ACCEPT_REQUEST";
export const UPDATE_TIMELINE_TASK_STATUS = "UPDATE_TIMELINE_TASK_STATUS";
export const UPDATE_TIMELINE_MEETINGS = "UPDATE_TIMELINE_MEETINGS";

export function fetchTimelineAsync(data, nextPage) {
  return { type: FETCH_TIMELINE_ASYNC, data, nextPage };
}

export function fetchTimeline(nextPage) {
  return { type: FETCH_TIMELINE, nextPage };
}

export function timelineLoading(keepState) {
  return { type: TIMELINE_LOADING, keepState };
}

export function timelineUpdateProgress(id, progress) {
  return { type: TIMELINE_UPDATE_PROGRESS, id, progress };
}

export function timelineCompleteTask(id) {
  return { type: TIMELINE_COMPLETE_TASK, id };
}

export function timelineAcceptRequest(id) {
  return { type: TIMELINE_ACCEPT_REQUEST, id };
}

export function updateTimelineTaskStatus(id, status) {
  return { type: UPDATE_TIMELINE_TASK_STATUS, id, status };
}

export function updateTimelineMeetings(id) {
  return { type: UPDATE_TIMELINE_MEETINGS, id };
}
