import React from "react";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import "./style.scss";

class UsersLoadingCard extends React.Component {
  constructor() {
    super();

    const width = window.innerWidth;
    this.state = {
      width,
      count: this.getCount(width)
    };
    window.addEventListener("resize", this.update);
  }

  update = () => {
    const width = window.innerWidth;
    this.setState({
      width,
      count: this.getCount(width)
    });
  };

  getCount = width => {
    if (width >= 2600) {
      return 8;
    }
    if (width >= 1700) {
      return 6;
    }
    if (width >= 1440) {
      return 4;
    }
    return 2;
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.update);
  }

  _renderItem = key => (
    <ContentLoader
      className="card loading-card"
      rtl={!this.ltr}
      key={key}
      width={400}
      height={120}
    >
      <rect x="20" y="25" rx="5" ry="5" width="150" height="10" />
      <rect x="20" y="45" rx="5" ry="5" width="150" height="10" />
      <rect x="20" y="65" rx="5" ry="5" width="150" height="10" />
      <rect x="20" y="85" rx="5" ry="5" width="150" height="10" />
      <circle cx="300" cy="60" r="40" />
    </ContentLoader>
  );

  render() {
    const { count } = this.state;

    return (
      <div className="users-list-loading">
        {[...Array(count)].map((element, index) => {
          return this._renderItem(index);
        })}
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ltr: state.locale.ltr
  };
};

export default connect(
  mapStateToProps,
  null
)(UsersLoadingCard);
