import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import "./style.scss";
import { daysLeft } from "../../utils";

const DaysLeft = props => {
  const { style, endDate } = props;
  const days = daysLeft(endDate);
  return (
    <span className="days-left" style={style}>
      <FormattedMessage
        id={`taskItem.${days.type}Left`}
        defaultMessage="{daysLeft, plural, =0 {no days left} one {tomorrow}  other {{daysLeft} days left}}"
        values={{ daysLeft: days.amount }}
      />
    </span>
  );
};

DaysLeft.propTypes = {
  style: PropTypes.object,
  endDate: PropTypes.number
};

export default DaysLeft;
