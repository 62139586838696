export * from "./user";
export * from "./locale";
export * from "./modal";
export * from "./task";
export * from "./theme";
export * from "./news";
export * from "./meeting";
export * from "./adhoc";
export * from "./committee";
export * from "./meetingRequest";
export * from "./project";
export * from "./confirmation";
export * from "./login";
export * from "./toast";
export * from "./users";
export * from "./calender";
export * from "./timeline";

export const DELETE_ATTACHMENT = "DELETE_ATTACHMENT";

export function deleteAttachment(module, id) {
  return { type: DELETE_ATTACHMENT, module, id };
}
