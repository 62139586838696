import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import {
  fetchTask,
  showModal,
  taskAddComment,
  withConfirmation
} from "../../actions";
import "./style.scss";
import DaysSince from "../../components/DaysSince";
import DaysLeft from "../../components/DaysLeft";
import AddTaskModal from "../../components/AddTaskModal";
import ProgressAvatar from "../../components/ProgressAvatar";
import CommentsContainer from "../../components/CommentsContainer";
import LocaleAwareText from "../../components/LocaleAwareText";
import "rc-slider/assets/index.css";
import UserItem from "../../components/UserItem";
import ActionBar from "../../components/ActionBar";
import MenuButton from "../../components/MenuButton";
import TaskDetailsLoadingCard from "../../components/LoadingCards/TaskDetailsLoadingCard";
import Button from "../../components/Button";
import AttachmentList from "../../components/AttachmentList";
import ProgressSlider from "../../components/ProgressSlider";
import {
  getPermission,
  getDisplayName,
  getTaskUsers,
  cleanUsersForMention,
  cleanApiDataForm
} from "../../utils";
import AttachmentModal from "../../components/TasksWorkFlowModals/AttachmentModal";
import RejectModal from "../../components/TasksWorkFlowModals/RejectModal";
import Tabs from "../../components/Tabs";
import PreviousProcedure from "../../components/PreviousProcedure";
import AsyncImg from "../../components/AsyncImg";

class TaskDetailsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonLoading: false,
      openMainDropDown: false,
      approvedLoading: false,
      rejectLoading: false
    };
    this.setUsersElements = this.setUsersElements.bind(this);
  }

  componentDidMount() {
    this.props.fetchTask(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    const { match, fetchTask } = this.props;

    if (match.params.id !== prevProps.match.params.id) {
      fetchTask(match.params.id);
    }
  }

  completeTask = event => {
    this.props.showModal(
      <AttachmentModal
        onConfirm={(attachments, setLoading) => {
          this.setState({ buttonLoading: true });

          axios
            .post(
              `/Tasks/Completed/${this.props.task.Task.ID}`,

              cleanApiDataForm({ attachments }),
              {
                headers: { "content-type": "multipart/form-data" }
              }
            )
            .then(({ data }) => {
              this.setState({ buttonLoading: false });
              if (data.StatusCode === "Success") {
                setLoading(false);
                this.props.history.replace("/tasks");
              }
            });
        }}
        buttonLoading={this.state.buttonLoading}
      />
    );
  };

  onAcceptReject = (type, comment, setLoading) => {
    const { match } = this.props;
    this.setState({
      approvedLoading: type === "Approved",
      rejectLoading: type === "Rejected"
    });
    axios
      .post(`Tasks/Response`, {
        ID: match.params.id,
        Response: type,
        comment
      })
      .then(({ data }) => {
        if (data.StatusCode === "Success") {
          this.setState({
            approvedLoading: false,
            rejectLoading: false
          });
          setLoading && setLoading(false);
          this.props.history.replace("/tasks");
        }
      });
  };

  approveRejectTask = (type, comment) => {
    if (type === "Rejected") {
      this.props.showModal(
        <RejectModal
          onConfirm={(comment, setLoading) =>
            this.onAcceptReject(type, comment, setLoading)
          }
        />
      );
    } else {
      this.onAcceptReject(type, comment);
    }
  };

  dropDownList = [
    {
      id: 1,
      name: "edit",
      action: () => this.editTask()
    }
  ];

  editTask() {
    this.props.showModal(<AddTaskModal data={this.props.task.Task} />);
  }

  setUsersElements() {
    const types = ["Accountable", "Consulted", "Informed"];
    const { theme, locale, task } = this.props;
    const cleanTypes = types.filter(type => !!task.Task[type]);
    return cleanTypes.map(type => {
      if (Array.isArray(task.Task[type])) {
        return task.Task[type].map((user, i) => {
          return (
            <UserItem
              role={type}
              user={user}
              key={user.DisplayName + i}
              theme={theme}
              locale={locale}
              isNameClickable={user.isActiveDirectoryUser && user.Username}
            />
          );
        });
      } else {
        return (
          <UserItem
            role={type}
            user={task.Task[type]}
            key={task.Task[type].DisplayName + type}
            theme={theme}
            locale={locale}
            isNameClickable={
              task.Task[type].isActiveDirectoryUser && task.Task[type].Username
            }
          />
        );
      }
    });
  }

  render() {
    const {
      theme,
      intl,
      task,
      locale,
      taskAddComment,
      currentUser,
      withConfirmation,
      suffix
    } = this.props;
    const { Task } = task;

    if (task.Loading || !Task) return <TaskDetailsLoadingCard />;

    const status = theme.Tasks_Status[Task.Status];
    const completed = Task.Status === "Completed";
    const canEdit = theme.allowUpdateTask;
    const canProgress =
      !completed &&
      getPermission(currentUser, task.Task, ["Responsible", "Accountable"]);
    // const canComplete =
    //   !completed &&
    //   getPermission(currentUser, task.Task, ["Creator", "AssignedBy"]);

    const canComplete = task.Task.AllowCompleteTask;

    const Sector = theme.lookups.Sectors.find(
      item => item.ID === task.Task.SectorId
    );

    const Entity = theme.lookups.Entities.find(
      item => item.ID === task.Task.EntityId
    );

    const Department = theme.lookups.Departments.find(
      item => item.ID === task.Task.DepartmentId
    );

    return (
      <div>
        <article className="taskDetailsContainer card">
          <div className="row">
            <div className="column">
              {task.Task.MainImage ? (
                <div className="main-img">
                  <AsyncImg
                    img={
                      this.props.task.Task.MainImage &&
                      this.props.task.Task.MainImage.AttachmentURL
                    }
                    alt="sourceImg"
                  />
                </div>
              ) : null}
              <div className="description-container">
                <div className="descriptionRow">
                  <div className="taskDescriptionContainer">
                    <LocaleAwareText>
                      <h4
                        className="taskName bold"
                        style={{ color: theme.primary }}
                      >
                        {task.Task.NameAr}
                      </h4>
                    </LocaleAwareText>

                    <div
                      className="taskName createdBy"
                      style={{ color: theme.textGray }}
                    >
                      <FormattedMessage
                        id="adhoc.CreatedBy"
                        defaultMessage="Created By"
                      />
                      {task.Task.AssignedBy
                        ? getDisplayName(task.Task.AssignedBy, locale.ltr)
                        : getDisplayName(task.Task.Creator, locale.ltr)}
                    </div>

                    <div
                      className="taskName createdBy task-sector-container"
                      style={{ color: theme.textGray }}
                    >
                      <span>
                        <FormattedMessage
                          id="label.Sector"
                          defaultMessage="Sector"
                        />{" "}
                        :<span> {Sector?.Title}</span>
                      </span>
                    </div>

                    <div
                      className="taskName createdBy task-sector-container"
                      style={{ color: theme.textGray }}
                    >
                      <span>
                        <FormattedMessage
                          id="label.Entity"
                          defaultMessage="Entity"
                        />{" "}
                        :<span> {Entity?.Title}</span>
                      </span>
                    </div>

                    <div
                      className="taskName createdBy task-sector-container"
                      style={{ color: theme.textGray }}
                    >
                      <span>
                        <FormattedMessage
                          id="label.Department"
                          defaultMessage="Department"
                        />{" "}
                        :<span> {Department?.Title}</span>
                      </span>
                    </div>

                    <div
                      className="taskName createdBy task-sector-container"
                      style={{ color: theme.textGray }}
                    >
                      <span>
                        <FormattedMessage
                          id="label.taskType"
                          defaultMessage="taskType"
                        />{" "}
                        :
                        <span>
                          {" "}
                          {task.Task.TaskType &&
                            theme.Task_Type[task.Task.TaskType][
                              `Title${suffix}`
                            ]}
                        </span>
                      </span>
                    </div>
                    <div className="taskDescription">
                      <LocaleAwareText>
                        <p>{task.Task.DescriptionAr}</p>
                      </LocaleAwareText>
                    </div>
                  </div>
                </div>
                {canEdit &&
                task.Task.TaskCompletedRequestStatus !== "Approved" ? (
                  <div className="arrow">
                    <MenuButton
                      title="taskOptions"
                      options={this.dropDownList}
                    />
                  </div>
                ) : null}
              </div>
              {task.Task.Responsible ? (
                <div className="detailsContainer">
                  <ProgressAvatar
                    image={task.Task.Responsible.UserProfilePicture}
                    progress={this.props.task && this.props.task.Task.Progress}
                    trailColor={theme.accentColor}
                    pathColor={theme.Tasks_Status[Task.Status].Color}
                    alt={intl.formatMessage({
                      id: `taskItem.profilePicture`,
                      defaultMessage: "Profile Picture"
                    })}
                    isNameClickable={
                      task.Task.Responsible.isActiveDirectoryUser &&
                      task.Task.Responsible.Username
                    }
                  />
                  <div className="taskDetails">
                    <div className="row">
                      <div className="userDetails">
                        <p
                          className="userName detailsText"
                          style={{ color: theme.primary }}
                        >
                          {getDisplayName(task.Task.Responsible, locale.ltr)}
                        </p>
                        <div
                          className="date-container"
                          style={{ color: theme.textGray }}
                        >
                          <DaysSince startDate={task.Task.CreatedDate} />
                          <span className="dash">-</span>
                          <DaysLeft
                            endDate={task.Task.DueDate}
                            ltr={locale.ltr}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <AttachmentList
                photos={this.props.task.Task.Attachments}
                deletable={canEdit}
                type="Tasks"
              />
            </div>

            <div
              className={locale.ltr ? "second-column ltr" : "second-column"}
              style={{
                borderColor: theme.accentColor,
                backgroundColor: theme.lightGray
              }}
            >
              {task.Task.Accountable ||
              task.Task.Informed ||
              task.Task.Consulted ? (
                <div
                  className="users"
                  style={{
                    borderColor:
                      !canProgress && !canComplete
                        ? "transparent"
                        : theme.accentColor
                  }}
                >
                  <div className="related-member">
                    <p style={{ color: theme.textGray }}>
                      {intl.formatMessage({
                        id: "task.details.member",
                        defaultMessage: "related members"
                      })}
                    </p>
                  </div>
                  {task.Task.Responsible ? this.setUsersElements() : null}
                </div>
              ) : null}
              <div />

              <div
                className="progress-container"
                style={{
                  borderColor:
                    canComplete || task.Task.AllowNeedAction
                      ? theme.accentColor
                      : "transparent"
                }}
              >
                <ProgressSlider
                  canProgress={canProgress}
                  sliderValue={this.props.task && this.props.task.Task.Progress}
                  id={this.props.task.Task.ID}
                  ltr={locale.ltr}
                  disabled={!canProgress}
                />
              </div>

              {canComplete ? (
                <div className="task-complete">
                  <div className="button-container">
                    <Button
                      loading={this.state.buttonLoading}
                      onClick={() => withConfirmation(this.completeTask)}
                    >
                      {intl.formatMessage({
                        id: "task.details.complete",
                        defaultMessage: "task complete"
                      })}
                    </Button>
                  </div>
                </div>
              ) : null}
              {task.Task.AllowNeedAction && (
                <div className="button-action-container">
                  <Button
                    loading={this.state.approvedLoading}
                    onClick={() =>
                      withConfirmation(() => this.approveRejectTask("Approved"))
                    }
                    style={{ backgroundColor: "green" }}
                  >
                    <FormattedMessage id="label.accept" />
                  </Button>

                  <Button
                    loading={this.state.rejectLoading}
                    onClick={() => this.approveRejectTask("Rejected")}
                    style={{ backgroundColor: "red" }}
                  >
                    <FormattedMessage id="meeting-request-reject" />
                  </Button>
                </div>
              )}
            </div>
          </div>

          <ActionBar
            intl={intl}
            theme={theme}
            data={Task}
            color={status.Color}
            text={status[`Title${locale.suffix}`]}
            secondStatus={
              task.Task.TaskCompletedRequestStatus !== "No Status" && {
                color:
                  task.Task.TaskCompletedRequestStatus &&
                  theme.TaskCompletedRequestStatus[
                    task.Task.TaskCompletedRequestStatus
                  ].Color,
                text:
                  task.Task.TaskCompletedRequestStatus &&
                  theme.TaskCompletedRequestStatus[
                    task.Task.TaskCompletedRequestStatus
                  ][`Title${locale.suffix}`]
              }
            }
          />

          <CommentsContainer
            addComment={taskAddComment}
            loading={task.CommentLoading}
            id={task.Task.ID}
            comments={task.Task.Comments}
            uploadIcon={true}
            disabled={task.Task.Status === "Completed"}
            progress={task.Progress}
            type="Tasks"
            users={cleanUsersForMention(getTaskUsers(Task))}
          />
          {task.Task.WorkflowHistories && task.Task.WorkflowHistories.length ? (
            <>
              <Tabs
                tabs={[{ name: "actions" }]}
                // selectTab={this.selectTab}
                selected="actions"
              />
              <PreviousProcedure
                data={task.Task.WorkflowHistories}
                stageTheme={theme.TaskWorkflow_Stage}
                statusTheme={theme.TaskCompletedRequestStatus}
              />
            </>
          ) : null}
        </article>
      </div>
    );
  }
}

TaskDetailsPage.propTypes = {
  intl: PropTypes.object,
  theme: PropTypes.object,
  task: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    ...state,
    currentUser: state.user.UserData.Username,
    suffix: state.locale.suffix
  };
};

const mapDispatchToProps = {
  fetchTask,
  showModal,
  taskAddComment,
  withConfirmation
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(TaskDetailsPage)));
