import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./style.scss";

class Circles extends Component {
  render() {
    const { theme, locale, big, user } = this.props;
    return (
      <div
        className={big ? "circles-container big" : "circles-container"}
        style={{
          borderColor: theme.accentColor,
          backgroundColor: !big && theme.primaryLight
        }}
      >
        {Object.keys(theme.RACI).map((type, index) => (
          <div
            key={index}
            className={
              locale.ltr
                ? "circle-text-container ltr"
                : "circle-text-container "
            }
            style={{ borderColor: theme.accentColor }}
          >
            <div
              className="circle"
              style={{ backgroundColor: theme.RACI[type].Color }}
            >
              <p>{user.Counts[`${type}Count`]}</p>
            </div>
            <div className="text">
              <p>
                {!big && locale.ltr
                  ? `${theme.RACI[type][`Title${locale.suffix}`].substring(
                      0,
                      4
                    )}.`
                  : theme.RACI[type][`Title${locale.suffix}`]}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

Circles.propTypes = {
  intl: PropTypes.object,
  theme: PropTypes.object,
  locale: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    user: state.user,
    theme: state.theme,
    locale: state.locale
  };
};

export default connect(
  mapStateToProps,
  null
)(Circles);
