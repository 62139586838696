import { USERS_LOADING, FETCH_USERS_ASYNC, USERS_FAILED } from "../actions";

const initialState = {
  Loading: false,
  Error,
  UsersList: [],
  User: null
};

function UsersList(state = initialState, action) {
  switch (action.type) {
    case FETCH_USERS_ASYNC:
      const data = Array.isArray(action.users)
        ? {
            UsersList: action.users
          }
        : { User: action.users };
      return {
        ...state,
        Loading: false,
        Error: "",
        ...data
      };
    case USERS_LOADING:
      return {
        ...state,
        Loading: true
      };
    case USERS_FAILED:
      return {
        ...state,

        Error: action.error
      };

    default:
      return state;
  }
}

export default UsersList;
