export const LOGIN = "LOGIN";
export const VERIFY = "VERIFY";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const VERIFY_FAILED = "VERIFY_FAILED";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const USER_BLOCK = "USER_BLOCK";
export const USER_LAST_ATTEMPT = "USER_LAST_ATTEMPT";
export const NEED_VERIFY = "NEED_VERIFY";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";
export const CLEAR_LOGIN = "CLEAR_LOGIN";

export function login(data) {
  return { type: LOGIN, data };
}

export function verify(data) {
  return { type: VERIFY, data };
}

export function loginFailed(error) {
  return { type: LOGIN_FAILED, error };
}

export function verifyFailed(error) {
  return { type: VERIFY_FAILED, error };
}

export function loginLoading() {
  return { type: LOGIN_LOADING };
}

export function userBlock(blockedUntil) {
  return { type: USER_BLOCK, blockedUntil };
}

export function userLastAttempt() {
  return { type: USER_LAST_ATTEMPT };
}

export function needVerify() {
  return { type: NEED_VERIFY };
}

export function clearLoginError() {
  return { type: CLEAR_LOGIN_ERROR };
}

export function clearLogin() {
  return { type: CLEAR_LOGIN };
}
