import React from "react";
import MeetingDetailsList from "../MeetingDetailsList";
import Table from "../Table";
import CommitteesCellProfile from "../CommitteesCellProfile";
import CommitteeAttachmentItem from "../CommitteeAttachmentItem";
import Folder from "../Folder";

const CommitteeTabs = props => {
  const { tab, committee, ltr } = props;
  switch (tab) {
    case "members":
      return (
        <Table data={committee.Members} Component={CommitteesCellProfile} />
      );
    case "meetings":
      return (
        <MeetingDetailsList
          list={committee.Meetings}
          request={false}
          committeesProfile={true}
        />
      );
    default:
      return (
        <div
          className={ltr ? "meetings-table photo ltr" : "meetings-table photo"}
        >
          {committee.Attachment.map((file, index) => {
            if (file.attachments)
              return <Folder key={index} folder={file} data={committee} />;
            return (
              <CommitteeAttachmentItem
                key={index}
                attachment={file}
                ltr={ltr}
              />
            );
          })}
        </div>
      );
  }
};

export default CommitteeTabs;
