import { SHOW_CONFIRMATION, HIDE_CONFIRMATION } from "../actions";

const initialState = {
  isOpen: false,
  Message: "",
  onConfirm: () => {}
};

function confirmation(state = initialState, action) {
  switch (action.type) {
    case SHOW_CONFIRMATION:
      return {
        isOpen: true,
        onConfirm: action.onConfirm,
        Message: action.message
      };
    case HIDE_CONFIRMATION:
      return initialState;
    default:
      return state;
  }
}

export default confirmation;
