import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import StepIcon from "../StepIcon";

import "./style.scss";
import sortBy from "lodash/sortBy";

class WorkFlowSteps extends Component {
  render() {
    const { theme, suffix, steps, currentStep, ltr } = this.props;

    return (
      <>
        <div
          className={
            ltr ? "workflow-steps-container ltr" : "workflow-steps-container"
          }
          style={{
            borderColor: theme.accentColor,
            backgroundColor: theme.primaryLight
          }}
        >
          <div className="workflow-steps">
            {sortBy(steps, ["Order"]).map((step, index) => (
              <div className="icon-with-line-container" key={index}>
                <div className="icon-with-line">
                  {step.Order !== 1 && (
                    <div
                      className="workflow-line"
                      style={{
                        backgroundColor:
                          step.status === "Pending" &&
                          step.AssignedTo !== currentStep
                            ? theme.accentColor
                            : theme.primary
                      }}
                    />
                  )}
                  <div className="text-with-icon-container">
                    <div className="text-with-icon">
                      <StepIcon step={step} currentStep={currentStep} />
                    </div>
                    <div className="responsible-container">
                      <p className="responsible">
                        {
                          theme.Workflow_Stage[step.AssignedTo][
                            `Title${suffix}`
                          ]
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

WorkFlowSteps.propTypes = {
  theme: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    theme: state.theme,
    suffix: state.locale.suffix,
    ltr: state.locale.ltr
  };
};

export default connect(
  mapStateToProps,
  null
)(WorkFlowSteps);
