import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TextTruncate from "react-text-truncate";
import "./style.scss";
import LocaleAwareText from "../LocaleAwareText";
import ProgressSlider from "../ProgressSlider";
import Badge from "../Badge";
import DaysSince from "../DaysSince";
import DaysLeft from "../DaysLeft";
import Button from "../Button";
import TimelineActionBar from "../TimelineActionBar";
import TextLogo from "../TextLogo";
import StatusIndicator from "../StatusIndicator";
import axios from "axios";
import { ReactComponent as ClockIcon } from "../../assets/images/clock-icon.svg";
import {
  getDisplayName,
  getPermission,
  getRoles,
  cleanApiDataForm
} from "../../utils";

import AttachmentModal from "../../components/TasksWorkFlowModals/AttachmentModal";
import RejectModal from "../../components/TasksWorkFlowModals/RejectModal";

import { showModal, withConfirmation, fetchTask } from "../../actions";

class TaskItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonLoading: false,
      approvedLoading: false,
      rejectLoading: false
    };
  }

  completeTask = () => {
    this.props.showModal(
      <AttachmentModal
        onConfirm={(attachments, setLoading) => {
          this.setState({ buttonLoading: true });

          axios
            .post(
              `/Tasks/Completed/${this.props.task.ID}`,

              cleanApiDataForm({ attachments }),
              {
                headers: { "content-type": "multipart/form-data" }
              }
            )
            .then(({ data }) => {
              this.setState({ buttonLoading: false });
              if (data.StatusCode === "Success") {
                setLoading(false);
                this.props.fetchTask();
                this.props.history.replace("/tasks");
              }
            });
        }}
        buttonLoading={this.state.buttonLoading}
      />
    );
  };

  onAcceptReject = (type, comment, setLoading) => {
    this.setState({
      approvedLoading: type === "Approved",
      rejectLoading: type === "Rejected"
    });
    axios
      .post(`Tasks/Response`, {
        ID: this.props.task.ID,
        Response: type,
        comment
      })
      .then(({ data }) => {
        if (data.StatusCode === "Success") {
          this.setState({
            approvedLoading: false,
            rejectLoading: false
          });
          setLoading && setLoading(false);
          this.props.fetchTask();
          this.props.history.replace("/tasks");
        }
      });
  };

  approveRejectTask = (type, comment) => {
    if (type === "Rejected") {
      this.props.showModal(
        <RejectModal
          onConfirm={(comment, setLoading) =>
            this.onAcceptReject(type, comment, setLoading)
          }
        />
      );
    } else {
      this.onAcceptReject(type, comment);
    }
  };
  render() {
    const {
      theme,
      intl,
      task,
      locale,
      currentUser,
      withConfirmation
    } = this.props;
    const status = theme.Tasks_Status[task.Status];
    const completed = task.Status === "Completed";
    const canProgress =
      !completed &&
      getPermission(currentUser, task, ["Responsible", "Accountable"]);
    const assigner = task.AssignedBy ? task.AssignedBy : task.Creator;

    const roles = getRoles(task, currentUser);

    return (
      <article
        className="taskItem "
        onClick={() =>
          this.props.history.push({
            pathname: `/tasks/${task.ID}`
          })
        }
      >
        <div className="task-item-container">
          <div
            className={
              task.MainImage ? "detailsContainer min" : "detailsContainer"
            }
          >
            <div className="task-item-details">
              <div className="task-title">
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex" }}>
                    <span
                      className="roleBadge"
                      style={{
                        background: `${status.Color}4C`,
                        color: status.Color
                      }}
                    >
                      {status[`Title${locale.suffix}`]}
                    </span>
                    <div className="title-with-slider">
                      <h4 className="des-height taskName bold">
                        {task.NameAr}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="actions-side">
              <div className="badges">
                <Badge
                  text={<DaysSince startDate={task.CreatedDate} />}
                  Icon={ClockIcon}
                  fixed
                />
                <Badge
                  text={<DaysLeft endDate={task.DueDate} ltr={locale.ltr} />}
                  Icon={ClockIcon}
                  fixed
                />
                {roles.length > 0 && (
                  <Badge
                    color="white"
                    backgroundColor={theme.RACI[roles[0]].Color}
                    text={theme.RACI[roles[0]][`Title${locale.suffix}`]}
                    fixed
                  />
                )}
              </div>
            </div>
          </div>

          <div className="text-logo-task">
            <div
              className="progress-slider-task"
              onClick={e => e.stopPropagation()}
            >
              <div className="progress-slider-task-container">
                <ProgressSlider
                  canProgress={canProgress}
                  sliderValue={task.Progress}
                  id={task.ID}
                  ltr={locale.ltr}
                  DueDate={task.DueDate}
                  status={task.Status}
                  disabled={!canProgress}
                  timeline
                  mini
                />
              </div>
            </div>
            <TextLogo
              text={`${intl.formatMessage({
                id: "title.addBy",
                defaultMessage: "Add by"
              })} : ${getDisplayName(assigner, locale.ltr)}`}
              img={assigner.UserProfilePicture}
            />
          </div>

          <div className="taskDescription">
            <LocaleAwareText text={task.DescriptionAr}>
              <TextTruncate
                line={3}
                truncateText="…"
                text={task.DescriptionAr}
              />
            </LocaleAwareText>
          </div>
          <div
            className={`action-container ${
              !task.AllowCompleteTask && !task.AllowNeedAction ? "end" : ""
            }`}
          >
            {task.TaskCompletedRequestStatus !== "No Status" &&
              task.TaskCompletedRequestStatus && (
                <StatusIndicator
                  text={
                    theme.TaskCompletedRequestStatus[
                      task.TaskCompletedRequestStatus
                    ][`Title${locale.suffix}`]
                  }
                  color={
                    theme.TaskCompletedRequestStatus[
                      task.TaskCompletedRequestStatus
                    ].Color
                  }
                />
              )}

            {task.AllowCompleteTask && false ? (
              <div className="approve-reject-buttons">
                <Button
                  loading={this.state.buttonLoading}
                  onClick={e => {
                    e.stopPropagation();
                    withConfirmation(() => {
                      this.completeTask();
                    });
                  }}
                >
                  {intl.formatMessage({
                    id: "task.details.complete",
                    defaultMessage: "task complete"
                  })}
                </Button>
              </div>
            ) : null}

            {task.AllowNeedAction && (
              <div className="approve-reject-buttons">
                <Button
                  loading={this.state.approvedLoading}
                  onClick={e => {
                    e.stopPropagation();
                    withConfirmation(() => {
                      this.approveRejectTask("Approved");
                    });
                  }}
                  style={{ backgroundColor: "green" }}
                >
                  <FormattedMessage id="label.accept" />
                </Button>

                <Button
                  loading={this.state.rejectLoading}
                  onClick={e => {
                    e.stopPropagation();
                    this.approveRejectTask("Rejected");
                  }}
                  style={{ backgroundColor: "red" }}
                >
                  <FormattedMessage id="meeting-request-reject" />
                </Button>
              </div>
            )}
          </div>
        </div>

        <TimelineActionBar
          intl={intl}
          theme={theme}
          post={{ ...task, TimelineType: "tasks" }}
          canComplete={false}
        />
      </article>
    );
  }
}

TaskItem.propTypes = {
  intl: PropTypes.object,
  theme: PropTypes.object,
  task: PropTypes.object
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    locale: state.locale,
    theme: state.theme,
    user: state.user,
    currentUser: state.user.UserData.Username
  };
};

const mapDispatchToProps = {
  showModal,
  withConfirmation,
  fetchTask
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(TaskItem)));
